import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import RecommendationPlan from "./Recommendation/Plan";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moralePlan from "./Recommendation/moralePlan.json";
import engagementPlan from "./Recommendation/engagementPlan.json";
import retentionPlan from "./Recommendation/retentionPlan.json";
import eNPSPlan from "./Recommendation/eNPSPlan.json";
import topicPlan from "./Recommendation/topicPlan.json";
import culturePlan from "./Recommendation/culturePlan.json";
import SidePanel from "./Recommendation/SidePanel";
import SavedPlanList from "./SavedPlanList";
import Button from "@mui/material/Button";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ResultsList from "./ResultsList";
import PlanDetails from "./SavedPlanDetails";
import RecommendedPlanDetails from "./RecommendedPlanDetails";
import SavedPlanDetails from "./SavedPlanDetails";
import CustomActionPlanModal from "./Recommendation/popup/CustomActionPlanModal";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { demoActionPlans } from "./Recommendation/demoActionPlans";
import Step1Modal from "../Walkthrough/Recommendation/Step1Modal";
import Step2Modal from "../Walkthrough/Recommendation/Step2Modal";
import Step3Modal from "../Walkthrough/Recommendation/Step3Modal";
import ResultDetails from "./ResultDetails";
import AssistantBox from "../Overview/AssistantBox";

function RecommendationSection() {
  const [currentMetric, setCurrentMetric] = useState("morale");
  const [currentPlans, setCurrentPlans] = useState([]);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  useEffect(() => {
    currentMetric === "morale"
      ? setCurrentPlans(
          actionPlans.filter(
            (item) => item.section === "Morale" || item.section === "morale"
          )
        )
      : currentMetric === "engagement"
      ? setCurrentPlans(
          actionPlans.filter((item) => item.section === "engagement")
        )
      : currentMetric === "retention"
      ? setCurrentPlans(
          actionPlans.filter((item) => item.section === "retention")
        )
      : currentMetric === "eNPS"
      ? setCurrentPlans(actionPlans.filter((item) => item.section === "enps"))
      : currentMetric === "topic"
      ? setCurrentPlans(actionPlans.filter((item) => item.section === "topic"))
      : currentMetric === "culture"
      ? setCurrentPlans(
          actionPlans.filter(
            (item) =>
              item.section === "culture" || item.section === "overallCulture"
          )
        )
      : setCurrentPlans(
          actionPlans.filter(
            (item) => item.section === "Morale" || item.section === "morale"
          )
        );
  }, [currentMetric, actionPlans]);

  const [selectedTab, setSelectedTab] = useState("recommendation");
  const [selectedPlan, setSelectedPlan] = useState("");
  // useEffect(() => {
  //   if (window.location.href.split("/")[6] === "recommendation") {
  //     setSelectedTab("recommendation");
  //   } else if (window.location.href.split("/")[6] === "saved") {
  //     setSelectedTab("saved");
  //   } else if (window.location.href.split("/")[6] === "result") {
  //     setSelectedTab("result");
  //   }
  // });

  useEffect(() => {
    if (window.location.href.split("/")[5] === "recommendation") {
      setSelectedTab("recommendation");
    } else if (window.location.href.split("/")[5] === "saved") {
      setSelectedTab("saved");
    } else if (window.location.href.split("/")[5] === "result") {
      setSelectedTab("result");
    }
  });

  useEffect(() => {
    window.location.href.split("/")[5] !== "recommendation" &&
    window.location.href.split("/")[5] !== "saved" &&
    window.location.href.split("/")[5] !== "result" &&
    window.location.href.split("/")[5]
      ? setSelectedPlan(window.location.href.split("/")[5])
      : setSelectedPlan("");
  });

  const [customModalOpen, setCustomModalOpen] = useState(false);

  let skipped = !JSON.parse(localStorage.getItem("skipped"));

  const [openIntroTutModal, setOpenIntroTutModal] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  const handleCloseIntroTutModal = () => {
    setOpenIntroTutModal(false);
  };

  return (
    <Box>
      {skipped ? (
        <Modal
          open={openIntroTutModal}
          onClose={handleCloseIntroTutModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {currentStep === 1 ? (
            <Step1Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          ) : currentStep === 2 ? (
            <Step2Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          ) : currentStep === 3 ? (
            <Step3Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          ) : (
            <Step3Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Modal>
      ) : (
        ""
      )}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <SidePanel
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </Grid>
        <Grid item xs={10}>
          {selectedTab === "recommendation" && selectedPlan === "" && (
            <Box my={4} style={{ background: "#fff", padding: "2rem" }}>
              <div>
                <AssistantBox page="insight" />
              </div>{" "}
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => {
                    setCustomModalOpen(true);
                  }}
                  variant="contained"
                  style={{
                    background: "#D06283",
                    color: "#fff",
                    margin: "1rem 0.5rem",
                  }}
                >
                  Create Custom Action Plan
                </Button>
                <Modal
                  open={customModalOpen}
                  onClose={() => {
                    setCustomModalOpen(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <CustomActionPlanModal
                    handleClose={() => {
                      setCustomModalOpen(false);
                    }}
                  />
                </Modal>
              </Box>
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={8}>
                  <Typography variant="h4" style={{ textAlign: "left" }}>
                    {currentMetric === "morale"
                      ? "Morale Action Plans"
                      : currentMetric === "engagement"
                      ? "Engagement Action Plans"
                      : currentMetric === "retention"
                      ? "Retention Action Plans"
                      : currentMetric === "eNPS"
                      ? "eNPS Action Plans"
                      : currentMetric === "topic"
                      ? "Topic Action Plans"
                      : currentMetric === "culture"
                      ? "Culture Action Plans"
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box style={{ display: "flex", justifyContent: "end" }}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Metric
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentMetric}
                        label="Metric"
                        onChange={(e) => {
                          setCurrentMetric(e.target.value);
                        }}
                      >
                        <MenuItem value={"morale"}>Morale</MenuItem>
                        <MenuItem value={"engagement"}>Engagement</MenuItem>
                        <MenuItem value={"retention"}>Retention</MenuItem>
                        <MenuItem value={"eNPS"}>eNPS</MenuItem>
                        {/* <MenuItem value={"topic"}>Topic</MenuItem> */}
                        <MenuItem value={"culture"}>Culture</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="stretch">
                {!_.isEmpty(currentPlans) &&
                  currentPlans
                    .reverse()
                    .filter((item) => item.status === "recommended")
                    .map((plan, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <RecommendationPlan
                          key={index}
                          plan={plan}
                          page="recommendation"
                        />{" "}
                      </Grid>
                    ))}
              </Grid>
            </Box>
          )}
          {selectedTab === "recommendation" && selectedPlan !== "" && (
            <RecommendedPlanDetails planID={selectedPlan} />
          )}
          {selectedTab === "saved" && selectedPlan === "" && (
            <Box style={{ background: "#fff", margin: "2rem 0" }}>
              {" "}
              <SavedPlanList />
            </Box>
          )}
          {selectedTab === "saved" && selectedPlan !== "" && (
            <Box style={{ background: "#fff", margin: "2rem 0" }}>
              {" "}
              <SavedPlanDetails planID={selectedPlan} />
            </Box>
          )}

          {selectedTab === "result" && selectedPlan === "" && (
            <Box style={{ background: "#fff", margin: "2rem 0" }}>
              {" "}
              <ResultsList />
            </Box>
          )}
          {selectedTab === "result" && selectedPlan !== "" && (
            <Box style={{ background: "#fff", margin: "2rem 0" }}>
              {" "}
              <ResultDetails planID={selectedPlan} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecommendationSection;
