import React, { useState, useMemo } from "react";
import "../styles/loginPage.css";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import Select from "react-select";
import api from "../services/axiosConfig";
import LoadingButton from "../Components/common/LoadingButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { AsYouType } from "libphonenumber-js";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StoreIcon from '@mui/icons-material/Store';
import axios from "axios";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LockPersonIcon />,
    2: <AddBusinessIcon />,
    3: <StoreIcon />,
    4: <CheckCircleOutlineIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Enter Personal Details",
  "Enter Business Details",
  "Edit Business Details",
  "All Set!"
];

const SignUpPage = () => {
  const history = useHistory();
  const base_url =process.env.REACT_APP_BASE_URL;
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [UserStatus, setUserStatus] = useState("");
  const [countryVal, setCountryVal] = useState({
    value: "US",
    label: "United States",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [companyDetailsFromAI, setCompanyDetailsFromAI] = useState({
    "company_name": "",
    "description": "",
    "location": "",
    "number_of_employees": "",
    "industry": "",
    "founded_year": "",
    "website": ""
  })
  const countryChangeHandler = (country) => {
    setCountryVal(country);
  };
  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const standardEmail =
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;
    const isValid = standardEmail.test(Email);
    if (isValid) {
      api
        .post("/checkDomainExists", { email: Email })
        .then((response) => {
          if (response?.data?.message === "Company Doesn't exists in DB") {
            setActiveStep(1);
          }
        })
        .catch((error) => {
          setUserStatus(error?.response?.data.error);
        });
      setUserStatus("");
    } else {
      setUserStatus("Please enter your business email address.");
    }
  };

  const checkCompany = async () => {
    const res = await axios.post(`${base_url}/openai/query`, {
      query: `Given the following input, generate the company details (return only JSON no other messages) fromat in a similar format as described below:

Input:
${JSON.stringify(
        {
          company: {
            country: countryVal.label,
            name: company.trim(),
            address: address,
            countryCode: getCountryCode(`+${phoneNumber}`),
          }
        }
      )}

Return the output with dynamically generated company details:
{
  "company_name": "[Generated Company Name]",
  "description": "[Generated Description]",
  "location": "[Generated Location]",
  "number_of_employees": [Generated Number of Employees],
  "industry": "[Generated Industry]",
  "founded_year": [Generated Founded Year],
  "website": "[Generated Website URL]"
}`,
      context: [],
    });

    setCompanyDetailsFromAI(JSON.parse(res.data.data.choices[0].message.content));
    setActiveStep(2)
  }


  const emailHandler = (event) => {
    setEmail(event.target.value);
  };
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };
  const confirmPasswordHandler = (event) => {
    setConfirmPassword(event.target.value);
  };
  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };
  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };
  const companyHandler = (event) => {
    setCompany(event.target.value);
  };
  const addressHandler = (event) => {
    setAddress(event.target.value);
  };
  function getCountryCode(input) {
    // Set default country code to US if no real country code is specified
    const defaultCountryCode = input.substr(0, 1) !== "+" ? "US" : null;
    let formatted = new AsYouType(defaultCountryCode).input(input);
    let countryCode = "";
    let withoutCountryCode = formatted;

    if (defaultCountryCode === "US") {
      countryCode = "+1";
      formatted = "+1 " + formatted;
    } else {
      const parts = formatted.split(" ");
      countryCode = parts.length > 1 ? parts.shift() : "";
      withoutCountryCode = parts.join(" ");
    }

    return countryCode;
  }
  const formHandler = async () => {
    setIsLoading(true);
    if (Email && Password && firstName && ConfirmPassword && company) {
      if (ConfirmPassword !== Password) {
        setUserStatus("Password not match");
      } else {
        const user = {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: Email.toLowerCase().trim(),
          password: Password,
          company: {
            phoneNumber: phoneNumber,
            country: countryVal.label,
            name: company.trim(),
            address: address,
            countryCode: getCountryCode(`+${phoneNumber}`),
          },
        };

        api
          .post("/register", user)
          .then((res) => {
            history.push("/successful-registration");
            setIsLoading(false);
          })
          .catch((error) => {
            setUserStatus(error?.response?.data?.message);
            setIsLoading(false);
          });
      }
    } else {
      setUserStatus("Input All Fields");
    }
  };

  return (
    <div className="loginPage">
      <div className="formSignContainer">
        <div className="formLogo">
          <img src="/Logo-3x.png" alt="logo" width="180px" />
        </div>
        <Stack sx={{ width: "100%", marginTop: "1rem" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{ color: "#fff" }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {" "}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>

        <React.Fragment>
          <form className="loginForm" method="post" action="/register">
            {activeStep === 0 && (
              <>
                <div className="inputContainer">
                  <label className="formLabels">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter your first name"
                    name="first-name"
                    required
                    value={firstName}
                    onChange={(event) => {
                      firstNameHandler(event);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter your last name"
                    name="last-name"
                    required
                    value={lastName}
                    onChange={(event) => {
                      lastNameHandler(event);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    required
                    value={Email}
                    onChange={(event) => {
                      emailHandler(event);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    required
                    value={Password}
                    onChange={(event) => {
                      passwordHandler(event);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Enter password again"
                    name="confirm-psw"
                    required
                    value={ConfirmPassword}
                    onChange={(event) => {
                      confirmPasswordHandler(event);
                    }}
                  />
                </div>
                <LoadingButton
                  className="submitButton"
                  style={{ marginTop: "2rem" }}
                  onClick={handleNext}
                >
                  <div className="buttonText">Next</div>
                </LoadingButton>
              </>
            )}
            {activeStep === 1 && (
              <>
                <div className="inputContainer">
                  <label className="formLabels">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter your company name"
                    name="company"
                    required
                    value={company}
                    onChange={(event) => {
                      companyHandler(event);
                    }}
                  />
                </div>

                <div id="countryFlag" className="inputContainer">
                  <label className="formLabels" htmlFor="number">
                    Country
                  </label>
                  <div className="countryContainer">
                    <Select
                      isSearchable
                      options={countryOptions}
                      value={countryVal}
                      onChange={(value) => countryChangeHandler(value)}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label className="formLabels" htmlFor="number">
                    Phone Number
                  </label>
                  <PhoneInput
                    country="us"
                    className="phoneNumber"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event)}
                  />
                </div>
                <label className="termsContainer">
                  <input type="checkbox" name="terms" />
                  <span className="termsMessage">
                    I accept all the terms and conditions
                  </span>
                </label>
                <LoadingButton
                  className="submitButton"
                  loading={isLoading}
                  onClick={checkCompany}
                // onClick={(e) => formHandler(e)}
                >
                  <div className="buttonText">Next</div>
                </LoadingButton>
                <div
                  to="/"
                  className="backBtn"
                  style={{ margin: "1rem 0" }}
                  onClick={(e) => setActiveStep(0)}
                >
                  <div
                    role="presentation"
                    style={{ margin: "0rem 0", cursor: "pointer" }}
                  >
                    <div className="buttonText" style={{ color: "#f37907" }}>
                      Back
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeStep === 2 && (
              <>
                <div className="inputContainer">
                  <label className="formLabels">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter your company name"
                    name="company"
                    required
                    value={companyDetailsFromAI.company_name}
                    onChange={(event) => {
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, company_name: event.target.value });
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Description</label>
                  <textarea
                    type="text"
                    placeholder="Enter your company description"
                    name="company"
                    style={{ minHeight: "100px", border: "1px solid #484848 !important" }}
                    required
                    value={companyDetailsFromAI.description}
                    onChange={(event) => {
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, description: event.target.value });
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Location</label>
                  <input
                    type="text"
                    placeholder="Enter your company location"
                    name="company"
                    required
                    value={companyDetailsFromAI.location}
                    onChange={(event) => {
                      setAddress(event.target.value)
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, location: event.target.value });
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Number of Employees</label>
                  <input
                    type="text"
                    placeholder="Enter the number of employees"
                    name="company"
                    required
                    value={companyDetailsFromAI.number_of_employees}
                    onChange={(event) => {
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, number_of_employees: event.target.value });
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Industry</label>
                  <input
                    type="text"
                    placeholder="Enter your Industry"
                    name="company"
                    required
                    value={companyDetailsFromAI.industry}
                    onChange={(event) => {
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, industry: event.target.value });
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label className="formLabels">Website</label>
                  <input
                    type="text"
                    placeholder="Enter your website"
                    name="company"
                    required
                    value={companyDetailsFromAI.website}
                    onChange={(event) => {
                      setCompanyDetailsFromAI({ ...companyDetailsFromAI, website: event.target.value });
                    }}
                  />
                </div>


                <label className="termsContainer">
                  <input type="checkbox" name="terms" />
                  <span className="termsMessage">
                    I accept all the terms and conditions
                  </span>
                </label>
                <LoadingButton
                  className="submitButton"
                  loading={isLoading}
                  onClick={(e) => formHandler(e)}
                >
                  <div className="buttonText">Register</div>
                </LoadingButton>
                <div
                  to="/"
                  className="backBtn"
                  style={{ margin: "1rem 0" }}
                  onClick={(e) => setActiveStep(0)}
                >
                  <div
                    role="presentation"
                    style={{ margin: "0rem 0", cursor: "pointer" }}
                  >
                    <div className="buttonText" style={{ color: "#f37907" }}>
                      Back
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Typography
                  className="signupText"
                  sx={{ margin: "2rem auto 1rem" }}
                >
                  Signed-up successfully!
                </Typography>{" "}
                <Typography className="signupText" sx={{ margin: " 0 auto" }}>
                  You will be redirected to login page in 5 seconds
                </Typography>{" "}
              </>
            )}

            {UserStatus.length > 0 && (
              <div className="errorMessage">{UserStatus}</div>
            )}
          </form>
        </React.Fragment>
      </div>
      <div>
        {activeStep !== 2 && (
          <span className="signupText">
            Already have an account?{" "}
            <Link to="/" style={{ color: "#8A7BE0" }}>
              Login
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
