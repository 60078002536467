import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
// import CheckIcon from "@material-ui/icons/Check";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#484848",
    },
  },
}));

export default function TrialModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 500 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <LockClockOutlinedIcon
            style={{ color: "#484848", margin: "auto", fontSize: "60px" }}
          />
          <p
            style={{
              color: "#484848",
              fontSize: "20px",
              fontWeight: "bold",
              margin: "1rem auto",
              textAlign: "center",
            }}
          >
            Your trial period has ended!
          </p>
          <p
            style={{
              color: "#484848",
              fontSize: "12px",
              margin: "auto",
              textAlign: "center",
            }}
          >
            Please upgrade to a paid plan for a full acces to the dashboard
          </p>
          <button
            onClick={handleClose}
            style={{
              color: "#fff",
              margin: "1rem auto",
              borderRadius: "12px",
              background: "#3629b7",
              padding: "0.5rem",
              border: "none",
            }}
          >
            View Details
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
