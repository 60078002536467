import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import moment from "moment";
import { debounce } from "lodash";
import "./index.css";
import CompareOptions from "../../../common/CompareOptions";
import api from "../../../../services/axiosConfig"
import {  generateMoraleScore } from "../demoData";
import EventDrop from "../../../common/Annotation/EventDrop";
import StatsOnTrendline from "../../../common/StatsOnTrendline";

const getAppliedFilterKeys = (selectedOptions, filterMapping) => {
  const appliedFilterNames = Object.keys(selectedOptions)
    .filter((filterKey) => {
      const selectedValues = selectedOptions[filterKey] || [];
      // Get the keys from filterMapping that match the selected values
      const filterValues = filterMapping[filterKey] || {};

      // For each selected value, find the key from the filterMapping
      return selectedValues.some((value) => {
        return Object.keys(filterValues).some((key) => filterValues[key].includes(value));
      });
    })
    .flatMap((filterKey) => {
      const selectedValues = selectedOptions[filterKey] || [];
      const filterValues = filterMapping[filterKey] || {};

      // For each selected value, map it to the key in filterMapping
      return selectedValues
        .map((value) => {
          const matchedKey = Object.keys(filterValues).find((key) => filterValues[key].includes(value));
          return matchedKey; // Return the key name from filterMapping
        })
        .filter(Boolean); // Filter out any undefined values
    });

  return [...new Set(appliedFilterNames)].join(", ");
};

function MoraleCompare({
  currentWorkflowID,
  currentFilter,
  customDate,
}) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [clipProcessing, setClipProcessing] = useState(false);

  const goals = useSelector((data) => data.dashboard.goals);

  //copy component to clipboard
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const moraleCompareRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleCompareRef.current);
    setClipProcessing(false);
    image && clip(image);
  };
  useEffect(() => {
    image && clip(image);
  }, [image]);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const filters = useSelector((data) => data.dashboard?.filters);
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [overallTrends, setOverallTrends] = useState([]);

  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  // useEffect(() => {
  //   const temp = [];
  //   publicHolidaySwitch &&
  //     publicHolidays.forEach((holiday) => {
  //       temp.push({
  //         x: new Date(holiday.date).getTime(),
  //         borderColor: "#d06283",
  //         label: {
  //           borderRadius: 4,
  //           position: "bottom",
  //           style: {
  //             color: "#fff",
  //             opacity: "0.3",
  //             fontSize: "13px",
  //             background: "rgba(208, 98, 131, 0.8)",
  //           },
  //           text: holiday.name,
  //         },
  //       });
  //     });
  //   calenderEvents.forEach((item) => {
  //     temp.push({
  //       x: new Date(item.date).getTime(),
  //       borderColor: item.color,
  //       label: {
  //         borderRadius: 4,
  //         position: "bottom",
  //         style: {
  //           color: "#fff",
  //           opacity: "0.3",
  //           fontSize: "13px",
  //           background: item.color,
  //         },
  //         text: item.name,
  //       },
  //     });
  //   });
  //   setAnnotations(temp);
  // }, [publicHolidays, calenderEvents]);

  useEffect(() => {
    if (isDemo) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, []);

  var options = {
    annotations: {
      xaxis: annotations,
      yaxis: [
        {
          y: goals.morale,
          borderColor: "#00E396",
          strokeWidth: 5,
          borderWidth: 3,
          label: {
            borderColor: "#00E396",
            style: {
              color: "#484848",
              background: "#00E396",
            },
            text: `Goal - ${goals.morale}`,
          },
        },
      ],
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetY: -10,
        tools: {
          download: false, // <== line to add
        },
      },
      zoom: {
        allowMouseWheelZoom: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1F3BB3"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: [2, 3],
      strokeWidth: 1,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && Math.round(value);
        },
      },
      title: {
        text: "Average Morale Score",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return val && Math.round(val);
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontWeight: 400,
      offsetX: 0,
      offsetY: 20,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 2,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };


  const [filterToCompare, setFiltersToCompare] = useState(["Overall"]);

  const filterMapping = useSelector((data) => data.dashboard.filterMapping);

  const [selectedOptions1, setSelectedOptions1] = useState({});

  const handleCheckboxChange = (filterKey, optionKey, isChecked, setSelectedOptions) => {
    setSelectedOptions((prevState) => {
      const currentValues = prevState[filterKey] || [];
      const mappedValues = filterMapping[filterKey][optionKey];

      return {
        ...prevState,
        [filterKey]: isChecked
          ? [...currentValues, ...mappedValues.filter((value) => !currentValues.includes(value))]
          : currentValues.filter((value) => !mappedValues.includes(value)),
      };
    });
  };

  const handleCheckbox1Change = (filterKey, optionKey, isChecked) => {
    handleCheckboxChange(filterKey, optionKey, isChecked, setSelectedOptions1);

  };

  const [selectedOptions2, setSelectedOptions2] = useState({});
  const handleCheckbox2Change = (filterKey, optionKey, isChecked) => {
    handleCheckboxChange(filterKey, optionKey, isChecked, setSelectedOptions2);

  };

  const [dataSeries1, setDataSeries1] = useState([])
  const [dataSeries2, setDataSeries2] = useState([])
  const [overallData, setOverallData] = useState([])

  const getOverallData = async (filter) => {
    setIsLoading(true)
    if (isDemo) {
      setOverallData(generateMoraleScore(currentFilter, customDate).moraleScoreAvg || [])
    } else {
      try {
        const res = await api.post("/getMoraleScores", {
          "workflowID": "overall",
          "timeOption": "all",
          "channels": [],
          "location": [],
          "ethnicity": [],
          "marital_status": [],
          "region": [],
          "length_of_service": [],
          "management_level": [],
          "income": [],
          "age": [],
          "term": "",
          "engagementTopic": "",
          "trendingTopic": "",
          startDate:
            currentFilter === "max"
              ? ""
              : currentFilter === "1Y"
                ? new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                : currentFilter === "3M"
                  ? new Date(new Date().setMonth(new Date().getMonth() - 3))
                  : currentFilter === "1M"
                    ? new Date(new Date().setMonth(new Date().getMonth() - 1))
                    : currentFilter === "5D"
                      ? new Date(new Date().setHours(new Date().getHours() - 24 * 5))
                      : currentFilter === "1D"
                        ? new Date(new Date().setHours(new Date().getHours() - 24))
                        : currentFilter === "custom"
                          ? new Date(customDate.start)
                          : "",
          endDate:
            currentFilter === "max"
              ? ""
              : currentFilter === "custom"
                ? new Date(customDate.end)
                : new Date(),
        }, {
          timeout: 500000,
          headers: {
            "Access-Control-Allow-Headers": "x-access-token",
            "x-access-token": localStorage.getItem("auth-token"),
          },
        })
        if (res.status === 200) {
          setOverallData(res.data.moraleScoreAvg || [])
        }
      } catch (error) {
        setIsFailed(true)
        console.error("Error fetching data:", error);
      }
    }
    setIsLoading(false)
  }

  const getCompareData1 = debounce(async () => {
    if (isDemo) {
      setDataSeries1(generateMoraleScore(currentFilter, customDate).moraleScoreAvg || [])
    } else {
      try {
        const res = await api.post("/getMoraleScores", {
          "workflowID": "overall",
          "timeOption": "all",
          "channels": [],
          "startDate": "",
          "endDate": "",
          "location": [],
          "ethnicity": [],
          "marital_status": [],
          "region": [],
          "length_of_service": [],
          "management_level": [],
          "income": [],
          "age": [],
          "term": "",
          "engagementTopic": "",
          "trendingTopic": "",
          ...selectedOptions1
        }, {
          timeout: 500000,
          headers: {
            "Access-Control-Allow-Headers": "x-access-token",
            "x-access-token": localStorage.getItem("auth-token"),
          },
        })
        if (res.status === 200) {
          setDataSeries1(res.data.moraleScoreAvg || [])
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, 300);

  const getCompareData2 = debounce(async () => {
    if (isDemo) {
      setDataSeries2(generateMoraleScore(currentFilter, customDate).moraleScoreAvg || [])
    } else {
      try {
        const res = await api.post("/getMoraleScores", {
          "workflowID": "overall",
          "timeOption": "all",
          "channels": [],
          "startDate": "",
          "endDate": "",
          "location": [],
          "ethnicity": [],
          "marital_status": [],
          "region": [],
          "length_of_service": [],
          "management_level": [],
          "income": [],
          "age": [],
          "term": "",
          "engagementTopic": "",
          "trendingTopic": "",
          ...selectedOptions2
        }, {
          timeout: 500000,
          headers: {
            "Access-Control-Allow-Headers": "x-access-token",
            "x-access-token": localStorage.getItem("auth-token"),
          },
        })
        if (res.status === 200) {
          setDataSeries2(res.data.moraleScoreAvg || [])

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, 300);

  useEffect(() => {
    getOverallData()
  }, [currentFilter, customDate]);

  useEffect(() => {
    getCompareData1()
  }, [selectedOptions1, currentFilter, customDate]);

  useEffect(() => {
    getCompareData2()
  }, [selectedOptions2, currentFilter, customDate]);

  const [series, setSeries] = useState([]);

  useEffect(() => {
    let series = []
    if (overallData.length > 0) {
      const temp = overallData?.filter(
        (item) =>
          new Date(item._id) < new Date() &&
          item.morale_avg !== null
      )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .map((item) => {
          return {
            y: item.morale_avg,
            x: new Date(item._id),
          };
        });
      series.push({
        name: "Overall",
        color: "#D06283",
        data: temp,
      });
    }
    if (dataSeries1.length > 0 && !_.isEmpty(selectedOptions1)) {
      const temp = dataSeries1?.filter(
        (item) =>
          new Date(item._id) < new Date() &&
          item.morale_avg !== null
      )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .map((item) => {
          return {
            y: item.morale_avg,
            x: new Date(item._id),
          };
        });
      series.push({
        name: getAppliedFilterKeys(selectedOptions1, filterMapping),
        color: "#89788D",
        data: temp,
      });
    }
    if (dataSeries2.length > 0 && !_.isEmpty(selectedOptions2)) {
      const temp = dataSeries2?.filter(
        (item) =>
          new Date(item._id) < new Date() &&
          item.morale_avg !== null
      )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .map((item) => {
          return {
            y: item.morale_avg,
            x: new Date(item._id),
          };
        });
      series.push({
        name: getAppliedFilterKeys(selectedOptions2, filterMapping),
        color: "#04B8AF",
        data: temp,
      });
    }
    setSeries(series)
  }, [overallData, dataSeries1, dataSeries2])
  console.log(series)

  return (
    <div
      ref={moraleCompareRef}
      style={{
        height: "425px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",

        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      {" "}
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Morale Comparison
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Morale Score Comparison by Groups
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p>
        <div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          {/* <StatsOnTrendline userCount={teamSize} textCount={totalText} /> */}
        </div>
        <div style={{ display: "flex" }}>
          <CompareOptions
            filterToCompare={filterToCompare}
            setFiltersToCompare={setFiltersToCompare}
            selectedOptions1={selectedOptions1}
            selectedOptions2={selectedOptions2}
            handleCheckbox1Change={handleCheckbox1Change}
            handleCheckbox2Change={handleCheckbox2Change}
          />
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Copy to clipboard
              </span>
            }
          >
            {clipProcessing ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <ContentCopyOutlinedIcon
                onClick={getImage}
                style={{
                  fontSize: "1.2rem",
                  cursor: clipProcessing ? "wait" : "pointer",
                  margin: "0 1rem "
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>
      {!isLoading ? (
        <div style={{ position: "relative", top: "30px" }}>
          <div className="morale-compare-container">
            <Chart
              options={options}
              series={series}
              type="line"
              width="100%"
              height="330px"
            />
          </div>
        </div>
      ) : isFailed ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {"No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default MoraleCompare;
