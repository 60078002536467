import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Button from "@mui/material/Button";
import { demoSurveys } from "../demoSurveys";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import LaunchModal from "../Popups/LaunchModal";
import ArchiveModal from "../Popups/ArchiveModal";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import AddIcon from "@mui/icons-material/Add";
import InsightsIcon from "@mui/icons-material/Insights";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import RepeatIcon from "@mui/icons-material/Repeat";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import CustomSurveyModal from "../Popups/CustomSurveyModal";
import { titleCase } from "../../../../utilities/dashboardUtils";
import { Link } from "react-router-dom";
import BaselineList from "./BaselineList";
import FavoriteModal from "../Popups/FavoriteModal";
const tableHead = {
  surveyName: "Name",
  section: "Metric",
  statement: "Key Insight",
  tags: "Target Group",
  status: "Status",
  action: "Action",
};

const metricMapping = {
  morale: ["Morale Score", "Morale"],
  engagement: [
    "User Engagement",
    "User Engagement Score",
    "Overall Engagement Score",
    "Text Engagement",
    "Text Engagement Score",
  ],
  retention: ["Retention Risk"],
  eNPS: ["eNPS Engagement Topic Based Score", "eNPS Score"],
  topic: [],
  culture: [
    "Adaptability",
    "Celebrating Diversity",
    "Collaboration",
    "Community Involvement",
    "Constructive Feedback",
    "Creativity and Innovation",
    "Empathy",
    "Employee Recognition",
    "Gratitude and Appreciation",
    "Hate Speech",
    "Identity Hate",
    "Inclusive Language",
    "Insult",
    "Insults",
    "Obscene",
    "Open Communication",
    "Overall Positive/Negative Culture",
    "Personal Development",
    "Professionalism",
    "Racism",
    "Sexual Behaviour",
    "Sexual Harassment",
    "Supportive Language",
    "Team Celebrations",
    "Textual Insult",
    "Toxic",
    "Toxic Behaviour",
    "Transparency",
    "Wellness and Health",
    "Work-Life Balance",
  ],
};

function ResultList({ setSeletedSurvey }) {
  const location = useLocation();

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [currentMetric, setCurrentMetric] = useState("morale");
  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);
  const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);
  const [surveyWithResult, setSurveyWithResult] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState(
    cloneDeep(surveyWithResult.slice(0, countPerPage))
  );

  const [currentList, setCurrentList] = useState("insight");

  const searchData = useRef(
    throttle((val) => {
      const data = surveyWithResult;
      const query = val.toLowerCase();
      setCurrentPage(1);
      const byID = cloneDeep(
        data
          .filter((item) => item._id.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byName = cloneDeep(
        data
          .filter((item) => item.surveyName.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byStatus = cloneDeep(
        data
          .filter((item) => item.status.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byModel = cloneDeep(
        data
          .filter((item) => item.section.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );

      const byInsightText = cloneDeep(
        data
          .filter((item) => item.statement.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byInsightID = cloneDeep(
        data
          .filter((item) => item.insightID.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection([
        ...byID,
        ...byName,
        ...byStatus,
        ...byModel,
        ...byInsightText,
        ...byInsightID,
      ]);
    }, 400)
  );

  useEffect(() => {
    // Update currentList based on the current URL
    const path = location.pathname.split("/").pop(); // Get the last part of the path
    switch (path) {
      case "insights-surveys":
        setCurrentList("insight");
        break;
      case "baseline-surveys":
        setCurrentList("baseline");
        break;
      case "recurring-surveys":
        setCurrentList("recurring");
        break;
      case "custom-surveys":
        setCurrentList("custom");
        break;
      case "archived-surveys":
        setCurrentList("archived");
        break;
      default:
        setCurrentList("insight");
    }
  }, [location.pathname]);

  useEffect(() => {
    setSurveyWithResult(
      surveyData
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .filter(
          (item) =>
            item.questions[0].response.length > 0 &&
            item.status !== "archived" &&
            // new Date(item.expiryDate) > new Date() &&
            metricMapping[currentMetric].includes(item.section)
        )
    );
  }, [surveyData, currentMetric]);

  useEffect(() => {
    setCollection(cloneDeep(surveyWithResult.slice(0, countPerPage)));
  }, [surveyWithResult]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(surveyWithResult.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/feedback/result/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Show
            </Button>
          </Link>

          <Button
            variant="contained"
            onClick={() => {
              setFavoriteModalOpen(true);
            }}
            style={{
              background: "#D06283",
              textTransform: "capitalize",
              minWidth: "150px",
              opacity:
                key.status === "running" || key.status === "archived"
                  ? "0.7"
                  : "1",
              pointerEvents:
                key.status === "running" || key.status === "archived"
                  ? "none"
                  : "",
            }}
          >
            Add to Recurring
          </Button>
          <Modal
            open={favoriteModalOpen}
            onClose={() => {
              setFavoriteModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <FavoriteModal
              surveyID={key._id}
              handleClose={() => {
                setFavoriteModalOpen(false);
              }}
            />
          </Modal>
        </Stack>
      ) : keyD === "statement" ? (
        <td key={i}>{`${key[keyD]} ID:${key["insightID"]}`}</td>
      ) : keyD === "tags" ? (
        <td key={i}>
          {key?.display?.target_group
            ? key?.display?.target_group
            : key[keyD] === ""
            ? "Overall"
            : titleCase(key[keyD])}
        </td>
      ) : keyD === "status" ? (
        <td key={i}>{titleCase(key[keyD])}</td>
      ) : keyD === "date" ? (
        <td key={i}>{moment(key[keyD]).format("MM-DD-YYYY")}</td>
      ) : keyD === "surveyName" ? (
        <td key={i}>{key?.display?.name ? key?.display?.name : key[keyD]}</td>
      ) : (
        <td style={{ cursor: "pointer" }} key={i}>
          {key[keyD]}
        </td>
      );
    });

    return <tr key={index}>{columnData}</tr>;
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  return (
    <Box>
      <Box
        sx={{
          height: "50px",
          padding: "2rem 1rem 0",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Link
            to={`/dashboard/feedback/result/insights-surveys`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              // onClick={() => {
              //   setCurrentList("insight");
              // }}
              variant={currentList === "insight" ? "contained" : "outlined"}
              startIcon={<InsightsIcon />}
              style={
                currentList === "insight"
                  ? {
                      background: "#D06283",
                      textTransform: "capitalize",
                      margin: "0 0.5rem",
                      fontSize: "1.1rem",
                    }
                  : {
                      border: "1px solid #D06283",
                      color: "#484848",
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      margin: "0 0.5rem",
                    }
              }
            >
              Insight
            </Button>
          </Link>{" "}
          <Link
            to={`/dashboard/feedback/result/baseline-surveys`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              // onClick={() => {
              //   setCurrentList("baseline");
              // }}
              variant={currentList === "baseline" ? "contained" : "outlined"}
              startIcon={<MonitorHeartIcon />}
              style={
                currentList === "baseline"
                  ? {
                      background: "#D06283",
                      textTransform: "capitalize",
                      margin: "0 0.5rem",
                      fontSize: "1.1rem",
                    }
                  : {
                      border: "1px solid #D06283",
                      color: "#484848",
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      margin: "0 0.5rem",
                    }
              }
            >
              Baseline
            </Button>{" "}
          </Link>
          <Link
            to={`/dashboard/feedback/result/recurring-surveys`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              // onClick={() => {
              //   setCurrentList("recurring");
              // }}
              variant={currentList === "recurring" ? "contained" : "outlined"}
              startIcon={<RepeatIcon />}
              style={
                currentList === "recurring"
                  ? {
                      background: "#D06283",
                      textTransform: "capitalize",
                      margin: "0 0.5rem",
                      fontSize: "1.1rem",
                    }
                  : {
                      border: "1px solid #D06283",
                      color: "#484848",
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      margin: "0 0.5rem",
                    }
              }
            >
              Recurring
            </Button>{" "}
          </Link>
          <Link
            to={`/dashboard/feedback/result/custom-surveys`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              // onClick={() => {
              //   setCurrentList("custom");
              // }}
              variant={currentList === "custom" ? "contained" : "outlined"}
              startIcon={<SmartButtonIcon />}
              style={
                currentList === "custom"
                  ? {
                      background: "#D06283",
                      textTransform: "capitalize",
                      margin: "0 0.5rem",
                      fontSize: "1.1rem",
                    }
                  : {
                      border: "1px solid #D06283",
                      color: "#484848",
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      margin: "0 0.5rem",
                    }
              }
            >
              Custom
            </Button>{" "}
          </Link>
          <Link
            to={`/dashboard/feedback/result/archived-surveys`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              // onClick={() => {
              //   setCurrentList("archived");
              // }}
              variant={currentList === "archived" ? "contained" : "outlined"}
              startIcon={<ArchiveIcon />}
              style={
                currentList === "archived"
                  ? {
                      background: "#D06283",
                      textTransform: "capitalize",
                      margin: "0 0.5rem",
                      fontSize: "1.1rem",
                    }
                  : {
                      border: "1px solid #D06283",
                      color: "#484848",
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      margin: "0 0.5rem",
                    }
              }
            >
              Archived
            </Button>{" "}
          </Link>
        </Box>
      </Box>

      {currentList === "insight" && (
        <Box>
          <Box style={{ margin: "2rem 2rem 0" }}>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "left" }}
            >
              Insight Surveys Results
            </Typography>
          </Box>
          <Box className="survey">
            <div class="survey-search">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Metric</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentMetric}
                  label="Metric"
                  onChange={(e) => {
                    setCurrentMetric(e.target.value);
                  }}
                >
                  <MenuItem value={"morale"}>Morale</MenuItem>
                  <MenuItem value={"engagement"}>Engagement</MenuItem>
                  <MenuItem value={"retention"}>Retention</MenuItem>
                  <MenuItem value={"eNPS"}>eNPS</MenuItem>
                  <MenuItem value={"topic"}>Topics</MenuItem>
                  <MenuItem value={"culture"}>Culture</MenuItem>
                </Select>
              </FormControl>
              <input
                style={{ margin: "0 1rem", minWidth: "500px" }}
                placeholder="Search Surveys"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <table>
              <thead>
                <tr>{headRow()}</tr>
              </thead>
              <tbody className="survey-trhover">{tableData()}</tbody>
            </table>
            <Pagination
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={surveyWithResult.length}
            />
          </Box>
        </Box>
      )}

      {currentList === "baseline" && (
        <Box>
          <Box style={{ margin: "2rem 2rem 0" }}></Box>
          <BaselineList setSeletedSurvey={setSeletedSurvey} />
        </Box>
      )}
    </Box>
  );
}

export default ResultList;
