import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import api from "../../../../../services/axiosConfig";
import axios from "axios";
import {
  GET_ALL_SURVEYS,
  UPDATE_SURVEY_QUESTION,
} from "../../../../../redux/Dashboard/Dashboard.types";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditQuestion({ surveyID, question, handleClose }) {
  const dispatch = useDispatch();

  const base_url =process.env.REACT_APP_BASE_URL;
  const [questionText, setQuestionText] = useState(question.question);
  const [options, setOptions] = useState([]);
  const [optionsWithScore, setOptionsWithScore] = useState({});
  useEffect(() => {
    // Ensure options is an array
    if (Array.isArray(question.options)) {
      setOptions(question.options);
    } else if (typeof question.options === "object") {
      setOptions(Object.keys(question.options));
    }
  }, [question.options]);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleUpdateClick = async () => {
    const res = await axios.post(`${base_url}/openai/query`, {
      query: `${JSON.stringify({
        Question: questionText,
        Options: options,
      })} Change this to this format with their score based on the positive or negative response
      {"Least": -2, "Slightly Less": -1, "Neutral": 0, "Slightly More": 1, "Most": 2}
      return only a json`,
      context: [],
    });
    if (res.status === 200) {
      setOptionsWithScore(JSON.parse(res.data.data.choices[0].message.content));
    }
    // handleUpdate({ question: questionText, options });
  };

  useEffect(() => {
    if (!_.isEmpty(optionsWithScore)) {
     
      dispatch({
        type: UPDATE_SURVEY_QUESTION,
        payload: {
          surveyID: surveyID,
          questionID: question.questionID,
          newQuestion: questionText,
          options: optionsWithScore,
        },
      });
      setTimeout(() => {
        dispatch({
          type: GET_ALL_SURVEYS,
        });
        handleClose();
      }, 2000);
    }
  }, [optionsWithScore]);

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Edit Question
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="column" spacing={2}>
          <TextField
            id="question-text"
            label="Question"
            variant="outlined"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
          {options.map((option, idx) => (
            <>
              {option !== "Other" && (
                <TextField
                  key={idx}
                  label={`Option ${idx + 1}`}
                  variant="outlined"
                  value={option}
                  onChange={(e) => handleOptionChange(idx, e.target.value)}
                />
              )}
            </>
          ))}
        </Stack>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          style={{ background: "#D06283", color: "#fff" }}
          variant="contained"
          onClick={handleUpdateClick}
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
}

EditQuestion.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.string),
    ]).isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default EditQuestion;
