import { ICONS } from "../AppConfig";

export function getIcon(app) {
  return ICONS[app.toUpperCase()];
}

export function SessionOut() {
  console.log("Session Out")
  localStorage.removeItem("auth-token");
  localStorage.removeItem("userData");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("isDemo");

  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export function getLengthOfService(arr) {
  const temp = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === "0-1") {
      temp.push({ min: 0, max: 1 });
    } else if (arr[i] === "1-5") {
      temp.push({ min: 1, max: 5 });
    } else if (arr[i] === "5-9") {
      temp.push({ min: 5, max: 9 });
    } else if (arr[i] === "10+") {
      temp.push({ min: 10, max: 100 });
    }
  }
  return temp;
}

export function getIncome(arr) {
  const temp = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === "0-50000") {
      temp.push({ min: 0, max: 50000 });
    } else if (arr[i] === "50001-100000") {
      temp.push({ min: 50001, max: 100000 });
    } else if (arr[i] === "100001-250000") {
      temp.push({ min: 100001, max: 250000 });
    } else if (arr[i] === "250,001+") {
      temp.push({ min: 250001, max: 1000000000 });
    }
  }
  return temp;
}

export function getAge(arr) {
  const temp = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === "0-25") {
      temp.push({ min: 0, max: 25 });
    } else if (arr[i] === "26-35") {
      temp.push({ min: 26, max: 35 });
    } else if (arr[i] === "36-49") {
      temp.push({ min: 36, max: 49 });
    } else if (arr[i] === "50+") {
      temp.push({ min: 50, max: 120 });
    }
  }
  return temp;
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}


export const filterToNumber = (filter) => {
  return filter === "1Y"
    ? 365
    : filter === "3M"
      ? 90
      : filter === "1M"
        ? 30
        : filter === "5D"
          ? 5
          : filter === "1D"
            ? 1
            : 365;
};


export const getStartandEndDate = (filter) => {
  return filter === "1Y"
    ? { start: new Date(), end: new Date() }
    : filter === "3M"
      ? 90
      : filter === "1M"
        ? 30
        : filter === "5D"
          ? 5
          : filter === "1D"
            ? 1
            : 365;
}