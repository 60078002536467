import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import { ADD_DEMO_FILTERS } from "../../../../../redux/Dashboard/Dashboard.types";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditFiltersModal({ handleClose }) {
  const dispatch = useDispatch();
  const base_url = process.env.REACT_APP_BASE_URL;
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const selectedFilters = useSelector((data) => data.dashboard.selectedFilters);
  const filterMapping = useSelector((data) => data.dashboard.filterMapping);
  const availableFilters = useSelector(
    (data) => data.dashboard.availableFilters
  );

  const [selected, setSelected] = useState(selectedFilters);

  const toggleFilter = (filter) => {
    setSelected((prevSelected) =>
      prevSelected.includes(filter)
        ? prevSelected.filter((f) => f !== filter)
        : [...prevSelected, filter]
    );
  };

  const handleSave = () => {
    isDemo && dispatch({ type: ADD_DEMO_FILTERS, payload: selected });
    setTimeout(() => {
      toast.success("Filters saved successfully");
      handleClose();
    }, 1000);
  };

  function removeUnderscoreAndCapitalize(str) {
    return str
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back together with spaces
  }
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Edit Filters
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />

      <Box style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        {Object.keys(availableFilters).map((item) => (
          <Box style={{ margin: "0.5rem" }}>
            <Accordion sx={{ width: "100%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#484848",
                      margin: "0 0.5rem",
                    }}
                  />
                }
                sx={{
                  backgroundColor: "#F6F7FC !important",
                  color: "#484848",
                  width: "100%",
                  padding: "0 0.5rem",
                  textAlign: "left",
                }}
              >
                <Box style={{ display: "flex", justifyContent: "start" }}>
                  <Checkbox
                    checked={selected.includes(item)}
                    onChange={() => toggleFilter(item)}
                  />
                  <p style={{ margin: "0.7rem 0 0" }}>
                    {removeUnderscoreAndCapitalize(item)}
                  </p>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#F6F7FC",
                  color: "#999 !important",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1rem",
                    margin: "0 2rem",
                  }}
                >
                  {filterMapping[item] &&
                    Object.keys(filterMapping[item]).map((item) => (
                      <Typography>
                        {removeUnderscoreAndCapitalize(item)}
                      </Typography>
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default EditFiltersModal;
