import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demoSurveys } from "../demoSurveys";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import InputLabel from "@mui/material/InputLabel";
import Markdown from "react-markdown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GET_FILTER_EMAILS } from "../../../../redux/Dashboard/Dashboard.types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import moment from "moment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";

function Result({ surveyID }) {
  const dispatch = useDispatch();
  const base_url = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [gender, setGender] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [managementLevel, setManagementLevel] = useState("");
  const [lengthOfService, setLengthOfService] = useState("");
  const [salary, setSalary] = useState("");
  const [age, setAge] = useState("");
  const [performance, setPerformance] = useState("");
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [alignment, setAlignment] = useState("summary");
  const [summarizedComments, setSummarizedComments] = useState("");
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const filterEmails = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.filterEmails);
  const currentSurvey = surveyData.filter((item) => item._id === surveyID)[0];
  const [result, setResult] = useState("");


  useEffect(() => {
    const payload = {
      workflowID: "overall",
      timeOption: "all",
      channels: [],
      gender: [],
      startDate: "",
      endDate: "",
      location: [],
      department: [],
      ethnicity: [],
      marital_status: [],
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      term: "",
    };
    if (gender !== "") {
      payload["gender"] = [gender];
    }
    if (department !== "") {
      payload["department"] = [department];
    }
    if (region !== "") {
      payload["region"] = [region];
    }
    if (location !== "") {
      payload["location"] = [location];
    }
    if (ethnicity !== "") {
      payload["ethnicity"] = [ethnicity];
    }
    if (maritalStatus !== "") {
      payload["marital_status"] = [maritalStatus];
    }
    if (managementLevel !== "") {
      payload["management_level"] = [managementLevel];
    }

    !isDemo &&
      dispatch({
        type: GET_FILTER_EMAILS,
        payload: payload,
      });
  }, [
    gender,
    department,
    region,
    location,
    ethnicity,
    maritalStatus,
    managementLevel,
  ]);

  const summarizeComments = async (comments) => {
    const res = await axios.post(`${base_url}/openai/query`, {
      query: `${JSON.stringify(
        comments
      )}, can you summarize these comments without revealing users email or name?`,
      context: [],
    });
    setSummarizedComments(res.data.data.choices[0].message.content);
  };

  useEffect(() => {
    currentSurvey &&
      currentSurvey?.comments?.length > 0 &&
      summarizeComments(currentSurvey.comments);
  }, [alignment]);

  return (
    <Box sx={{ padding: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "start" }}>

        <Button onClick={() => {
          history.goBack();
        }}
          variant="outlined"
          style={{
            padding: "0.5rem 0",
            border: "2px solid #D06283",
            color: "#D06283",
            margin: "1rem",
          }}
        >
          <ArrowBackIcon />
        </Button>
      </Box>
      {currentSurvey && (
        <Box>
          <Box>
            <Box
              sx={{
                padding: "1rem 1rem",
                backgroundColor: "#f9f9f9",
                border: "1px solid #dddddd",
                borderRadius: "4px",
                margin: " 1rem 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ width: "70%" }}>
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Survey Name: {currentSurvey?.display?.name ? currentSurvey?.display?.name : currentSurvey?.surveyName}
                </Typography>
                <Typography
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  {`Metric: ${currentSurvey?.section}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  Target:
                  {currentSurvey?.display?.target_group
                    ? currentSurvey?.display?.target_group
                    : currentSurvey?.tags?.length > 0
                      ? ` ${currentSurvey.tags}`
                      : " Overall"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  Purpose:
                  {currentSurvey.statement}
                </Typography>
                <Stack direction="row">
                  <Link
                    to={`/dashboard/feedback/result/${currentSurvey._id}`}
                    style={{ textDecoration: "none", color: "#484848" }}
                  >
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        textAlign: "left",
                        color: "#D06283",
                        cursor: "default",
                      }}
                    >
                      {`${currentSurvey.questions[0].response?.length} ${currentSurvey.questions[0].response?.length > 1
                          ? "Responses"
                          : "Response"
                        }`}
                    </Typography>
                  </Link>
                </Stack>
              </Box>
              <Box style={{ width: "30%" }}>
                <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
                  Start Date: {moment(currentSurvey.date).format("MM-DD-YYYY")}
                </Typography>

                <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
                  End Date:{" "}
                  {moment(currentSurvey.expiryDate).format("MM-DD-YYYY")}
                </Typography>
              </Box>
            </Box>
            {currentSurvey?.report && (
              <Box
                style={{
                  background: "#f9f9f9",
                  border: "1px solid #dddddd",
                  borderRadius: "4px",
                  margin: "1rem 0",
                  textAlign: "left",
                  padding: " 0.5rem 1rem",
                }}
              >
                <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Summary
                </Typography>
                <Markdown>{currentSurvey.report.data}</Markdown>
              </Box>
            )}
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Grid container spacing={2}>
                {!currentSurvey.tags.includes("gender") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        label="Gender"
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Gender</MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"non-binary"}>Non-Binary</MenuItem>
                        <MenuItem value={"transgender"}>Transgender</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("department") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "150px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Department
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={department}
                        label="Department"
                        onChange={(e) => {
                          setDepartment(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Department</MenuItem>
                        <MenuItem value={"Accounting and Finance"}>
                          Accounting and Finance
                        </MenuItem>
                        <MenuItem value={"Engineering"}>Engineering</MenuItem>
                        <MenuItem value={"Human Resource"}>
                          Human Resource
                        </MenuItem>
                        <MenuItem value={"Information Technology"}>
                          Information Technology
                        </MenuItem>
                        <MenuItem value={"Operations"}>Operations</MenuItem>
                        <MenuItem value={"Research and Development"}>
                          Research and Development
                        </MenuItem>
                        <MenuItem value={"Sales and Marketing"}>
                          Sales and Marketing
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("region") && (
                  <Grid item>
                    <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Region
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={region}
                        label="Region"
                        onChange={(e) => {
                          setRegion(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Region</MenuItem>
                        <MenuItem value={"Asia Pacific"}>Asia Pacific</MenuItem>
                        <MenuItem value={"Europe"}>Europe</MenuItem>
                        <MenuItem value={"Middle East/Africa"}>
                          Middle East/Africa
                        </MenuItem>
                        <MenuItem value={"North America"}>
                          North America
                        </MenuItem>
                        <MenuItem value={"South America"}>
                          South America
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("location") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Location
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={location}
                        label="Location"
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Location</MenuItem>
                        <MenuItem value={"Headquarters"}>Headquarters</MenuItem>
                        <MenuItem value={"Regional Office"}>
                          Regional Office
                        </MenuItem>
                        <MenuItem value={"Home office"}>Home office</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("ethnicity") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Race
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ethnicity}
                        label="Race"
                        onChange={(e) => {
                          setEthnicity(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Race</MenuItem>
                        <MenuItem value={"American Indian or Alaska Native"}>
                          American Indian or Alaska Native
                        </MenuItem>
                        <MenuItem value={"Asian"}>Asian</MenuItem>
                        <MenuItem value={"Black or African American"}>
                          Black or African American
                        </MenuItem>

                        <MenuItem value={"Hispanic or Latino"}>
                          Hispanic or Latino
                        </MenuItem>
                        <MenuItem
                          value={"Native Hawaiian or Other Pacific Islander"}
                        >
                          Native Hawaiian or Other Pacific Islander
                        </MenuItem>
                        <MenuItem value={"White"}>White</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("marital") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "150px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Marital Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={maritalStatus}
                        label="Location"
                        onChange={(e) => {
                          setMaritalStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={""}>Marital Status</MenuItem>
                        <MenuItem value={"Married"}>Married</MenuItem>
                        <MenuItem value={"Single"}>Single</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("management") && (
                  <Grid item>
                    <FormControl sx={{ minWidth: "200px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Management Level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={managementLevel}
                        label="Management Level"
                        onChange={(e) => {
                          setManagementLevel(e.target.value);
                        }}
                      >
                        {" "}
                        <MenuItem value={""}>Management Level</MenuItem>
                        <MenuItem value={"Executive"}>Executive</MenuItem>
                        <MenuItem value={"SVP"}>SVP</MenuItem>
                        <MenuItem value={"VP"}>VP</MenuItem>
                        <MenuItem value={"Director"}>Director</MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                        <MenuItem value={"Individual Contributor"}>
                          Individual Contributor
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!currentSurvey.tags.includes("length_of_service") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "180px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Length of Service
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={lengthOfService}
                        label="Management Level"
                        onChange={(e) => {
                          setLengthOfService(e.target.value);
                        }}
                      >
                        {" "}
                        <MenuItem value={""}>Length of Service</MenuItem>
                        <MenuItem value={"{ min: 0, max: 1 }"}>
                          {"<1 Year"}
                        </MenuItem>
                        <MenuItem value={"{ min: 1, max: 5 }"}>
                          {"1 Year - 5 Years"}
                        </MenuItem>
                        <MenuItem value={"{ min: 5, max: 9 }"}>
                          {"5 Years - 9 Years"}
                        </MenuItem>
                        <MenuItem value={"{ min: 10, max: 100 }"}>
                          {"10+ Years"}
                        </MenuItem>
                        <MenuItem value={"{ min: 0, max: 100 }"}>
                          {"Exited Employees"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {!currentSurvey.tags.includes("income") && (
                  <Grid item>
                    <FormControl sx={{ minWidth: "160px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Salary Level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={salary}
                        label="Management Level"
                        onChange={(e) => {
                          setSalary(e.target.value);
                        }}
                      >
                        {" "}
                        <MenuItem value={""}>Salary Level</MenuItem>
                        <MenuItem value={"{ min: 0, max: 50000 }"}>
                          {"< $50,000"}
                        </MenuItem>
                        <MenuItem value={"{ min: 50001, max: 100000 }"}>
                          {"$50,001 - $100,000"}
                        </MenuItem>
                        <MenuItem value={"{ min: 100001, max: 250000 }"}>
                          {"$100,001 - $250,000"}
                        </MenuItem>
                        <MenuItem value={"{ min: 250001, max: 100000000000 }"}>
                          {"$250,001 +"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {!currentSurvey.tags.includes("age") && (
                  <Grid item>
                    {" "}
                    <FormControl sx={{ minWidth: "160px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Management Level"
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                      >
                        {" "}
                        <MenuItem value={""}>Age</MenuItem>
                        <MenuItem value={"{ min: 0, max: 25 }"}>
                          {"< 25"}
                        </MenuItem>
                        <MenuItem value={"{ min: 26, max: 35 }"}>
                          {"26 - 35"}
                        </MenuItem>
                        <MenuItem value={"{ min: 36, max: 49 }"}>
                          {"36 - $49"}
                        </MenuItem>
                        <MenuItem value={"{ min: 50, max: 100 }"}>
                          {"50 +"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {!currentSurvey.tags.includes("performance") && (
                  <Grid item>
                    <FormControl sx={{ minWidth: "160px", margin: "0 0.5rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Performance
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={performance}
                        label="Management Level"
                        onChange={(e) => {
                          setPerformance(e.target.value);
                        }}
                      >
                        {" "}
                        <MenuItem value={""}>Performance</MenuItem>
                        <MenuItem value={"outstanding"}>Outstanding</MenuItem>
                        <MenuItem value={"very-satisfactory"}>
                          Very Satisfactory
                        </MenuItem>
                        <MenuItem value={"satisfactory"}>Satisfactory</MenuItem>
                        <MenuItem value={"unsatisfactory"}>
                          Unsatisfactory
                        </MenuItem>
                        <MenuItem value={"poor"}>Poor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          {currentSurvey?.questions?.length > 0 &&
            currentSurvey.questions.map((item, id) => {
              var options = {
                chart: {
                  type: "pie",
                },
                dataLabels: {
                  enabled: true,
                },
                fill: {
                  colors: [
                    "#27AE60",
                    "#2ECC71",
                    "#F1C40F",
                    "#E74C3C",
                    "#C0392B",
                  ],
                },
                plotOptions: {
                  pie: {
                    startAngle: -180,
                    endAngle: 180,
                    offsetY: 10,
                    dataLabels: {
                      offset: -30,
                    },
                  },
                },

                stroke: {
                  show: false,
                },
                grid: {
                  padding: {
                    // bottom: -80
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                    },
                  },
                ],
                legend: {
                  show: true,
                  position: "top",
                  horizontalAlign: "right",
                  fontSize: "14px",
                  fontWeight: 400,
                  // offsetY: 4,
                  labels: {
                    colors: ["#666"],
                    useSeriesColors: false,
                  },
                  markers: {
                    width: 14,
                    height: 14,
                    fillColors:  [
                      "#27AE60",
                      "#2ECC71",
                      "#F1C40F",
                      "#E74C3C",
                      "#C0392B",
                    ],
                    radius: 0,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0,
                  },
                  onItemClick: {
                    toggleDataSeries: true,
                  },
                  onItemHover: {
                    highlightDataSeries: true,
                  },
                },
                tooltip: {
                  fillSeriesColor: true,
                  y: {
                    formatter: function (value) {
                      return value;
                    },
                  },
                },
                style: {
                  fontSize: "12px",
                  color: "#333",
                },
                colors: ["#666", "#666"],
                labels: Object.keys(item.options),
              };

              var series = [];
              if (isDemo) {
                item.options &&
                  Object.keys(item.options).forEach((option, id) => {
                    console.log(item.response.filter(
                      (res) =>
                        Object.keys(res.answer ? res.answer : {})[0] ===
                        option
                    )?.length)
                    series.push(
                      item.response.filter(
                        (res) =>
                          Object.keys(res.answer ? res.answer : {})[0] ===
                          option
                      )?.length
                    );
                  });
              } else {
                item.options &&
                  Object.keys(item.options).forEach((option, id) => {
                    series.push(
                      item.response
                        .filter((item) => filterEmails.includes(item.email))
                        .filter(
                          (res) =>
                            Object.keys(res.answer ? res.answer : {})[0] ===
                            option
                        )?.length
                    );
                  });
              }

              return (
                <Box>
                  {!series.every((item) => item === 0) && (
                    <Box sx={{ margin: "1rem" }}>
                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        {`${id + 1}. ${item.question}`}
                      </Typography>
                      <Box>
                        {/* {item.response.map((item) => (
                  <Box sx={{ border: "1px solid #e5e5e5" }}>
                    <Stack direction="row" spacing={2}>
                      <Box>{item.email}</Box>
                      <hr /> <Box>{item.answer}</Box>
                    </Stack>
                  </Box>
                ))} */}
                        {
                          <Chart
                            options={options}
                            series={series}
                            type="pie"
                            width="100%"
                            height="300px"
                          />
                        }
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          {currentSurvey?.comments?.length > 0 && (
            <>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "1.5rem",
                  margin: "0.5rem 0",
                }}
              >
                Comments:
              </Typography>
              <Box style={{ display: "flex", justifyContent: "start" }}>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="summary">Summary</ToggleButton>
                  <ToggleButton value="general">Details</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box style={{ margin: "1rem 0" }}>
                {alignment === "general" &&
                  currentSurvey.comments.map((comment) => (
                    <Box style={{ display: "flex" }}>
                      {comment?.comment?.length > 0 && (
                        <Typography style={{ textAlign: "left" }}>
                          - {comment.comment}
                        </Typography>
                      )}
                    </Box>
                  ))}
                {alignment === "summary" && (
                  <Box style={{ display: "flex" }}>
                    {summarizedComments?.length > 0 && (
                      <Typography style={{ textAlign: "left" }}>
                        {summarizedComments}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Result;
