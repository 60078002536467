import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  GET_INSIGHT_SCHEDULE_SUCCESS,
  GET_MESSAGE_DELETION_INTERVAL_SUCCESS,
  GET_MINIMUM_USER_COUNT_SUCCESS,
  SET_MINIMUM_USER_COUNT,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function MessageDeletionModal({ plan, handleClose }) {
  const dispatch = useDispatch();
  const insightSchedule = useSelector((data) => data.dashboard.insightSchedule);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const messageInterval = useSelector((data) => data.dashboard.messageInterval);

  const [interval, setInterval] = useState(messageInterval);

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  const handleSave = () => {
    if (isDemo) {
      dispatch({
        type: GET_MESSAGE_DELETION_INTERVAL_SUCCESS,
        payload: interval,
      });
    } else {
      //   dispatch({
      //     type: SET_MINIMUM_USER_COUNT,
      //     payload: {
      //       MINIMUM_USER_COUNT: interval,
      //     },
      //   });
    }
    handleClose();
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Set Source Message Deletion Interval
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <hr />
      <Typography style={{ margin: "1rem 0", fontSize: "1rem" }}>
        Specify how long source messages should be retained before they are
        deleted. This helps manage storage efficiently and ensures compliance
        with your data retention policies.
      </Typography>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography>Number of Days</Typography>
        <TextField
          fullWidth
          id="question-text"
          type="number"
          variant="outlined"
          value={interval}
          onChange={handleChange}
        />
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default MessageDeletionModal;
