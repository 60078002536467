import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useSelector } from "react-redux";



const getAppliedFilterKeys = (selectedOptions, filterMapping) => {
  const appliedFilterNames = Object.keys(selectedOptions)
    .filter((filterKey) => {
      const selectedValues = selectedOptions[filterKey] || [];
      // Get the keys from filterMapping that match the selected values
      const filterValues = filterMapping[filterKey] || {};
      
      // For each selected value, find the key from the filterMapping
      return selectedValues.some((value) => {
        return Object.keys(filterValues).some((key) => filterValues[key].includes(value));
      });
    })
    .flatMap((filterKey) => {
      const selectedValues = selectedOptions[filterKey] || [];
      const filterValues = filterMapping[filterKey] || {};
      
      // For each selected value, map it to the key in filterMapping
      return selectedValues
        .map((value) => {
          const matchedKey = Object.keys(filterValues).find((key) => filterValues[key].includes(value));
          return matchedKey; // Return the key name from filterMapping
        })
        .filter(Boolean); // Filter out any undefined values
    });

  return [...new Set(appliedFilterNames)].join(", ");
};


const FilterRenderer = ({ selectedFilters, filterMapping, selectedOptions, handleCheckboxChange }) => {
  // const [selectedOptions, setSelectedOptions] = useState({});
  
  function removeUnderscoreAndCapitalize(str) {
    return str
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back together with spaces
  }
  return (
    <div>
      {selectedFilters.map((filterKey) => (
        (filterKey !== "age" && filterKey !== "income" && filterKey !== "experience") ? <div key={filterKey}>
          <p style={{ textAlign: "left", fontWeight: "Bold", margin: "0.5rem 0" }}>{removeUnderscoreAndCapitalize(filterKey)}</p>
          {filterMapping[filterKey] &&
            Object.keys(filterMapping[filterKey]).map((optionKey) => (
              <label key={optionKey} style={{ display: "block", textAlign: "left" }}>
                <input
                  type="checkbox"
                  value={optionKey}
                  style={{ textAlign: "left" }}
                  checked={
                    selectedOptions[filterKey]?.includes(optionKey) || false
                  }
                  onChange={(e) =>
                    handleCheckboxChange(filterKey, optionKey, e.target.checked)
                  }
                />
                {optionKey}
              </label>
            ))}
        </div> : <></>
      ))}
    </div>
  );
};

const App = ({ selectedOptions1, selectedOptions2, handleCheckbox1Change, handleCheckbox2Change }) => {
  // const selectedFilters = ["gender", "department", "age"];
  // const filterMapping = {
  //   gender: {
  //     Male: ["male"],
  //     Female: ["female"],
  //   },
  //   department: {
  //     Engineering: ["Engineering"],
  //     HR: ["HR"],
  //   },
  //   age: {
  //     "Below 25": { $lte: 25 },
  //     "26-35": { $gte: 26, $lte: 35 },
  //   },
  // };
  const selectedFilters = useSelector((data) => data.dashboard.selectedFilters);
  const filterMapping = useSelector((data) => data.dashboard.filterMapping);

  const [filter1Open, setFilter1Open] = useState(false)
  const [filter2Open, setFilter2Open] = useState(false)


  return (
    <Box style={{ display: "flex" }}>
      <Box>
        <div
          className="dropdown"
          style={{
            fontSize: "0.8rem",
            padding: "0.1rem 0.5rem",
            position: "relative",
            top: "1px",
          }}
          onClick={() => { setFilter1Open(!filter1Open) }}
        >
          <span style={{ position: "relative", top: "-5px" }}>
            {_.isEmpty(selectedOptions1) ? "Filter Set 1" : getAppliedFilterKeys(selectedOptions1, filterMapping)}
          </span>
          {filter1Open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        {filter1Open && (
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: "250px",
                height: "310px",
                background: "#fff",
                right: "200px",
                padding: "0.5rem 0.5rem",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "20px",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <FilterRenderer
                  selectedFilters={selectedFilters}
                  filterMapping={filterMapping}
                  selectedOptions={selectedOptions1}
                  handleCheckboxChange={handleCheckbox1Change}
                />
              </div>
            </div>
          </div>
        )}
      </Box>
      <Box>

        <div
          className="dropdown"
          style={{
            fontSize: "0.8rem",
            padding: "0.1rem 0.5rem",
            position: "relative",
            top: "1px",
          }}
          onClick={() => { setFilter2Open(!filter2Open) }}
        >
          <span style={{ position: "relative", top: "-5px" }}>
            {_.isEmpty(selectedOptions2) ? "Filter Set 2" : getAppliedFilterKeys(selectedOptions2, filterMapping)}
          </span>
          {filter2Open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        {filter2Open && (
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: "250px",
                height: "310px",
                background: "#fff",
                right: "0",
                padding: "0.5rem 0.5rem",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "20px",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <FilterRenderer
                  selectedFilters={selectedFilters}
                  filterMapping={filterMapping}
                  selectedOptions={selectedOptions2}
                  handleCheckboxChange={handleCheckbox2Change}
                />
              </div>
            </div>
          </div>
        )}
      </Box>

    </Box>
  );
};

export default App;
