import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import * as clipboard from "clipboard-polyfill";
import moment from "moment";
import { base64StringToBlob } from "blob-util";
import { SET_USER_ENGAGEMENT_PREDICTION } from "../../../../redux/Dashboard/Dashboard.types";
import { engagementPredictionData } from "../demoData";
import EventDrop from "../../../common/Annotation/EventDrop";
import StatsOnTrendline from "../../../common/StatsOnTrendline";

function EnagagementUsers({ currentWorkflowID, engagementData }) {
  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#d06283",
      "&:hover": {
        backgroundColor: alpha("#d06283", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#d06283",
    },
  }));

  const dispatch = useDispatch();
  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const moraleTrendRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleTrendRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const goals = useSelector((data) => data.dashboard.goals);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);

  const [AvgTrend, setAvgTrend] = useState([]);
  const [prediction, setPrediction] = useState([]);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const showPrediction = useSelector(
    (data) => data.dashboard.userEngagementPrediction
  );
  const [showEvents, setShowEvents] = useState(true);

  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    var tempAvgData = [];
    tempAvgData = engagementData?.UserWiseEngagement?.filter(
      (item) => new Date(item.date) < new Date()
    )
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: isDemo ? item.count : item.count,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    setAvgTrend(tempAvgData);
    var tempPredictionData = [];
    tempPredictionData = engagementPredictionData()
      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: isDemo ? item.count : item.count,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    showPrediction && setPrediction(tempPredictionData);
  }, [engagementData, filters, showPrediction]);

  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  useEffect(() => {
    const temp = [];
    publicHolidaySwitch &&
      publicHolidays.forEach((holiday) => {
        temp.push({
          x: new Date(holiday.date).getTime(),
          borderColor: "#d06283",
          label: {
            borderRadius: 4,
            position: "bottom",
            style: {
              color: "#fff",
              opacity: "0.3",
              fontSize: "13px",
              background: "rgba(208, 98, 131, 0.8)",
            },
            text: holiday.name,
          },
        });
      });
    calenderEvents.forEach((item) => {
      temp.push({
        x: new Date(item.date).getTime(),
        borderColor: item.color,
        label: {
          borderRadius: 4,
          position: "bottom",
          style: {
            color: "#fff",
            opacity: "0.3",
            fontSize: "13px",
            background: item.color,
          },
          text: item.name,
        },
      });
    });
    setAnnotations(temp);
  }, [publicHolidays, calenderEvents]);

  var options = {
    annotations: {
      xaxis: showEvents ? annotations : [],
      yaxis: [
        {
          y: goals.engagement.user,
          borderColor: "#135714",
          borderWidth: 3,
          label: {
            borderColor: "#135714",
            borderWidth: 3,
            style: {
              color: "#fff",
              background: "#135714",
            },
            text: `Goal - ${goals.engagement.user}`,
          },
        },
      ],
    },
    chart: {
      type: "area",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      zoom: {
        allowMouseWheelZoom: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#04B8AF"],
    markers: {
      size: [3, 3],
      strokeColors: ["#0396FE", "#d06283"],
      strokeWidth: 2,
      strokeOpacity: 0.3,
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return parseInt(value);
        },
      },
      title: {
        text: "Score",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
      dashArray: [0, 5],
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
      dashArray: [0, 5],
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return parseInt(val);
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
  };

  var series = [
    {
      name: "Engagement Score",
      data: AvgTrend,
    },
  ];
  if (showPrediction) {
    series.push({
      name: "Predicted Engagement Score",
      color: "#d06283",
      data: prediction,
    });
  }

  return (
    <div
      ref={moraleTrendRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            zIndex: 3,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          User Engagement Trend
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                % of Total Employees Messaging on the Communication Platform
                Over Time
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "2px",
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p>
        <div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          <StatsOnTrendline userCount={teamSize} textCount={totalText} />
        </div>
        <Box sx={{ display: "flex" }}>
        <FormControlLabel
            sx={{ position: "relative", top: "-8px" }}
            control={
              <PinkSwitch
                onChange={() => {
                  setShowEvents(!showEvents);
                }}
                checked={showEvents}
              />
            }
            label="Show Events"
          />
          <FormControlLabel
            sx={{ position: "relative", top: "-8px" }}
            control={
              <PinkSwitch
                onChange={() => {
                  dispatch({
                    type: SET_USER_ENGAGEMENT_PREDICTION,
                    payload: !showPrediction,
                  });
                }}
                checked={showPrediction}
              />
            }
            label="Prediction"
          />
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Copy to clipboard
              </span>
            }
          >
            {clipProcessing ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <ContentCopyOutlinedIcon
                onClick={getImage}
                style={{
                  fontSize: "1.2rem",
                  cursor: clipProcessing ? "wait" : "pointer",
                }}
              />
            )}
          </Tooltip>
        </Box>
      </div>
      {engagementData.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div className="morale-trend-container">
            <Chart
              options={options}
              series={series}
              type="area"
              width="100%"
              height="290px"
            />
          </div>
        </div>
      ) : engagementData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"220px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default EnagagementUsers;
