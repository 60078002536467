import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { demoSurveys } from "../../Feedback/demoSurveys";
import { demoActionPlans } from "../../Assistant/Recommendation/demoActionPlans";
import { Link } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
export default function ActionMenu({ insight }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [open, setOpen] = useState(false);

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const currentSurvey = surveyData.filter(
    (survey) => survey.insightID === insight.surveyID
  )[0];

  const currentActionPlan = actionPlans.filter(
    (plan) => plan.insightID === insight.surveyID
  )[
    actionPlans.filter((plan) => plan.insightID === insight.surveyID).length - 1
  ];

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <span style={{ position: "relative" }}>
      <span
        style={{
          fontSize: "0.8rem",
          padding: "0.1rem 0.5rem",
          position: "relative",
          top: "1px",
        }}
        onClick={toggleList}
        onMouseOver={() => {
          setOpen(true);
        }}
      >
        <span
          style={{
            position: "relative",
            left: "-10px",
            top: "2px",
            cursor: "pointer",
          }}
        >
          {/* {currentWorkflowName} */}
          <MoreVertIcon />
        </span>
      </span>
      {open && (
        <div
          style={{
            position: "absolute",
            zIndex: 700,
            right: "0",
          }}
        >
          <div
            style={{
              // width: "100px",
              // height: "100px",
              background: "#fff",
              right: "0",
              padding: "0.5rem 0",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              position: "relative",
              top: "0px",
              left: "70px",
            }}
          >
            <div>
              {_.isEmpty(currentSurvey) && (
                <MenuItem style={{ padding: "0 1rem" }}><NoteAddIcon
                // onClick={() => {
                //   setSurveyModalOpen(true);
                //   setCurrentInsight(item);
                // }}
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              />Create Survey</MenuItem>
              )}
              {!_.isEmpty(currentSurvey) && (
                <Link
                  to={`/dashboard/feedback/survey/${currentSurvey._id}`}
                  style={{
                    textDecoration: "none",
                    color: "#484848",
                  }}
                >
                  <MenuItem style={{ padding: "0 1rem" }}><FileOpenIcon
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                }}
              />View Survey</MenuItem>
                </Link>
              )}
              <MenuItem style={{ padding: "0 1rem" }}>
              <GroupsIcon
            // onClick={() => {
            //   setFocusModalOpen(true);
            //   setCurrentInsight(item);
            // }}
            style={{
              color: "#727272",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
          /> Create Focus Group
              </MenuItem>
              {/* <MenuItem style={{ padding: "0 1rem" }}>
                View Focus Group
              </MenuItem> */}
              {_.isEmpty(currentActionPlan) && (
                <MenuItem style={{ padding: "0 1rem" }}>
                  <CallToActionIcon
              // onClick={() => {
              //   setActionPlanModalOpen(true);
              //   setCurrentInsight(item);
              // }}
              style={{
                color: "#727272",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
            />Create Action Plan
                </MenuItem>
              )}
              {!_.isEmpty(currentActionPlan) && (
                <Link
                  to={`/dashboard/action-plan/${currentActionPlan._id}`}
                  style={{
                    textDecoration: "none",
                    color: "#484848",
                  }}
                >
                  <MenuItem style={{ padding: "0 1rem" }}>
                  <PendingActionsIcon
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                }}
              />View Action Plan
                  </MenuItem>
                </Link>
              )}
              {/* <MenuItem style={{ padding: "0 1rem" }}>
                Create 1:1 Interviews
              </MenuItem> */}

{/* dashboard/feedback/focus-chat/674e992e5f31af87aa1991b8 */}
<Link
                  to={`/dashboard/feedback/focus-chat/674e992e5f31af87aa1991b8`}
                  style={{
                    textDecoration: "none",
                    color: "#484848",
                  }}
                >

              <MenuItem style={{ padding: "0 1rem" }}>
              <InterpreterModeIcon
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                }}
              />View 1:1 Interview
              </MenuItem></Link>
            </div>
          </div>
        </div>
      )}
    </span>
  );
}
