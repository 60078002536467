/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* getAccessTokenApi(action) {
  let resp;
  // const data = {
  //   client_id: "2415696530561.2545040630322",
  //   client_secret: "0c1861725bb85a59215b9caffe69787b",
  //   code: action.payload.code,
  //   redirect_uri: "https://checkbackend.azurewebsites.net/oauth",
  //   grant_type: "authorization_code",
  // };

  const base_url =process.env.REACT_APP_BASE_URL;
  const params = new URLSearchParams(); 
  params.append("client_id", "2415696530561.2965925345619");
  params.append("client_secret", "43d62860657970a88f53bfeb7c22c0ed");
  params.append("code", action.payload.code);
  params.append("redirect_uri", `${base_url}/oauth`);
  params.append("grant_type", "authorization_code"); 
  delete api.defaults.headers.common.Authorization;
  yield api
    .post("https://slack.com/api/oauth.v2.access", params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // transformRequest: (data, headers) => {
      //   delete headers.common.Authorization;
      //   return [
      //     ...data,
      //     { "Content-Type": "application/x-www-form-urlencoded" },
      //   ];
      // },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Error in uploading file");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
      }
    })
    .catch((err) => {
      resp = err;
      toast.error("Data not found");
    });
  return resp;
}
