import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import ArchiveIcon from "@mui/icons-material/Archive";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import { demoSurveys } from "../demoSurveys";
import Modal from "@mui/material/Modal";
import LaunchModal from "../Popups/LaunchModal";
import ArchiveModal from "../Popups/ArchiveModal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import CustomSurveyModal from "../Popups/CustomSurveyModal";
import { titleCase } from "../../../../utilities/dashboardUtils";

const tableHead = {
  surveyName: "Name",
  section: "Metric",
  statement: "Key Insight",
  tags: "Target Group",
  status: "Status",
  action: "Action",
};
function CompletedList({ setSeletedSurvey }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState({});

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [collection, setCollection] = useState(
    cloneDeep(sortedData.slice(0, countPerPage))
  );

  const searchData = useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      const filteredData = sortedData.filter(
        (item) =>
          item._id.toLowerCase().includes(query) ||
          item.surveyName.toLowerCase().includes(query) ||
          item.status.toLowerCase().includes(query) ||
          item.section.toLowerCase().includes(query) ||
          item.statement.toLowerCase().includes(query) ||
          item.insightID.toLowerCase().includes(query)
      );
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
      setCurrentPage(1);
    }, 400)
  );

  const search = (query) => {
    const filteredData = sortedData.filter(
      (item) =>
        item._id.toLowerCase().includes(query) ||
        item.surveyName.toLowerCase().includes(query) ||
        item.status.toLowerCase().includes(query) ||
        item.section.toLowerCase().includes(query) ||
        item.statement.toLowerCase().includes(query) ||
        item.insightID.toLowerCase().includes(query)
    );
    setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    setCurrentPage(1);
  };

  useEffect(() => {
    setCollection(cloneDeep(sortedData.slice(0, countPerPage)));
  }, [sortedData]);

  useEffect(() => {
    let temp = surveyData
      .reverse()
      .filter((item) => new Date(item.expiryDate) < new Date());
    setSortedData(temp);
  }, [surveyData]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      search(value);
      // searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(sortedData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/feedback/survey/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{ border: "1px solid #D06283", color: "#484848" }}
            >
              Show
            </Button>
          </Link>
         
         
        </Stack>
      ) : keyD === "statement" ? (
        <td style={{ cursor: "pointer" }} key={i}>
          <Link
            to={`/dashboard/feedback/survey/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            {`${key[keyD]} ID:${key["insightID"]}`}
          </Link>
        </td>
      ) : keyD === "tags" ? (
        <td style={{ cursor: "pointer" }} key={i}>
          <Link
            to={`/dashboard/feedback/survey/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            {key[keyD].length > 0 ? titleCase(key[keyD]) : "Overall"}
          </Link>
        </td>
      ) : keyD === "status" ? (
        <td style={{ cursor: "pointer" }} key={i}>
          <Link
            to={`/dashboard/feedback/survey/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            {titleCase(key[keyD])}
          </Link>
        </td>
      ) : (
        <td style={{ cursor: "pointer" }} key={i}>
          <Link
            to={`/dashboard/feedback/survey/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            {key[keyD]}
          </Link>
        </td>
      );
    });

    return (
      <tr style={{ border: "1px solid #727272" }} key={index}>
        {columnData}
      </tr>
    );
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  return (
    <Box>
      {sortedData.length > 0 && (
        <Box>
          <Box
            sx={{
              height: "50px",
              padding: "2rem 1rem 0",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              {`Completed Surveys (${sortedData.length})`}
            </Typography>
          </Box>

          <Box className="survey">
            <div class="survey-search">
              <input
                placeholder="Search Surveys"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <table>
              <thead>
                <tr>{headRow()}</tr>
              </thead>
              <tbody className="survey-trhover">{tableData()}</tbody>
            </table>
            <Pagination
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={sortedData.length}
            />
          </Box>
        </Box>
      )}
      <Modal
        open={launchModalOpen}
        onClose={() => {
          setLaunchModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LaunchModal
          survey={currentSurvey}
          handleClose={() => {
            setLaunchModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={customModalOpen}
        onClose={() => {
          setCustomModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomSurveyModal
          handleClose={() => {
            setCustomModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={archiveModalOpen}
        onClose={() => {
          setArchiveModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ArchiveModal
          survey={currentSurvey}
          handleClose={() => {
            setArchiveModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default CompletedList;
