import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button } from "@material-ui/core";
const CustomLoader = () => {
  const dispatch = useDispatch();
  const minUserCount = useSelector((data) => data.dashboard.minUserCount);
  const filters = {
    gender: [],
    location: [],
    department: [],
    keyTopic: [],
    ethnicity: [],
    channels: [],
    marital_status: [],
    region: [],
    length_of_service: [],
    management_level: [],
    income: [],
    age: [],
    performance: [],
    trendingTopic: [],
    engagementTopic: [],
    generalTopic: [],
  };

  const handleClear = () => {
    dispatch({ type: "SET_FILTERS", payload: filters });
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        // bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        backgroundColor: "#f2f2f2",
        padding: "1rem",
        borderRadius: "8px",
        // filter: "blur(1rem)",
      }}
    >
      <span style={{ color: "#484848", textAlign: "center" }}>
        <WarningAmberIcon style={{ position: "relative", top: "5px" }} />{" "}
        Insufficient Group Size
      </span>
      <p style={{ color: "#484848", fontSize: "0.9rem", textAlign: "left" }}>
        {`The selected group does not meet the minimum sample size requirement of
        ${minUserCount}. To protect privacy, data for this group cannot be
        displayed.`}
      </p>
      <p style={{ color: "#484848", fontSize: "0.8rem", textAlign: "left" }}>
        Try removing one or two filters to increase the group size and display
        the data OR
      </p>
      <Button
        onClick={handleClear}
        style={{
          background: "#D06283",
          color: "#fff",
          textTransform: "capitalize",
        }}
        variant="contained"
      >
        Clear Filters
      </Button>
    </div>
  );
};
export default CustomLoader;
