import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";
import {
  SET_CURRENT_SOURCE,
  SET_LOADING,
} from "../../redux/Dashboard/Dashboard.types";
import Checkbox from "@mui/material/Checkbox";

export default function WorkflowSelect({
  workflowChangeHandler,
  currentWorkflowName,
  currentWorkflowID,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const workflows = useSelector((data) => data.dashboard.userWorkflows.data);
  const currentSource = useSelector((data) => data.dashboard.currentSource);

  const [open, setOpen] = useState(false);
  const [chatWork, setChatWork] = useState([]);
  const [zoomWork, setZoomWork] = useState([]);

  useEffect(() => {
    setChatWork(
      workflows.Workflows.filter((item) => item.source === "Archive")
    );
    setZoomWork(workflows.Workflows.filter((item) => item.source === "zoom"));
  }, [workflows]);

  const toggleList = () => {
    setOpen(!open);
  };

  const handleChange = (value) => {
    if (value === "slack") {
      history.push("/dashboard/slack");
    } else if (value === "teams") {
      history.push("/dashboard/teams");
    } else if (value === "archive") {
      history.push("/dashboard/archive");
    } else if (value === "zoom") {
      history.push("/dashboard/email");
    } else if (value === "glassdoor") {
      history.push("/dashboard/glassdoor");
    } else if (value === "teamblind") {
      history.push("/dashboard/teamblind");
    } else if (value === "overall") {
      history.push("/dashboard/overview");
    }
   dispatch({ type: SET_LOADING, payload: { status: true } });
    workflowChangeHandler(value);
    toggleList();
  };

  const [slackChecked, setSlackChecked] = useState(
    currentSource.includes("slack")
  );
  const [teamsChecked, setTeamsChecked] = useState(
    currentSource.includes("teams")
  );
  const [zoomChecked, setZoomChecked] = useState(
    currentSource.includes("zoom")
  );
  const [glassdoorChecked, setGlassdoorChecked] = useState(
    currentSource.includes("glassdoor")
  );
  const [blindChecked, setBlindChecked] = useState(
    currentSource.includes("teamblind")
  );
  const [warning, setWarning] = useState("");

  const validEntry =
    ((slackChecked || teamsChecked) &&
      (zoomChecked || blindChecked || glassdoorChecked)) ||
    (zoomChecked && (blindChecked || glassdoorChecked)) ||
    (blindChecked && (glassdoorChecked || zoomChecked)) ||
    (glassdoorChecked && (zoomChecked || blindChecked));
  const handleApply = () => {
    if (validEntry) {
      setWarning("Selected Sources cannot be combined");
    } else if (slackChecked || teamsChecked) {
      history.push("/dashboard/overview");
      if (slackChecked && teamsChecked) {
        dispatch({ type: SET_CURRENT_SOURCE, payload: ["slack", "teams"] });
      } else if (slackChecked) {
        dispatch({ type: SET_CURRENT_SOURCE, payload: ["slack"] });
      } else if (teamsChecked) {
        dispatch({ type: SET_CURRENT_SOURCE, payload: ["teams"] });
      }
    } else if (zoomChecked) {
      history.push("/dashboard/email");
      dispatch({ type: SET_CURRENT_SOURCE, payload: ["zoom"] });
    } else if (glassdoorChecked) {
      history.push("/dashboard/glassdoor");
      dispatch({ type: SET_CURRENT_SOURCE, payload: ["glassdoor"] });
    } else if (blindChecked) {
      history.push("/dashboard/teamblind");
      dispatch({ type: SET_CURRENT_SOURCE, payload: ["teamblind"] });
    }
    !validEntry && setOpen(false);
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="dropdown"
          style={{
            fontSize: "0.8rem",
            padding: "0.1rem 0.5rem",
            position: "relative",
            top: "1px",
          }}
          onClick={toggleList}
        >
          <span style={{ position: "relative", top: "-5px" }}>
            {/* {currentWorkflowName} */}
            {slackChecked && teamsChecked
              ? "Multiple"
              : slackChecked
              ? "Slack"
              : teamsChecked
              ? "Teams"
              : zoomChecked
              ? "Email"
              : glassdoorChecked
              ? "Glassdoor"
              : blindChecked
              ? "Blind"
              : ""}
          </span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        {open && (
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width:  "320px" ,
                height:"250px",
                background: "#fff",
                right: "0",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                  <>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div>
                        <Checkbox
                          checked={slackChecked}
                          onChange={(e) => {
                            setSlackChecked(e.target.checked);
                          }}
                        />
                        <span
                          onClick={() => {
                            setSlackChecked(!slackChecked);
                          }}
                          style={{
                            color: "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                          }}
                        >
                          Slack
                        </span>
                      </div>
                      <div>
                        <Checkbox
                          checked={teamsChecked}
                          onChange={(e) => {
                            setTeamsChecked(e.target.checked);
                          }}
                        />
                        <span
                          onClick={() => {
                            setTeamsChecked(!teamsChecked);
                          }}
                          style={{
                            color: "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                          }}
                        >
                          Teams
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", pointerEvents:"none", filter:"opacity(0.6)" }}>
                      <div>
                        <Checkbox
                          checked={zoomChecked}
                          onChange={(e) => {
                            setZoomChecked(e.target.checked);
                          }}
                        />
                        <span
                          onClick={() => {
                            setZoomChecked(!zoomChecked);
                          }}
                          style={{
                            color: "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                          }}
                        >
                          Email
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div>
                        <Checkbox
                          checked={glassdoorChecked}
                          onChange={(e) => {
                            setGlassdoorChecked(e.target.checked);
                          }}
                        />
                        <span
                          onClick={() => {
                            setGlassdoorChecked(!glassdoorChecked);
                          }}
                          style={{
                            color: "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                          }}
                        >
                          Glassdoor
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div>
                        <Checkbox
                          checked={blindChecked}
                          onChange={(e) => {
                            setBlindChecked(e.target.checked);
                          }}
                        />
                        <span
                          onClick={() => {
                            setBlindChecked(!blindChecked);
                          }}
                          style={{
                            color: "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                          }}
                        >
                          Blind
                        </span>
                      </div>
                    </div>
                    {warning.length > 0 && (
                      <p
                        style={{
                          color: "#f00",
                          margin: "0",
                          fontSize: "0.8rem",
                        }}
                      >
                        {warning}
                      </p>
                    )}
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        onClick={handleApply}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          margin: "0.5rem",
                          background: "#D06283",
                          color: "#fff",
                          padding: "0.5rem",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        Apply
                      </div>
                    </div>
                  </>
                
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
