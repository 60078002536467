import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { demoFocusGroup, demoSurveys } from "../Feedback/demoSurveys";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import cloneDeep from "lodash/cloneDeep";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { Link } from "react-router-dom";
import moralePlan from "./Recommendation/moralePlan.json";
import engagementPlan from "./Recommendation/engagementPlan.json";
import retentionPlan from "./Recommendation/retentionPlan.json";
import eNPSPlan from "./Recommendation/eNPSPlan.json";
import topicPlan from "./Recommendation/topicPlan.json";
import culturePlan from "./Recommendation/culturePlan.json";
import InitiateActionPlanModal from "./Recommendation/popup/InitiateActionPlanModal";
import CloseActionPlanModal from "./Recommendation/popup/CloseActionPlanModal";
import { demoActionPlans } from "./Recommendation/demoActionPlans";

const tableHead = {
  objective: "Objective",
  type: "Source",
  section: "Metric",
  // startTime: "Started on",
  // endTime: "Ends on",
  targetGroup: "Target Group",
  status: "Status",
  action: "Action",
};

function formatQuery(query) {
  // Parse the JSON string into an object
  if (!query || query === "overall") {
    return "Overall";
  } else {
    let queryObject = JSON.parse(query);

    // Map for comparison operators
    const comparisonMap = {
      $lte: "<=",
      $gte: ">=",
      $lt: "<",
      $gt: ">",
    };

    let result = [];

    // Loop through each key-value pair in the object
    for (let [key, value] of Object.entries(queryObject)) {
      // Capitalize the key (replace underscores with spaces and capitalize words)
      let formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      // Check if value is an object (for comparison operators like $lte, $gte, etc.)
      if (typeof value === "object" && value !== null) {
        for (let [operator, comparisonValue] of Object.entries(value)) {
          // Map the operator to its symbol or keep it as is
          let comparison = comparisonMap[operator] || operator;
          result.push(`${formattedKey}: ${comparison} ${comparisonValue}`);
        }
      } else {
        // For simple key-value pairs, just capitalize the value and append
        let formattedValue = value
          .toString()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        result.push(`${formattedKey}: ${formattedValue}`);
      }
    }

    // Join the results into a string
    return result.join(", ");
  }
}

function SavedPlanList({ setSeletedSurvey }) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);

  const [currentMetric, setCurrentMetric] = useState("morale");
  const [currentPlans, setCurrentPlans] = useState([]);

  const [surveyWithResult, setSurveyWithResult] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState(
    cloneDeep(surveyWithResult.slice(0, countPerPage))
  );
  const [initiatePlanModalOpen, setInitiatePlanModalOpen] = useState(false);
  const [closePlanModalOpen, setClosePlanModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [customModalOpen, setCustomModalOpen] = useState("");

  const searchData = useRef(
    throttle((val) => {
      const data = surveyWithResult;
      const query = val.toLowerCase();
      setCurrentPage(1);
      const byID = cloneDeep(
        data
          .filter((item) => item._id.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byName = cloneDeep(
        data
          .filter((item) => item.topic.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      //   const byStatus = cloneDeep(
      //     data
      //       .filter((item) => item.status.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      //   const byModel = cloneDeep(
      //     data
      //       .filter((item) => item.section.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );

      //   const byInsightText = cloneDeep(
      //     data
      //       .filter((item) => item.statement.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      //   const byInsightID = cloneDeep(
      //     data
      //       .filter((item) => item.insightID.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      setCollection([
        ...byID,
        ...byName,
        // ...byStatus,
        // ...byModel,
        // ...byInsightText,
        // ...byInsightID,
      ]);
    }, 400)
  );

  useEffect(() => {
    setSurveyWithResult(currentPlans.filter((item) => item.status === "saved"));
  }, [currentPlans]);

  useEffect(() => {
    setCollection(cloneDeep(surveyWithResult.slice(0, countPerPage)));
  }, [surveyWithResult]);

  useEffect(() => {
    currentMetric === "morale"
      ? setCurrentPlans(
          actionPlans.filter(
            (item) => item.section === "Morale" || item.section === "morale"
          )
        )
      : currentMetric === "engagement"
      ? setCurrentPlans(
          actionPlans.filter((item) => item.section === "engagement")
        )
      : currentMetric === "retention"
      ? setCurrentPlans(
          actionPlans.filter((item) => item.section === "retention")
        )
      : currentMetric === "eNPS"
      ? setCurrentPlans(actionPlans.filter((item) => item.section === "enps"))
      : currentMetric === "topic"
      ? setCurrentPlans(actionPlans.filter((item) => item.section === "topic"))
      : currentMetric === "culture"
      ? setCurrentPlans(
          actionPlans.filter(
            (item) =>
              item.section === "culture" || item.section === "overallCulture"
          )
        )
      : setCurrentPlans(
          actionPlans.filter(
            (item) => item.section === "Morale" || item.section === "morale"
          )
        );
  }, [currentMetric, actionPlans]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(surveyWithResult.slice(from, to)));
  };

  const generateReport = (id) => {};

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return keyD === "startTime" ? (
        <td key={i}>
          {key[keyD] ? moment(key[keyD]).format("MMM DD yyyy HH:MM") : ""}
        </td>
      ) : keyD === "endTime" ? (
        <td key={i}>
          {key[keyD] ? moment(key[keyD]).format("MMM DD yyyy HH:MM") : ""}
        </td>
      ) : keyD === "users" ? (
        <td key={i}>{key[keyD].length}</td>
      ) : keyD === "messages" ? (
        <td key={i}>{key[keyD].length - 1}</td>
      ) : keyD === "section" ? (
        <td key={i}>
          <Typography style={{ textTransform: "capitalize" }}>
            {key[keyD] === "overallCulture" ? "Culture" : key[keyD]}
          </Typography>
        </td>
      ) : keyD === "targetGroup" ? (
        <td key={i}>
          {formatQuery(key[keyD])
            .split(", ")
            .map((item) => (
              <Typography>{item}</Typography>
            ))}
        </td>
      ) : keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/action-plan/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Show
            </Button>
          </Link>
          {key.status === "saved" && (
            <Button
              onClick={() => {
                setInitiatePlanModalOpen(true);
                setSelectedPlan(key);
              }}
              variant="contained"
              style={{
                background: "#D06283",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Initiate
            </Button>
          )}
          {key.status === "active" && (
            <Button
              onClick={() => {
                setClosePlanModalOpen(true);
                setSelectedPlan(key);
              }}
              variant="contained"
              style={{
                background: "#D06283",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Close
            </Button>
          )}
        </Stack>
      ) : (
        <td key={i}>
          <Typography style={{ textTransform: "capitalize" }}>
            {key[keyD]}
          </Typography>
        </td>
      );
    });

    return <tr key={index}>{columnData}</tr>;
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  return (
    <Box>
      <Box
        sx={{
          height: "50px",
          padding: "2rem 2rem 0",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          Saved Action Plans
        </Typography>
        <Box>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Metric</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentMetric}
                label="Metric"
                onChange={(e) => {
                  setCurrentMetric(e.target.value);
                }}
              >
                <MenuItem value={"morale"}>Morale</MenuItem>
                <MenuItem value={"engagement"}>Engagement</MenuItem>
                <MenuItem value={"retention"}>Retention</MenuItem>
                <MenuItem value={"eNPS"}>eNPS</MenuItem>
                {/* <MenuItem value={"topic"}>Topic</MenuItem> */}
                <MenuItem value={"culture"}>Culture</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box className="survey">
        {/* <div class="survey-search">
          <input
            placeholder="Search Surveys"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div> */}
        <table>
          <thead>
            <tr>{headRow()}</tr>
          </thead>
          <tbody className="survey-trhover">{tableData()}</tbody>
        </table>
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={surveyWithResult.length}
        />
      </Box>
      <Modal
        open={initiatePlanModalOpen}
        onClose={() => {
          setInitiatePlanModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InitiateActionPlanModal
          plan={selectedPlan}
          handleClose={() => {
            setInitiatePlanModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={closePlanModalOpen}
        onClose={() => {
          setClosePlanModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CloseActionPlanModal
          plan={selectedPlan}
          handleClose={() => {
            setClosePlanModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default SavedPlanList;
