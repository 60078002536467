import { takeLatest, put, delay } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import {
  BUILD_WORKFLOW,
  SHOW_BUILD_STATUS,
  SHOW_BUILD_STATUS_SUCCESS,
  SHOW_BUILD_STATUS_FAILURE,
  BUILD_WORKFLOW_FAILURE,
  BUILD_WORKFLOW_SUCCESS,
  GET_DETECTED_USER_DETAILS,
  GET_DETECTED_USER_DETAILS_FAILURE,
  GET_DETECTED_USER_DETAILS_SUCCESS,
  GET_MODEL_CHANNEL,
  GET_MODEL_CHANNEL_SUCCESS,
  GET_TOTAL_CONVO_DETAILS,
  GET_TOTAL_CONVO_DETAILS_FAILURE,
  GET_TOTAL_CONVO_DETAILS_SUCCESS,
  GET_WORKFLOW,
  GET_WORKFLOW_DETAILS,
  GET_WORKFLOW_DETAILS_SUCCESS,
  GET_WORKFLOW_FAILURE,
  GET_WORKFLOW_SUCCESS,
  GET_USER_WORKFLOW,
  SAVE_WORKFLOW,
  SAVE_WORKFLOW_SUCCESS,
  SAVE_WORKFLOW_FAILURE,
  UPDATE_WORKFLOW,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_FAILURE,
  UPLOAD_SOURCE_FILES,
  UPLOAD_SOURCE_FILES_FAILURE,
  UPLOAD_SOURCE_FILES_SUCCESS,
  GET_USER_WORKFLOW_SUCCESS,
  GET_USER_WORKFLOW_FAILURE,
  CLEAR_DATA,
  CLEAR_DATA_SUCCESS,
  CHECK_KEYS,
  CHECK_KEYS_SUCCESS,
  CHECK_KEYS_FAILURE,
  EMPTY_CHANNELS,
  EMPTY_CHANNELS_SUCCESS,
  GET_PIPHISHING_MODEL_SUCCESS,
  GET_PIPHISHING_MODEL_FAILURE,
  GET_PIPHISHING_MODEL,
  GET_CHANNELS,
  GET_CHANNELS_SUCCESS,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  GET_CHANNELS_FAILURE,
  VERIFY_TOKEN,
  VERIFY_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ZOOM_TOKEN,
  GET_ZOOM_TOKEN_SUCCESS,
  SAVING_ACCESS_TOKEN,
  SAVING_ACCESS_TOKEN_SUCCESS,
  GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_INVESTIGATING_TABLE,
  GET_INVESTIGATING_TABLE_SUCCESS,
  GET_INVESTIGATING_TABLE_FAILURE,
  GET_DETAILED_INVESTIGATING_TABLE,
  GET_DETAILED_INVESTIGATING_TABLE_SUCCESS,
  GET_DETAILED_INVESTIGATING_TABLE_FAILURE,
  GET_MORALE_SCORE,
  GET_MORALE_SCORE_SUCCESS,
  GET_MORALE_SCORE_FAILURE,
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_FAILURE,
  GET_RETENTION,
  GET_RETENTION_SUCCESS,
  GET_RETENTION_FAILURE,
  GET_SUPPORTER_TREND,
  GET_SUPPORTER_TREND_SUCCESS,
  GET_SUPPORTER_TREND_FAILURE,
  GET_PROFANITY_TRENDS,
  GET_PROFANITY_TRENDS_SUCCESS,
  GET_PROFANITY_TRENDS_FAILURE,
  GET_DEI_TRENDS,
  GET_DEI_TRENDS_SUCCESS,
  GET_DEI_TRENDS_FAILURE,
  GET_HATE_SPEECH_TRENDS,
  GET_HATE_SPEECH_TRENDS_SUCCESS,
  GET_HATE_SPEECH_TRENDS_FAILURE,
  GET_INSULT_TRENDS,
  GET_INSULT_TRENDS_SUCCESS,
  GET_INSULT_TRENDS_FAILURE,
  GET_SENTIMENTS_TRENDS,
  GET_SENTIMENTS_TRENDS_SUCCESS,
  GET_SENTIMENTS_TRENDS_FAILURE,
  GET_SENTIMENTS_DIST,
  GET_SENTIMENTS_DIST_SUCCESS,
  GET_SENTIMENTS_DIST_FAILURE,
  GET_SEXUAL_HARRAS_TRENDS,
  GET_SEXUAL_HARRAS_TRENDS_SUCCESS,
  GET_SEXUAL_HARRAS_TRENDS_FAILURE,
  GET_SEXISM_TRENDS,
  GET_SEXISM_TRENDS_SUCCESS,
  GET_SEXISM_TRENDS_FAILURE,
  GET_WORD_CLOUD_DATA,
  GET_WORD_CLOUD_DATA_SUCCESS,
  GET_WORD_CLOUD_DATA_FAILURE,
  GET_TRENDING_TOPICS,
  GET_TRENDING_TOPICS_SUCCESS,
  GET_TRENDING_TOPICS_FAILURE,
  GET_HASH_TAGS,
  GET_HASH_TAGS_SUCCESS,
  GET_HASH_TAGS_FAILURE,
  GET_POLITICAL_WORDS,
  GET_POLITICAL_WORDS_SUCCESS,
  GET_POLITICAL_WORDS_FAILURE,
  GET_TEXT_ABUSE_DATA,
  GET_TEXT_ABUSE_DATA_SUCCESS,
  GET_TEXT_ABUSE_DATA_FAILURE,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_MODEL_CHANNEL_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SAVE_TEAM_CHATS,
  SAVE_TEAM_CHATS_SUCCESS,
  SAVE_TEAM_CHATS_FAILURE,
  SAVE_DISCORD_ID,
  SAVE_DISCORD_ID_SUCCESS,
  SAVE_DISCORD_ID_FAILURE,
  OVERVIEW_EXPORT,
  OVERVIEW_EXPORT_SUCCESS,
  OVERVIEW_EXPORT_FAILURE,
  GET_DIVERSITY_DATA,
  GET_DIVERSITY_DATA_SUCCESS,
  GET_DIVERSITY_DATA_FAILURE,
  GET_CUSTOM_ANALYSIS_DATA,
  GET_CUSTOM_ANALYSIS_DATA_SUCCESS,
  GET_CUSTOM_ANALYSIS_DATA_FAILURE,
  GET_EMOTION_FEAR,
  GET_EMOTION_FEAR_SUCCESS,
  GET_EMOTION_FEAR_FAILURE,
  GET_EMOTION_SAD,
  GET_EMOTION_SAD_SUCCESS,
  GET_EMOTION_SAD_FAILURE,
  GET_EMOTION_SURPRISE,
  GET_EMOTION_SURPRISE_SUCCESS,
  GET_EMOTION_SURPRISE_FAILURE,
  GET_EMOTION_ANGER,
  GET_EMOTION_ANGER_SUCCESS,
  GET_EMOTION_ANGER_FAILURE,
  GET_EMOTION_JOY,
  GET_EMOTION_JOY_SUCCESS,
  GET_EMOTION_JOY_FAILURE,
  GET_EMOTION_TOTAL,
  GET_EMOTION_TOTAL_SUCCESS,
  GET_EMOTION_TOTAL_FAILURE,
  GET_EMOTION_NEUTRAL,
  GET_EMOTION_NEUTRAL_SUCCESS,
  GET_EMOTION_NEUTRAL_FAILURE,
  GET_MORALE_USERS_NUMBER,
  GET_MORALE_USERS_NUMBER_SUCCESS,
  GET_MORALE_USERS_NUMBER_FAILURE,
  GET_DASHBOARD_SECTIONS,
  GET_DASHBOARD_SECTIONS_SUCCESS,
  GET_DASHBOARD_SECTIONS_FAILURE,
  SAVE_DASHBOARD_SECTIONS,
  SAVE_DASHBOARD_SECTIONS_SUCCESS,
  SAVE_DASHBOARD_SECTIONS_FAILURE,
  GET_TOTAL_TEXTS,
  GET_TOTAL_TEXTS_SUCCESS,
  GET_TOTAL_TEXTS_FAILURE,
  GET_TEAM_SIZE,
  GET_TEAM_SIZE_SUCCESS,
  GET_TEAM_SIZE_FAILURE,
  GET_TOPIC_NUMBER,
  GET_TOPIC_NUMBER_SUCCESS,
  GET_TOPIC_NUMBER_FAILURE,
  GET_MORALE_CHANGE,
  GET_MORALE_CHANGE_SUCCESS,
  GET_MORALE_CHANGE_FAILURE,
  GET_LICENSE_PLANS,
  GET_LICENSE_PLANS_SUCCESS,
  GET_LICENSE_PLANS_FAILURE,
  OPT_LICENSE,
  OPT_LICENSE_SUCCESS,
  OPT_LICENSE_FAILURE,
  GET_FILTER_CHANNEL,
  GET_FILTER_CHANNEL_SUCCESS,
  GET_FILTER_CHANNEL_FAILURE,
  GET_RACISM_TREND_DATA,
  GET_RACISM_TREND_DATA_SUCCESS,
  GET_RACISM_TREND_DATA_FAILURE,
  GET_HRIS_USERS,
  GET_HRIS_USERS_SUCCESS,
  GET_HRIS_USERS_FAILURE,
  SAVE_HRIS_USERS,
  SAVE_HRIS_USERS_SUCCESS,
  SAVE_HRIS_USERS_FAILURE,
  ZOOM_FILE_UPLOAD,
  ZOOM_FILE_UPLOAD_SUCCESS,
  ZOOM_FILE_UPLOAD_FAILURE,
  GET_MORALE_COMPARISON,
  GET_ENGAGEMENT_COMPARISON,
  GET_RETENTION_COMPARISON,
  GET_SUPPORTER_COMPARISON,
  GET_MORALE_COMPARISON_SUCCESS,
  GET_MORALE_COMPARISON_FAILURE,
  GET_ENGAGEMENT_COMPARISON_SUCCESS,
  GET_ENGAGEMENT_COMPARISON_FAILURE,
  GET_RETENTION_COMPARISON_SUCCESS,
  GET_RETENTION_COMPARISON_FAILURE,
  GET_SUPPORTER_COMPARISON_SUCCESS,
  GET_SUPPORTER_COMPARISON_FAILURE,
  GET_MORALE_INSIGHTS,
  GET_MORALE_INSIGHTS_SUCCESS,
  GET_MORALE_INSIGHTS_FAILURE,
  GET_PUBLIC_HOLIDAYS,
  GET_PUBLIC_HOLIDAYS_SUCCESS,
  GET_PUBLIC_HOLIDAYS_FAILURE,
  GET_SINGLE_INSIGHTS,
  GET_SINGLE_INSIGHTS_SUCCESS,
  GET_SINGLE_INSIGHTS_FAILURE,
  GET_MULTIPLE_INSIGHTS,
  GET_MULTIPLE_INSIGHTS_SUCCESS,
  GET_MULTIPLE_INSIGHTS_FAILURE,
  GET_CALENDER_EVENTS,
  GET_CALENDER_EVENTS_SUCCESS,
  GET_CALENDER_EVENTS_FAILURE,
  ADD_CALENDER_EVENT,
  ADD_CALENDER_EVENT_SUCCESS,
  ADD_CALENDER_EVENT_FAILURE,
  UPDATE_CALENDER_EVENT,
  UPDATE_CALENDER_EVENT_SUCCESS,
  UPDATE_CALENDER_EVENT_FAILURE,
  DELETE_CALENDER_EVENT,
  DELETE_CALENDER_EVENT_SUCCESS,
  DELETE_CALENDER_EVENT_FAILURE,
  GET_ALL_SURVEYS,
  GET_ALL_SURVEYS_SUCCESS,
  GET_ALL_SURVEYS_FAILURE,
  UPDATE_SURVEY,
  UPDATE_SURVEY_SUCCESS,
  UPDATE_SURVEY_FAILURE,
  LAUNCH_SURVEY_SUCCESS,
  LAUNCH_SURVEY,
  LAUNCH_SURVEY_FAILURE,
  GET_ALL_ACTION_PLANS,
  GET_ALL_ACTION_PLANS_SUCCESS,
  GET_ALL_ACTION_PLANS_FAILURE,
  GET_STATUS_BOX,
  GET_STATUS_BOX_SUCCESS,
  GET_STATUS_BOX_FAILURE,
  UPDATE_STATUS_BOX,
  UPDATE_STATUS_BOX_SUCCESS,
  UPDATE_STATUS_BOX_FAILURE,
  CREATE_CUSTOM_SURVEY,
  UPDATE_SURVEY_DETAILS,
  GET_NONE_INSIGHTS,
  GET_NONE_INSIGHTS_SUCCESS,
  GET_NONE_INSIGHTS_FAILURE,
  DELETE_SURVEY_QUESTION,
  UPDATE_SURVEY_QUESTION,
  GET_ALL_FOCUS_GROUPS,
  GET_ALL_FOCUS_GROUPS_SUCCESS,
  GET_ALL_FOCUS_GROUPS_FAILURE,
  GET_ALL_FOCUS_CHAT,
  GET_ALL_FOCUS_CHAT_SUCCESS,
  GET_ALL_FOCUS_CHAT_FAILURE,
  GET_POSITIVE_CULTURE,
  GET_NEGATIVE_CULTURE,
  GET_POSITIVE_CULTURE_SUCCESS,
  GET_POSITIVE_CULTURE_FAILURE,
  GET_NEGATIVE_CULTURE_SUCCESS,
  GET_NEGATIVE_CULTURE_FAILURE,
  GET_MORALE_TOPICS,
  GET_MORALE_TOPICS_SUCCESS,
  GET_MORALE_TOPICS_FAILURE,
  GET_INSIGHTS_THRESHOLD,
  GET_POSITIVE_CULTURE_THRESHOLD,
  GET_NEGATIVE_CULURE_THRESHOLD,
  GET_INSIGHTS_THRESHOLD_SUCCESS,
  GET_INSIGHTS_THRESHOLD_FAILURE,
  GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS,
  GET_POSITIVE_CULTURE_THRESHOLD_FAILURE,
  GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS,
  GET_NEGATIVE_CULURE_THRESHOLD_FAILURE,
  SAVE_INSIGHTS_THRESHOLD,
  SAVE_POSITIVE_CULTURE_THRESHOLD,
  SAVE_NEGATIVE_CULURE_THRESHOLD,
  GET_FAVORITE_FILTERS,
  GET_FAVORITE_FILTERS_SUCCESS,
  GET_FAVORITE_FILTERS_FAILURE,
  SAVE_FAVORITE_FILTERS,
  SAVE_FAVORITE_FILTERS_SUCCESS,
  SAVE_FAVORITE_FILTERS_FAILURE,
  GET_ENPS_TEXT_SCORE,
  GET_ENPS_TOPIC_SCORE,
  GET_ENPS_SURVEY_SCORE,
  GET_ENPS_TEXT_SCORE_SUCCESS,
  GET_ENPS_TEXT_SCORE_FAILURE,
  GET_ENPS_TOPIC_SCORE_SUCCESS,
  GET_ENPS_TOPIC_SCORE_FAILURE,
  GET_ENPS_SURVEY_SCORE_SUCCESS,
  GET_ENPS_SURVEY_SCORE_FAILURE,
  GET_ENPS_TEXT_TREND,
  GET_ENPS_TOPIC_TREND,
  GET_ENPS_SURVEY_TREND,
  GET_ENPS_TEXT_TREND_SUCCESS,
  GET_ENPS_TEXT_TREND_FAILURE,
  GET_ENPS_TOPIC_TREND_SUCCESS,
  GET_ENPS_TOPIC_TREND_FAILURE,
  GET_ENPS_SURVEY_TREND_SUCCESS,
  GET_ENPS_SURVEY_TREND_FAILURE,
  CREATE_CUSTOM_FOCUS_GROUP,
  CREATE_CUSTOM_FOCUS_GROUP_SUCCESS,
  CREATE_CUSTOM_FOCUS_GROUP_FAILURE,
  GET_ENPS_OVERALL_SCORE,
  GET_ENPS_OVERALL_TREND,
  GET_ENPS_OVERALL_SCORE_SUCCESS,
  GET_ENPS_OVERALL_SCORE_FAILURE,
  GET_ENPS_OVERALL_TREND_SUCCESS,
  GET_ENPS_OVERALL_TREND_FAILURE,
  GET_OVERALL_CULTURE,
  GET_OVERALL_CULTURE_SUCCESS,
  GET_OVERALL_CULTURE_FAILURE,
  GET_ENGAGEMENT_THRESHOLD,
  GET_ENGAGEMENT_THRESHOLD_SUCCESS,
  GET_ENGAGEMENT_THRESHOLD_FAILURE,
  SAVE_ENGAGEMENT_THRESHOLD,
  SAVE_ENGAGEMENT_THRESHOLD_SUCCESS,
  SAVE_ENGAGEMENT_THRESHOLD_FAILURE,
  GET_ENPS_THRESHOLD,
  GET_ENPS_THRESHOLD_SUCCESS,
  GET_ENPS_THRESHOLD_FAILURE,
  SAVE_ENPS_THRESHOLD,
  GET_SURVEY_SETTINGS,
  GET_SURVEY_SETTINGS_SUCCESS,
  GET_SURVEY_SETTINGS_FAILURE,
  SAVE_SURVEY_SETTINGS,
  SAVE_SURVEY_SETTINGS_SUCCESS,
  SAVE_SURVEY_SETTINGS_FAILURE,
  GET_ENGAGEMENT_TOPICS,
  GET_ENGAGEMENT_TOPICS_SUCCESS,
  GET_ENGAGEMENT_TOPICS_FAILURE,
  SAVE_ENGAGEMENT_TOPICS,
  SAVE_ENGAGEMENT_TOPICS_SUCCESS,
  SAVE_ENGAGEMENT_TOPICS_FAILURE,
  GET_SUGGESTED_QUESTION_SUCCESS,
  GET_SUGGESTED_QUESTION_FAILURE,
  GET_SUGGESTED_QUESTION,
  ADD_SURVEY_QUESTION,
  GET_INSIGHTS,
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHTS_FAILURE,
  GENEREATE_FOCUS_GROUP_REPORT,
  GENEREATE_FOCUS_GROUP_REPORT_SUCCESS,
  GENEREATE_FOCUS_GROUP_REPORT_FAILURE,
  GET_ENGAGEMENT_TOPIC_TREND_FAILURE,
  GET_ENGAGEMENT_TOPIC_TREND,
  GET_ENGAGEMENT_TOPIC_TREND_SUCCESS,
  GET_FILTER_EMAILS_SUCCESS,
  GET_FILTER_EMAILS,
  GET_FILTER_EMAILS_FAILURE,
  GET_ALL_BASELINE_SURVEYS,
  GET_ALL_BASELINE_SURVEYS_SUCCESS,
  GET_ALL_BASELINE_SURVEYS_FAILURE,
  GET_TOPIC_SINGLE_INSIGHTS,
  GET_TOPIC_MULTIPLE_INSIGHTS,
  GET_TOPIC_NONE_INSIGHTS,
  GET_EVENT_SINGLE_INSIGHTS,
  GET_EVENT_MULTIPLE_INSIGHTS,
  GET_EVENT_NONE_INSIGHTS,
  GET_TOPIC_SINGLE_INSIGHTS_SUCCESS,
  GET_TOPIC_SINGLE_INSIGHTS_FAILURE,
  GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS,
  GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE,
  GET_TOPIC_NONE_INSIGHTS_SUCCESS,
  GET_TOPIC_NONE_INSIGHTS_FAILURE,
  GET_EVENT_SINGLE_INSIGHTS_SUCCESS,
  GET_EVENT_SINGLE_INSIGHTS_FAILURE,
  GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS,
  GET_EVENT_MULTIPLE_INSIGHTS_FAILURE,
  GET_EVENT_NONE_INSIGHTS_SUCCESS,
  GET_EVENT_NONE_INSIGHTS_FAILURE,
  DELETE_FAVORITE_FILTER,
  DELETE_FAVORITE_FILTER_SUCCESS,
  DELETE_FAVORITE_FILTER_FAILURE,
  CREATE_FOCUS_GROUP,
  CREATE_FOCUS_GROUP_SUCCESS,
  CREATE_FOCUS_GROUP_FAILURE,
  GET_ALL_ENGAGEMENT_TOPIC_SUCCESS,
  GET_ALL_ENGAGEMENT_TOPIC_FAILURE,
  GET_ALL_ENGAGEMENT_TOPIC,
  GET_ACTIVE_ENGAGEMENT_TOPICS,
  SAVE_ACTIVE_ENGAGEMENT_TOPICS,
  GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS,
  GET_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
  SAVE_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
  GET_MORALE_GOAL,
  GET_ENGAGEMENT_GOAL,
  GET_RETENTION_GOAL,
  GET_ENPS_GOAL,
  GET_CULTURE_GOAL,
  GET_MORALE_GOAL_SUCCESS,
  GET_MORALE_GOAL_FAILURE,
  GET_ENGAGEMENT_GOAL_SUCCESS,
  GET_ENGAGEMENT_GOAL_FAILURE,
  GET_RETENTION_GOAL_SUCCESS,
  GET_RETENTION_GOAL_FAILURE,
  GET_ENPS_GOAL_SUCCESS,
  GET_ENPS_GOAL_FAILURE,
  GET_CULTURE_GOAL_SUCCESS,
  GET_CULTURE_GOAL_FAILURE,
  CREATE_FOCUS_GROUP_ACTION_PLAN,
  SAVE_ACTION_PLAN_FAILURE,
  SAVE_ACTION_PLAN,
  DELETE_ACTION_PLAN,
  DELETE_ACTION_PLAN_FAILURE,
  INITIATE_ACTION_PLAN,
  INITIATE_ACTION_PLAN_FAILURE,
  CLOSE_ACTION_PLAN,
  CLOSE_ACTION_PLAN_FAILURE,
  GET_DYNAMIC_FILTERS,
  GET_DYNAMIC_FILTERS_SUCCESS,
  GET_DYNAMIC_FILTERS_FAILURE,
  SAVE_DYNAMIC_FILTERS,
  SAVE_DYNAMIC_FILTERS_SUCCESS,
  SAVE_DYNAMIC_FILTERS_FAILURE,
  GET_MINIMUM_USER_COUNT,
  GET_MINIMUM_USER_COUNT_SUCCESS,
  GET_MINIMUM_USER_COUNT_FAILURE,
  SET_MINIMUM_USER_COUNT,
  SET_MINIMUM_USER_COUNT_SUCCESS,
  SET_MINIMUM_USER_COUNT_FAILURE,
} from "../redux/Dashboard/Dashboard.types";
import { getActionsData } from "../services/Overview/actions.api";
import { getDetectedUserData } from "../services/Overview/detectedUser.api";
import { getEmotionalData } from "../services/Overview/emotional.api";
import { getModelsChannels } from "../services/Overview/getModelChannels.api";
import { getPiAndPhishingData } from "../services/Overview/piAndPhishing";
import { getTotalConvoData } from "../services/Overview/totalConversation.api";
import { getWorkflowData } from "../services/Overview/workflows.api";
import { buildWorkflowStart } from "../services/Logic/buildWorkflowStart";
import { getUserWorkflowsData } from "../services/Logic/getUserWorkflows.api";
import { getRawWorkflow } from "../services/Logic/getWorkflow.api";
import { saveWorkflow } from "../services/Logic/save_workflow.api";
import { updateWorkflow } from "../services/Logic/updateWorkflow.api";
import { uploadSource } from "../services/Logic/upload_source_files.api";
import { deleteWorkflowData } from "../services/Logic/deleteWorkflow.api";
import { getChannelsFromApp } from "../services/Logic/updateChannels.api";
import { verifyTokenKey } from "../services/Logic/verifyToken.api";
import { getAccessTokenApi } from "../services/Logic/getAccessToken.api";
import { getZoomTokenApi } from "../services/Logic/getZoomToken.api";
import { savingToken } from "../services/Logic/savingAccessToken.api";
import { getUserDetails } from "../services/Profile/getUserDetails.api";
import { updateUserDetails } from "../services/Profile/updateUserDetails.api";
import { updatePassword } from "../services/Profile/updatePassword.api";
import { getInvestigationTable } from "../services/Investigation/getInvestigationTable.api";
import { getDetailedInvestigationTable } from "../services/Investigation/getDetailedInvestigationTable.api";
import { getMoraleScore } from "../services/Overview/getMoraleScore.api";
import { getEngagement } from "../services/Overview/getEngagement.api";
import { getRetention } from "../services/Overview/getRetention.api";
import { getSupporter } from "../services/Overview/getSupporter.api";
import { getProfanity } from "../services/Overview/profanity.api";
import { getHateSpeech } from "../services/Overview/hateSpeech.api";
import { getInsult } from "../services/Overview/insult.api";
import { getSentiments } from "../services/Overview/getSentiments.api";
import { getSexualHarras } from "../services/Overview/getSexualHarras.api";
import { getSexism } from "../services/Overview/getSexism.api";
import { getWordCloud } from "../services/Overview/getWordCloud.api";
import { getExportPdf } from "../services/Overview/getExportPdf.api";
import { getTrendingTopics } from "../services/Overview/getTrendingTopics.api";
import { getHashTags } from "../services/Overview/getHashTags.api";
import { getPoliticalWords } from "../services/Overview/getPoliticalWords.api";
import { getTextAbuse } from "../services/Overview/getTextAbuse.api";
import { getRacism } from "../services/Overview/getRacism.api";
import { addNewUser } from "../services/Profile/addNewUser.api";
import { getUsers } from "../services/Profile/getUsers.api";
import { deleteUser } from "../services/Profile/deleteUser.api";
import { updateUser } from "../services/Profile/updateUser.api";
import { saveTeamChats } from "../services/Logic/saveTeamChats.api";
import { resetPassword } from "../services/Profile/resetPassword.api";
import { saveDiscordID } from "../services/Logic/saveDiscordID.api";
import { getDiversity } from "../services/Overview/getDiversity.api";
import { getCustomAnalyzedData } from "../services/Overview/getCustomData.api";
import { getSentimentsDist } from "../services/Overview/getSentimentsDist.api";
import { getMoraleUserNumber } from "../services/Overview/getMoraleUserNumber.api";
import { getDashboardSections } from "../services/Overview/getDashboardSections.api";
import { getNumTexts } from "../services/Overview/getNumTexts.api";
import { getTeamSize } from "../services/Overview/getTeamSize.api";
import { getMoraleChange } from "../services/Overview/getMoraleChange.api";
import { getTopicNumber } from "../services/Overview/getTopicNumber.api";
import { saveDashboardSections } from "../services/Overview/saveDashboardSections.api";
import { getBuildStatus } from "../services/Logic/getBuildStatus.api";
import { getLicensePlans } from "../services/Overview/getLicensePlans.api";
import { optLicense } from "../services/Overview/optLicense.api";
import { getFilterChannel } from "../services/Overview/getFilterChannel.api";
import { getHrisUsers } from "../services/Overview/getHrisUsers.api";
import { saveHrisUsers } from "../services/Overview/saveHrisUsers.api";
import { uploadZoomFile } from "../services/Overview/uploadZoomFile.api";
import { getMoraleComparison } from "../services/Overview/getMoraleComparison.api";
import { getEngagementComparison } from "../services/Overview/getEngagementComparison.api";
import { getRetentionComparison } from "../services/Overview/getRetentionComparison.api";
import { getSupporterComparison } from "../services/Overview/getSupporterComparison.api";
import { getMoraleInsights } from "../services/Overview/getMoraleInsights.api";
import { getSingleInsights } from "../services/Overview/getSingleInsights.api";
import { getMultipleInsights } from "../services/Overview/getMultipleInsights.api";
import { getPublicHolidays } from "../services/Overview/getPublicHolidays.api";
import { getCalenderEvents } from "../services/Overview/getCalenderEvents.api";
import { saveCalenderEvent } from "../services/Overview/saveCalenderEvent.api";
import { deleteCalenderEvent } from "../services/Overview/deleteCalenderEvent.api";
import { updateCalenderEvent } from "../services/Overview/updateCalenderEvent.api";
import { getAllSurveys } from "../services/Overview/getAllSurveys.api";
import { updateSurvey } from "../services/Overview/updateSurvey.api";
import { launchSurvey } from "../services/Overview/launchSurvey.api";
import { getAllActionPlans } from "../services/Overview/getAllActionPlans.api";
import { getStatusBox } from "../services/Overview/getStatusBox.api";
import { saveStatusBox } from "../services/Overview/saveStatusBox.api";
import { createSurvey } from "../services/Overview/createSurvey.api";
import { getNoneInsights } from "../services/Overview/getNoneInsights.api";
import { deleteSurveyQuestion } from "../services/Overview/deleteSurveyQuestion";
import { updateSurveyQuestion } from "../services/Overview/updateSurveyQuestion.api";
import { getAllFocusGroups } from "../services/Overview/getAllFocusGroups.api";
import { getPositiveCulture } from "../services/Overview/getPositiveCulture.api";
import { getNegativeCulture } from "../services/Overview/getNegativeCulture.api";
import { getMoraleTopics } from "../services/Overview/getMoraleTopics.api";
import { getInsightThreshold } from "../services/Overview/getInsightThreshold.api";
import { getPositiveThreshold } from "../services/Overview/getPositiveThreshold.api";
import { getNegativeThreshold } from "../services/Overview/getNegativeThreshold.api";
import { saveInsightThreshold } from "../services/Overview/saveInsightThreshold.api";
import { savePositiveThreshold } from "../services/Overview/savePositiveThreshold.api";
import { saveNegativeThreshold } from "../services/Overview/saveNegativeThreshold.api";
import { getFavoriteFilters } from "../services/Overview/getFavoriteFilters.api";
import { saveFavoriteFilters } from "../services/Overview/saveFavoriteFilters.api";
import { getENPSTextScore } from "../services/Overview/getENPSTextScore.api";
import { getENPSTopicScore } from "../services/Overview/getENPSTopicScore.api";
import { getENPSSurveyScore } from "../services/Overview/getENPSSurveyScore.api";
import { getENPSTextTrend } from "../services/Overview/getENPSTextTrend.api";
import { getENPSTopicTrend } from "../services/Overview/getENPSTopicTrend.api";
import { getENPSSurveyTrend } from "../services/Overview/getENPSSurveyTrend.api";
import { createCustomFocusGroup } from "../services/Overview/createCustomFocusGroup.api";
import { getENPSOverallTrend } from "../services/Overview/getENPSOverallTrend.api";
import { getENPSOverallScore } from "../services/Overview/getENPSOverallScore.api";
import { getOverallCulture } from "../services/Overview/getOverallCulture.api";
import { getEngagementThreshold } from "../services/Overview/getEngagementThreshold.api";
import { saveEngagementThreshold } from "../services/Overview/saveEngagementThreshold.api";
import { getENPSThreshold } from "../services/Overview/getENPSThreshold.api";
import { saveENPSThreshold } from "../services/Overview/saveENPSThreshold.api";
import { getSurveySettings } from "../services/Overview/getSurveySettings.api";
import { saveSurveySettings } from "../services/Overview/saveSurveySettings.api";
import { getEngagementTopics } from "../services/Overview/getEngagementTopics.api";
import { saveEngagementTopics } from "../services/Overview/saveEngagementTopics.api";
import { getSuggestedQuestion } from "../services/Overview/getSuggestedQuestion.api";
import { addSurveyQuestion } from "../services/Overview/addSurveyQuestion.api";
import { generateFocusReport } from "../services/Overview/generateFocusReport.api";
import { getEngagementTopicTrend } from "../services/Overview/getEngagementTopicTrend.api";
import { getFilterEmails } from "../services/Overview/getFilterEmails.api";
import { getAllBaselineSurvey } from "../services/Overview/getAllBaselineSurvey.api";
import { getTopicSingleInsights } from "../services/Overview/getTopicSingleInsights.api";
import { getTopicMultipleInsights } from "../services/Overview/getTopicMultipleInsights.api";
import { getTopicNoneInsights } from "../services/Overview/getTopicNoneInsights.api";
import { getEventSingleInsights } from "../services/Overview/getEventSingleInsights.api";
import { getEventMultipleInsights } from "../services/Overview/getEventMultipleInsights.api";
import { getEventNoneInsights } from "../services/Overview/getEventNoneInsights.api";
import { deleteFavoriteFilter } from "../services/Overview/deleteFavoriteFilter.api";
import { createFocusGroup } from "../services/Overview/createFocusGroup.api";
import { getAllEngagementTopics } from "../services/Overview/getAllEngagementTopics.api";
import { getActiveEngagementTopics } from "../services/Overview/getActiveEngagementTopics.api";
import { saveActiveEngagementTopics } from "../services/Overview/saveActiveEngagementTopics.api";
import { makeFocusGroupActionPlan } from "../services/Overview/makeFocusGroupActionPlan.api";
import { saveActionPlan } from "../services/Overview/saveActionPlan.api";
import { deleteActionPlan } from "../services/Overview/deleteActionPlan.api";
import { initiateActionPlan } from "../services/Overview/initiateActionPlan.api";
import { closeActionPlan } from "../services/Overview/closeActionPlan.api";
import { getDynamicFilters } from "../services/Overview/getDynamicFilters.api";
import { saveDynamicFilters } from "../services/Overview/saveDynamicFilters.api";
import { getAllFocusChats } from "../services/Overview/getAllFocusChats.api";
import { getMinUserCount } from "../services/Overview/getMinUserCount.api";
import { setMinUserCount } from "../services/Overview/setMinUserCount.api";

function* fetchTotalConvoDetails(action) {
  const response = yield getTotalConvoData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOTAL_CONVO_DETAILS_SUCCESS,
        payload: {
          totalData: response.data.conversation_trend,
          positiveData: response.data.sentiment_positive,
          negativeData: response.data.sentiment_negative,
          query: action.payload,
        },
      });
    }
  } else {
    yield put({
      type: GET_TOTAL_CONVO_DETAILS_FAILURE,
      payload: {
        negativeData: [],
        positiveData: [],
        totalData: [],
        query: action.payload,
      },
    });
  }
}

function* fetchDetectedUserDetails(action) {
  const response = yield getDetectedUserData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      const xAxisData = [];
      response.data.timeSlots.forEach((item) =>
        xAxisData.push(item.start.split("T")[0])
      );
      const data = {
        detectedUser: {
          totalCount: response.data.totalCount,
          internalUser: response.data.interalFlagged,
          externalUser: response.data.externalFlagged,
          yAxis: response.data.slotsCounts,
          xAxis: xAxisData,
        },
      };
      yield put({
        type: GET_DETECTED_USER_DETAILS_SUCCESS,
        payload: data,
      });
    }
  } else {
    yield put({
      type: GET_DETECTED_USER_DETAILS_FAILURE,
      payload: {
        totalCount: 0,
        internalUser: 0,
        externalUser: 0,
        xAxis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        yAxis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    });
  }
}

function* fetchPiAndPhishing(action) {
  const piAndPhishingData = yield getPiAndPhishingData(action);
  if (piAndPhishingData) {
    yield put({
      type: GET_PIPHISHING_MODEL_SUCCESS,
      payload: piAndPhishingData,
    });
  } else {
    yield put({
      type: GET_PIPHISHING_MODEL_FAILURE,
      payload: {
        piModels: {
          xAxisData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          yAxisData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        phishingModel: {
          xAxisData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          yAxisData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      },
    });
  }
}

function* getJoyData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;

    yield put({
      type: GET_EMOTION_JOY_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_JOY_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getAngerData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;

    yield put({
      type: GET_EMOTION_ANGER_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_ANGER_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getFearData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;
    yield put({
      type: GET_EMOTION_FEAR_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_FEAR_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getSurpriseData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;
    yield put({
      type: GET_EMOTION_SURPRISE_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_SURPRISE_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getSadData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;

    yield put({
      type: GET_EMOTION_SAD_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_SAD_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getEmoTotalData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;
    yield put({
      type: GET_EMOTION_TOTAL_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_TOTAL_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* getEmoNeutralData(action) {
  const emotionalData = yield getEmotionalData(action);
  if (emotionalData) {
    emotionalData.data.query = action.payload;
    yield put({
      type: GET_EMOTION_NEUTRAL_SUCCESS,
      payload: emotionalData.data,
    });
  } else {
    yield put({
      type: GET_EMOTION_NEUTRAL_FAILURE,
      payload: {
        joy: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        anger: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        sad: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        surprise: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        fear: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
        total: {
          emotionPercenatge: {
            Emotion: 0,
          },
        },
      },
    });
  }
}

function* fetchWorkflowDetails(action) {
  const response = yield getWorkflowData(action);
  yield put({
    type: GET_WORKFLOW_DETAILS_SUCCESS,
    payload: response,
  });
}

function* fetchModelChannel(action) {
  const response = yield getModelsChannels(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MODEL_CHANNEL_SUCCESS,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_MODEL_CHANNEL_FAILURE,
    });
  }
}

function* uploadSourceFiles(action) {
  const response = yield uploadSource(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPLOAD_SOURCE_FILES_SUCCESS,
        payload: {
          status: "PASS",
          channels: response.data.channels,
          fileName: response.data.filename,
          dms: response.data.dms,
        },
      });
    }
  } else {
    yield put({
      type: UPLOAD_SOURCE_FILES_FAILURE,
      payload: {
        status: "FAIL",
        channels: [],
        fileName: action.payload.fileName,
      },
    });
  }
}

function* saveWorkflowDetails(action) {
  const response = yield saveWorkflow(action);
  if (response.status >= 200 && response.status < 300) {
    yield put({
      type: SAVE_WORKFLOW_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: SAVE_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* updateWorkflowDetails(action) {
  const response = yield updateWorkflow(action);
  if (response.status >= 200 && response.status < 300) {
    yield put({
      type: UPDATE_WORKFLOW_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: UPDATE_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* getWorkflow(action) {
  const response = yield getRawWorkflow(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_WORKFLOW_SUCCESS,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* buildWorkflow(action) {
  yield put({
    type: SHOW_BUILD_STATUS,
    payload: { workflowID: action.payload.workflowID },
  });
  const response = yield buildWorkflowStart(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: BUILD_WORKFLOW_SUCCESS,
        payload: response,
      });
    }
  } else {
    yield put({
      type: BUILD_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* deleteWorkflow(action) {
  const response = yield deleteWorkflowData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: DELETE_WORKFLOW_SUCCESS,
        payload: response,
      });
    }
  } else {
    yield put({
      type: DELETE_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* getUserWorkflows(action) {
  const response = yield getUserWorkflowsData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_USER_WORKFLOW_SUCCESS,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_USER_WORKFLOW_FAILURE,
      payload: response,
    });
  }
}

function* clearData() {
  yield put({
    type: CLEAR_DATA_SUCCESS,
  });
}

function* checkKeysData(action) {
  const response = yield getUserWorkflowsData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: CHECK_KEYS_SUCCESS,
        payload: response,
      });
    }
  } else {
    yield put({
      type: CHECK_KEYS_FAILURE,
      payload: response,
    });
  }
}

function* emptyChannels() {
  yield put({
    type: EMPTY_CHANNELS_SUCCESS,
    payload: {
      status: "EMPTY",
      channels: [],
      fileName: "",
      modelsConfigData: {
        emotionData: [],
        personalInfoData: [],
        profanityData: [],
        hateSpeechData: [],
        textAbuseData: [],
        scriptData: [],
      },
      actionConfigData: {
        email: [],
        sms: [],
        alert: [],
        notification: [],
      },
      slackKey: {
        data: [],
      },
      accessToken: {
        data: {},
      },
      saveStatus: "",
    },
  });
}

function* updateChannels(action) {
  const response = yield getChannelsFromApp(action);
  if (response) {
    yield put({
      type: GET_CHANNELS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: GET_CHANNELS_FAILURE,
      payload: response,
    });
  }
}

function* verifyToken(action) {
  const response = yield verifyTokenKey(action);
  if (response) {
    yield put({
      type: VERIFY_TOKEN_SUCCESS,
      payload: response,
    });
  }
}

function* getAccessTokenData(action) {
  const response = yield getAccessTokenApi(action);
  if (response) {
    if (response.data.ok) {
      yield put({
        type: GET_ACCESS_TOKEN_SUCCESS,
        payload: response,
      });
    }
  }
}
function* getZoomTokenData(action) {
  const response = yield getZoomTokenApi(action);
  if (response) {
    if (response) {
      yield put({
        type: GET_ZOOM_TOKEN_SUCCESS,
        payload: response.data,
      });
    }
  }
}

function* savingTokenData(action) {
  const response = yield savingToken(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVING_ACCESS_TOKEN_SUCCESS,
        payload: response,
      });
    }
  }
}

function* getEvent(action) {
  // const response = yield fetchEventData(action);
  yield put({
    type: GET_EVENT_SUCCESS,
    payload: {
      eventId: "EVENT123",
      timeDate: "5/09/2021",
      user: "anjani",
      model: "Emotional",
      decision: "Email",
      action: "Nothing",
    },
  });
  // if (response) {
  //   if (response.status >= 200 && response.status < 300) {
  //     yield put({
  //       type: GET_EVENT_SUCCESS,
  //       payload: response,
  //     });
  //   }
  // }
}

function* getUserDetailsData(action) {
  const response = yield getUserDetails(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_USER_DETAILS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_USER_DETAILS_FAILURE,
        payload: response,
      });
    }
  }
}

function* updateUserDetailsData(action) {
  const response = yield updateUserDetails(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: response,
      });
      toast.success(response.message);
    } else {
      yield put({
        type: UPDATE_USER_DETAILS_FAILURE,
        payload: response,
      });
    }
  }
}

function* changePassword(action) {
  const response = yield updatePassword(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: UPDATE_PASSWORD_FAILURE,
        payload: response,
      });
    }
  }
}

function* resetPasswordHandler(action) {
  const response = yield resetPassword(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: RESET_PASSWORD_FAILURE,
        payload: response,
      });
    }
  }
}

function* getInvestigationTableData(action) {
  const response = yield getInvestigationTable(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_INVESTIGATING_TABLE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_INVESTIGATING_TABLE_FAILURE,
        payload: response,
      });
    }
  }
}

function* getDetailedInvestigationTableData(action) {
  const response = yield getDetailedInvestigationTable(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_DETAILED_INVESTIGATING_TABLE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_DETAILED_INVESTIGATING_TABLE_FAILURE,
        payload: response,
      });
    }
  }
}

function* getMoraleScoreData(action) {
  const response = yield getMoraleScore(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_SCORE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_MORALE_SCORE_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_MORALE_SCORE_FAILURE,
      payload: {},
    });
  }
}
function* fetchMoraleComparison(action) {
  const response = yield getMoraleComparison(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_COMPARISON_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_MORALE_COMPARISON_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_MORALE_COMPARISON_FAILURE,
      payload: {},
    });
  }
}

function* getEngagementData(action) {
  const response = yield getEngagement(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_FAILURE,
      payload: {},
    });
  }
}

function* fetchEngagementComparison(action) {
  const response = yield getEngagementComparison(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_COMPARISON_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_COMPARISON_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_COMPARISON_FAILURE,
      payload: {},
    });
  }
}

function* getRetentionData(action) {
  const response = yield getRetention(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_RETENTION_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_RETENTION_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_RETENTION_FAILURE,
      payload: {},
    });
  }
}

function* fetchRetentionComparison(action) {
  const response = yield getRetentionComparison(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_RETENTION_COMPARISON_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_RETENTION_COMPARISON_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_RETENTION_COMPARISON_FAILURE,
      payload: {},
    });
  }
}

function* getSupporterData(action) {
  const response = yield getSupporter(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SUPPORTER_TREND_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SUPPORTER_TREND_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_SUPPORTER_TREND_FAILURE,
      payload: {},
    });
  }
}

function* fetchSupporterComparison(action) {
  const response = yield getSupporterComparison(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SUPPORTER_COMPARISON_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SUPPORTER_COMPARISON_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_SUPPORTER_COMPARISON_FAILURE,
      payload: {},
    });
  }
}

function* fetchMoraleInsights(action) {
  const response = yield getMoraleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MORALE_INSIGHTS_FAILURE,
        payload: {},
      });
    }
  } else {
    yield put({
      type: GET_MORALE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* getProfanityData(action) {
  const response = yield getProfanity(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_PROFANITY_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_PROFANITY_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}
function* getDEIData(action) {
  const response = yield getActionsData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_DEI_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_DEI_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getHateSpeechData(action) {
  const response = yield getHateSpeech(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_HATE_SPEECH_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_HATE_SPEECH_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* fetchOverallCulture(action) {
  const response = yield getOverallCulture(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_OVERALL_CULTURE_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_OVERALL_CULTURE_FAILURE,
        payload: response,
      });
    }
  }
}

function* fetchPositiveCulture(action) {
  const response = yield getPositiveCulture(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_POSITIVE_CULTURE_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_POSITIVE_CULTURE_FAILURE,
        payload: response,
      });
    }
  }
}
function* fetchNegativeCulture(action) {
  const response = yield getNegativeCulture(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_NEGATIVE_CULTURE_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_NEGATIVE_CULTURE_FAILURE,
        payload: response,
      });
    }
  }
}

function* getInsultData(action) {
  const response = yield getInsult(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      response.query = action.payload;
      yield put({
        type: GET_INSULT_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      response.query = action.payload;
      yield put({
        type: GET_INSULT_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getSentimentsData(action) {
  const response = yield getSentiments(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SENTIMENTS_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SENTIMENTS_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getSentimentsDistData(action) {
  const response = yield getSentimentsDist(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SENTIMENTS_DIST_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SENTIMENTS_DIST_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_SENTIMENTS_DIST_FAILURE,
      payload: response,
    });
  }
}

function* getSexualHarrasData(action) {
  const response = yield getSexualHarras(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SEXUAL_HARRAS_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SEXUAL_HARRAS_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getSexismData(action) {
  const response = yield getSexism(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SEXISM_TRENDS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_SEXISM_TRENDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getWordCloudData(action) {
  const response = yield getWordCloud(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_WORD_CLOUD_DATA_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_WORD_CLOUD_DATA_FAILURE,
        payload: response,
      });
    }
  }
}

function* getTrendingTopicsData(action) {
  const response = yield getTrendingTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TRENDING_TOPICS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_TRENDING_TOPICS_FAILURE,
        payload: response,
      });
    }
  }
}

function* fetchAllEngagementTopics(action) {
  const response = yield getAllEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ENGAGEMENT_TOPIC_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_ALL_ENGAGEMENT_TOPIC_FAILURE,
        payload: response,
      });
    }
  }
}

function* getHashTagsData(action) {
  const response = yield getHashTags(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_HASH_TAGS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_HASH_TAGS_FAILURE,
        payload: response,
      });
    }
  }
}
function* getPoliticalWordsData(action) {
  const response = yield getPoliticalWords(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_POLITICAL_WORDS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_POLITICAL_WORDS_FAILURE,
        payload: response,
      });
    }
  }
}

function* getTextAbuseData(action) {
  const response = yield getTextAbuse(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TEXT_ABUSE_DATA_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_TEXT_ABUSE_DATA_FAILURE,
        payload: response,
      });
    }
  }
}

function* getRacismData(action) {
  const response = yield getRacism(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_RACISM_TREND_DATA_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_RACISM_TREND_DATA_FAILURE,
        payload: response,
      });
    }
  }
}

function* addUser(action) {
  const response = yield addNewUser(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ADD_NEW_USER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ADD_NEW_USER_FAILURE,
        payload: response,
      });
    }
  }
}

function* getUsersData(action) {
  const response = yield getUsers(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_USERS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_USERS_FAILURE,
        payload: response,
      });
    }
  }
}

function* handleDeleteUser(action) {
  const response = yield deleteUser(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: DELETE_USER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: DELETE_USER_FAILURE,
        payload: response,
      });
    }
  }
}

function* handleUpdateUser(action) {
  const response = yield updateUser(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_USER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: UPDATE_USER_FAILURE,
        payload: response,
      });
    }
  }
}

function* saveChats(action) {
  const response = yield saveTeamChats(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_TEAM_CHATS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SAVE_TEAM_CHATS_FAILURE,
        payload: response,
      });
    }
  }
}

function* saveDisocrd(action) {
  const response = yield saveDiscordID(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_DISCORD_ID_SUCCESS,
        payload: action.payload.server_id,
      });
    } else {
      yield put({
        type: SAVE_DISCORD_ID_FAILURE,
        payload: response,
      });
    }
  }
}

function* exportPdf(action) {
  const response = yield getExportPdf(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: OVERVIEW_EXPORT_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: OVERVIEW_EXPORT_FAILURE,
        payload: response,
      });
    }
  }
}

function* getDiversityData(action) {
  const response = yield getDiversity(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_DIVERSITY_DATA_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_DIVERSITY_DATA_FAILURE,
        payload: response,
      });
    }
  }
}

function* getCustomData(action) {
  const response = yield getCustomAnalyzedData(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_CUSTOM_ANALYSIS_DATA_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_CUSTOM_ANALYSIS_DATA_FAILURE,
        payload: response,
      });
    }
  }
}
function* getMoraleUserNum(action) {
  const response = yield getMoraleUserNumber(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_USERS_NUMBER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_MORALE_USERS_NUMBER_FAILURE,
        payload: response,
      });
    }
  }
}
function* getBuildProgressStatus(action) {
  const response = yield getBuildStatus(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SHOW_BUILD_STATUS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SHOW_BUILD_STATUS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: SHOW_BUILD_STATUS_FAILURE,
      payload: {},
    });
  }
}

function* getSections(action) {
  const response = yield getDashboardSections(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_DASHBOARD_SECTIONS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_DASHBOARD_SECTIONS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_DASHBOARD_SECTIONS_FAILURE,
      payload: {},
    });
  }
}

function* saveSections(action) {
  const response = yield saveDashboardSections(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_DASHBOARD_SECTIONS_SUCCESS,
        payload: response,
      });
      yield put({
        type: GET_DASHBOARD_SECTIONS,
        payload: action.payload.workflowID,
      });
    } else {
      yield put({
        type: SAVE_DASHBOARD_SECTIONS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: SAVE_DASHBOARD_SECTIONS_FAILURE,
      payload: {},
    });
  }
}

function* fetchTeamSize(action) {
  const response = yield getTeamSize(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TEAM_SIZE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_TEAM_SIZE_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TEAM_SIZE_FAILURE,
      payload: {},
    });
  }
}

function* fetchNumTexts(action) {
  const response = yield getNumTexts(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOTAL_TEXTS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_TOTAL_TEXTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TOTAL_TEXTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchTopicNumber(action) {
  const response = yield getTopicNumber(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOPIC_NUMBER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_TOPIC_NUMBER_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TOPIC_NUMBER_FAILURE,
      payload: {},
    });
  }
}

function* fetchMoraleChange(action) {
  const response = yield getMoraleChange(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_CHANGE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_MORALE_CHANGE_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_MORALE_CHANGE_FAILURE,
      payload: {},
    });
  }
}

function* fetchLicensePlans(action) {
  const response = yield getLicensePlans(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_LICENSE_PLANS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_LICENSE_PLANS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_LICENSE_PLANS_FAILURE,
      payload: {},
    });
  }
}

function* fetchFilterChannel(action) {
  const response = yield getFilterChannel(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_FILTER_CHANNEL_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_FILTER_CHANNEL_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_FILTER_CHANNEL_FAILURE,
      payload: {},
    });
  }
}

function* handleOptLicence(action) {
  const response = yield optLicense(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: OPT_LICENSE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: OPT_LICENSE_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: OPT_LICENSE_FAILURE,
      payload: {},
    });
  }
}
function* fetchHrisUsers(action) {
  const response = yield getHrisUsers(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_HRIS_USERS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_HRIS_USERS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_HRIS_USERS_FAILURE,
      payload: {},
    });
  }
}
function* saveHRISUsers(action) {
  const response = yield saveHrisUsers(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_HRIS_USERS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SAVE_HRIS_USERS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: SAVE_HRIS_USERS_FAILURE,
      payload: {},
    });
  }
}

function* zoomfileUpload(action) {
  const response = yield uploadZoomFile(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ZOOM_FILE_UPLOAD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ZOOM_FILE_UPLOAD_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: ZOOM_FILE_UPLOAD_FAILURE,
      payload: {},
    });
  }
}
function* fetchSingleInsights(action) {
  const response = yield getSingleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SINGLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_SINGLE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_SINGLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}
function* fetchMultipleInsights(action) {
  const response = yield getMultipleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MULTIPLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MULTIPLE_INSIGHTS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_MULTIPLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchNoneInsight(action) {
  const response = yield getNoneInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_NONE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_NONE_INSIGHTS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_NONE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchTopicSingleInsights(action) {
  const response = yield getTopicSingleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOPIC_SINGLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_TOPIC_SINGLE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TOPIC_SINGLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchTopicMultipleInsights(action) {
  const response = yield getTopicMultipleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchTopicNoneInsights(action) {
  const response = yield getTopicNoneInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_TOPIC_NONE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_TOPIC_NONE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_TOPIC_NONE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchEventSingleInsights(action) {
  const response = yield getEventSingleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_EVENT_SINGLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_EVENT_SINGLE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_EVENT_SINGLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchEventMultipleInsights(action) {
  const response = yield getEventMultipleInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_EVENT_MULTIPLE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_EVENT_MULTIPLE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchEventNoneInsights(action) {
  const response = yield getEventNoneInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_EVENT_NONE_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_EVENT_NONE_INSIGHTS_FAILURE,
        payload: response,
      });
    }
  } else {
    yield put({
      type: GET_EVENT_NONE_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* fetchPublicHolidays(action) {
  const holidays = yield getPublicHolidays(action);
  if (holidays.length > 0) {
    yield put({
      type: GET_PUBLIC_HOLIDAYS_SUCCESS,
      payload: holidays,
    });
  }
}

function* fetchCalenderEvents(action) {
  const response = yield getCalenderEvents(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_CALENDER_EVENTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_CALENDER_EVENTS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_CALENDER_EVENTS_FAILURE,
      payload: {},
    });
  }
}

function* addCalenderEvent(action) {
  const response = yield saveCalenderEvent(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ADD_CALENDER_EVENT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ADD_CALENDER_EVENT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: ADD_CALENDER_EVENT_FAILURE,
      payload: {},
    });
  }
}

function* removeCalenderEvent(action) {
  const response = yield deleteCalenderEvent(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: DELETE_CALENDER_EVENT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: DELETE_CALENDER_EVENT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: DELETE_CALENDER_EVENT_FAILURE,
      payload: {},
    });
  }
}

function* changeCalenderEvent(action) {
  const response = yield updateCalenderEvent(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_CALENDER_EVENT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: UPDATE_CALENDER_EVENT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: UPDATE_CALENDER_EVENT_FAILURE,
      payload: {},
    });
  }
}

function* fetchAllSurveys(action) {
  const response = yield getAllSurveys(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_SURVEYS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ALL_SURVEYS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ALL_SURVEYS_FAILURE,
      payload: {},
    });
  }
}

function* fetchAllBaselineSurvey(action) {
  const response = yield getAllBaselineSurvey(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_BASELINE_SURVEYS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ALL_BASELINE_SURVEYS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ALL_BASELINE_SURVEYS_FAILURE,
      payload: {},
    });
  }
}

function* fetchAllFocusGroups(action) {
  const response = yield getAllFocusGroups(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_FOCUS_GROUPS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ALL_FOCUS_GROUPS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ALL_FOCUS_GROUPS_FAILURE,
      payload: {},
    });
  }
}

function* fetchAllFocusChats(action) {
  const response = yield getAllFocusChats(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_FOCUS_CHAT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ALL_FOCUS_CHAT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ALL_FOCUS_CHAT_FAILURE,
      payload: {},
    });
  }
}



function* editSurvey(action) {
  const response = yield updateSurvey(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_SURVEY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: UPDATE_SURVEY_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: UPDATE_SURVEY_FAILURE,
      payload: {},
    });
  }
}

function* sendSurvey(action) {
  const response = yield launchSurvey(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: LAUNCH_SURVEY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: LAUNCH_SURVEY_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: LAUNCH_SURVEY_FAILURE,
      payload: {},
    });
  }
}

function* fetchAllActionPlans(action) {
  const response = yield getAllActionPlans(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ACTION_PLANS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ALL_ACTION_PLANS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ALL_ACTION_PLANS_FAILURE,
      payload: {},
    });
  }
}

function* fetchStatusBox(action) {
  const response = yield getStatusBox(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_STATUS_BOX_SUCCESS,
        payload: response.data.statusBox ? response.data.statusBox : [],
      });
    } else {
      yield put({
        type: GET_STATUS_BOX_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_STATUS_BOX_FAILURE,
      payload: {},
    });
  }
}

function* fetchMoraleTopics(action) {
  const response = yield getMoraleTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_TOPICS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MORALE_TOPICS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_MORALE_TOPICS_FAILURE,
      payload: {},
    });
  }
}

function* updateStatusBox(action) {
  const response = yield saveStatusBox(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: UPDATE_STATUS_BOX_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_STATUS_BOX,
      });
    } else {
      yield put({
        type: UPDATE_STATUS_BOX_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: UPDATE_STATUS_BOX_FAILURE,
      payload: {},
    });
  }
}

function* makeSurvey(action) {
  const response = yield createSurvey(action);
}

function* removeSurveyQuestion(action) {
  const response = yield deleteSurveyQuestion(action);
}

function* changeSurveyQuestion(action) {
  const response = yield updateSurveyQuestion(action);
}

function* fetchInsightThreshold(action) {
  const response = yield getInsightThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_INSIGHTS_THRESHOLD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_INSIGHTS_THRESHOLD_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_INSIGHTS_THRESHOLD_FAILURE,
      payload: {},
    });
  }
}

function* fetchPositiveThreshold(action) {
  const response = yield getPositiveThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_POSITIVE_CULTURE_THRESHOLD_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_POSITIVE_CULTURE_THRESHOLD_FAILURE,
      payload: {},
    });
  }
}

function* fetchNegativeThreshold(action) {
  const response = yield getNegativeThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_NEGATIVE_CULURE_THRESHOLD_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_NEGATIVE_CULURE_THRESHOLD_FAILURE,
      payload: {},
    });
  }
}

function* fetchEngagementThreshold(action) {
  const response = yield getEngagementThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_THRESHOLD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_THRESHOLD_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_THRESHOLD_FAILURE,
      payload: {},
    });
  }
}

function* updateENgagementThreshold(action) {
  const response = yield saveEngagementThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_THRESHOLD,
      });
    }
  }
}

function* fetchENPSThreshold(action) {
  const response = yield getENPSThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_THRESHOLD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_THRESHOLD_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_THRESHOLD_FAILURE,
      payload: {},
    });
  }
}

function* updateENPSThreshold(action) {
  const response = yield saveENPSThreshold(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_THRESHOLD,
        payload: response.data,
      });
      yield put({
        type: GET_ENPS_OVERALL_TREND,
      });
      yield put({
        type: GET_ENPS_OVERALL_SCORE,
      });
    }
  }
}

function* fetchFavoriteFilters(action) {
  const response = yield getFavoriteFilters(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_FAVORITE_FILTERS_SUCCESS,
        payload: response.data.favoritefilter,
      });
    } else {
      yield put({
        type: GET_FAVORITE_FILTERS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_FAVORITE_FILTERS_FAILURE,
      payload: {},
    });
  }
}

function* updateFavoriteFilters(action) {
  const response = yield saveFavoriteFilters(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_FAVORITE_FILTERS_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_FAVORITE_FILTERS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SAVE_FAVORITE_FILTERS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_FAVORITE_FILTERS_FAILURE,
      payload: {},
    });
  }
}

function* removeFavoriteFilter(action) {
  const response = yield deleteFavoriteFilter(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: DELETE_FAVORITE_FILTER_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_FAVORITE_FILTERS,
        payload: response.data,
      });
    } else {
      yield put({
        type: DELETE_FAVORITE_FILTER_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: DELETE_FAVORITE_FILTER_FAILURE,
      payload: {},
    });
  }
}

function* updateInsightThreshold(action) {
  const response = yield saveInsightThreshold(action);
}
function* updatePositiveThreshold(action) {
  const response = yield savePositiveThreshold(action);
}
function* updateNegativeThreshold(action) {
  const response = yield saveNegativeThreshold(action);
}

function* fetchENPSTextScore(action) {
  const response = yield getENPSTextScore(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_TEXT_SCORE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_TEXT_SCORE_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_TEXT_SCORE_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSTopicScore(action) {
  const response = yield getENPSTopicScore(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_TOPIC_SCORE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_TOPIC_SCORE_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_TOPIC_SCORE_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSSurveyScore(action) {
  const response = yield getENPSSurveyScore(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_SURVEY_SCORE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_SURVEY_SCORE_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_SURVEY_SCORE_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSTextTrend(action) {
  const response = yield getENPSTextTrend(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_TEXT_TREND_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_TEXT_TREND_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_TEXT_TREND_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSTopicTrend(action) {
  const response = yield getENPSTopicTrend(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_TOPIC_TREND_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_TOPIC_TREND_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_TOPIC_TREND_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSSurveyTrend(action) {
  const response = yield getENPSSurveyTrend(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_SURVEY_TREND_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_SURVEY_TREND_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_SURVEY_TREND_FAILURE,
      payload: {},
    });
  }
}

function* addCustomFocusGroup(action) {
  const response = yield createCustomFocusGroup(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: CREATE_CUSTOM_FOCUS_GROUP_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_ALL_FOCUS_GROUPS,
      });
    } else {
      yield put({
        type: CREATE_CUSTOM_FOCUS_GROUP_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: CREATE_CUSTOM_FOCUS_GROUP_FAILURE,
      payload: {},
    });
  }
}

function* addFocusGroup(action) {
  const response = yield createFocusGroup(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_FOCUS_GROUPS,
        payload: response.data,
      });
    }
  }
}

function* fetchOverallENPSScore(action) {
  const response = yield getENPSOverallScore(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_OVERALL_SCORE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_OVERALL_SCORE_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_OVERALL_SCORE_FAILURE,
      payload: {},
    });
  }
}
function* fetchOverallENPSTrend(action) {
  const response = yield getENPSOverallTrend(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_OVERALL_TREND_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_OVERALL_TREND_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_OVERALL_TREND_FAILURE,
      payload: {},
    });
  }
}

function* fetchSurveySettings(action) {
  const response = yield getSurveySettings(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SURVEY_SETTINGS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_SURVEY_SETTINGS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_SURVEY_SETTINGS_FAILURE,
      payload: {},
    });
  }
}

function* updateSurveySettings(action) {
  const response = yield saveSurveySettings(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_SURVEY_SETTINGS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SAVE_SURVEY_SETTINGS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_SURVEY_SETTINGS_FAILURE,
      payload: {},
    });
  }
}

function* fetchEngagementTopics(action) {
  const response = yield getEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_TOPICS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_TOPICS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_TOPICS_FAILURE,
      payload: {},
    });
  }
}

function* updateEngagementTopics(action) {
  const response = yield saveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_TOPICS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SAVE_ENGAGEMENT_TOPICS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_ENGAGEMENT_TOPICS_FAILURE,
      payload: {},
    });
  }
}

function* fetchSuggesttedQuestion(action) {
  const response = yield getSuggestedQuestion(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_SUGGESTED_QUESTION_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_SUGGESTED_QUESTION_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_SUGGESTED_QUESTION_FAILURE,
      payload: {},
    });
  }
}

function* fetchInsights(action) {
  const response = yield getInsights(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_INSIGHTS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_INSIGHTS_FAILURE,
      payload: {},
    });
  }
}

function* addQuestion(action) {
  const response = yield addSurveyQuestion(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_SURVEYS,
      });
    }
  }
}

function* makeReport(action) {
  const response = yield generateFocusReport(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_FOCUS_GROUPS,
      });
    }
  }
}

function* fetchEngagementTopicsTrend(action) {
  const response = yield getEngagementTopicTrend(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_TOPIC_TREND_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_TOPIC_TREND_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_TOPIC_TREND_FAILURE,
      payload: {},
    });
  }
}

function* fetchFilterEmails(action) {
  const response = yield getFilterEmails(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_FILTER_EMAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_FILTER_EMAILS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_FILTER_EMAILS_FAILURE,
      payload: {},
    });
  }
}

function* fetchActiveEngagementTopics(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
      payload: {},
    });
  }
}

function* updateActiveEngagementTopics(action) {
  const response = yield saveActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ACTIVE_ENGAGEMENT_TOPICS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SAVE_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_ACTIVE_ENGAGEMENT_TOPICS_FAILURE,
      payload: {},
    });
  }
}

function* fetchMoraleGoal(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MORALE_GOAL_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MORALE_GOAL_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_MORALE_GOAL_FAILURE,
      payload: {},
    });
  }
}

function* fetchEngagementGoal(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENGAGEMENT_GOAL_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENGAGEMENT_GOAL_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENGAGEMENT_GOAL_FAILURE,
      payload: {},
    });
  }
}

function* fetchRetentionGoal(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_RETENTION_GOAL_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_RETENTION_GOAL_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_RETENTION_GOAL_FAILURE,
      payload: {},
    });
  }
}

function* fetchENPSGoal(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ENPS_GOAL_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ENPS_GOAL_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_ENPS_GOAL_FAILURE,
      payload: {},
    });
  }
}

function* fetchCultureGoal(action) {
  const response = yield getActiveEngagementTopics(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_CULTURE_GOAL_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_CULTURE_GOAL_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_CULTURE_GOAL_FAILURE,
      payload: {},
    });
  }
}

function* createFocusGroupActionPlan(action) {
  const response = yield makeFocusGroupActionPlan(action);

}



function* saveActionPlans(action) {
  const response = yield saveActionPlan(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ACTION_PLANS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SAVE_ACTION_PLAN_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_ACTION_PLAN_FAILURE,
      payload: {},
    });
  }
}





function* discardActionPlan(action) {
  const response = yield deleteActionPlan(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ACTION_PLANS,
        payload: response.data,
      });
    } else {
      yield put({
        type: DELETE_ACTION_PLAN_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: DELETE_ACTION_PLAN_FAILURE,
      payload: {},
    });
  }
}

function* startActionPlan(action) {
  const response = yield initiateActionPlan(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ACTION_PLANS,
        payload: response.data,
      });
    } else {
      yield put({
        type: INITIATE_ACTION_PLAN_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: INITIATE_ACTION_PLAN_FAILURE,
      payload: {},
    });
  }
}

function* completeActionPlan(action) {
  const response = yield closeActionPlan(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_ACTION_PLANS,
        payload: response.data,
      });
    } else {
      yield put({
        type: CLOSE_ACTION_PLAN_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: CLOSE_ACTION_PLAN_FAILURE,
      payload: {},
    });
  }
}


function* fetchDynamicFilters(action) {
  const response = yield getDynamicFilters(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_DYNAMIC_FILTERS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_DYNAMIC_FILTERS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_DYNAMIC_FILTERS_FAILURE,
      payload: {},
    });
  }
}




function* updateDynamicFilters(action) {
  const response = yield saveDynamicFilters(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SAVE_DYNAMIC_FILTERS_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_DYNAMIC_FILTERS,
      });
    } else {
      yield put({
        type: SAVE_DYNAMIC_FILTERS_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SAVE_DYNAMIC_FILTERS_FAILURE,
      payload: {},
    });
  }
}

function* fetchMinUserCount(action) {
  const response = yield getMinUserCount(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MINIMUM_USER_COUNT_SUCCESS,
        payload: response.data.MINIMUM_USER_COUNT,
      });
    } else {
      yield put({
        type: GET_MINIMUM_USER_COUNT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: GET_MINIMUM_USER_COUNT_FAILURE,
      payload: {},
    });
  }
}

function* updateMinUserCount(action) {
  const response = yield setMinUserCount(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_MINIMUM_USER_COUNT,
        payload: {}
      });
      yield put({
        type: SET_MINIMUM_USER_COUNT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SET_MINIMUM_USER_COUNT_FAILURE,
        payload: response.data,
      });
    }
  } else {
    yield put({
      type: SET_MINIMUM_USER_COUNT_FAILURE,
      payload: {},
    });
  }
}



function* DashboardSaga() {
  yield takeLatest(GET_TOTAL_CONVO_DETAILS, fetchTotalConvoDetails);
  yield takeLatest(GET_DETECTED_USER_DETAILS, fetchDetectedUserDetails);
  yield takeLatest(GET_PIPHISHING_MODEL, fetchPiAndPhishing);
  yield takeLatest(GET_WORKFLOW_DETAILS, fetchWorkflowDetails);
  yield takeLatest(GET_MODEL_CHANNEL, fetchModelChannel);
  yield takeLatest(UPLOAD_SOURCE_FILES, uploadSourceFiles);
  yield takeLatest(SAVE_WORKFLOW, saveWorkflowDetails);
  yield takeLatest(UPDATE_WORKFLOW, updateWorkflowDetails);
  yield takeLatest(GET_WORKFLOW, getWorkflow);
  yield takeLatest(BUILD_WORKFLOW, buildWorkflow);
  yield takeLatest(DELETE_WORKFLOW, deleteWorkflow);
  yield takeLatest(GET_USER_WORKFLOW, getUserWorkflows);
  yield takeLatest(CLEAR_DATA, clearData);
  yield takeLatest(CHECK_KEYS, checkKeysData);
  yield takeLatest(EMPTY_CHANNELS, emptyChannels);
  yield takeLatest(GET_CHANNELS, updateChannels);
  yield takeLatest(VERIFY_TOKEN, verifyToken);
  yield takeLatest(GET_ACCESS_TOKEN, getAccessTokenData);
  yield takeLatest(GET_ZOOM_TOKEN, getZoomTokenData);
  yield takeLatest(SAVING_ACCESS_TOKEN, savingTokenData);
  yield takeLatest(GET_EVENT, getEvent);
  yield takeLatest(GET_USER_DETAILS, getUserDetailsData);
  yield takeLatest(UPDATE_USER_DETAILS, updateUserDetailsData);
  yield takeLatest(UPDATE_PASSWORD, changePassword);
  yield takeLatest(RESET_PASSWORD, resetPasswordHandler);
  yield takeLatest(GET_INVESTIGATING_TABLE, getInvestigationTableData);
  yield takeLatest(
    GET_DETAILED_INVESTIGATING_TABLE,
    getDetailedInvestigationTableData
  );
  yield takeLatest(GET_MORALE_SCORE, getMoraleScoreData);
  yield takeLatest(GET_ENGAGEMENT, getEngagementData);
  yield takeLatest(GET_RETENTION, getRetentionData);
  yield takeLatest(GET_SUPPORTER_TREND, getSupporterData);
  yield takeLatest(GET_PROFANITY_TRENDS, getProfanityData);
  yield takeLatest(GET_DEI_TRENDS, getDEIData);

  yield takeLatest(GET_POSITIVE_CULTURE, fetchPositiveCulture);
  yield takeLatest(GET_NEGATIVE_CULTURE, fetchNegativeCulture);
  yield takeLatest(GET_HATE_SPEECH_TRENDS, getHateSpeechData);
  yield takeLatest(GET_INSULT_TRENDS, getInsultData);
  yield takeLatest(GET_SENTIMENTS_TRENDS, getSentimentsData);
  yield takeLatest(GET_SENTIMENTS_DIST, getSentimentsDistData);
  yield takeLatest(GET_SEXUAL_HARRAS_TRENDS, getSexualHarrasData);
  yield takeLatest(GET_SEXISM_TRENDS, getSexismData);
  yield takeLatest(GET_DIVERSITY_DATA, getDiversityData);
  yield takeLatest(GET_WORD_CLOUD_DATA, getWordCloudData);
  yield takeLatest(GET_TRENDING_TOPICS, getTrendingTopicsData);
  yield takeLatest(GET_ALL_ENGAGEMENT_TOPIC, fetchAllEngagementTopics);
  yield takeLatest(GET_HASH_TAGS, getHashTagsData);
  yield takeLatest(GET_POLITICAL_WORDS, getPoliticalWordsData);
  yield takeLatest(GET_TEXT_ABUSE_DATA, getTextAbuseData);
  yield takeLatest(GET_RACISM_TREND_DATA, getRacismData);
  yield takeLatest(ADD_NEW_USER, addUser);
  yield takeLatest(GET_USERS, getUsersData);
  yield takeLatest(DELETE_USER, handleDeleteUser);
  yield takeLatest(UPDATE_USER, handleUpdateUser);
  yield takeLatest(SAVE_TEAM_CHATS, saveChats);
  yield takeLatest(SAVE_DISCORD_ID, saveDisocrd);
  yield takeLatest(OVERVIEW_EXPORT, exportPdf);
  yield takeLatest(GET_CUSTOM_ANALYSIS_DATA, getCustomData);

  yield takeLatest(GET_EMOTION_JOY, getJoyData);
  yield takeLatest(GET_EMOTION_ANGER, getAngerData);
  yield takeLatest(GET_EMOTION_FEAR, getFearData);
  yield takeLatest(GET_EMOTION_SURPRISE, getSurpriseData);
  yield takeLatest(GET_EMOTION_SAD, getSadData);
  yield takeLatest(GET_EMOTION_TOTAL, getEmoTotalData);
  yield takeLatest(GET_EMOTION_NEUTRAL, getEmoNeutralData);
  yield takeLatest(GET_MORALE_USERS_NUMBER, getMoraleUserNum);
  yield takeLatest(SHOW_BUILD_STATUS, getBuildProgressStatus);
  yield takeLatest(GET_DASHBOARD_SECTIONS, getSections);
  yield takeLatest(SAVE_DASHBOARD_SECTIONS, saveSections);
  yield takeLatest(GET_TOTAL_TEXTS, fetchNumTexts);
  yield takeLatest(GET_TEAM_SIZE, fetchTeamSize);
  yield takeLatest(GET_TOPIC_NUMBER, fetchTopicNumber);
  yield takeLatest(GET_MORALE_CHANGE, fetchMoraleChange);

  yield takeLatest(GET_LICENSE_PLANS, fetchLicensePlans);
  yield takeLatest(OPT_LICENSE, handleOptLicence);
  yield takeLatest(GET_FILTER_CHANNEL, fetchFilterChannel);
  yield takeLatest(GET_HRIS_USERS, fetchHrisUsers);
  yield takeLatest(SAVE_HRIS_USERS, saveHRISUsers);
  yield takeLatest(ZOOM_FILE_UPLOAD, zoomfileUpload);
  yield takeLatest(GET_MORALE_COMPARISON, fetchMoraleComparison);
  yield takeLatest(GET_ENGAGEMENT_COMPARISON, fetchEngagementComparison);
  yield takeLatest(GET_RETENTION_COMPARISON, fetchRetentionComparison);
  yield takeLatest(GET_SUPPORTER_COMPARISON, fetchSupporterComparison);
  yield takeLatest(GET_MORALE_INSIGHTS, fetchMoraleInsights);
  yield takeLatest(GET_PUBLIC_HOLIDAYS, fetchPublicHolidays);

  yield takeLatest(GET_SINGLE_INSIGHTS, fetchSingleInsights);
  yield takeLatest(GET_MULTIPLE_INSIGHTS, fetchMultipleInsights);
  yield takeLatest(GET_NONE_INSIGHTS, fetchNoneInsight);

  yield takeLatest(GET_TOPIC_SINGLE_INSIGHTS, fetchTopicSingleInsights);
  yield takeLatest(GET_TOPIC_MULTIPLE_INSIGHTS, fetchTopicMultipleInsights);
  yield takeLatest(GET_TOPIC_NONE_INSIGHTS, fetchTopicNoneInsights);

  yield takeLatest(GET_EVENT_SINGLE_INSIGHTS, fetchEventSingleInsights);
  yield takeLatest(GET_EVENT_MULTIPLE_INSIGHTS, fetchEventMultipleInsights);
  yield takeLatest(GET_EVENT_NONE_INSIGHTS, fetchEventNoneInsights);

  yield takeLatest(GET_CALENDER_EVENTS, fetchCalenderEvents);
  yield takeLatest(ADD_CALENDER_EVENT, addCalenderEvent);
  yield takeLatest(DELETE_CALENDER_EVENT, removeCalenderEvent);
  yield takeLatest(UPDATE_CALENDER_EVENT, changeCalenderEvent);

  yield takeLatest(GET_ALL_SURVEYS, fetchAllSurveys);
  yield takeLatest(GET_ALL_BASELINE_SURVEYS, fetchAllBaselineSurvey);

  yield takeLatest(GET_ALL_FOCUS_GROUPS, fetchAllFocusGroups);
  yield takeLatest(GET_ALL_FOCUS_CHAT, fetchAllFocusChats);

  yield takeLatest(CREATE_CUSTOM_FOCUS_GROUP, addCustomFocusGroup);
  yield takeLatest(CREATE_FOCUS_GROUP, addFocusGroup);

  yield takeLatest(UPDATE_SURVEY, editSurvey);
  yield takeLatest(LAUNCH_SURVEY, sendSurvey);


  yield takeLatest(GET_STATUS_BOX, fetchStatusBox);
  yield takeLatest(UPDATE_STATUS_BOX, updateStatusBox);

  yield takeLatest(CREATE_CUSTOM_SURVEY, makeSurvey);

  yield takeLatest(DELETE_SURVEY_QUESTION, removeSurveyQuestion);

  yield takeLatest(UPDATE_SURVEY_QUESTION, changeSurveyQuestion);

  // yield takeLatest(GET_MORALE_TOPICS, fetchMoraleTopics);

  yield takeLatest(GET_INSIGHTS_THRESHOLD, fetchInsightThreshold);

  yield takeLatest(GET_POSITIVE_CULTURE_THRESHOLD, fetchPositiveThreshold);

  yield takeLatest(GET_NEGATIVE_CULURE_THRESHOLD, fetchNegativeThreshold);

  yield takeLatest(SAVE_INSIGHTS_THRESHOLD, updateInsightThreshold);
  yield takeLatest(SAVE_POSITIVE_CULTURE_THRESHOLD, updatePositiveThreshold);
  yield takeLatest(SAVE_NEGATIVE_CULURE_THRESHOLD, updateNegativeThreshold);

  yield takeLatest(SAVE_NEGATIVE_CULURE_THRESHOLD, updateNegativeThreshold);

  yield takeLatest(GET_ENGAGEMENT_THRESHOLD, fetchEngagementThreshold);

  yield takeLatest(SAVE_ENGAGEMENT_THRESHOLD, updateENgagementThreshold);

  yield takeLatest(GET_ENPS_THRESHOLD, fetchENPSThreshold);

  yield takeLatest(SAVE_ENPS_THRESHOLD, updateENPSThreshold);

  yield takeLatest(GET_OVERALL_CULTURE, fetchOverallCulture);

  yield takeLatest(GET_FAVORITE_FILTERS, fetchFavoriteFilters);
  yield takeLatest(SAVE_FAVORITE_FILTERS, updateFavoriteFilters);
  yield takeLatest(DELETE_FAVORITE_FILTER, removeFavoriteFilter);

  yield takeLatest(GET_ENPS_TEXT_SCORE, fetchENPSTextScore);
  yield takeLatest(GET_ENPS_TOPIC_SCORE, fetchENPSTopicScore);
  yield takeLatest(GET_ENPS_SURVEY_SCORE, fetchENPSSurveyScore);
  yield takeLatest(GET_ENPS_OVERALL_SCORE, fetchOverallENPSScore);

  yield takeLatest(GET_ENPS_TEXT_TREND, fetchENPSTextTrend);
  yield takeLatest(GET_ENPS_TOPIC_TREND, fetchENPSTopicTrend);
  yield takeLatest(GET_ENPS_SURVEY_TREND, fetchENPSSurveyTrend);
  yield takeLatest(GET_ENPS_OVERALL_TREND, fetchOverallENPSTrend);

  yield takeLatest(GET_SURVEY_SETTINGS, fetchSurveySettings);
  yield takeLatest(SAVE_SURVEY_SETTINGS, updateSurveySettings);

  yield takeLatest(GET_ENGAGEMENT_TOPICS, fetchEngagementTopics);
  yield takeLatest(SAVE_ENGAGEMENT_TOPICS, updateEngagementTopics);

  yield takeLatest(GET_SUGGESTED_QUESTION, fetchSuggesttedQuestion);

  yield takeLatest(ADD_SURVEY_QUESTION, addQuestion);

  yield takeLatest(GET_INSIGHTS, fetchInsights);

  yield takeLatest(GENEREATE_FOCUS_GROUP_REPORT, makeReport);

  yield takeLatest(GET_ENGAGEMENT_TOPIC_TREND, fetchEngagementTopicsTrend);

  yield takeLatest(GET_FILTER_EMAILS, fetchFilterEmails);

  yield takeLatest(GET_ACTIVE_ENGAGEMENT_TOPICS, fetchActiveEngagementTopics);
  yield takeLatest(SAVE_ACTIVE_ENGAGEMENT_TOPICS, updateActiveEngagementTopics);

  // yield takeLatest(GET_MORALE_GOAL, fetchMoraleGoal);
  // yield takeLatest(GET_ENGAGEMENT_GOAL, fetchEngagementGoal);
  // yield takeLatest(GET_RETENTION_GOAL, fetchRetentionGoal);
  // yield takeLatest(GET_ENPS_GOAL, fetchENPSGoal);
  // yield takeLatest(GET_CULTURE_GOAL, fetchCultureGoal);

  yield takeLatest(GET_ALL_ACTION_PLANS, fetchAllActionPlans);

  yield takeLatest(SAVE_ACTION_PLAN, saveActionPlans);


  yield takeLatest(DELETE_ACTION_PLAN, discardActionPlan);

  yield takeLatest(INITIATE_ACTION_PLAN, startActionPlan);

  yield takeLatest(CLOSE_ACTION_PLAN, completeActionPlan);

  yield takeLatest(CREATE_FOCUS_GROUP_ACTION_PLAN, createFocusGroupActionPlan);

  yield takeLatest(GET_DYNAMIC_FILTERS, fetchDynamicFilters);
  yield takeLatest(SAVE_DYNAMIC_FILTERS, updateDynamicFilters);


  yield takeLatest(GET_MINIMUM_USER_COUNT, fetchMinUserCount);
  yield takeLatest(SET_MINIMUM_USER_COUNT, updateMinUserCount);


}

export default DashboardSaga;
