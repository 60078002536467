export const demoFavoritesInsights = [
    {
        "month": "2025-01",
        "morale": [
            {
                "statement": "Morale score for transgender employees decreased by 59.66% in October compared to September.",
                "score": -59.65909090909091,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "3213dbd6a9b60c152dd20b6d"
            },
            {
                "statement": "Morale score for transgender and male employees in the Accounting & Finance department decreased by 61.11% in October compared to September.",
                "score": -61.10526315789474,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "5fc39bbed9417d59bcb5773a"
            },
            {
                "statement": "Morale score for non-binary employees in the Operations department decreased by 62.63% in October compared to September.",
                "score": -62.63157894736842,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "9408333f59aa2df20ed6e308"
            }
        ],
        "retention": [
            {
                "statement": "Retention for transgender employees increased by 29.25% in October compared to September.",
                "score": -29.25,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "5cc6ed67654b786158118334"
            },
            {
                "statement": "Retention for transgender and male employees in the Accounting & Finance department increased by 33.42% in October compared to September.",
                "score": -33.42,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "d250bfd4e060c9852282c398"
            },
            {
                "statement": "Retention for non-binary employees in the Operations department increased by 38.63% in October compared to September.",
                "score": -38.63,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "b08a8f1e8b6ac2d9fb57b21c"
            }
        ],
        "enps": [
            {
                "statement": "Employee net promoter score for transgender employees remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "d3502006fed46feca1d7902b"
            },
            {
                "statement": "Employee net promoter score for transgender and males in Accounting & Finance remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "1e5b99457114387acfd282ad"
            },
            {
                "statement": "Employee net promoter score for non-binary employees in Operations remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "f661e9c8fe30a5f480128d1c"
            }
        ],
        "engagement": [
            {
                "statement": "Transgender users saw a 40.9% decrease in overall engagement from September to October.",
                "score": -40.904761904761905,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "1722446c9c16d3ef7449d18b"
            },
            {
                "statement": "Transgender and male users in Accounting & Finance saw a 45.74% decrease in overall engagement from September to October.",
                "score": -45.73809523809524,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "eff44e4524df14489b77d7e2"
            },
            {
                "statement": "Non-binary users in Operations saw a 37.25% decrease in overall engagement from September to October.",
                "score": -37.25,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "b4a3fc9a7afe524876cf082a"
            }
        ],
        "culture": [
            {
                "statement": "Transgender users experienced a decrease of 2 in Overall culture in October compared to September.",
                "score": -2,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "f44436230b7236a6d21fb60c"
            },
            {
                "statement": "Transgender and male users in Accounting & Finance saw a decrease of 7 in Overall culture from September to October.",
                "score": -7,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "4201f08350921841a66decca"
            },
            {
                "statement": "Non-binary users in Operations experienced a decrease of 11 in Overall culture in October compared to September.",
                "score": -11,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "0cef271a2f73f50c37f6b4a8"
            }
        ],
        "topic": []
    }
]

export const demoQuarterInsights = {
    "message": "Quarter Single Insight Fetched Successfully",
    "quarterSingleInsight": [
        {
            "insightID": "KI1220243581",
            "name": "Infosys_December_insight_17",
            "insight": {
                "morale": [
                    {
                        "statement": "Morale among employees aged 50+ decreased by 6.45% in Q3 2024 compared to Q2 2024.",
                        "score": -6.45,
                        "feature": "{\"age\":{\"$gte\":50}}",
                        "surveyID": "7a4156f38277c251068a45cf"
                    },
                    {
                        "statement": "Morale among single employees decreased by 5.39% in Q3 2024 compared to Q2 2024.",
                        "score": -5.39,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "3126a55826c8ebb557ba012e"
                    },
                    {
                        "statement": "Morale in the Information Technology department fell by 3.24% in Q3 2024 compared to Q2 2024.",
                        "score": -3.24,
                        "feature": "{\"department\":\"Information Technology\"}",
                        "surveyID": "bc3050af5185f7fe3d1663e3"
                    },
                    {
                        "statement": "Morale among employees aged 25 and under in the Sales department dropped by 2.61% from Q2 to Q3 2024.",
                        "score": -2.61,
                        "feature": "{\"age\":{\"$lte\":25}}",
                        "surveyID": "69aeeffed9112f296ab58a0e"
                    },
                    {
                        "statement": "Morale in the Human Resources department fell by 2.34% in Q3 2024 compared to Q2 2024.",
                        "score": -2.34,
                        "feature": "{\"department\":\"Human Resources\"}",
                        "surveyID": "31b5ccd26ba349c374211918"
                    }
                ],
                "retention": [
                    {
                        "statement": "Retention risk for SVP employees with salaries between $100,001 and $250,000 rose by 9.11% from Q2 to Q3 2024.",
                        "score": -9.11,
                        "feature": "{\"management_level\":\"SVP\"}",
                        "surveyID": "4f42df7bb570369c8c8008e9"
                    },
                    {
                        "statement": "Retention risk for North America employees earning $100,001 to $250,000 rose by 8.05% from Q2 to Q3 2024.",
                        "score": -8.05,
                        "feature": "{\"region\":\"North America\"}",
                        "surveyID": "d4042bce90f10515c9074d85"
                    },
                    {
                        "statement": "Retention risk for IT employees earning $100,001 to $250,000 rose by 5.65% in Q3 2024 vs. Q2 2024.",
                        "score": -5.65,
                        "feature": "{\"department\":\"Information Technology\"}",
                        "surveyID": "cf2627faa1d37a2ce0b62193"
                    },
                    {
                        "statement": "Retention risk for individual contributors with salaries between $100,001 and $250,000 rose by 5.28% from Q2 to Q3 2024.",
                        "score": -5.28,
                        "feature": "{\"management_level\":\"Individual Contributor\"}",
                        "surveyID": "ab2364a2ed54f39c84858c72"
                    },
                    {
                        "statement": "Retention risk for VP-level employees with salaries between $100,001 and $250,000 rose by 4.98% from Q2 to Q3 2024.",
                        "score": -4.98,
                        "feature": "{\"management_level\":\"VP\"}",
                        "surveyID": "fa87de2f4fea07fc3b2cd821"
                    }
                ],
                "enps": [
                    {
                        "statement": "The single marital status group saw a negative change of 100 in employee net promoter score in Q3 2024 compared to Q2 2024.",
                        "score": -100,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "59de17d096f8baecbf40b8a9"
                    },
                    {
                        "statement": "The Human Resources department saw a negative change of 36 in employee net promoter score from Q2 to Q3 2024.",
                        "score": -36,
                        "feature": "{\"department\":\"Human Resources\"}",
                        "surveyID": "3e4f28349cba53606f01d26f"
                    },
                    {
                        "statement": "The Asian ethnicity group saw a negative change of 36 in employee net promoter score in Q3 2024 compared to Q2 2024.",
                        "score": -36,
                        "feature": "{\"ethnicity\":\"Asian\"}",
                        "surveyID": "93e33e8fd218d3a7f8f48e5b"
                    },
                    {
                        "statement": "In Q3 2024, the female employee net promoter score dropped by 31 points from Q2 2024.",
                        "score": -31,
                        "feature": "{\"gender\":\"female\"}",
                        "surveyID": "e0ce6e38f8f391ccd7545190"
                    },
                    {
                        "statement": "Europe saw a -27 change in employee net promoter score in Q3 2024 vs. Q2 2024 for employees with 5-9 years of experience.",
                        "score": -27,
                        "feature": "{\"experience\":{\"$gte\":5,\"$lte\":9}}",
                        "surveyID": "ee7d4ca2a3e1b6ab09a599d5"
                    }
                ],
                "engagement": [
                    {
                        "statement": "Engagement for transgender individuals dropped by 36.15% from Q2 to Q3 2024.",
                        "score": -36.15,
                        "feature": "{\"gender\":\"transgender\"}",
                        "surveyID": "34e81ee56449b2dd1b275f13"
                    },
                    {
                        "statement": "Engagement for Directors decreased by 35.79% in Q3 2024 compared to Q2 2024.",
                        "score": -35.79,
                        "feature": "{\"management_level\":\"Director\"}",
                        "surveyID": "bdda14cca246a552d5b04813"
                    },
                    {
                        "statement": "Engagement for incomes between $100,001 and $250,000 dropped by 35.63% from Q2 to Q3 2024.",
                        "score": -35.63,
                        "feature": "{\"income\":{\"$gte\":100001,\"$lte\":250000}}",
                        "surveyID": "67f0ad5f1ec2b58b4482e39b"
                    },
                    {
                        "statement": "Engagement in the Home Office location dropped by 35.21% from Q2 2024 to Q3 2024.",
                        "score": -35.21,
                        "feature": "{\"location\":\"Home Office\"}",
                        "surveyID": "f7a6429bf54d39b4de679a5e"
                    },
                    {
                        "statement": "Hispanic engagement dropped by 34.85% in Q3 2024 compared to Q2 2024.",
                        "score": -34.85,
                        "feature": "{\"ethnicity\":\"Hispanic\"}",
                        "surveyID": "0f18a9fb1271fb246408d4a8"
                    }
                ],
                "culture": [
                    {
                        "statement": "The onsite office type experienced a decrease in Overall culture by 25 points in Q3 2024 compared to Q2 2024.",
                        "score": -25,
                        "feature": "{\"Office Type\":\"Onsite\"}",
                        "surveyID": "13e01cd02d3d6d732b0a3794"
                    },
                    {
                        "statement": "Individuals aged 50 and above experienced a decrease in Overall culture by 13 points in Q3 2024 compared to Q2 2024.",
                        "score": -13,
                        "feature": "{\"age\":{\"$gte\":50}}",
                        "surveyID": "cd656fde0f5de1ca5f771494"
                    },
                    {
                        "statement": "The African American ethnicity experienced a decrease in Overall culture by 8 points in Q3 2024 compared to Q2 2024.",
                        "score": -8,
                        "feature": "{\"ethnicity\":\"African American\"}",
                        "surveyID": "a22668ea6027a5479f08eaef"
                    },
                    {
                        "statement": "The management level of Director showed an increase in Overall culture of 7 score in Q3 2024 compared to Q2 2024.",
                        "score": 7,
                        "feature": "{\"management_level\":\"Director\"}",
                        "surveyID": "52d54ced9a3c673418efb449"
                    },
                    {
                        "statement": "The Other ethnicity group experienced a decrease in Overall culture by 7 points in Q3 2024 compared to Q2 2024.",
                        "score": -7,
                        "feature": "{\"ethnicity\":\"Other\"}",
                        "surveyID": "52e3a9dcd3c82d49816295e8"
                    }
                ],
                "topic": [
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals earning $100,001 to $250,000 is Company Culture, with 1,015 mentions.",
                        "score": 1015,
                        "feature": "{\"income\":{\"$gte\":100001,\"$lte\":250000}}",
                        "surveyID": "fb2268ece06a401e49a10a3e"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals aged 36-49 is Company Culture, with 819 mentions.",
                        "score": 819,
                        "feature": "{\"age\":{\"$gte\":36,\"$lte\":49}}",
                        "surveyID": "9bb549fc9e78b687fd92f71d"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among single individuals is Company Culture, with 742 mentions.",
                        "score": 742,
                        "feature": "{\"marital_status\":\"Single\"}",
                        "surveyID": "6edbe3db8505f90168c40bf6"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among married individuals is Company Culture, with 688 mentions.",
                        "score": 688,
                        "feature": "{\"marital_status\":\"Married\"}",
                        "surveyID": "75bc2cdbb511207be36c1934"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals with 5-9 years of experience is Company Culture, with 661 mentions.",
                        "score": 661,
                        "feature": "{\"experience\":{\"$gte\":5,\"$lte\":9}}",
                        "surveyID": "b588685b1a43d7008e896cb0"
                    }
                ]
            },
            "positiveinsight": {
                "morale": [
                    {
                        "statement": "Morale in the Accounting & Finance department rose by 2.28% in Q3 2024 compared to Q2 2024.",
                        "score": 2.28,
                        "feature": "{\"department\":\"Accounting & Finance\"}",
                        "surveyID": "a68aa9822fe8ca47ae6fda9b"
                    },
                    {
                        "statement": "Morale among Executive-level employees rose by 2.15% in Q3 2024 compared to Q2 2024.",
                        "score": 2.15,
                        "feature": "{\"management_level\":\"Executive\"}",
                        "surveyID": "d956d26c7fbfb78737c16432"
                    },
                    {
                        "statement": "Morale among onsite employees rose by 2.03% in Q3 2024 compared to Q2 2024.",
                        "score": 2.03,
                        "feature": "{\"Office Type\":\"Onsite\"}",
                        "surveyID": "2b2b18a6241534fa3de4217a"
                    },
                    {
                        "statement": "Morale among African American employees rose by 1.69% in Q3 2024 compared to Q2 2024.",
                        "score": 1.69,
                        "feature": "{\"ethnicity\":\"African American\"}",
                        "surveyID": "3f5d78c77f8a4a3d510a559e"
                    },
                    {
                        "statement": "Morale in the Marketing department rose by 1.4% in Q3 2024 compared to Q2 2024.",
                        "score": 1.4,
                        "feature": "{\"department\":\"Marketing\"}",
                        "surveyID": "481e6784abc87f590a02d46b"
                    }
                ],
                "retention": [
                    {
                        "statement": "Retention risk for single employees decreased by 3.26% in Q3 2024 compared to Q2 2024.",
                        "score": 3.26,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "20e3eb54d2d3dcb35e50cbd3"
                    },
                    {
                        "statement": "Retention risk for executive-level employees with salaries between $100,001 and $250,000 decreased by 3.1% from Q2 to Q3 2024.",
                        "score": 3.1,
                        "feature": "{\"management_level\":\"Executive\"}",
                        "surveyID": "f8b5b13e8a09d0f29d75b627"
                    },
                    {
                        "statement": "Retention risk for Directors decreased by 2.63% in Q3 2024 vs. Q2 2024 for employees earning $100,001 to $250,000.",
                        "score": 2.63,
                        "feature": "{\"management_level\":\"Director\"}",
                        "surveyID": "28969770776550627a872e11"
                    },
                    {
                        "statement": "Retention risk for employees aged 25 and under decreased by 2.49% in Q3 2024 compared to Q2 2024.",
                        "score": 2.49,
                        "feature": "{\"age\":{\"$lte\":25}}",
                        "surveyID": "73a882b561ba7d256b04a520"
                    },
                    {
                        "statement": "Retention risk for Caucasian employees decreased by 1.59% in Q3 2024 compared to Q2 2024.",
                        "score": 1.59,
                        "feature": "{\"ethnicity\":\"Caucasian\"}",
                        "surveyID": "cadbdc3cb1fce50e01add6f1"
                    }
                ],
                "enps": [
                    {
                        "statement": "The management level of Individual Contributors saw a positive change of 20 in employee net promoter score from Q2 to Q3 2024.",
                        "score": 20,
                        "feature": "{\"management_level\":\"Individual Contributor\"}",
                        "surveyID": "0cfad4464ad0e078b73549ff"
                    },
                    {
                        "statement": "Europe saw a 7-point rise in employee net promoter score from Q2 to Q3 2024, with incomes between $50,001 and $100,000.",
                        "score": 7,
                        "feature": "{\"income\":{\"$gte\":50001,\"$lte\":100000}}",
                        "surveyID": "cd68f0b28cb6e049541bc15a"
                    },
                    {
                        "statement": "The Accounting & Finance department saw a positive change of 6 in employee net promoter score from Q2 to Q3 2024.",
                        "score": 6,
                        "feature": "{\"department\":\"Accounting & Finance\"}",
                        "surveyID": "eed1a9902be07147e7ddd31b"
                    },
                    {
                        "statement": "The African American employee net promoter score rose by 6 in Q3 2024 compared to Q2 2024.",
                        "score": 6,
                        "feature": "{\"ethnicity\":\"African American\"}",
                        "surveyID": "97c6af6551e96b0c9779be14"
                    },
                    {
                        "statement": "Europe saw a positive change of 5 in employee net promoter score from Q2 to Q3 2024, with 1-4 years of experience.",
                        "score": 5,
                        "feature": "{\"experience\":{\"$gte\":1,\"$lt\":5}}",
                        "surveyID": "b8b6ccd8762b7e876dd7c1bd"
                    }
                ],
                "engagement": [
                    {
                        "statement": "Single individuals saw a 20.12% increase in engagement from Q2 to Q3 2024.",
                        "score": 20.12,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "1740aa235dbf63bc643e29fb"
                    },
                    {
                        "statement": "Engagement for onsite employees rose by 6.68% in Q3 2024 compared to Q2 2024.",
                        "score": 6.68,
                        "feature": "{\"Office Type\":\"Onsite\"}",
                        "surveyID": "96773613c57afcbba411cc9e"
                    },
                    {
                        "statement": "Engagement for gender 1 showed no change of 0% from Q2 2024 to Q3 2024 under the specified filters.",
                        "score": 0,
                        "feature": "{\"gender\":\"1\"}",
                        "surveyID": "ea81ad0fe078755c4a866279"
                    },
                    {
                        "statement": "Engagement for dogs remained unchanged at 0% from Q2 2024 to Q3 2024.",
                        "score": 0,
                        "feature": "{\"gender\":\"dog\"}",
                        "surveyID": "a21dfa5e35229e2e0e5af3ff"
                    },
                    {
                        "statement": "Engagement remained unchanged at 0% for department 1 between Q3 2024 and Q2 2024.",
                        "score": 0,
                        "feature": "{\"department\":\"1\"}",
                        "surveyID": "b8cee958339897ce29567f21"
                    }
                ],
                "culture": [
                    {
                        "statement": "The management level of Director showed an increase in Overall culture of 7 score in Q3 2024 compared to Q2 2024.",
                        "score": 7,
                        "feature": "{\"management_level\":\"Director\"}",
                        "surveyID": "d445853403360e079fa0d8f6"
                    },
                    {
                        "statement": "The SVP management level experienced a change in Overall culture with a score difference of 5 in Q3 2024 compared to Q2 2024.",
                        "score": 5,
                        "feature": "{\"management_level\":\"SVP\"}",
                        "surveyID": "4e50f0fb274a204e1d200560"
                    },
                    {
                        "statement": "Caucasian ethnicity experienced a change of 5 in Overall culture in Q3 2024 compared to Q2 2024.",
                        "score": 5,
                        "feature": "{\"ethnicity\":\"Caucasian\"}",
                        "surveyID": "e6a719536e3e450fe8d6488a"
                    },
                    {
                        "statement": "The non-binary gender group experienced a change of 4 in Overall culture in Q3 2024 compared to Q2 2024.",
                        "score": 4,
                        "feature": "{\"gender\":\"non-binary\"}",
                        "surveyID": "466076e629eb7a601f9e80b9"
                    },
                    {
                        "statement": "The experience range of 1 to 4 years showed a change in Overall culture of 4 score in Q3 2024 compared to Q2 2024.",
                        "score": 4,
                        "feature": "{\"experience\":{\"$gte\":1,\"$lt\":5}}",
                        "surveyID": "d9ba96d98b3a58248b5cd723"
                    }
                ],
                "topic": [
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals earning $100,001 to $250,000 is Company Culture, with 1,015 mentions.",
                        "score": 1015,
                        "feature": "{\"income\":{\"$gte\":100001,\"$lte\":250000}}",
                        "surveyID": "fb2268ece06a401e49a10a3e"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals aged 36-49 is Company Culture, with 819 mentions.",
                        "score": 819,
                        "feature": "{\"age\":{\"$gte\":36,\"$lte\":49}}",
                        "surveyID": "9bb549fc9e78b687fd92f71d"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among single individuals is Company Culture, with 742 mentions.",
                        "score": 742,
                        "feature": "{\"marital_status\":\"Single\"}",
                        "surveyID": "6edbe3db8505f90168c40bf6"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among married individuals is Company Culture, with 688 mentions.",
                        "score": 688,
                        "feature": "{\"marital_status\":\"Married\"}",
                        "surveyID": "75bc2cdbb511207be36c1934"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals with 5-9 years of experience is Company Culture, with 661 mentions.",
                        "score": 661,
                        "feature": "{\"experience\":{\"$gte\":5,\"$lte\":9}}",
                        "surveyID": "b588685b1a43d7008e896cb0"
                    }
                ]
            },
            "negativeinsight": {
                "morale": [
                    {
                        "statement": "Morale among employees aged 50+ decreased by 6.45% in Q3 2024 compared to Q2 2024.",
                        "score": -6.45,
                        "feature": "{\"age\":{\"$gte\":50}}",
                        "surveyID": "30b4733a49828be33f32a3a0"
                    },
                    {
                        "statement": "Morale among single employees decreased by 5.39% in Q3 2024 compared to Q2 2024.",
                        "score": -5.39,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "617b41e89c29585c4d58bd02"
                    },
                    {
                        "statement": "In the Information Technology department, morale dropped by 3.24% from Q2 2024 to Q3 2024.",
                        "score": -3.24,
                        "feature": "{\"department\":\"Information Technology\"}",
                        "surveyID": "6f75ee101a12f8efbd1e2412"
                    },
                    {
                        "statement": "Morale among employees aged 25 and under in the Sales department dropped by 2.61% from Q2 to Q3 2024.",
                        "score": -2.61,
                        "feature": "{\"age\":{\"$lte\":25}}",
                        "surveyID": "85a8a627bd71fcde6e018a05"
                    },
                    {
                        "statement": "Morale in the Human Resources department fell by 2.34% in Q3 2024 compared to Q2 2024.",
                        "score": -2.34,
                        "feature": "{\"department\":\"Human Resources\"}",
                        "surveyID": "65f0818ab07fce662b4bbe7e"
                    }
                ],
                "retention": [
                    {
                        "statement": "Retention risk for SVP employees with salaries between $100,001 and $250,000 rose by 9.11% from Q2 to Q3 2024.",
                        "score": -9.11,
                        "feature": "{\"management_level\":\"SVP\"}",
                        "surveyID": "03ebad77150456d721a3fb6f"
                    },
                    {
                        "statement": "Retention risk for North America employees earning $100,001 to $250,000 rose by 8.05% from Q2 to Q3 2024.",
                        "score": -8.05,
                        "feature": "{\"region\":\"North America\"}",
                        "surveyID": "9c84706d32dcaaf121f90290"
                    },
                    {
                        "statement": "Retention risk for IT employees earning $100,001 to $250,000 rose by 5.65% in Q3 2024 vs. Q2 2024.",
                        "score": -5.65,
                        "feature": "{\"department\":\"Information Technology\"}",
                        "surveyID": "eb6d0f4fdb066aa956338955"
                    },
                    {
                        "statement": "Retention risk for individual contributors with salaries between $100,001 and $250,000 rose by 5.28% from Q2 to Q3 2024.",
                        "score": -5.28,
                        "feature": "{\"management_level\":\"Individual Contributor\"}",
                        "surveyID": "9c55705373929c62e0c81d83"
                    },
                    {
                        "statement": "Retention risk for VP-level employees with salaries between $100,001 and $250,000 rose by 4.98% from Q2 to Q3 2024.",
                        "score": -4.98,
                        "feature": "{\"management_level\":\"VP\"}",
                        "surveyID": "ec05e111dc6a4ecbe14b399c"
                    }
                ],
                "enps": [
                    {
                        "statement": "The single marital status group saw a negative change of 100 in employee net promoter score in Q3 2024 compared to Q2 2024.",
                        "score": -100,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "40ad791c59e20b87d8e8ace9"
                    },
                    {
                        "statement": "The Human Resources department saw a negative change of 36 in employee net promoter score from Q2 to Q3 2024.",
                        "score": -36,
                        "feature": "{\"department\":\"Human Resources\"}",
                        "surveyID": "760fa2e8165482c368fa4010"
                    },
                    {
                        "statement": "The Asian ethnicity group saw a negative change of 36 in employee net promoter score from Q2 to Q3 2024.",
                        "score": -36,
                        "feature": "{\"ethnicity\":\"Asian\"}",
                        "surveyID": "b21a16492482a35a3ccbb320"
                    },
                    {
                        "statement": "In Q3 2024, the female employee net promoter score dropped by 31 points from Q2 2024.",
                        "score": -31,
                        "feature": "{\"gender\":\"female\"}",
                        "surveyID": "9770e63e18212b5535dc4b95"
                    },
                    {
                        "statement": "Europe saw a -27 change in employee net promoter score in Q3 2024 vs. Q2 2024 for employees with 5-9 years of experience.",
                        "score": -27,
                        "feature": "{\"experience\":{\"$gte\":5,\"$lte\":9}}",
                        "surveyID": "d40f272680ea87bfe97f2737"
                    }
                ],
                "engagement": [
                    {
                        "statement": "Engagement for transgender individuals dropped by 36.15% in Q3 2024 compared to Q2 2024.",
                        "score": -36.15,
                        "feature": "{\"gender\":\"transgender\"}",
                        "surveyID": "30f4000f3711a768ef72eca8"
                    },
                    {
                        "statement": "Engagement for Directors decreased by 35.79% in Q3 2024 compared to Q2 2024.",
                        "score": -35.79,
                        "feature": "{\"management_level\":\"Director\"}",
                        "surveyID": "f164695f4861da6f99050f7b"
                    },
                    {
                        "statement": "Engagement for incomes between $100,001 and $250,000 dropped by 35.63% from Q2 to Q3 2024.",
                        "score": -35.63,
                        "feature": "{\"income\":{\"$gte\":100001,\"$lte\":250000}}",
                        "surveyID": "eb1b6c425d6adf36b0bca736"
                    },
                    {
                        "statement": "Engagement in the Home Office category dropped by 35.21% from Q2 2024 to Q3 2024.",
                        "score": -35.21,
                        "feature": "{\"location\":\"Home Office\"}",
                        "surveyID": "25c9347aa2e78516832cfa8d"
                    },
                    {
                        "statement": "Hispanic engagement dropped by 34.85% in Q3 2024 compared to Q2 2024.",
                        "score": -34.85,
                        "feature": "{\"ethnicity\":\"Hispanic\"}",
                        "surveyID": "41760f1c3636e7feb7ce8826"
                    }
                ],
                "culture": [
                    {
                        "statement": "The onsite office type experienced a decrease in Overall culture by 25 points in Q3 2024 compared to Q2 2024.",
                        "score": -25,
                        "feature": "{\"Office Type\":\"Onsite\"}",
                        "surveyID": "df1a95c9bcb1de00158b2110"
                    },
                    {
                        "statement": "Individuals aged 50 and above experienced a decrease in Overall culture by 13 points in Q3 2024 compared to Q2 2024.",
                        "score": -13,
                        "feature": "{\"age\":{\"$gte\":50}}",
                        "surveyID": "2991a6e18064f1ea68ef1346"
                    },
                    {
                        "statement": "The African American ethnicity showed a decrease in Overall culture of 8 score in Q3 2024 compared to Q2 2024.",
                        "score": -8,
                        "feature": "{\"ethnicity\":\"African American\"}",
                        "surveyID": "e80ae6544462396f414bb2fe"
                    },
                    {
                        "statement": "The Other ethnicity group experienced a decrease in Overall culture by 7 points in Q3 2024 compared to Q2 2024.",
                        "score": -7,
                        "feature": "{\"ethnicity\":\"Other\"}",
                        "surveyID": "4578a73fe7a144321660176a"
                    },
                    {
                        "statement": "The single marital status group experienced a decrease in Overall culture by 7 points in Q3 2024 compared to Q2 2024.",
                        "score": -7,
                        "feature": "{\"marital_status\":\"single\"}",
                        "surveyID": "7da97d311baefeec696999da"
                    }
                ],
                "topic": [
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals earning $100,001 to $250,000 is Company Culture, with 1,015 mentions.",
                        "score": 1015,
                        "feature": "{\"income\":{\"$gte\":100001,\"$lte\":250000}}",
                        "surveyID": "fb2268ece06a401e49a10a3e"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals aged 36-49 is Company Culture, with 819 mentions.",
                        "score": 819,
                        "feature": "{\"age\":{\"$gte\":36,\"$lte\":49}}",
                        "surveyID": "9bb549fc9e78b687fd92f71d"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among single individuals is Company Culture, with 742 mentions.",
                        "score": 742,
                        "feature": "{\"marital_status\":\"Single\"}",
                        "surveyID": "6edbe3db8505f90168c40bf6"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among married individuals is Company Culture, with 688 mentions.",
                        "score": 688,
                        "feature": "{\"marital_status\":\"Married\"}",
                        "surveyID": "75bc2cdbb511207be36c1934"
                    },
                    {
                        "statement": "In Q3 2024, the most discussed topic among individuals with 5-9 years of experience is Company Culture, with 661 mentions.",
                        "score": 661,
                        "feature": "{\"experience\":{\"$gte\":5,\"$lte\":9}}",
                        "surveyID": "b588685b1a43d7008e896cb0"
                    }
                ]
            },
            "timeProcessed": "2024-12-17T08:28:08.284Z"
        }
    ]
}
