import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../../styles/loginPage.css";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import "../../../styles/profilePage.css";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import ClearIcon from "@material-ui/icons/Clear";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { AsYouType } from "libphonenumber-js";
import countryList from "react-select-country-list";
import { Tooltip } from "@material-ui/core";
import {
  GET_USER_DETAILS,
  UPDATE_PASSWORD,
  UPDATE_USER_DETAILS,
} from "../../../redux/Dashboard/Dashboard.types";

export default function ProfilePage() {
  const [currentScreen, setCurrentScreen] = useState("profile");
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [formDisabled, setFormDisabled] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [industry, setIndustry] = useState("");
  const [website, setWebsite] = useState("");
  const [countryVal, setCountryVal] = useState({});

  const [profilePic, setProfilePic] = useState("");
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const getProfileData = () => {
    !isDemo && dispatch({ type: GET_USER_DETAILS });
  };

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setCompanyName(userDetails.company_name);
    setFirstName(userDetails.first_name);
    setLastName(userDetails.last_name);
    setEmail(userDetails.email);
    setPhoneNumber(userDetails.phone);
    setCountryVal({
      value: userDetails.country,
      label: userDetails.country,
    });
    setProfilePic(userDetails.profilePic);
    if (userDetails.first_name === "Raymond") {
      setCompanyDescription("A leading provider of innovative solutions in the manufacturing sector, specializing in high-quality products and exceptional customer service.")
      setCompanyLocation("123 Innovation Drive, Springfield, IL, USA");
      setNumberOfEmployees(250);
      setIndustry("Manufacturing")
      setWebsite("www.acmecorp.org")

    }
  }, [userDetails]);

  const handleChangePasswordButtonClick = () => {
    if (oldPassword === "") {
      toast.error("Please Fill Old Password");
    } else if (newPassword === "") {
      toast.error("Please Fill New Password");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        !isDemo &&
          dispatch({
            type: UPDATE_PASSWORD,
            payload: {
              currentPassword: oldPassword,
              newPassword,
              verifyPassword: confirmPassword,
              userId: userID,
            },
          });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } catch (error) {
        toast.success(error);
      }
    }
  };

  const changeProfilePic = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setProfilePic(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  function getCountryCode(input) {
    // Set default country code to US if no real country code is specified
    const defaultCountryCode = input.substr(0, 1) !== "+" ? "US" : null;
    let formatted = new AsYouType(defaultCountryCode).input(input);
    let countryCode = "";
    let withoutCountryCode = formatted;

    if (defaultCountryCode === "US") {
      countryCode = "+1";
      formatted = "+1 " + formatted;
    } else {
      const parts = formatted.split(" ");
      countryCode = parts.length > 1 ? parts.shift() : "";
      withoutCountryCode = parts.join(" ");
    }

    return countryCode;
  }

  const handleUpdateProfile = () => {
    if (firstName === "") {
      toast.error("Please fill First Name");
    } else if (lastName === "") {
      toast.error("Please fill Last Name");
    } else if (email === "") {
      toast.error("Please fill Email");
    } else if (phoneNumber === "") {
      toast.error("Please fill Phone Number");
    } else if (!countryVal) {
      toast.error("Please Select Country");
    } else {
      const dataTobeUpdated = {
        company_name: companyName,
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        country: countryVal.value,
        userID,
        profilePic,
      };
      // const dataTobeUpdated = {
      //   first_name: firstName.trim(),
      //   last_name: lastName.trim(),
      //   email: email,
      //   company: {
      //     phoneNumber: phoneNumber,
      //     country: countryVal.label,
      //     name: companyName,
      //     countryCode: getCountryCode(`+${phoneNumber}`),
      //     address: address,
      //     userID,
      //     profilePic,
      //   },
      // };


      !isDemo &&
        dispatch({
          type: UPDATE_USER_DETAILS,
          payload: { ...dataTobeUpdated },
        });
      setFormDisabled(true);
    }
  };

  const handleCancelForUserDetails = () => {
    setCompanyName(userDetails.company_name);
    setFirstName(userDetails.first_name);
    setLastName(userDetails.last_name);
    setEmail(userDetails.email);
    setPhoneNumber(userDetails.phone);
    setCountryVal({
      value: userDetails.country,
      label: userDetails.country,
    });
    setProfilePic(userDetails.profilePic);
    setFormDisabled(true);
  };
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <div style={{ height: "91vh" }}>
      <div
        className="profileCard"
        style={!isNight ? { backgroundColor: "#212047" } : {}}
      >
        <div className="logic-sidebar">
          <div className="profile-info">
            <div>
              <li
                onClick={() => setCurrentScreen("profile")}
                className={`${currentScreen === "profile" ? "active profile" : ""
                  }`}
              >
                Personal
              </li>
            </div>

            <div>
              <li
                onClick={() => setCurrentScreen("organization")}
                className={`${currentScreen === "organization" ? "active organization" : ""
                  }`}
              >
                Organization
              </li>
            </div>

            <div>
              <li
                onClick={() => setCurrentScreen("change_pass")}
                className={`${currentScreen === "change_pass" ? "active change_pass" : ""
                  }`}
              >
                Change Password
              </li>
            </div>
          </div>
          {currentScreen === "profile" && (
            <div style={{ paddingBottom: "20px" }}>
              <div style={{ width: "75%", margin: "auto" }}>
                <div>
                  <div
                    role="presentation"
                    // className="navbarRight"
                    style={{ marginTop: "30px" }}
                  >
                    {/* <img src={clientPhoto} alt="" width="140" height="140" /> */}
                    {!profilePic ? (
                      <svg
                        style={{ filter: "invert(0.5)" }}
                        width="140"
                        height="140"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5 0C8.47115 0 0 8.47115 0 18.5C0 28.5289 8.47115 37 18.5 37C28.5289 37 37 28.5289 37 18.5C37 8.47115 28.5289 0 18.5 0ZM18.5 9.25C21.695 9.25 24.05 11.6032 24.05 14.8C24.05 17.9968 21.695 20.35 18.5 20.35C15.3069 20.35 12.95 17.9968 12.95 14.8C12.95 11.6032 15.3069 9.25 18.5 9.25ZM9.0539 27.3282C10.7134 24.8862 13.481 23.2582 16.65 23.2582H20.35C23.5209 23.2582 26.2867 24.8862 27.9461 27.3282C25.5818 29.859 22.2278 31.45 18.5 31.45C14.7723 31.45 11.4182 29.859 9.0539 27.3282Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <img
                        src={profilePic}
                        alt=""
                        width="140"
                        height="140"
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className="editIcon"
                  style={
                    !formDisabled
                      ? {
                        pointerEvents: "none",
                        opacity: "0.30",
                        filter: "alpha(opacity = 30)",
                        boxShadow: "none",
                      }
                      : {}
                  }
                >
                  <Tooltip title="Edit Profile">
                    <EditOutlinedIcon onClick={() => setFormDisabled(false)} />
                  </Tooltip>
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    value={isDemo ? "Demo" : firstName}
                    disabled={formDisabled}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    value={isDemo ? "User" : lastName}
                    disabled={formDisabled}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </div>
                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={isDemo ? "demo@scanta.io" : email}
                    disabled={true}

                  />
                </div>
                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    htmlFor="number"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country="us"
                    className="phoneNumber"
                    value={isDemo ? "19999999999" : phoneNumber}
                    onChange={(event) => setPhoneNumber(event)}
                    disabled={formDisabled}
                  />
                </div>


                {!formDisabled ? (
                  <div className="inputContainer">
                    <label
                      className="formLabels form_label"
                      style={!isNight ? { color: "#aebcc9" } : {}}
                    >
                      Profile Image
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      name="Profile Pic Upload"
                      disabled={formDisabled}
                      onChange={(event) => {
                        changeProfilePic(event);
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {!formDisabled && (
                  <div className="btnOptions" style={{ marginTop: "20px" }}>
                    <div
                      className="cancelBtn"
                      onClick={() => {
                        handleCancelForUserDetails(true);
                      }}
                    >
                      <div className="rangeBtn">
                        <ClearIcon style={{ color: "#000" }} />
                        <div
                          className="btnText"
                          style={{ color: !isNight ? "#aebcc9" : "#000" }}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                    <div className="submitBtn">
                      <div
                        className="rangeBtn"
                        type="submit"
                        onClick={handleUpdateProfile}
                      >
                        <TurnedInNotIcon />
                        <div className="btnText">Update</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentScreen === "organization" && (
            <div style={{ paddingBottom: "20px" }}>
              <div style={{ width: "75%", margin: "auto" }}>
                <div>
                  <div
                    role="presentation"
                    // className="navbarRight"
                    style={{ marginTop: "30px" }}
                  >

                  </div>
                </div>

                <div
                  className="editIcon"
                  style={
                    !formDisabled
                      ? {
                        pointerEvents: "none",
                        opacity: "0.30",
                        filter: "alpha(opacity = 30)",
                        boxShadow: "none",
                      }
                      : {}
                  }
                >
                  <Tooltip title="Edit Profile">
                    <EditOutlinedIcon onClick={() => setFormDisabled(false)} />
                  </Tooltip>
                </div>
                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={isDemo ? "Scanta Inc" : companyName}
                    onChange={(e) => { setCompanyName(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="company" style={{ minHeight: "100px", border: "1px solid #484848 !important" }}
                    value={isDemo ? "Scanta is a leading technology company specializing in innovative software solutions and digital transformation services." : companyDescription}
                    onChange={(e) => { setCompanyDescription(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={isDemo ? "San Francisco, California, United States" : companyLocation}
                    onChange={(e) => { setCompanyLocation(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Number of Employees
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={isDemo ? "25" : numberOfEmployees}
                    onChange={(e) => { setNumberOfEmployees(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Industry
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={isDemo ? "Technology" : industry}
                    onChange={(e) => { setIndustry(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div className="inputContainer">
                  <label
                    className="formLabels form_label"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Website
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={isDemo ? "www.scanta.io" : website}
                    onChange={(e) => { setWebsite(e.target.value) }}
                    disabled={formDisabled}
                  />
                </div>

                <div id="countryFlag" className="inputContainer">
                  <label
                    className="formLabels form_label"
                    htmlFor="number"
                    style={!isNight ? { color: "#aebcc9" } : {}}
                  >
                    Country
                  </label>
                  <div className="countryContainer">
                    <Select
                      isSearchable
                      options={countryOptions}
                      value={isDemo ? "india" : countryVal}
                      disabled={formDisabled}
                      onChange={(value) => setCountryVal(value)}
                    />
                  </div>
                </div>



                {!formDisabled && (
                  <div className="btnOptions" style={{ marginTop: "20px" }}>
                    <div
                      className="cancelBtn"
                      onClick={() => {
                        handleCancelForUserDetails(true);
                      }}
                    >
                      <div className="rangeBtn">
                        <ClearIcon style={{ color: "#000" }} />
                        <div
                          className="btnText"
                          style={{ color: !isNight ? "#aebcc9" : "#000" }}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                    <div className="submitBtn">
                      <div
                        className="rangeBtn"
                        type="submit"
                        onClick={handleUpdateProfile}
                      >
                        <TurnedInNotIcon />
                        <div className="btnText">Update</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentScreen === "change_pass" && (
            <div style={{ width: "75%", margin: "auto" }}>
              <div className="inputContainer">
                <label
                  className="formLabels form_label"
                  style={!isNight ? { color: "#aebcc9" } : {}}
                >
                  Old Password
                </label>
                <input
                  type="password"
                  name="oldPassword"
                  required
                  value={oldPassword}
                  onChange={(event) => {
                    setOldPassword(event.target.value);
                  }}
                />
              </div>
              <div className="inputContainer">
                <label
                  className="formLabels form_label"
                  style={!isNight ? { color: "#aebcc9" } : {}}
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  required
                  value={newPassword}
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                />
              </div>
              <div className="inputContainer">
                <label
                  className="formLabels form_label"
                  style={!isNight ? { color: "#aebcc9" } : {}}
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  required
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </div>
              <div className="btnOptions" style={{ marginTop: "20px" }}>
                {/* <div
                  className="cancelBtn"
                  // onClick={handleClose}
                >
                  <div className="rangeBtn">
                    <ClearIcon />
                    <div className="btnText">Cancel</div>
                  </div>
                </div> */}
                <div className="submitBtn">
                  <div
                    className="rangeBtn"
                    type="submit"
                    onClick={handleChangePasswordButtonClick}
                  >
                    <TurnedInNotIcon />
                    <div className="btnText">Change</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
