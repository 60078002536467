import {
  BUILD_WORKFLOW_FAILURE,
  BUILD_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  GET_DETECTED_USER_DETAILS_FAILURE,
  GET_DETECTED_USER_DETAILS_SUCCESS,
  GET_MODEL_CHANNEL_SUCCESS,
  GET_TOTAL_CONVO_DETAILS,
  GET_TOTAL_CONVO_DETAILS_FAILURE,
  GET_TOTAL_CONVO_DETAILS_SUCCESS,
  GET_WORKFLOW_DETAILS_SUCCESS,
  GET_WORKFLOW,
  GET_WORKFLOW_FAILURE,
  GET_WORKFLOW_SUCCESS,
  SAVE_WORKFLOW_SUCCESS,
  GET_MORALE_SCORE,
  GET_MORALE_SCORE_SUCCESS,
  GET_MORALE_SCORE_FAILURE,
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_FAILURE,
  GET_RETENTION,
  GET_RETENTION_SUCCESS,
  GET_RETENTION_FAILURE,
  GET_SUPPORTER_TREND,
  GET_SUPPORTER_TREND_SUCCESS,
  GET_SUPPORTER_TREND_FAILURE,
  UPLOAD_SOURCE_FILES_SUCCESS,
  GET_USER_WORKFLOW_SUCCESS,
  GET_USER_WORKFLOW_FAILURE,
  CLEAR_DATA_SUCCESS,
  EMPTY_CHANNELS_SUCCESS,
  SET_MODEL_CONFIGURATION,
  GET_PIPHISHING_MODEL_FAILURE,
  GET_PIPHISHING_MODEL_SUCCESS,
  GET_CHANNELS_SUCCESS,
  SET_ACTION_CONFIGURATION,
  VERIFY_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ZOOM_TOKEN_SUCCESS,
  SAVING_ACCESS_TOKEN_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_INVESTIGATING_TABLE_SUCCESS,
  GET_INVESTIGATING_TABLE_FAILURE,
  GET_DETAILED_INVESTIGATING_TABLE_SUCCESS,
  GET_DETAILED_INVESTIGATING_TABLE_FAILURE,
  GET_PROFANITY_TRENDS_SUCCESS,
  GET_PROFANITY_TRENDS_FAILURE,
  GET_DEI_TRENDS_SUCCESS,
  GET_DEI_TRENDS_FAILURE,
  GET_HATE_SPEECH_TRENDS,
  GET_HATE_SPEECH_TRENDS_SUCCESS,
  GET_HATE_SPEECH_TRENDS_FAILURE,
  GET_POSITIVE_CULTURE,
  GET_POSITIVE_CULTURE_SUCCESS,
  GET_POSITIVE_CULTURE_FAILURE,
  GET_NEGATIVE_CULTURE,
  GET_NEGATIVE_CULTURE_SUCCESS,
  GET_NEGATIVE_CULTURE_FAILURE,
  GET_INSULT_TRENDS,
  GET_INSULT_TRENDS_SUCCESS,
  GET_INSULT_TRENDS_FAILURE,
  GET_SENTIMENTS_TRENDS_SUCCESS,
  GET_SENTIMENTS_DIST_SUCCESS,
  GET_SENTIMENTS_DIST_FAILURE,
  GET_SEXUAL_HARRAS_TRENDS,
  GET_SEXUAL_HARRAS_TRENDS_SUCCESS,
  GET_SEXUAL_HARRAS_TRENDS_FAILURE,
  GET_SEXISM_TRENDS_SUCCESS,
  GET_WORD_CLOUD_DATA,
  GET_WORD_CLOUD_DATA_SUCCESS,
  GET_TRENDING_TOPICS,
  GET_TRENDING_TOPICS_SUCCESS,
  GET_HASH_TAGS,
  GET_HASH_TAGS_SUCCESS,
  GET_HASH_TAGS_FAILURE,
  GET_POLITICAL_WORDS,
  GET_POLITICAL_WORDS_SUCCESS,
  GET_POLITICAL_WORDS_FAILURE,
  GET_TEXT_ABUSE_DATA,
  GET_TEXT_ABUSE_DATA_SUCCESS,
  GET_TEXT_ABUSE_DATA_FAILURE,
  GET_RACISM_TREND_DATA,
  GET_RACISM_TREND_DATA_SUCCESS,
  GET_RACISM_TREND_DATA_FAILURE,
  GET_USERS_SUCCESS,
  SAVE_CHANNELSFROMTEAMS,
  SAVE_TEAMID,
  CHECK_SOURCE_DISCORD,
  CHARTS_IMAGE_DATA,
  SAVE_DISCORD_ID_SUCCESS,
  GET_DIVERSITY_DATA_SUCCESS,
  GET_CUSTOM_ANALYSIS_DATA_SUCCESS,
  GET_CUSTOM_ANALYSIS_DATA_FAILURE,
  GET_SENTIMENTS_DIST,
  GET_SENTIMENTS_TRENDS,
  SET_FILTERS,
  GET_EMOTION_JOY,
  GET_EMOTION_JOY_SUCCESS,
  GET_EMOTION_JOY_FAILURE,
  GET_EMOTION_FEAR,
  GET_EMOTION_FEAR_SUCCESS,
  GET_EMOTION_FEAR_FAILURE,
  GET_EMOTION_SURPRISE,
  GET_EMOTION_SURPRISE_SUCCESS,
  GET_EMOTION_SURPRISE_FAILURE,
  GET_EMOTION_SAD,
  GET_EMOTION_SAD_SUCCESS,
  GET_EMOTION_SAD_FAILURE,
  GET_EMOTION_ANGER,
  GET_EMOTION_ANGER_SUCCESS,
  GET_EMOTION_ANGER_FAILURE,
  GET_EMOTION_TOTAL,
  GET_EMOTION_TOTAL_SUCCESS,
  GET_EMOTION_TOTAL_FAILURE,
  GET_EMOTION_NEUTRAL,
  GET_EMOTION_NEUTRAL_SUCCESS,
  GET_EMOTION_NEUTRAL_FAILURE,
  SAVE_DEMO_WORK,
  SET_DATE_FILTER_SENTIMENT,
  SET_SECTIONS,
  GET_MORALE_USERS_NUMBER,
  GET_MORALE_USERS_NUMBER_SUCCESS,
  GET_MORALE_USERS_NUMBER_FAILURE,
  SHOW_BUILD_STATUS,
  SHOW_BUILD_STATUS_SUCCESS,
  SHOW_BUILD_STATUS_FAILURE,
  GET_DASHBOARD_SECTIONS_SUCCESS,
  GET_DASHBOARD_SECTIONS_FAILURE,
  SET_NIGHT_MODE,
  GET_TEAM_SIZE,
  GET_TEAM_SIZE_SUCCESS,
  GET_TEAM_SIZE_FAILURE,
  GET_TOPIC_NUMBER,
  GET_TOPIC_NUMBER_SUCCESS,
  GET_TOPIC_NUMBER_FAILURE,
  GET_TOTAL_TEXTS,
  GET_TOTAL_TEXTS_SUCCESS,
  GET_TOTAL_TEXTS_FAILURE,
  GET_MORALE_CHANGE,
  GET_MORALE_CHANGE_SUCCESS,
  GET_MORALE_CHANGE_FAILURE,
  GET_LICENSE_PLANS_SUCCESS,
  GET_LICENSE_PLANS_FAILURE,
  OPT_LICENSE_SUCCESS,
  OPT_LICENSE_FAILURE,
  GET_FILTER_CHANNEL,
  GET_FILTER_CHANNEL_SUCCESS,
  GET_FILTER_CHANNEL_FAILURE,
  GET_HRIS_USERS,
  GET_HRIS_USERS_SUCCESS,
  GET_HRIS_USERS_FAILURE,
  SAVE_ZOOM_TRANSCRIPT,
  ZOOM_FILE_UPLOAD_SUCCESS,
  GET_MORALE_COMPARISON,
  GET_MORALE_COMPARISON_SUCCESS,
  GET_MORALE_COMPARISON_FAILURE,
  GET_ENGAGEMENT_COMPARISON,
  GET_ENGAGEMENT_COMPARISON_SUCCESS,
  GET_ENGAGEMENT_COMPARISON_FAILURE,
  GET_RETENTION_COMPARISON,
  GET_RETENTION_COMPARISON_SUCCESS,
  GET_RETENTION_COMPARISON_FAILURE,
  GET_SUPPORTER_COMPARISON,
  GET_SUPPORTER_COMPARISON_SUCCESS,
  GET_SUPPORTER_COMPARISON_FAILURE,
  GET_MORALE_INSIGHTS,
  GET_MORALE_INSIGHTS_SUCCESS,
  GET_MORALE_INSIGHTS_FAILURE,
  SET_CHAT_CONTEXT,
  GET_PUBLIC_HOLIDAYS_SUCCESS,
  GET_PUBLIC_HOLIDAYS_FAILURE,
  GET_SINGLE_INSIGHTS_SUCCESS,
  GET_SINGLE_INSIGHTS_FAILURE,
  GET_MULTIPLE_INSIGHTS_SUCCESS,
  GET_MULTIPLE_INSIGHTS_FAILURE,
  GET_CALENDER_EVENTS_SUCCESS,
  GET_CALENDER_EVENTS_FAILURE,
  ADD_CALENDER_EVENT,
  ADD_CALENDER_EVENT_SUCCESS,
  ADD_CALENDER_EVENT_FAILURE,
  SAVE_DEMO_ANNOTATION,
  UPDATE_CALENDER_EVENT_SUCCESS,
  UPDATE_CALENDER_EVENT_FAILURE,
  SET_LOADING,
  SET_LEVEL,
  TOGGLE_PUBLIC_HOLIDAYS,
  GET_ALL_SURVEYS_SUCCESS,
  GET_ALL_FOCUS_GROUPS_SUCCESS,
  GET_ALL_FOCUS_CHAT_SUCCESS,
  GET_ALL_ACTION_PLANS_SUCCESS,
  SET_CURRENT_SOURCE,
  GET_STATUS_BOX,
  GET_STATUS_BOX_SUCCESS,
  SET_MORALE_PREDICTION,
  SET_USER_ENGAGEMENT_PREDICTION,
  SET_TEXT_ENGAGEMENT_PREDICTION,
  SET_RETENTION_PREDICTION,
  GET_NONE_INSIGHTS_SUCCESS,
  GET_NONE_INSIGHTS_FAILURE,
  GET_MORALE_TOPICS_SUCCESS,
  GET_INSIGHTS_THRESHOLD_SUCCESS,
  GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS,
  GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS,
  GET_FAVORITE_FILTERS,
  GET_FAVORITE_FILTERS_SUCCESS,
  GET_ENPS_SURVEY_SCORE,
  GET_ENPS_SURVEY_SCORE_SUCCESS,
  GET_ENPS_TEXT_SCORE,
  GET_ENPS_TEXT_SCORE_SUCCESS,
  GET_ENPS_TEXT_SCORE_FAILURE,
  GET_ENPS_TOPIC_SCORE,
  GET_ENPS_TOPIC_SCORE_SUCCESS,
  GET_ENPS_TEXT_TREND,
  GET_ENPS_TEXT_TREND_SUCCESS,
  GET_ENPS_TEXT_TREND_FAILURE,
  GET_ENPS_TOPIC_TREND_SUCCESS,
  GET_ENPS_TOPIC_TREND_FAILURE,
  GET_ENPS_SURVEY_TREND_SUCCESS,
  GET_ENPS_SURVEY_TREND_FAILURE,
  GET_ENPS_SURVEY_TREND,
  GET_ENPS_TOPIC_TREND,
  GET_ENPS_TOPIC_SCORE_FAILURE,
  GET_ENPS_SURVEY_SCORE_FAILURE,
  GET_ENPS_OVERALL_SCORE_FAILURE,
  GET_ENPS_OVERALL_SCORE_SUCCESS,
  GET_ENPS_OVERALL_SCORE,
  GET_ENPS_OVERALL_TREND,
  GET_ENPS_OVERALL_TREND_SUCCESS,
  GET_ENPS_OVERALL_TREND_FAILURE,
  GET_OVERALL_CULTURE,
  GET_OVERALL_CULTURE_SUCCESS,
  GET_OVERALL_CULTURE_FAILURE,
  GET_ENGAGEMENT_THRESHOLD_SUCCESS,
  GET_ENPS_THRESHOLD_SUCCESS,
  GET_SURVEY_SETTINGS_SUCCESS,
  SAVE_SURVEY_SETTINGS_SUCCESS,
  GET_ENGAGEMENT_TOPICS_SUCCESS,
  GET_ENGAGEMENT_TOPICS_FAILURE,
  GET_SUGGESTED_QUESTION_SUCCESS,
  GET_ENGAGEMENT_TOPIC_TREND_SUCCESS,
  GET_ENGAGEMENT_TOPIC_TREND_FAILURE,
  GET_ENGAGEMENT_TOPIC_TREND,
  GET_FILTER_EMAILS_SUCCESS,
  GET_ALL_BASELINE_SURVEYS_SUCCESS,
  GET_TOPIC_SINGLE_INSIGHTS_SUCCESS,
  GET_TOPIC_SINGLE_INSIGHTS_FAILURE,
  GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS,
  GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE,
  GET_TOPIC_NONE_INSIGHTS_SUCCESS,
  GET_EVENT_SINGLE_INSIGHTS_SUCCESS,
  GET_EVENT_SINGLE_INSIGHTS_FAILURE,
  GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS,
  GET_EVENT_MULTIPLE_INSIGHTS_FAILURE,
  GET_EVENT_NONE_INSIGHTS_SUCCESS,
  GET_EVENT_NONE_INSIGHTS_FAILURE,
  GET_ALL_ENGAGEMENT_TOPIC,
  GET_ALL_ENGAGEMENT_TOPIC_SUCCESS,
  GET_ACTIVE_ENGAGEMENT_TOPICS,
  GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS,
  SET_TOPIC_ENGAGEMENT_PREDICTION,
  SET_OVERALL_ENGAGEMENT_PREDICTION,
  GET_MORALE_GOAL,
  GET_MORALE_GOAL_SUCCESS,
  GET_MORALE_GOAL_FAILURE,
  GET_ENGAGEMENT_GOAL_SUCCESS,
  GET_ENGAGEMENT_GOAL_FAILURE,
  GET_RETENTION_GOAL_SUCCESS,
  GET_RETENTION_GOAL_FAILURE,
  GET_ENPS_GOAL_SUCCESS,
  GET_ENPS_GOAL_FAILURE,
  GET_CULTURE_GOAL_SUCCESS,
  GET_CULTURE_GOAL_FAILURE,
  GET_MESSAGE_DELETION_DAYS_SUCCESS,
  GET_DYNAMIC_FILTERS_SUCCESS,
  ADD_DEMO_FILTERS,
  GET_MINIMUM_USER_COUNT_SUCCESS,
  GET_MINIMUM_USER_COUNT_FAILURE,
  GET_INSIGHT_SCHEDULE_SUCCESS,
  GET_INSIGHT_SCHEDULE_FAILURE,
  GET_FINANCIAL_YEAR_FAILURE,
  GET_FINANCIAL_YEAR_SUCCESS,
  GET_MESSAGE_DELETION_INTERVAL_SUCCESS,
  GET_MESSAGE_DELETION_INTERVAL_FAILURE,
  GET_HRIS_DATA_UPDATE_INTERVAL_SUCCESS,
  GET_HRIS_DATA_UPDATE_INTERVAL_FAILURE
} from "./Dashboard.types";

const isDemo = JSON.parse(localStorage.getItem("demoMode"));

const initialState = {
  totalConvoAnalyzed: {
    status: "loading",
    positiveDaa: [],
    negativeData: [],
    totalData: [],
  },
  totalConvoAnalyzedMale: {
    status: "loading",
    positiveDaa: [],
    negativeData: [],
    totalData: [],
  },
  totalConvoAnalyzedFemale: {
    status: "loading",
    positiveDaa: [],
    negativeData: [],
    totalData: [],
  },
  detectedUser: {
    totalCount: 12,
    internalUser: 20,
    externalUser: 30,
    xAxis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    yAxis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  emotionPercenatge: {
    joy: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    anger: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    sad: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    surprise: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    fear: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    total: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
    neutral: {
      emotionPercenatge: {
        Emotion: 0,
      },
      query: {},
    },
  },
  emotionPercenatgeMale: {
    joy: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    anger: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    sad: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    surprise: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    fear: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    total: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    neutral: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
  },
  emotionPercenatgeFemale: {
    joy: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    anger: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    sad: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    surprise: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    fear: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    total: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
    neutral: {
      emotionPercenatge: {
        Emotion: 0,
      },
    },
  },

  piPhishing: {
    piModels: {
      xAxisData: [],
      yAxisData: [],
    },
    phishingModel: {
      xAxisData: [],
      yAxisData: [],
    },
  },
  totalWorkflows: 0,
  actions: {
    totalNotifications: 0,
    totalAlerts: 0,
    totalEmails: 0,
    totalLogged: 0,
  },
  modelChannel: {
    data: {
      allChannels: [],
      allModels: [],
    },
  },
  uploadSourceStatus: {
    status: "",
    channels: [],
    dms: [],
    fileName: "",
  },
  workflow: {
    data: [],
    workflowName: "",
  },
  saveStatus: "",
  userWorkflows: {
    data: {
      Workflows: isDemo
        ? [
          {
            workflowName: "DemoWorkflow",
            workflowID: "oBxKDQP0yw",
          },
        ]
        : [],
    },
  },
  buildStatus: "",
  modelsConfigData: {
    emotionData: [],
    personalInfoData: [],
    profanityData: [],
    hateSpeechData: [],
    scriptData: [],
    textAbuseData: [],
    sexualHarassmentData: [],
    RacismData: [],
    SentimentData: [],
    insultData: [],
    sexismData: [],
    diversityData: [],
  },
  actionConfigData: {
    email: [],
    sms: [],
    alert: [],
    notification: [],
  },
  slackKey: {
    data: [],
  },
  accessToken: {
    data: {},
  },
  zoomRecordings: {},
  accessTokenSave: false,
  searchEvent: {
    eventId: "",
    timeDate: "",
    user: "",
    model: "",
    decision: "",
    action: "",
  },

  userDetails: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    company_name: "",
    permissions: {},
    role: "",
    profilePic: "",
    licenseActive: false,
    license: [],
  },

  tableData: [],
  detailedTableData: [],

  moraleScore: {
    status: "loading",
  },
  moraleComparison: {
    status: "loading",
  },
  engagementData: {
    success: "loading",
    finalTopicEngage: {},
    UserWiseEngagement: [],
    overallTopic: [],
    overallTextEngage: [],
    overAllEngagement: [],
  },
  engagementTopicTrendData: { status: "loading" },
  engagementComparison: {
    status: "loading",
  },
  retentionData: {
    status: "loading",
  },
  retentionComparison: {
    status: "loading",
  },
  supporterData: {
    status: "loading",
  },
  supporterComparison: {
    status: "loading",
  },
  moraleInsight: {},
  profanityData: { status: "loading" },
  profanityDataMale: { status: "loading" },
  profanityDataFemale: { status: "loading" },
  deiData: { status: "loading" },
  deiDataMale: { status: "loading" },
  deiDataFemale: { status: "loading" },
  hateSpeechData: { status: "loading" },
  overallCultureData: { status: "loading" },
  positiveCultureData: { status: "loading" },
  negativeCultureData: { status: "loading" },

  sexualHarassmentData: { status: "loading" },
  hateSpeechDataMale: { status: "loading" },
  hateSpeechDataFemale: { status: "loading" },
  insultData: { status: "loading" },
  insultDataMale: { status: "loading" },
  insultDataFemale: { status: "loading" },
  textAbuseData: { status: "loading" },
  racismData: { status: "loading" },
  sentimentsData: {},
  sentimentsDist: { status: "loading" },
  sexualHarrasmentData: {},
  sexismData: {},
  wordcloudData: {},
  trendingTopicsData: {},
  engagementTopicsData: [],
  hashTagsData: {},
  politicalWords: {},
  userManagementTableData: {},
  teamID: "",
  teamChannels: [],
  resetPassSuccess: false,
  checkSourceDiscord: false,
  chartsImgData: [],
  discordServerID: "",
  diversityData: {},
  customAnalysisData: {},
  hrisUsers: [],
  filters: {
    gender: [],
    location: [],
    department: [],
    keyTopic: [],
    ethnicity: [],
    channels: [],
    marital_status: [],
    region: [],
    length_of_service: [],
    management_level: [],
    income: [],
    age: [],
    performance: [],
    trendingTopic: [],
    engagementTopic: [],
    generalTopic: [],
  },
  dateFilterSentiment: { start: "", end: "" },
  sections: [],
  moraleUserCount: 0,
  currentWorkID: "",
  buildProgressStatus: "",
  buildProgressValue: 0,
  buildProgressCallCount: 0,

  dashboardSections: [],
  totalText: isDemo ? 32694 : 0,
  teamSize: isDemo ? 100 : 0,
  topicNumber: 0,
  moraleChange: null,
  isNightSwitchOn: false,
  licensePlans: [],
  licenseOptStatus: {},
  filterChannels: [],
  zoomTranscript: [],
  zoomMeetTime: new Date(),
  zoomfileName: "",
  chatContext: [],
  singleInsights: {},
  multipleInsights: {},
  noneInsights: {},
  topicSingleInsights: {},
  topicMultipleInsights: {},
  topicNoneInsights: {},
  eventSingleInsights: {},
  eventMultipleInsights: {},
  eventNoneInsights: {},
  publicHolidays: [],
  calenderEvents: isDemo
    ? [
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Quarterly Earnings Call",
        description: "Company quarterly earnings call with investors.",
        date: new Date("11-01-2024"),
        isVisible: true,
        color: "#135714",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Layoffs Announced",
        description: "5K US Employee Layoffs Announced",
        date: new Date("11-14-2024"),
        isVisible: true,
        color: "#cca529",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Corporate Culture Training",
        description:
          "DEI, Sexual Harassment and Toxicity in the Workplace Training Seminar.",
        date: new Date("11-21-2024"),
        isVisible: true,
        color: "#153465",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Stock Options Announced",
        description: "New Employee Stock Options Grant Announced",
        date: new Date("12-14-2024"),
        isVisible: true,
        color: "#135714",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "New CEO",
        description: "Jane Smith Announced as New CEO",
        date: new Date("12-01-2024"),
        isVisible: true,
        color: "#cca529",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Workplace Communication Training",
        description:
          "Training on the best way to communicate with team members.",
        date: new Date("12-14-2024"),
        isVisible: true,
        color: "#1A45C8",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Company Reorg Announced",
        description: "Reorganization of company divisions announced.",
        date: new Date("12-20-2024"),
        isVisible: true,
        color: "#135714",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Acquisition Announced",
        description: "Announcement of acquisition of Tech Corp",
        date: new Date("05-01-2024"),
        isVisible: true,
        color: "#CCA529",
      },
      {
        _id: Math.floor(Math.random() * 90000) + 10000,
        name: "Corporate Benefits Announced",
        description: "Next Years Benefits Plan Announced to Employees",
        date: new Date("04-01-2024"),
        isVisible: true,
        color: "#153465",
      },
    ]
    : [],
  calenderEventSaved: true,
  isLoading: false,
  level: "admin",
  department: "",
  access: {
    direct_reports: true,
    overall_company: true,
    filters: true,
    key_insights: true,
    morale: true,
    engagement: true,
    retention_risk: true,
    supporter_detractor: true,
    topic_analysis: true,
    culture: true,
  },
  publicHolidaySwitch: true,
  surveyQuestion: [],
  baseLineSurveys: [],
  suggestedQuestion: {
    question: "",
    options: {},
  },
  focusGroups: [],
  focusChats: [],
  actionPlans: [],
  currentSource: ["slack", "teams"],
  statusBoxItems: [
    "Morale",
    "Engagement",
    "Retention",
    "ENPS",
    "Culture",
    "Topic",
  ],
  moralePrediction: false,
  overallEngagementPrediction: false,
  topicEngagementPrediction: false,
  textEngagementPrediction: false,
  userEngagementPrediction: false,
  retentionPrediction: false,
  moraleTopics: [],
  insightThreshold: {
    moraleInsights: 5,
    userEngageInsights: 5,
    textEngageInsights: 50,
    retentionInsights: 5,
    positiveInsights: 5,
    negativeInsights: 5,
    detractorInsights: 5,
    supporterInsights: 5,
    culture: 5,
  },
  positiveThreshold: {
    gratitude_and_appreciation: 5,
    collaboration: 5,
    supportive_language: 5,
    constructive_feedback: 5,
    inclusive_language: 5,
    professionalism: 5,
    mentoring_mentions: 5,
    "work-life_balance": 5,
    employee_recognition: 5,
    empathy: 5,
    transparency: 5,
    adaptability: 5,
    creativity_and_innovation: 5,
    personal_development: 5,
    community_involvement: 5,
    wellness_and_health: 5,
    celebrating_diversity: 5,
    open_communication: 5,
    team_celebrations: 5,
  },
  negativeThreshold: {},
  engagementThreshold: {
    survey: 30,
    text: 5,
    topic: 60,
    user: 5,
  },
  eNPSThreshold: {
    textWeight: 0.33,
    topicWeight: 0.5,
    surveyWeight: 0.5,
  },
  favoriteFilters: [
    {
      gender: ["Transgender"],
      location: [],
      department: [],
      keyTopic: [],
      ethinicity: [],
      channels: [],
      marital_status: "",
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      trendingTopic: [],
      engagementTopic: [],
    }, {
      gender: ["Transgender", "Male"],
      location: [],
      department: [],
      keyTopic: [],
      ethnicity: [],
      channels: [],
      marital_status: [],
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      trendingTopic: [],
      engagementTopic: [],
    }, {
      gender: ["Transgender", "Male"],
      location: [],
      department: [],
      keyTopic: [],
      ethnicity: [],
      channels: [],
      marital_status: [],
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      trendingTopic: [],
      engagementTopic: [],
    },
    {
      gender: ["Non-Binary"],
      location: [],
      department: [],
      keyTopic: [],
      ethnicity: [],
      channels: [],
      marital_status: [],
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      trendingTopic: [],
      engagementTopic: [],
    },
  ],

  textENPSScore: {},
  topicENPSScore: {},
  surveyENPSScore: {},
  overallENPSScore: {},

  textENPSTrend: {},
  topicENPSTrend: {},
  surveyENPSTrend: {},
  overallENPSTrend: {},

  surveySettings: {
    questions: 5,
    comments: true,
  },

  engagementTopics: [
    "Accomplishment",
    "Autonomy & Empowerment",
    "Company Benefits",
    "Career Development & Growth",
    "Coaching & Mentorship",
    "Collaboration",
    "Commitment",
    "Communication",
    "Compensation & Pay Scale",
    "Diversity, Equity & Inclusion",
    "Freedom of Opinion",
    "Job Satisfaction",
    "Leadership",
    "Management Support",
    "Meaningful Work",
    "Mental Health",
    "Motivation",
    "Company Culture",
    "Passion for the Mission",
    "Peer Relationships",
    "Recognition",
    "Sense of Purpose",
    "Strategy",
    "Workload",
    "Workplace Environment",
  ],

  filterEmails: [],
  activeEngagementTopics: {
    engageTopicList: [
      "Accomplishment",
      "Autonomy & Empowerment",
      "Company Benefits",
      "Career Development & Growth",
      "Coaching & Mentorship",
      "Collaboration",
      "Commitment",
      "Communication",
      "Compensation & Pay Scale",
      "Diversity, Equity & Inclusion",
      "Freedom of Opinion",
      "Job Satisfaction",
      "Leadership",
      "Management Support",
      "Meaningful Work",
      "Mental Health",
      "Motivation",
      "Company Culture",
      "Passion for the Mission",
      "Peer Relationships",
      "Recognition",
      "Sense of Purpose",
      "Strategy",
      "Workload",
      "Workplace Environment",
    ],
  },
  goals: {
    morale: 50,
    engagement: {
      overall: 50,
      user: 50,
      message: 50,
      topic: 50,
    },
    retention: 10,
    enps: {
      overall: 50,
      topic: 50,
      survey: 50,
    },
    culture: {
      overall: 50,
      individual: 50,
    },
  },
  addedTopics: {
    messsage: "loading",
    topics: {
      "401k": 58,
      AC: 0,
      Acquisitions: 0,
      Badge: 58,
      Beverages: 0,
      Bonus: 0,
      Breakroom: 14,
      Breaktime: 69,
      Budget: 31,
      "Budget Cuts": 87,
      Cafeteria: 48,
      Carpool: 29,
      Chairs: 73,
      Charging: 25,
      Childcare: 84,
      Cleaning: 32,
      Coffee: 69,
      Commute: 0,
      Dental: 18,
      Desks: 73,
      Dishwasher: 56,
      "Earnings Call": 53,
      Elevators: 39,
      "End Of Quarter": 49,
      Food: 96,
      Forecast: 0,
      FSA: 92,
      Gym: 64,
      "Happy Hour": 35,
      "Health Plan": 81,
      Heating: 0,
      "Holiday Party": 76,
      Insurance: 58,
      Keycard: 27,
      KPIs: 31,
      Laptop: 22,

    },
  },
  messageDeletionDays: 90,
  dynamicFilters: {},
  selectedFilters: [
    "gender",
    "department",
    "age",
    "region",
    "location",
    "experience",
    "management_level",
    "ethnicity",
    "marital_status",
    "income"
  ],
  availableFilters: {
    "gender": [
      "female",
      "transgender",
      "dog",
      "1",
      "male",
      "non-binary"
    ],
    "department": [
      "Accounting & Finance",
      "Research & Development",
      "Operations",
      "Sales",
      "Marketing",
      "Information Technology",
      "1",
      "Human Resources",
      "Engineering"
    ],
    "region": [
      "Middle East/Africa",
      "North America",
      "South America",
      "Asia Pacific",
      "Europe",
      "1"
    ],
    "location": [
      "Headquarters",
      "1e",
      "Home Office",
      "Regional Office"
    ],
    "management_level": [
      "Manager",
      "Individual Contributor",
      "VP",
      "Executive",
      "SVP",
      "Director"
    ],
    "ethnicity": [
      "African American",
      "Asian",
      "Caucasian",
      "Other",
      "Hispanic",
      "21"
    ],
    "marital_status": [
      "Married",
      "Single",
      "single",
      "e",
      "WiDow",
      "DivorseD"
    ],
    "Work Schedule": [
      "Rotational Shift",
      "Rotational Shift",
      "Rotational Shift",
      "Night Shift",
      "Day Shift"
    ],
    "Diversity Group": [
      "BIPOC Employees",
      "BIPOC Employees",
      "BIPOC Employees",
      "LGBTQ+ Allies",
      "Woman in Leadership"
    ],
    "Employment Type": [
      "Contractor",
      "Contractor",
      "Contractor",
      "Full-Time",
      "Full-Time"
    ],
    "Office Type": [
      "Onsite",
      "Onsite",
      "Onsite",
      "Hybrid",
      "Remote"
    ],
    "Job Role": [
      "Designer",
      "Designer",
      "Designer",
      "HR Specialist",
      "Product Manager"
    ],
    "Workstream": [
      "QA Testing",
      "QA Testing",
      "QA Testing",
      "DevOps",
      "Frontend Development"
    ],
    "Past Role": [
      "Full Stack Engineer",
      "Devops Engineer",
      "ML Engineer"
    ],
    "experience": [
      {
        "$lt": 1
      },
      {
        "$gte": 1,
        "$lt": 5
      },
      {
        "$gte": 5,
        "$lte": 9
      },
      {
        "$gte": 10
      }
    ],
    "income": [
      {
        "$lte": 50000
      },
      {
        "$gte": 50001,
        "$lte": 100000
      },
      {
        "$gte": 100001,
        "$lte": 250000
      },
      {
        "$gte": 250001
      }
    ],
    "age": [
      {
        "$lte": 25
      },
      {
        "$gte": 26,
        "$lte": 35
      },
      {
        "$gte": 36,
        "$lte": 49
      },
      {
        "$gte": 50
      }
    ]
  },
  filterMapping: {
    "gender": {
      "Male": [
        "male",
        "Male"
      ],
      "Non-Binary": [
        "non-binary",
        "Non-Binary"
      ],
      "Female": [
        "female",
        "Female"
      ],
      "Transgender": [
        "transgender",
        "transgenders",
        "Transgender"
      ]
    },
    "department": {
      "Research & Development": [
        "Research & Development"
      ],
      "Human Resources": [
        "Human Resources"
      ],
      "Sales & Marketing": [
        "Marketing",
        "Sales",
        "Sales & Marketing"
      ],
      "Engineering": [
        "Engineering"
      ],
      "Information Technology": [
        "Information Technology"
      ],
      "Accounting & Finance": [
        "Accounting & Finance"
      ],
      "Operations": [
        "Operations"
      ]
    },
    "region": {
      "South America": [
        "South America"
      ],
      "Europe": [
        "Europe"
      ],
      "Asia Pacific": [
        "Asia Pacific"
      ],
      "North America": [
        "North America"
      ],
      "Middle East/Africa": [
        "Middle East/Africa"
      ]
    },
    "location": {
      "Headquarters": [
        "Headquarters"
      ],
      "Regional Office": [
        "Regional Office"
      ],
      "Home Office": [
        "Home Office"
      ]
    },
    "management_level": {
      "Executive": [
        "Executive"
      ],
      "Director": [
        "Director"
      ],
      "VP": [
        "VP"
      ],
      "Individual Contributor": [
        "Individual Contributor"
      ],
      "SVP": [
        "SVP"
      ],
      "Manager": [
        "Manager"
      ]
    },
    "ethnicity": {
      "African American": [
        "African American"
      ],
      "Asian": [
        "Asian"
      ],
      "Others": [
        "Other",
        "Others"
      ],
      "Hispanic": [
        "Hispanic"
      ],
      "White": [
        "Caucasian",
        "White"
      ]
    },
    "marital_status": {
      "Married": [
        "Married"
      ],
      "Single": [
        "Single",
        "single"
      ],
      "Divorce": [
        "Divorce",
        "divorse",
        "divorce"
      ]
    },
    "Work Schedule": {
      "Day Shift": [
        "Day Shift"
      ],
      "Night Shift": [
        "Night Shift"
      ]
    },
    "Diversity Group": {
      "Woman In Leadership": [
        "Woman in Leadership"
      ]
    },
    "Employment Type": {
      "Full-time": [
        "Full-Time"
      ],
      "Part-time": [
        "Part-Time"
      ]
    },
    "Office Type": {
      "Remote": [
        "Remote"
      ],
      "Hybrid": [
        "Hybrid"
      ]
    },
    "Job Role": {
      "Product Manager": [
        "Product Manager"
      ],
      "Hr Manager": [
        "HR Manager"
      ]
    },
    "Past Role": {
      "Ml Engineer": [
        "ML Engineer"
      ],
      "Ml Analyst": [
        "ML Analyst"
      ],
      "Image Analyst": [
        "Image Analyst"
      ]
    },
    "Workstream": {
      "Frontend Development": [
        "Frontend Development"
      ],
      "Fullstack Development": [
        "FullStack Development"
      ]
    }
  },
  minUserCount: isDemo ? 10 : 0,
  insightSchedule: "monthly",
  financialYearStartDate: new Date("04/01/2024"),
  messageInterval: 90,
  hrisUpdateInterval: "monthly"
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_FILTER_SENTIMENT:
      return {
        ...state,
        // dateFilterSentiment: { ...action.payload },
        dateFilterSentiment: {
          start:
            action.payload.start === "" ? "" : new Date(action.payload.start),
          end: action.payload.end === "" ? "" : new Date(action.payload.end),
        },
      };

    case GET_TOTAL_CONVO_DETAILS:
      return { ...state, totalConvoAnalyzed: { status: "loading" } };
    case GET_TOTAL_CONVO_DETAILS_SUCCESS:
      return {
        ...state,
        totalConvoAnalyzed: { ...action.payload, status: "success" },
      };

    case GET_TOTAL_CONVO_DETAILS_FAILURE:
      return {
        ...state,
        totalConvoAnalyzed: { status: "fail" },
      };

    case "RESET_DATA":
      return {
        ...state,
        totalConvoAnalyzed: { status: "loading" },
        totalConvoAnalyzedMale: { status: "loading" },
        totalConvoAnalyzedFemale: { status: "loading" },
      };

    case GET_DETECTED_USER_DETAILS_SUCCESS:
      return { ...state, detectedUser: action.payload.detectedUser };
    case GET_DETECTED_USER_DETAILS_FAILURE:
      return { ...state, detectedUser: action.payload };
    case GET_PIPHISHING_MODEL_SUCCESS:
      return { ...state, piPhishing: action.payload };
    case GET_PIPHISHING_MODEL_FAILURE:
      return { ...state, piPhishing: action.payload };

    case GET_WORKFLOW_DETAILS_SUCCESS:
      return { ...state, totalWorkflows: action.payload.totalWorkflows };

    case GET_MODEL_CHANNEL_SUCCESS:
      return { ...state, modelChannel: action.payload };

    case UPLOAD_SOURCE_FILES_SUCCESS:
      return { ...state, uploadSourceStatus: action.payload };
    case GET_CHANNELS_SUCCESS:
      return { ...state, uploadSourceStatus: action.payload };

    case SAVE_WORKFLOW_SUCCESS:
      return { ...state, saveStatus: action.payload };

    case GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflow: {
          data: action.payload.workflow,
          workflowName: action.payload.workflowName,
        },
        saveStatus: "loaded",
        modelsConfigData: action.payload.model_config,
        actionConfigData: action.payload.action_config,
      };
    case GET_WORKFLOW_FAILURE:
      return { ...state, workflow: [] };

    case BUILD_WORKFLOW_SUCCESS:
      return { ...state, buildStatus: action.payload };
    case BUILD_WORKFLOW_FAILURE:
      return { ...state, buildStatus: action.payload };

    case GET_USER_WORKFLOW_SUCCESS:
      return { ...state, userWorkflows: action.payload };
    case GET_USER_WORKFLOW_FAILURE:
      return { ...state, userWorkflows: action.payload };
    case EMPTY_CHANNELS_SUCCESS:
      return {
        ...state,
        uploadSourceStatus: action.payload,
        modelsConfigData: action.payload.modelsConfigData,
        slackKey: action.payload.slackKey,
        accessToken: action.payload.accessToken,
        accessTokenSave: false,
        saveStatus: action.payload.saveStatus,
      };

    case SET_MODEL_CONFIGURATION:
      return { ...state, modelsConfigData: action.payload.modelsConfigData };

    case SET_ACTION_CONFIGURATION:
      return { ...state, actionConfigData: action.payload.actionConfigData };

    case VERIFY_TOKEN_SUCCESS:
      return { ...state, slackKey: action.payload };

    case GET_ACCESS_TOKEN_SUCCESS:
      return { ...state, accessToken: action.payload };

    case GET_ZOOM_TOKEN_SUCCESS:
      return { ...state, zoomRecordings: action.payload };

    case SAVING_ACCESS_TOKEN_SUCCESS:
      return { ...state, accessTokenSave: true };

    case CLEAR_DATA_SUCCESS:
      return initialState;

    case DELETE_WORKFLOW_SUCCESS:
      return { ...state, workflow: initialState.workflow, workflowName: "" };

    case DELETE_WORKFLOW_FAILURE:
      return { ...state };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload.data.user_info,
        level: action.payload.data.user_info.level,
        department: action.payload.data.user_info.department,
        access:
          action.payload.data.user_info.level === "admin"
            ? {
              direct_reports: true,
              overall_company: true,
              filters: true,
              key_insights: true,
              morale: true,
              engagement: true,
              retention_risk: true,
              supporter_detractor: true,
              topic_analysis: true,
              culture: true,
            }
            : action.payload.data.user_info.access,
      };

    case GET_USER_DETAILS_FAILURE:
      return { ...state, userDetails: "" };

    case GET_INVESTIGATING_TABLE_SUCCESS:
      return { ...state, tableData: action.payload.data.tableData };

    case GET_INVESTIGATING_TABLE_FAILURE:
      return { ...state, tableData: [] };

    case GET_DETAILED_INVESTIGATING_TABLE_SUCCESS:
      return { ...state, detailedTableData: action.payload.data.data };

    case GET_DETAILED_INVESTIGATING_TABLE_FAILURE:
      return { ...state, detailedTableData: [] };
    case GET_MORALE_SCORE:
      return { ...state, moraleScore: { status: "loading" } };
    case GET_MORALE_SCORE_SUCCESS:
      return {
        ...state,
        moraleScore: { ...action.payload.data, status: "success" },
      };
    case GET_MORALE_SCORE_FAILURE:
      return { ...state, moraleScore: { status: "fail" } };

    case GET_MORALE_COMPARISON:
      return { ...state, moraleComparison: { status: "loading" } };
    case GET_MORALE_COMPARISON_SUCCESS:
      return {
        ...state,
        moraleComparison: { result: action.payload.data, status: "success" },
      };
    case GET_MORALE_COMPARISON_FAILURE:
      return { ...state, moraleComparison: { status: "fail" } };

    case GET_ENGAGEMENT:
      return { ...state, engagementData: { status: "loading" } };
    case GET_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        engagementData: { ...action.payload.data, status: "success" },
      };
    case GET_ENGAGEMENT_FAILURE:
      return { ...state, engagementData: { status: "fail" } };

    case GET_ENGAGEMENT_TOPIC_TREND:
      return { ...state, engagementTopicTrendData: { status: "loading" } };
    case GET_ENGAGEMENT_TOPIC_TREND_SUCCESS:
      return {
        ...state,
        engagementTopicTrendData: { ...action.payload, status: "success" },
      };
    case GET_ENGAGEMENT_TOPIC_TREND_FAILURE:
      return { ...state, engagementTopicTrendData: { status: "fail" } };

    case GET_ENGAGEMENT_COMPARISON:
      return { ...state, engagementComparison: { status: "loading" } };
    case GET_ENGAGEMENT_COMPARISON_SUCCESS:
      return {
        ...state,
        engagementComparison: { ...action.payload.data, status: "success" },
      };
    case GET_ENGAGEMENT_COMPARISON_FAILURE:
      return { ...state, engagementComparison: { status: "fail" } };

    case GET_RETENTION:
      return { ...state, retentionData: { status: "loading" } };
    case GET_RETENTION_SUCCESS:
      return {
        ...state,
        retentionData: { ...action.payload.data, status: "success" },
      };
    case GET_RETENTION_FAILURE:
      return { ...state, retentionData: { status: "fail" } };

    case GET_RETENTION_COMPARISON:
      return { ...state, retentionComparison: { status: "loading" } };
    case GET_RETENTION_COMPARISON_SUCCESS:
      return {
        ...state,
        retentionComparison: { result: action.payload.data, status: "success" },
      };
    case GET_RETENTION_COMPARISON_FAILURE:
      return { ...state, retentionComparison: { status: "fail" } };

    case GET_SUPPORTER_TREND:
      return { ...state, supporterData: { status: "loading" } };
    case GET_SUPPORTER_TREND_SUCCESS:
      return {
        ...state,
        supporterData: { ...action.payload.data, status: "success" },
      };
    case GET_SUPPORTER_TREND_FAILURE:
      return { ...state, supporterData: { status: "fail" } };

    case GET_ENPS_TEXT_SCORE:
      return { ...state, textENPSScore: { status: "loading" } };
    case GET_ENPS_TEXT_SCORE_SUCCESS:
      return {
        ...state,
        textENPSScore: { ...action.payload, status: "success" },
      };
    case GET_ENPS_TEXT_SCORE_FAILURE:
      return { ...state, textENPSScore: { status: "fail" } };

    case GET_ENPS_TOPIC_SCORE:
      return { ...state, topicENPSScore: { status: "loading" } };
    case GET_ENPS_TOPIC_SCORE_SUCCESS:
      return {
        ...state,
        topicENPSScore: { ...action.payload, status: "success" },
      };
    case GET_ENPS_TOPIC_SCORE_FAILURE:
      return { ...state, topicENPSScore: { status: "fail" } };

    case GET_ENPS_SURVEY_SCORE:
      return { ...state, surveyENPSScore: { status: "loading" } };
    case GET_ENPS_SURVEY_SCORE_SUCCESS:
      return {
        ...state,
        surveyENPSScore: { ...action.payload, status: "success" },
      };
    case GET_ENPS_SURVEY_SCORE_FAILURE:
      return { ...state, surveyENPSScore: { status: "fail" } };

    case GET_ENPS_TEXT_TREND:
      return { ...state, textENPSTrend: { status: "loading" } };
    case GET_ENPS_TEXT_TREND_SUCCESS:
      return {
        ...state,
        textENPSTrend: { result: [...action.payload], status: "success" },
      };
    case GET_ENPS_TEXT_TREND_FAILURE:
      return { ...state, textENPSTrend: { status: "fail" } };

    case GET_ENPS_TOPIC_TREND:
      return { ...state, topicENPSTrend: { status: "loading" } };
    case GET_ENPS_TOPIC_TREND_SUCCESS:
      return {
        ...state,
        topicENPSTrend: { result: [...action.payload], status: "success" },
      };
    case GET_ENPS_TOPIC_TREND_FAILURE:
      return { ...state, topicENPSTrend: { status: "fail" } };

    case GET_ENPS_SURVEY_TREND:
      return { ...state, surveyENPSTrend: { status: "loading" } };
    case GET_ENPS_SURVEY_TREND_SUCCESS:
      return {
        ...state,
        surveyENPSTrend: { result: [...action.payload], status: "success" },
      };
    case GET_ENPS_SURVEY_TREND_FAILURE:
      return { ...state, surveyENPSTrend: { status: "fail" } };

    case GET_ENPS_OVERALL_SCORE:
      return { ...state, overallENPSScore: { status: "loading" } };
    case GET_ENPS_OVERALL_SCORE_SUCCESS:
      return {
        ...state,
        overallENPSScore: { ...action.payload, status: "success" },
      };
    case GET_ENPS_OVERALL_SCORE_FAILURE:
      return { ...state, overallENPSScore: { status: "fail" } };

    case GET_ENPS_OVERALL_TREND:
      return { ...state, overallENPSTrend: { status: "loading" } };
    case GET_ENPS_OVERALL_TREND_SUCCESS:
      return {
        ...state,
        overallENPSTrend: { result: [...action.payload], status: "success" },
      };
    case GET_ENPS_OVERALL_TREND_FAILURE:
      return { ...state, overallENPSTrend: { status: "fail" } };

    case GET_SUPPORTER_COMPARISON:
      return { ...state, supporterComparison: { status: "loading" } };
    case GET_SUPPORTER_COMPARISON_SUCCESS:
      return {
        ...state,
        supporterComparison: { ...action.payload.data, status: "success" },
      };
    case GET_SUPPORTER_COMPARISON_FAILURE:
      return { ...state, supporterComparison: { status: "fail" } };

    case GET_MORALE_INSIGHTS:
      return { ...state, moraleInsight: { status: "loading" } };
    case GET_MORALE_INSIGHTS_SUCCESS:
      return {
        ...state,
        moraleInsight: { ...action.payload, status: "success" },
      };
    case GET_MORALE_INSIGHTS_FAILURE:
      return { ...state, moraleInsight: { status: "fail" } };

    case GET_PROFANITY_TRENDS_SUCCESS:
      if (action.payload.query.gender === "") {
        return {
          ...state,
          profanityData: { ...action.payload, status: "success" },
        };
      } else if (action.payload.query.gender === "m") {
        return {
          ...state,
          profanityDataMale: { ...action.payload, status: "success" },
        };
      } else if (action.payload.query.gender === "f") {
        return {
          ...state,
          profanityDataFemale: {
            ...action.payload,
            status: "success",
          },
        };
      }

    case GET_PROFANITY_TRENDS_FAILURE:
      return {
        ...state,
        profanityData: { status: "fail" },
      };

    case GET_DEI_TRENDS_SUCCESS:
      if (action.payload.query.gender === "") {
        return {
          ...state,
          deiData: { ...action.payload, status: "success" },
        };
      } else if (action.payload.query.gender === "m") {
        return {
          ...state,
          deiDataMale: { ...action.payload, status: "success" },
        };
      } else if (action.payload.query.gender === "f") {
        return {
          ...state,
          deiDataFemale: {
            ...action.payload,
            status: "success",
          },
        };
      }

    case GET_DEI_TRENDS_FAILURE:
      return {
        ...state,
        deiData: { status: "fail" },
      };
    case GET_HATE_SPEECH_TRENDS:
      return {
        ...state,
        hateSpeechData: { status: "loading" },
      };

    case GET_HATE_SPEECH_TRENDS_SUCCESS:
      return {
        ...state,
        hateSpeechData: { ...action.payload.data, status: "success" },
      };
    case GET_HATE_SPEECH_TRENDS_FAILURE:
      return {
        ...state,
        hateSpeechData: { status: "fail" },
      };

    case GET_OVERALL_CULTURE:
      return {
        ...state,
        overallCultureData: { status: "loading" },
      };

    case GET_OVERALL_CULTURE_SUCCESS:
      return {
        ...state,
        overallCultureData: { cultureData: !_.isEmpty(action.payload.data) ? [...action.payload.data] : [], status: "success" },
      };
    case GET_OVERALL_CULTURE_FAILURE:
      return {
        ...state,
        overallCultureData: { status: "fail" },
      };

    case GET_POSITIVE_CULTURE:
      return {
        ...state,
        positiveCultureData: { status: "loading" },
      };

    case GET_POSITIVE_CULTURE_SUCCESS:
      return {
        ...state,
        positiveCultureData: { ...action.payload.data, status: "success" },
      };
    case GET_POSITIVE_CULTURE_FAILURE:
      return {
        ...state,
        positiveCultureData: { status: "fail" },
      };

    case GET_NEGATIVE_CULTURE:
      return {
        ...state,
        negativeCultureData: { status: "loading" },
      };

    case GET_NEGATIVE_CULTURE_SUCCESS:
      return {
        ...state,
        negativeCultureData: { ...action.payload.data, status: "success" },
      };
    case GET_NEGATIVE_CULTURE_FAILURE:
      return {
        ...state,
        negativeCultureData: { status: "fail" },
      };

    case GET_INSULT_TRENDS:
      return {
        ...state,
        insultData: { status: "loading" },
      };
    case GET_INSULT_TRENDS_SUCCESS:
      return {
        ...state,
        insultData: { ...action.payload.data, status: "success" },
      };

    case GET_INSULT_TRENDS_FAILURE:
      return {
        ...state,
        insultData: { status: "fail" },
      };
    case GET_SENTIMENTS_TRENDS:
      return { ...state, sentimentsData: {} };
    case GET_SENTIMENTS_TRENDS_SUCCESS:
      return { ...state, sentimentsData: action.payload.data };
    case GET_SENTIMENTS_DIST:
      return { ...state, sentimentsDist: { status: "loading" } };
    case GET_SENTIMENTS_DIST_SUCCESS:
      return {
        ...state,
        sentimentsDist: { ...action.payload.data, status: "success" },
      };
    case GET_SENTIMENTS_DIST_FAILURE:
      return { ...state, sentimentsDist: { status: "fail" } };
    case GET_SEXUAL_HARRAS_TRENDS:
      return { ...state, sexualHarassmentData: { status: "loading" } };
    case GET_SEXUAL_HARRAS_TRENDS_SUCCESS:
      return { ...state, sexualHarassmentData: action.payload.data };
    case GET_SEXUAL_HARRAS_TRENDS_FAILURE:
      return { ...state, sexualHarassmentData: { status: "fail" } };
    case GET_SEXISM_TRENDS_SUCCESS:
      return { ...state, sexismData: action.payload.data };

    case GET_WORD_CLOUD_DATA_SUCCESS:
      return { ...state, wordcloudData: action.payload.data };
    case GET_WORD_CLOUD_DATA:
      return { ...state, wordcloudData: { message: "Success", result: [] } };

    case GET_TRENDING_TOPICS:
      return { ...state, trendingTopicsData: {} };
    case GET_TRENDING_TOPICS_SUCCESS:
      return { ...state, trendingTopicsData: action.payload.data.topics };
    case GET_ALL_ENGAGEMENT_TOPIC:
      return { ...state, engagementTopicsData: [] };
    case GET_ALL_ENGAGEMENT_TOPIC_SUCCESS:
      return {
        ...state,
        engagementTopicsData: action.payload.data.engagementTopics,
      };

    case GET_HASH_TAGS:
      return { ...state, hashTagsData: {} };
    case GET_HASH_TAGS_SUCCESS:
      return { ...state, hashTagsData: action.payload.data.tags };
    case GET_HASH_TAGS_FAILURE:
      return { ...state, hashTagsData: {} };

    case GET_POLITICAL_WORDS:
      return { ...state, politicalWords: {} };
    case GET_POLITICAL_WORDS_SUCCESS:
      return { ...state, politicalWords: action.payload.data.words };
    case GET_POLITICAL_WORDS_FAILURE:
      return { ...state, politicalWords: {} };

    case GET_TEXT_ABUSE_DATA:
      return { ...state, textAbuseData: { status: "loading" } };
    case GET_TEXT_ABUSE_DATA_SUCCESS:
      return { ...state, textAbuseData: action.payload.data };
    case GET_TEXT_ABUSE_DATA_FAILURE:
      return { ...state, textAbuseData: { status: "fail" } };

    case GET_RACISM_TREND_DATA:
      return { ...state, racismData: { status: "loading" } };
    case GET_RACISM_TREND_DATA_SUCCESS:
      return { ...state, racismData: action.payload.data };
    case GET_RACISM_TREND_DATA_FAILURE:
      return { ...state, racismData: { status: "fail" } };

    case GET_USERS_SUCCESS:
      return { ...state, userManagementTableData: action.payload.data };
    case SAVE_CHANNELSFROMTEAMS:
      return { ...state, teamChannels: action.payload };
    case SAVE_TEAMID:
      return { ...state, teamID: action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassSuccess: true };
    case RESET_PASSWORD_FAILURE:
      return { ...state, resetPassSuccess: false };
    case CHECK_SOURCE_DISCORD:
      return { ...state, checkSourceDiscord: action.payload };
    case CHARTS_IMAGE_DATA:
      return { ...state, chartsImgData: action.payload };
    case SAVE_DISCORD_ID_SUCCESS:
      return {
        ...state,
        discordServerID: action.payload,
      };
    case GET_DIVERSITY_DATA_SUCCESS:
      return {
        ...state,
        diversityData: action.payload.data,
      };
    case GET_CUSTOM_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        customAnalysisData: action.payload,
      };

    case GET_CUSTOM_ANALYSIS_DATA_FAILURE:
      return {
        ...state,
        customAnalysisData: {},
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case GET_EMOTION_JOY_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          joy: action.payload,
        },
      };

    case GET_EMOTION_JOY_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          joy: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_ANGER_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          anger: action.payload,
        },
      };

    case GET_EMOTION_ANGER_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          anger: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_SAD_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          sad: action.payload,
        },
      };

    case GET_EMOTION_SAD_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          sad: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_SURPRISE_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          surprise: action.payload,
        },
      };

    case GET_EMOTION_SURPRISE_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          surprise: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_FEAR_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          fear: action.payload,
        },
      };

    case GET_EMOTION_FEAR_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          fear: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_TOTAL_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          total: action.payload,
        },
      };

    case GET_EMOTION_TOTAL_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          total: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_NEUTRAL_SUCCESS:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          neutral: action.payload,
        },
      };

    case GET_EMOTION_NEUTRAL_FAILURE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          neutral: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_EMOTION_JOY:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          joy: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_FEAR:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          fear: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_SURPRISE:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          surprise: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_SAD:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          sad: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_ANGER:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          anger: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };
    case GET_EMOTION_TOTAL:
      return {
        ...state,
        emotionPercenatge: {
          ...state.emotionPercenatge,
          total: {
            emotionPercenatge: {
              Emotion: 0,
            },
          },
        },
      };

    case GET_MORALE_USERS_NUMBER:
      return { ...state, moraleUserCount: 0 };
    case GET_MORALE_USERS_NUMBER_SUCCESS:
      return { ...state, moraleUserCount: action.payload.data.numUsers };
    case GET_MORALE_USERS_NUMBER_FAILURE:
      return { ...state, moraleUserCount: 0 };

    case SAVE_DEMO_WORK:
      return { ...state, userWorkflows: action.payload, saveStatus: "saved" };
    case SET_SECTIONS:
      return { ...state, sections: action.payload };

    case SHOW_BUILD_STATUS:
      return {
        ...state,
        buildProgressStatus: "loading",
      };
    case SHOW_BUILD_STATUS_SUCCESS:
      if (action.payload.data.percentCompleted === 100) {
        return {
          ...state,
          buildProgressValue: action.payload.data.percentCompleted,
          buildProgressStatus: "completed",
          buildProgressCallCount: 0,
        };
      }
      return {
        ...state,
        buildProgressValue: action.payload.data.percentCompleted,
        buildProgressCallCount: state.buildProgressCallCount + 1,
      };

    case SHOW_BUILD_STATUS_FAILURE:
      return {
        ...state,
        buildProgressValue: 0,
        buildProgressCallCount: state.buildProgressCallCount + 1,
        buildProgressStatus: "rest",
      };
    case GET_WORKFLOW:
      return {
        ...state,
        buildProgressValue: 0,
        buildProgressStatus: "rest",
        buildProgressCallCount: 0,
      };

    case GET_DASHBOARD_SECTIONS_SUCCESS:
      return {
        ...state,
        dashboardSections: action.payload.data,
      };

    case GET_DASHBOARD_SECTIONS_FAILURE:
      return {
        ...state,
        dashboardSections: [],
      };
    case GET_TEAM_SIZE:
      return {
        ...state,
        teamSize: 0,
      };
    case GET_TEAM_SIZE_SUCCESS:
      return {
        ...state,
        teamSize: action.payload.data.total_strength,
      };
    case GET_TEAM_SIZE_FAILURE:
      return {
        ...state,
        teamSize: 0,
      };

    case GET_TOPIC_NUMBER:
      return {
        ...state,
        topicNumber: 0,
      };
    case GET_TOPIC_NUMBER_SUCCESS:
      return {
        ...state,
        topicNumber: action.payload.data.NumTopics.length,
      };
    case GET_TOPIC_NUMBER_FAILURE:
      return {
        ...state,
        topicNumber: 0,
      };

    case GET_MORALE_CHANGE:
      return {
        ...state,
        moraleChange: null,
      };
    case GET_MORALE_CHANGE_SUCCESS:
      return {
        ...state,
        moraleChange: parseFloat(action.payload.data.morale_change),
      };
    case GET_MORALE_CHANGE_FAILURE:
      return {
        ...state,
        moraleChange: 0,
      };

    case GET_TOTAL_TEXTS:
      return {
        ...state,
        totalText: 0,
      };

    case GET_TOTAL_TEXTS_SUCCESS:
      return {
        ...state,
        totalText: action.payload.data.total_texts,
      };
    case GET_TOTAL_TEXTS_FAILURE:
      return {
        ...state,
        totalText: 0,
      };

    case GET_FILTER_CHANNEL:
      return {
        ...state,
        filterChannels: [],
      };

    case GET_FILTER_CHANNEL_SUCCESS:
      return {
        ...state,
        filterChannels: action.payload.data.allChannels,
      };

    case GET_FILTER_CHANNEL_FAILURE:
      return {
        ...state,
        filterChannels: [],
      };

    case SET_NIGHT_MODE:
      return {
        ...state,
        isNightSwitchOn: action.payload,
      };

    case GET_LICENSE_PLANS_SUCCESS:
      return {
        ...state,
        licensePlans: action.payload,
      };

    case GET_LICENSE_PLANS_FAILURE:
      return {
        ...state,
        licensePlans: action.payload,
      };

    case OPT_LICENSE_SUCCESS:
      return {
        ...state,
        licenseOptStatus: action.payload.data,
      };
    case OPT_LICENSE_FAILURE:
      return {
        ...state,
        licenseOptStatus: action.payload.data,
      };

    case GET_HRIS_USERS_SUCCESS:
      return {
        ...state,
        hrisUsers: action.payload.data.result,
      };
    case GET_HRIS_USERS_FAILURE:
      return {
        ...state,
        hrisUsers: [],
      };
    case SAVE_ZOOM_TRANSCRIPT:
      return {
        ...state,
        zoomTranscript: action.payload.transcript,
        zoomMeetTime: action.payload.meetingTime,
      };

    case ZOOM_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        zoomfileName: action.payload.filename,
      };
    case SET_CHAT_CONTEXT:
      return {
        ...state,
        chatContext: [...state.chatContext, action.payload],
      };
    case GET_PUBLIC_HOLIDAYS_SUCCESS:
      return {
        ...state,
        publicHolidays: action.payload,
      };

    case GET_SINGLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        singleInsights: action.payload,
      };
    case GET_SINGLE_INSIGHTS_FAILURE:
      return {
        ...state,
        singleInsights: {},
      };

    case GET_MULTIPLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        multipleInsights: action.payload,
      };
    case GET_MULTIPLE_INSIGHTS_FAILURE:
      return {
        ...state,
        multipleInsights: {},
      };

    case GET_NONE_INSIGHTS_SUCCESS:
      return {
        ...state,
        noneInsights: action.payload,
      };
    case GET_NONE_INSIGHTS_FAILURE:
      return {
        ...state,
        noneInsights: {},
      };

    case GET_TOPIC_SINGLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        topicSingleInsights: action.payload,
      };
    case GET_TOPIC_SINGLE_INSIGHTS_FAILURE:
      return {
        ...state,
        topicSingleInsights: {},
      };
    case GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        topicMultipleInsights: action.payload,
      };
    case GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE:
      return {
        ...state,
        topicMultipleInsights: {},
      };

    case GET_TOPIC_NONE_INSIGHTS_SUCCESS:
      return {
        ...state,
        topicNoneInsights: action.payload,
      };
    case GET_TOPIC_NONE_INSIGHTS_SUCCESS:
      return {
        ...state,
        topicNoneInsights: {},
      };
    case GET_EVENT_SINGLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        eventSingleInsights: action.payload,
      };
    case GET_EVENT_SINGLE_INSIGHTS_FAILURE:
      return {
        ...state,
        eventSingleInsights: {},
      };

    case GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS:
      return {
        ...state,
        eventMultipleInsights: action.payload,
      };
    case GET_EVENT_MULTIPLE_INSIGHTS_FAILURE:
      return {
        ...state,
        eventMultipleInsights: {},
      };

    case GET_EVENT_NONE_INSIGHTS_SUCCESS:
      return {
        ...state,
        eventNoneInsights: action.payload,
      };
    case GET_EVENT_NONE_INSIGHTS_FAILURE:
      return {
        ...state,
        eventNoneInsights: {},
      };

    case GET_CALENDER_EVENTS_SUCCESS:
      return {
        ...state,
        calenderEvents: action.payload,
      };
    case GET_CALENDER_EVENTS_FAILURE:
      return {
        ...state,
        calenderEvents: [],
      };
    case ADD_CALENDER_EVENT:
      return {
        ...state,
        calenderEventSaved: false,
      };
    case ADD_CALENDER_EVENT_SUCCESS:
      return {
        ...state,
        calenderEventSaved: true,
      };
    case ADD_CALENDER_EVENT_FAILURE:
      return {
        ...state,
        calenderEventSaved: false,
      };
    case UPDATE_CALENDER_EVENT_SUCCESS:
      return {
        ...state,
        calenderEventSaved: true,
      };
    case UPDATE_CALENDER_EVENT_SUCCESS:
      return {
        ...state,
        calenderEventSaved: false,
      };

    case SAVE_DEMO_ANNOTATION:
      return {
        ...state,
        calenderEvents: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.status,
      };
    case SET_LEVEL:
      return {
        ...state,
        level: action.payload.level,
      };
    case TOGGLE_PUBLIC_HOLIDAYS:
      return {
        ...state,
        publicHolidaySwitch: action.payload,
      };

    case GET_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        surveyQuestion: action.payload,
      };

    case GET_ALL_BASELINE_SURVEYS_SUCCESS:
      return {
        ...state,
        baseLineSurveys: action.payload,
      };

    case GET_ALL_FOCUS_GROUPS_SUCCESS:
      return {
        ...state,
        focusGroups: action.payload,
      };

    case GET_ALL_FOCUS_CHAT_SUCCESS:
      return {
        ...state,
        focusChats: action.payload,
      };




    case GET_ALL_ACTION_PLANS_SUCCESS:
      return {
        ...state,
        actionPlans: action.payload.ActionPlan,
      };

    case SET_CURRENT_SOURCE:
      return {
        ...state,
        currentSource: action.payload,
      };

    case GET_STATUS_BOX_SUCCESS:
      return {
        ...state,
        statusBoxItems: action.payload,
      };

    case SET_MORALE_PREDICTION:
      return {
        ...state,
        moralePrediction: action.payload,
      };

    case SET_USER_ENGAGEMENT_PREDICTION:
      return {
        ...state,
        userEngagementPrediction: action.payload,
      };
    case SET_TEXT_ENGAGEMENT_PREDICTION:
      return {
        ...state,
        textEngagementPrediction: action.payload,
      };
    case SET_OVERALL_ENGAGEMENT_PREDICTION:
      return {
        ...state,
        overallEngagementPrediction: action.payload,
      };
    case SET_TOPIC_ENGAGEMENT_PREDICTION:
      return {
        ...state,
        topicEngagementPrediction: action.payload,
      };
    case SET_RETENTION_PREDICTION:
      return {
        ...state,
        retentionPrediction: action.payload,
      };
    case GET_MORALE_TOPICS_SUCCESS:
      return {
        ...state,
        moraleTopics: action.payload,
      };

    case GET_INSIGHTS_THRESHOLD_SUCCESS:
      return {
        ...state,
        insightThreshold: action.payload,
      };
    case GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS:
      return {
        ...state,
        positiveThreshold: action.payload,
      };
    case GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS:
      return {
        ...state,
        negativeThreshold: action.payload,
      };

    case GET_ENGAGEMENT_THRESHOLD_SUCCESS:
      return {
        ...state,
        engagementThreshold: action.payload.engagementThresholdData,
      };

    case GET_ENPS_THRESHOLD_SUCCESS:
      return {
        ...state,
        eNPSThreshold: action.payload,
      };

    // case GET_FAVORITE_FILTERS_SUCCESS:
    //   return {
    //     ...state,
    //     favoriteFilters: action.payload,
    //   };

    case GET_SURVEY_SETTINGS_SUCCESS:
      return {
        ...state,
        surveySettings: action.payload,
      };

    case SAVE_SURVEY_SETTINGS_SUCCESS:
      return {
        ...state,
        surveySettings: action.payload,
      };

    case GET_ENGAGEMENT_TOPICS_SUCCESS:
      return {
        ...state,
        engagementTopics: action.payload.topicList,
      };

    case GET_ENGAGEMENT_TOPICS_FAILURE:
      return {
        ...state,
        engagementTopics: [],
      };

    case GET_SUGGESTED_QUESTION_SUCCESS:
      return {
        ...state,
        suggestedQuestion: action.payload,
      };

    case GET_FILTER_EMAILS_SUCCESS:
      return {
        ...state,
        filterEmails: action.payload,
      };
    case GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS:
      return {
        ...state,
        activeEngagementTopics: action.payload,
      };

    case GET_MORALE_GOAL_SUCCESS:
      return {
        ...state,
        goals: { ...state.goals, morale: action.payload },
      };
    case GET_MORALE_GOAL_FAILURE:
      return {
        ...state,
        goals: { ...state.goals, morale: 0 },
      };

    case GET_ENGAGEMENT_GOAL_SUCCESS:
      return {
        ...state,
        goals: { ...state.goals, engagement: action.payload },
      };
    case GET_ENGAGEMENT_GOAL_FAILURE:
      return {
        ...state,
        goals: {
          ...state.goals,
          engagement: {
            overall: 0,
            user: 0,
            message: 0,
            topic: 0,
          },
        },
      };

    case GET_RETENTION_GOAL_SUCCESS:
      return {
        ...state,
        goals: { ...state.goals, retention: action.payload },
      };
    case GET_RETENTION_GOAL_FAILURE:
      return {
        ...state,
        goals: { ...state.goals, retention: 0 },
      };

    case GET_ENPS_GOAL_SUCCESS:
      return {
        ...state,
        goals: { ...state.goals, enps: action.payload },
      };
    case GET_ENPS_GOAL_FAILURE:
      return {
        ...state,
        goals: {
          ...state.goals,
          enps: {
            overall: 0,
            topic: 0,
            survey: 0,
          },
        },
      };

    case GET_CULTURE_GOAL_SUCCESS:
      return {
        ...state,
        goals: { ...state.goals, culture: action.payload },
      };
    case GET_CULTURE_GOAL_FAILURE:
      return {
        ...state,
        goals: {
          ...state.goals,
          culture: {
            overall: 0,
            topic: 0,
            survey: 0,
          },
        },
      };

    case GET_MESSAGE_DELETION_DAYS_SUCCESS:
      return {
        ...state,
        messageDeletionDays: action.payload.days
      };

    case GET_DYNAMIC_FILTERS_SUCCESS:
      return {
        ...state,
        selectedFilters: action.payload.selectedFilter,
        availableFilters: action.payload.filter,
        filterMapping: action.payload.uniqueFilter || action.payload.filterMapping || filterMapping
      };

    case ADD_DEMO_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };

    case GET_MINIMUM_USER_COUNT_SUCCESS:
      return {
        ...state,
        minUserCount: action.payload,
      };
    case GET_MINIMUM_USER_COUNT_FAILURE:
      return {
        ...state,
        minUserCount: action.payload,
      };

    case GET_INSIGHT_SCHEDULE_SUCCESS:
      return {
        ...state,
        insightSchedule: action.payload,
      };
    case GET_INSIGHT_SCHEDULE_FAILURE:
      return {
        ...state,
        insightSchedule: action.payload,
      };
    case GET_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        financialYearStartDate: action.payload,
      };
    case GET_FINANCIAL_YEAR_FAILURE:
      return {
        ...state,
        financialYearStartDate: action.payload,
      };
    case GET_MESSAGE_DELETION_INTERVAL_SUCCESS:
      return {
        ...state,
        messageInterval: action.payload,
      };
    case GET_MESSAGE_DELETION_INTERVAL_FAILURE:
      return {
        ...state,
        messageInterval: action.payload,
      };
    case GET_HRIS_DATA_UPDATE_INTERVAL_SUCCESS:
      return {
        ...state,
        hrisUpdateInterval: action.payload,
      };
    case GET_HRIS_DATA_UPDATE_INTERVAL_FAILURE:
      return {
        ...state,
        hrisUpdateInterval: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
