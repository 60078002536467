export const GET_TOTAL_CONVO_DETAILS = "GET_TOTAL_CONVO_DETAILS";
export const GET_TOTAL_CONVO_DETAILS_SUCCESS =
  "GET_TOTAL_CONVO_DETAILS_SUCCESS";
export const GET_TOTAL_CONVO_DETAILS_FAILURE =
  "GET_TOTAL_CONVO_DETAILS_FAILURE";
export const GET_DETECTED_USER_DETAILS = "GET_DETECTED_USER_DETAILS";
export const GET_DETECTED_USER_DETAILS_SUCCESS =
  "GET_DETECTED_USER_DETAILS_SUCCESS";
export const GET_DETECTED_USER_DETAILS_FAILURE =
  "GET_DETECTED_USER_DETAILS_FAILURE";
export const GET_MODEL_TRENDS_DETAILS = "GET_MODEL_TRENDS_DETAILS";
export const GET_MODEL_TRENDS_DETAILS_SUCCESS =
  "GET_MODEL_TRENDS_DETAILS_SUCCESS";
export const GET_MODEL_TRENDS_DETAILS_FAILURE =
  "GET_MODEL_TRENDS_DETAILS_FAILURE";
export const GET_EMOTIONAL_MODEL = "GET_EMOTIONAL_MODEL";
export const GET_EMOTIONAL_MODEL_SUCCESS = "GET_EMOTIONAL_MODEL_SUCCESS";
export const GET_EMOTIONAL_MODEL_FAILURE = "GET_EMOTIONAL_MODEL_FAILURE";
export const GET_PIPHISHING_MODEL = "GET_PIPHISHING_MODEL";
export const GET_PIPHISHING_MODEL_SUCCESS = "GET_PIPHISHING_MODEL_SUCCESS";
export const GET_PIPHISHING_MODEL_FAILURE = "GET_PIPHISHING_MODEL_FAILURE";
export const GET_WORKFLOW_DETAILS = "GET_WORKFLOW_DETAILS";
export const GET_WORKFLOW_DETAILS_SUCCESS = "GET_WORKFLOW_DETAILS_SUCCESS";
export const GET_WORKFLOW_DETAILS_FAILURE = "GET_WORKFLOW_DETAILS_FAILURE";
export const GET_MODEL_CHANNEL = "GET_MODEL_CHANNEL";
export const GET_MODEL_CHANNEL_SUCCESS = "GET_MODEL_CHANNEL_SUCCESS";
export const GET_MODEL_CHANNEL_FAILURE = "GET_MODEL_CHANNEL_FAILURE";
export const UPLOAD_SOURCE_FILES = "UPLOAD_SOURCE_FILES";
export const UPLOAD_SOURCE_FILES_SUCCESS = "UPLOAD_SOURCE_FILES_SUCCESS";
export const UPLOAD_SOURCE_FILES_FAILURE = "UPLOAD_SOURCE_FILES_FAILURE";
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_FAILURE = "GET_CHANNELS_FAILURE";
export const SAVE_WORKFLOW = "SAVE_WORKFLOW";
export const SAVE_WORKFLOW_SUCCESS = "SAVE_WORKFLOW_SUCCESS";
export const SAVE_WORKFLOW_FAILURE = "SAVE_WORKFLOW_FAILURE";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const UPDATE_WORKFLOW_SUCCESS = "UPDATE_WORKFLOW_SUCCESS";
export const UPDATE_WORKFLOW_FAILURE = "UPDATE_WORKFLOW_FAILURE";
export const GET_WORKFLOW = "GET_WORKFLOW";
export const GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS";
export const GET_WORKFLOW_FAILURE = "GET_WORKFLOW_FAILURE";
export const BUILD_WORKFLOW = "BUILD_WORKFLOW";
export const BUILD_WORKFLOW_SUCCESS = "BUILD_WORKFLOW_SUCCESS";
export const SHOW_BUILD_STATUS = "SHOW_BUILD_STATUS";
export const SHOW_BUILD_STATUS_SUCCESS = "SHOW_BUILD_STATUS_SUCCESS";
export const SHOW_BUILD_STATUS_FAILURE = "SHOW_BUILD_STATUS_FAILURE";
export const BUILD_WORKFLOW_FAILURE = "BUILD_WORKFLOW_FAILURE";
export const GET_USER_WORKFLOW = "GET_USER_WORKFLOW";
export const GET_USER_WORKFLOW_SUCCESS = "GET_USER_WORKFLOW_SUCCESS";
export const GET_USER_WORKFLOW_FAILURE = "GET_USER_WORKFLOW_FAILURE";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_FAILURE = "DELETE_WORKFLOW_FAILURE";
export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_DATA_SUCCESS = "CLEAR_DATA_SUCCESS";
export const CHECK_KEYS = "CHECK_KEYS";
export const CHECK_KEYS_SUCCESS = "CHECK_KEYS_SUCCESS";
export const CHECK_KEYS_FAILURE = "CHECK_KEYS_FAILURE";
export const EMPTY_CHANNELS = "EMPTY_CHANNELS";
export const EMPTY_CHANNELS_SUCCESS = "EMPTY_CHANNELS_SUCCESS";
export const EMPTY_CHANNELS_FAILURE = "EMPTY_CHANNELS_FAILURE";
export const SET_MODEL_CONFIGURATION = "SET_MODEL_CONFIGURATION";
export const SET_ACTION_CONFIGURATION = "SET_ACTION_CONFIGURATION";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_ACCESS_TOKEN_SUCCESS = "GET_ACCESS_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN_FAILURE = "GET_ACCESS_TOKEN_FAILURE";
export const SAVING_ACCESS_TOKEN = "SAVING_ACCESS_TOKEN";
export const SAVING_ACCESS_TOKEN_SUCCESS = "SAVING_ACCESS_TOKEN_SUCCESS";
export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const GET_INVESTIGATING_TABLE = "GET_INVESTIGATING_TABLE";
export const GET_INVESTIGATING_TABLE_SUCCESS =
  "GET_INVESTIGATING_TABLE_SUCCESS";
export const GET_INVESTIGATING_TABLE_FAILURE =
  "GET_INVESTIGATING_TABLE_FAILURE";
export const GET_DETAILED_INVESTIGATING_TABLE =
  "GET_DETAILED_INVESTIGATING_TABLE";
export const GET_DETAILED_INVESTIGATING_TABLE_SUCCESS =
  "GET_DETAILED_INVESTIGATING_TABLE_SUCCESS";
export const GET_DETAILED_INVESTIGATING_TABLE_FAILURE =
  "GET_DETAILED_INVESTIGATING_TABLE_FAILURE";
export const GET_MORALE_SCORE = "GET_MORALE_SCORE";
export const GET_MORALE_SCORE_SUCCESS = "GET_MORALE_SCORE_SUCCESS";
export const GET_MORALE_SCORE_FAILURE = "GET_MORALE_SCORE_FAILURE";

export const GET_ENGAGEMENT = "GET_ENGAGEMENT";
export const GET_ENGAGEMENT_SUCCESS = "GET_ENGAGEMENT_SUCCESS";
export const GET_ENGAGEMENT_FAILURE = "GET_ENGAGEMENT_FAILURE";

export const GET_RETENTION = "GET_RETENTION";
export const GET_RETENTION_SUCCESS = "GET_RETENTION_SUCCESS";
export const GET_RETENTION_FAILURE = "GET_RETENTION_FAILURE";

export const GET_SUPPORTER_TREND = "GET_SUPPORTER_TREND";
export const GET_SUPPORTER_TREND_SUCCESS = "GET_SUPPORTER_TREND_SUCCESS";
export const GET_SUPPORTER_TREND_FAILURE = "GET_SUPPORTER_TREND_FAILURE";

export const GET_PROFANITY_TRENDS = "GET_PROFANITY_TRENDS";
export const GET_PROFANITY_TRENDS_SUCCESS = "GET_PROFANITY_TRENDS_SUCCESS";
export const GET_PROFANITY_TRENDS_FAILURE = "GET_PROFANITY_TRENDS_FAILURE";
export const GET_HATE_SPEECH_TRENDS = "GET_HATE_SPEECH_TRENDS";
export const GET_HATE_SPEECH_TRENDS_SUCCESS = "GET_HATE_SPEECH_TRENDS_SUCCESS";
export const GET_HATE_SPEECH_TRENDS_FAILURE = "GET_HATE_SPEECH_TRENDS_FAILURE";
export const GET_INSULT_TRENDS = "GET_INSULT_TRENDS";
export const GET_INSULT_TRENDS_SUCCESS = "GET_INSULT_TRENDS_SUCCESS";
export const GET_INSULT_TRENDS_FAILURE = "GET_INSULT_TRENDS_FAILURE";
export const GET_SENTIMENTS_TRENDS = "GET_SENTIMENTS_TRENDS";
export const GET_SENTIMENTS_TRENDS_SUCCESS = "GET_SENTIMENTS_TRENDS_SUCCESS";
export const GET_SENTIMENTS_TRENDS_FAILURE = "GET_SENTIMENTS_TRENDS_FAILURE";
export const GET_SENTIMENTS_DIST = "GET_SENTIMENTS_DIST";
export const GET_SENTIMENTS_DIST_SUCCESS = "GET_SENTIMENTS_DIST_SUCCESS";
export const GET_SENTIMENTS_DIST_FAILURE = "GET_SENTIMENTS_DIST_FAILURE";
export const GET_SEXUAL_HARRAS_TRENDS = "GET_SEXUAL_HARRAS_TRENDS";
export const GET_SEXUAL_HARRAS_TRENDS_SUCCESS =
  "GET_SEXUAL_HARRAS_TRENDS_SUCCESS";
export const GET_SEXUAL_HARRAS_TRENDS_FAILURE =
  "GET_SEXUAL_HARRAS_TRENDS_FAILURE";
export const GET_SEXISM_TRENDS = "GET_SEXISM_TRENDS";
export const GET_SEXISM_TRENDS_SUCCESS = "GET_SEXISM_TRENDS_SUCCESS";
export const GET_SEXISM_TRENDS_FAILURE = "GET_SEXISM_TRENDS_FAILURE";
export const GET_WORD_CLOUD_DATA = "GET_WORD_CLOUD_DATA";
export const GET_WORD_CLOUD_DATA_SUCCESS = "GET_WORD_CLOUD_DATA_SUCCESS";
export const GET_WORD_CLOUD_DATA_FAILURE = "GET_WORD_CLOUD_DATA_FAILURE";
export const GET_TRENDING_TOPICS = "GET_TRENDING_TOPICS";
export const GET_TRENDING_TOPICS_SUCCESS = "GET_TRENDING_TOPICS_SUCCESS";
export const GET_TRENDING_TOPICS_FAILURE = "GET_TRENDING_TOPICS_FAILURE";

export const GET_ALL_ENGAGEMENT_TOPIC = "GET_ENGAGEMENT_TOPIC";
export const GET_ALL_ENGAGEMENT_TOPIC_SUCCESS = "GET_ENGAGEMENT_TOPIC_SUCCESS";
export const GET_ALL_ENGAGEMENT_TOPIC_FAILURE = "GET_ENGAGEMENT_TOPIC_FAILURE";

export const GET_ADDED_TOPICS = "GET_ADDED_TOPICS";
export const GET_ADDED_TOPICS_SUCCESS = "GET_ADDED_TOPICS_SUCCESS";
export const GET_ADDED_TOPICS_FAILURE = "GET_ADDED_TOPICS_FAILURE";


export const GET_HASH_TAGS = "GET_HASH_TAGS";
export const GET_HASH_TAGS_SUCCESS = "GET_HASH_TAGS_SUCCESS";
export const GET_HASH_TAGS_FAILURE = "GET_HASH_TAGS_FAILURE";
export const GET_POLITICAL_WORDS = "GET_POLITICAL_WORDS";
export const GET_POLITICAL_WORDS_SUCCESS = "GET_POLITICAL_WORDS_SUCCESS";
export const GET_POLITICAL_WORDS_FAILURE = "GET_POLITICAL_WORDS_FAILURE";
export const GET_TEXT_ABUSE_DATA = "GET_TEXT_ABUSE_DATA";
export const GET_TEXT_ABUSE_DATA_SUCCESS = "GET_TEXT_ABUSE_DATA_SUCCESS";
export const GET_TEXT_ABUSE_DATA_FAILURE = "GET_TEXT_ABUSE_DATA_FAILURE";
export const GET_RACISM_TREND_DATA = "GET_RACISM_TREND_DATA";
export const GET_RACISM_TREND_DATA_SUCCESS = "GET_RACISM_TREND_DATA_SUCCESS";
export const GET_RACISM_TREND_DATA_FAILURE = "GET_RACISM_TREND_DATA_FAILURE";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const SAVE_CHANNELSFROMTEAMS = "SAVE_CHANNELSFROMTEAMS";
export const SAVE_TEAMID = "SAVE_TEAMID";
export const SAVE_TEAM_CHATS = "SAVE_TEAM_CHATS";
export const SAVE_TEAM_CHATS_SUCCESS = "SAVE_TEAM_CHATS_SUCCESS";
export const SAVE_TEAM_CHATS_FAILURE = "SAVE_TEAM_CHATS_FAILURE";

export const SAVE_DISCORD_ID = "SAVE_DISCORD_ID";
export const SAVE_DISCORD_ID_SUCCESS = "SAVE_DISCORD_ID_SUCCESS";
export const SAVE_DISCORD_ID_FAILURE = "SAVE_DISCORD_ID_FAILURE";
export const CHECK_SOURCE_DISCORD = "CHECK_SOURCE_DISCORD";

export const OVERVIEW_EXPORT = "OVERVIEW_EXPORT";
export const OVERVIEW_EXPORT_SUCCESS = "OVERVIEW_EXPORT_SUCCESS";
export const OVERVIEW_EXPORT_FAILURE = "OVERVIEW_EXPORT_FAILURE";

export const CHARTS_IMAGE_DATA = "CHARTS_IMAGE_DATA";

export const GET_DIVERSITY_DATA = "GET_DIVERSITY_DATA";
export const GET_DIVERSITY_DATA_SUCCESS = "GET_DIVERSITY_DATA_SUCCESS";
export const GET_DIVERSITY_DATA_FAILURE = "GET_DIVERSITY_DATA_FAILURE";

export const GET_CUSTOM_ANALYSIS_DATA = "GET_CUSTOM_ANALYSIS_DATA";
export const GET_CUSTOM_ANALYSIS_DATA_SUCCESS =
  "GET_CUSTOM_ANALYSIS_DATA_SUCCESS";
export const GET_CUSTOM_ANALYSIS_DATA_FAILURE =
  "GET_CUSTOM_ANALYSIS_DATA_FAILURE";

export const SET_FILTERS = "SET_FILTERS";

export const GET_EMOTION_JOY = "GET_EMOTION_JOY";
export const GET_EMOTION_JOY_SUCCESS = "GET_EMOTION_JOY_SUCCESS";
export const GET_EMOTION_JOY_FAILURE = "GET_EMOTION_FAILURE";

export const GET_EMOTION_ANGER = "GET_EMOTION_ANGER";
export const GET_EMOTION_ANGER_SUCCESS = "GET_EMOTION_ANGER_SUCCESS";
export const GET_EMOTION_ANGER_FAILURE = "GET_EMOTION_ANGER_FAILURE";

export const GET_EMOTION_SURPRISE = "GET_EMOTION_SURPRISE";
export const GET_EMOTION_SURPRISE_SUCCESS = "GET_EMOTION_SURPRISE_SUCCESS";
export const GET_EMOTION_SURPRISE_FAILURE = "GET_EMOTION_SURPRISE_FAILURE";

export const GET_EMOTION_SAD = "GET_EMOTION_SAD";
export const GET_EMOTION_SAD_SUCCESS = "GET_EMOTION_SAD_SUCCESS";
export const GET_EMOTION_SAD_FAILURE = "GET_EMOTION_SAD_FAILURE";

export const GET_EMOTION_FEAR = "GET_EMOTION_FEAR";
export const GET_EMOTION_FEAR_SUCCESS = "GET_EMOTION_FEAR_SUCCESS";
export const GET_EMOTION_FEAR_FAILURE = "GET_EMOTION_FEAR_FAILURE";

export const GET_EMOTION_TOTAL = "GET_EMOTION_TOTAL";
export const GET_EMOTION_TOTAL_SUCCESS = "GET_EMOTION_TOTAL_SUCCESS";
export const GET_EMOTION_TOTAL_FAILURE = "GET_EMOTION_TOTAL_FAILURE";

export const GET_EMOTION_NEUTRAL = "GET_EMOTION_NEUTRAL";
export const GET_EMOTION_NEUTRAL_SUCCESS = "GET_EMOTION_NEUTRAL_SUCCESS";
export const GET_EMOTION_NEUTRAL_FAILURE = "GET_EMOTION_NEUTRAL_FAILURE";

export const SAVE_DEMO_WORK = "SAVE_DEMO_WORK";

export const SET_DATE_FILTER_SENTIMENT = "SET_DATE_FILTER_SENTIMENT";

export const SET_SECTIONS = "SET_SECTIONS";

export const GET_MORALE_USERS_NUMBER = "GET_MORALE_USERS_NUMBER";
export const GET_MORALE_USERS_NUMBER_SUCCESS =
  "GET_MORALE_USERS_NUMBER_SUCCESS";
export const GET_MORALE_USERS_NUMBER_FAILURE =
  "GET_MORALE_USERS_NUMBER_FAILURE";

export const GET_DASHBOARD_SECTIONS = "GET_DASHBOARD_SECTIONS";
export const GET_DASHBOARD_SECTIONS_SUCCESS = "GET_DASHBOARD_SECTIONS_SUCCESS";
export const GET_DASHBOARD_SECTIONS_FAILURE = "GET_DASHBOARD_SECTIONS_FAILURE";

export const SAVE_DASHBOARD_SECTIONS = "SAVE_DASHBOARD_SECTIONS";
export const SAVE_DASHBOARD_SECTIONS_SUCCESS =
  "SAVE_DASHBOARD_SECTIONS_SUCCESS";
export const SAVE_DASHBOARD_SECTIONS_FAILURE =
  "SAVE_DASHBOARD_SECTIONS_FAILURE";

export const GET_DEI_TRENDS = "GET_DEI_TRENDS";
export const GET_DEI_TRENDS_SUCCESS = "GET_DEI_TRENDS_SUCCESS";
export const GET_DEI_TRENDS_FAILURE = "GET_DEI_TRENDS_FAILURE";

export const SET_NIGHT_MODE = "SET_NIGHT_MODE";

export const GET_TOTAL_TEXTS = "GET_TOTAL_TEXTS";
export const GET_TOTAL_TEXTS_SUCCESS = "GET_TOTAL_TEXTS_SUCCESS";
export const GET_TOTAL_TEXTS_FAILURE = "GET_TOTAL_TEXTS_FAILURE";

export const GET_TEAM_SIZE = "GET_TEAM_SIZE";
export const GET_TEAM_SIZE_SUCCESS = "GET_TEAM_SIZE_SUCCESS";
export const GET_TEAM_SIZE_FAILURE = "GET_TEAM_SIZE_FAILURE";

export const GET_TOPIC_NUMBER = "GET_TOPIC_NUMBER";
export const GET_TOPIC_NUMBER_SUCCESS = "GET_TOPIC_NUMBER_SUCCESS";
export const GET_TOPIC_NUMBER_FAILURE = "GET_TOPIC_NUMBER_FAILURE";

export const GET_MORALE_CHANGE = "GET_MORALE_CHANGE";
export const GET_MORALE_CHANGE_SUCCESS = "GET_MORALE_CHANGE_SUCCESS";
export const GET_MORALE_CHANGE_FAILURE = "GET_MORALE_CHANGE_FAILURE";

export const GET_LICENSE_PLANS = "GET_LICENSE_PLANS";
export const GET_LICENSE_PLANS_SUCCESS = "GET_LICENSE_PLANS_SUCCESS";
export const GET_LICENSE_PLANS_FAILURE = "GET_LICENSE_PLANS_FAILURE";

export const OPT_LICENSE = "OPT_LICENSE";
export const OPT_LICENSE_SUCCESS = "OPT_LICENSE_SUCCESS";
export const OPT_LICENSE_FAILURE = "OPT_LICENSE_FAILURE";

export const GET_FILTER_CHANNEL = "GET_FILTER_CHANNEL";
export const GET_FILTER_CHANNEL_SUCCESS = "GET_FILTER_CHANNEL_SUCCESS";
export const GET_FILTER_CHANNEL_FAILURE = "GET_FILTER_CHANNEL_FAILURE";

export const GET_HRIS_USERS = "GET_HRIS_USERS";
export const GET_HRIS_USERS_SUCCESS = "GET_HRIS_USERS_SUCCESS";
export const GET_HRIS_USERS_FAILURE = "GET_HRIS_USERS_FAILURE";

export const SAVE_HRIS_USERS = "SAVE_HRIS_USERS";
export const SAVE_HRIS_USERS_SUCCESS = "SAVE_HRIS_USERS_SUCCESS";
export const SAVE_HRIS_USERS_FAILURE = "SAVE_HRIS_USERS_FAILURE";

export const SAVE_ZOOM_TRANSCRIPT = "SAVE_ZOOM_TRANSCRIPT";

export const ZOOM_FILE_UPLOAD = "ZOOM_FILE_UPLOAD";
export const ZOOM_FILE_UPLOAD_SUCCESS = "ZOOM_FILE_UPLOAD_SUCCESS";
export const ZOOM_FILE_UPLOAD_FAILURE = "ZOOM_FILE_UPLOAD_FAILURE";

export const GET_MORALE_COMPARISON = "GET_MORALE_COMPARISON";
export const GET_MORALE_COMPARISON_SUCCESS = "GET_MORALE_COMPARISON_SUCESS";
export const GET_MORALE_COMPARISON_FAILURE = "GET_MORALE_COMPARISON_FAILURE";

export const GET_ENGAGEMENT_COMPARISON = "GET_ENGAGEMENT_COMPARISON";
export const GET_ENGAGEMENT_COMPARISON_SUCCESS =
  "GET_ENGAGEMENT_COMPARISON_SUCCESS";
export const GET_ENGAGEMENT_COMPARISON_FAILURE =
  "GET_ENGAGEMENT_COMPARISON_FAILURE";

export const GET_RETENTION_COMPARISON = "GET_RETENTION_COMPARISON";
export const GET_RETENTION_COMPARISON_SUCCESS =
  "GET_RETENTION_COMPARISON_SUCCESS";
export const GET_RETENTION_COMPARISON_FAILURE =
  "GET_RETENTION_COMPARISON_FAILURE";

export const GET_SUPPORTER_COMPARISON = "GET_SUPPORTER_COMPARISON";
export const GET_SUPPORTER_COMPARISON_SUCCESS =
  "GET_SUPPORTER_COMPARISON_SUCCESS";
export const GET_SUPPORTER_COMPARISON_FAILURE =
  "GET_SUPPORTER_COMPARISON_FAILURE";

export const GET_ZOOM_TOKEN = "GET_ZOOM_TOKEN";
export const GET_ZOOM_TOKEN_SUCCESS = "GET_ZOOM_TOKEN_SUCCESS";
export const GET_ZOOM_TOKEN_FAILURE = "GET_ZOOM_TOKEN_FAILURE";

export const GET_MORALE_INSIGHTS = "GET_MORALE_INSIGHTS";
export const GET_MORALE_INSIGHTS_SUCCESS = "GET_MORALE_INSIGHTS_SUCCESS";
export const GET_MORALE_INSIGHTS_FAILURE = "GET_MORALE_INSIGHTS_FAILURE";

export const SET_CHAT_CONTEXT = "SET_CHAT_CONTEXT";

export const GET_PUBLIC_HOLIDAYS = "GET_PUBLIC_HOLIDAYS";
export const GET_PUBLIC_HOLIDAYS_SUCCESS = "GET_PUBLIC_HOLIDAYS_SUCCESS";
export const GET_PUBLIC_HOLIDAYS_FAILURE = "GET_PUBLIC_HOLIDAYS_FAILURE";

export const GET_SINGLE_INSIGHTS = "GET_SINGLE_INSIGHTS";
export const GET_SINGLE_INSIGHTS_SUCCESS = "GET_SINGLE_INSIGHTS_SUCCESS";
export const GET_SINGLE_INSIGHTS_FAILURE = "GET_SINGLE_INSIGHTS_FAILURE";

export const GET_MULTIPLE_INSIGHTS = "GET_MULTIPLE_INSIGHTS";
export const GET_MULTIPLE_INSIGHTS_SUCCESS = "GET_MULTIPLE_INSIGHTS_SUCCESS";
export const GET_MULTIPLE_INSIGHTS_FAILURE = "GET_MULTIPLE_INSIGHTS_FAILURE";

export const GET_NONE_INSIGHTS = "GET_NONE_INSIGHTS";
export const GET_NONE_INSIGHTS_SUCCESS = "GET_NONE_INSIGHTS_SUCCESS";
export const GET_NONE_INSIGHTS_FAILURE = "GET_NONE_INSIGHTS_FAILURE";

export const GET_TOPIC_SINGLE_INSIGHTS = "GET_TOPIC_SINGLE_INSIGHTS";
export const GET_TOPIC_SINGLE_INSIGHTS_SUCCESS =
  "GET_TOPIC_SINGLE_INSIGHTS_SUCCESS";
export const GET_TOPIC_SINGLE_INSIGHTS_FAILURE =
  "GET_TOPIC_SINGLE_INSIGHTS_FAILURE";

export const GET_TOPIC_MULTIPLE_INSIGHTS = "GET_TOPIC_MULTIPLE_INSIGHTS";
export const GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS =
  "GET_TOPIC_MULTIPLE_INSIGHTS_SUCCESS";
export const GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE =
  "GET_TOPIC_MULTIPLE_INSIGHTS_FAILURE";

export const GET_TOPIC_NONE_INSIGHTS = "GET_TOPIC_NONE_INSIGHTS";
export const GET_TOPIC_NONE_INSIGHTS_SUCCESS =
  "GET_TOPIC_NONE_INSIGHTS_SUCCESS";
export const GET_TOPIC_NONE_INSIGHTS_FAILURE =
  "GET_TOPIC_NONE_INSIGHTS_FAILURE";

export const GET_EVENT_SINGLE_INSIGHTS = "GET_EVENT_SINGLE_INSIGHTS";
export const GET_EVENT_SINGLE_INSIGHTS_SUCCESS =
  "GET_EVENT_SINGLE_INSIGHTS_SUCCESS";
export const GET_EVENT_SINGLE_INSIGHTS_FAILURE =
  "GET_EVENT_SINGLE_INSIGHTS_FAILURE";

export const GET_EVENT_MULTIPLE_INSIGHTS = "GET_EVENT_MULTIPLE_INSIGHTS";
export const GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS =
  "GET_EVENT_MULTIPLE_INSIGHTS_SUCCESS";
export const GET_EVENT_MULTIPLE_INSIGHTS_FAILURE =
  "GET_EVENT_MULTIPLE_INSIGHTS_FAILURE";

export const GET_EVENT_NONE_INSIGHTS = "GET_EVENT_NONE_INSIGHTS";
export const GET_EVENT_NONE_INSIGHTS_SUCCESS =
  "GET_EVENT_NONE_INSIGHTS_SUCCESS";
export const GET_EVENT_NONE_INSIGHTS_FAILURE =
  "GET_EVENT_NONE_INSIGHTS_FAILURE";

export const GET_CALENDER_EVENTS = "GET_CALENDER_EVENTS";
export const GET_CALENDER_EVENTS_SUCCESS = "GET_CALENDER_EVENTS_SUCCESS";
export const GET_CALENDER_EVENTS_FAILURE = "GET_CALENDER_EVENTS_FAILURE";

export const ADD_CALENDER_EVENT = "ADD_CALENDER_EVENTS";
export const ADD_CALENDER_EVENT_SUCCESS = "ADD_CALENDER_EVENTS_SUCCESS";
export const ADD_CALENDER_EVENT_FAILURE = "ADD_CALENDER_EVERY_FAILURE";

export const UPDATE_CALENDER_EVENT = "UPDATE_CALENDER_EVENTS";
export const UPDATE_CALENDER_EVENT_SUCCESS = "UPDATE_CALENDER_EVENTS_SUCCESS";
export const UPDATE_CALENDER_EVENT_FAILURE = "UPDATE_CALENDER_EVENTS_FAILURE";

export const DELETE_CALENDER_EVENT = "DELETE_CALENDER_EVENTS";
export const DELETE_CALENDER_EVENT_SUCCESS = "DELETE_CALENDER_EVENTS_SUCCESS";
export const DELETE_CALENDER_EVENT_FAILURE = "DELETE_CALENDER_EVENTS_FAILURE";

export const SAVE_DEMO_ANNOTATION = "SAVE_DEMO_ANNOTATION";
export const DELETE_DEMO_ANNOTATION = "DELETE_DEMO_ANNOTATION";

export const SET_LOADING = "SET_LOADING";

export const SET_LEVEL = "SET_LEVEL";

export const TOGGLE_PUBLIC_HOLIDAYS = "SHOW_PUBLIC_HOLIDAYS";

export const GET_ALL_SURVEYS = "GET_ALL_SURVEYS";
export const GET_ALL_SURVEYS_SUCCESS = "GET_ALL_SURVEYS_SUCCESS";
export const GET_ALL_SURVEYS_FAILURE = "GET_ALL_SURVEYS_FAILURE";

export const GET_ALL_BASELINE_SURVEYS = "GET_ALL_BASELINE_SURVEYS";
export const GET_ALL_BASELINE_SURVEYS_SUCCESS =
  "GET_ALL_BASELINE_SURVEYS_SUCCESS";
export const GET_ALL_BASELINE_SURVEYS_FAILURE =
  "GET_ALL_BASELINE_SURVEYS_FAILURE";

export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAILURE = "UPDATE_SURVEY_FAILURE";

export const LAUNCH_SURVEY = "LAUNCH_SURVEY";
export const LAUNCH_SURVEY_SUCCESS = "LAUNCH_SURVEY_SUCCESS";
export const LAUNCH_SURVEY_FAILURE = "LAUNCH_SURVEY_FAILURE";

export const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";

export const UPDATE_SURVEY_QUESTION = "UPDATE_SURVEY_QUESTION";

export const SET_CURRENT_SOURCE = "SET_CURRENT_SOURCE";

export const GET_STATUS_BOX = "GET_STATUS_BOX";
export const GET_STATUS_BOX_SUCCESS = "GET_STATUS_BOX_SUCCESS";
export const GET_STATUS_BOX_FAILURE = "GET_STATUS_BOX_FAILURE";

export const UPDATE_STATUS_BOX = "UPDATE_STATUS_BOX";
export const UPDATE_STATUS_BOX_SUCCESS = "UPDATE_STATUS_BOX_SUCCESS";
export const UPDATE_STATUS_BOX_FAILURE = "UPDATE_STATUS_BOX_FAILURE";

export const CREATE_CUSTOM_SURVEY = "CREATE_CUSTOM_SURVEY";

export const UPDATE_SURVEY_DETAILS = "UPDATE_SURVEY_DETAILS";

export const SET_MORALE_PREDICTION = "SET_MORALE_PREDICTION";

export const SET_OVERALL_ENGAGEMENT_PREDICTION =
  "SET_OVERALL_ENGAGEMENT_PREDICTION";
export const SET_USER_ENGAGEMENT_PREDICTION = "SET_USER_ENGAGEMENT_PREDICTION";
export const SET_TEXT_ENGAGEMENT_PREDICTION = "SET_TEXT_ENGAGEMENT_PREDICTION";
export const SET_TOPIC_ENGAGEMENT_PREDICTION =
  "SET_TOPIC_ENGAGEMENT_PREDICTION";
export const SET_RETENTION_PREDICTION = "SET_RETENTION_PREDICTION";

export const GET_ALL_FOCUS_GROUPS = "GET_ALL_FOCUS_GROUPS";
export const GET_ALL_FOCUS_GROUPS_SUCCESS = "GET_ALL_FOCUS_GROUPS_SUCCESS";
export const GET_ALL_FOCUS_GROUPS_FAILURE = "GET_ALL_FOCUS_GROUPS_FAILURE";

export const GET_ALL_FOCUS_CHAT = "GET_ALL_FOCUS_CHAT";
export const GET_ALL_FOCUS_CHAT_SUCCESS = "GET_ALL_FOCUS_CHAT_SUCCESS";
export const GET_ALL_FOCUS_CHAT_FAILURE = "GET_ALL_FOCUS_CHAT_FAILURE";

export const CREATE_FOCUS_GROUP = "CREATE_FOCUS_GROUP";
export const CREATE_FOCUS_GROUP_SUCCESS = "CREATE_FOCUS_GROUP_SUCCESS";
export const CREATE_FOCUS_GROUP_FAILURE = "CREATE_FOCUS_GROUP_FAILURE";

export const CREATE_CUSTOM_FOCUS_GROUP = "CREATE_CUSTOM_FOCUS_GROUP";
export const CREATE_CUSTOM_FOCUS_GROUP_SUCCESS =
  "CREATE_CUSTOM_FOCUS_GROUP_SUCCESS";
export const CREATE_CUSTOM_FOCUS_GROUP_FAILURE =
  "CREATE_CUSTOM_FOCUS_GROUP_FAILURE";

export const GET_OVERALL_CULTURE = "GET_OVERALL_CULTURE";
export const GET_OVERALL_CULTURE_SUCCESS = "GET_OVERALL_CULTURE_SUCCESS";
export const GET_OVERALL_CULTURE_FAILURE = "GET_OVERALL_CULTURE_FAILURE";

export const GET_POSITIVE_CULTURE = "GET_POSITIVE_CULTURE";
export const GET_POSITIVE_CULTURE_SUCCESS = "GET_POSITIVE_CULTURE_SUCCESS";
export const GET_POSITIVE_CULTURE_FAILURE = "GET_POSITIVE_CULTURE_FAILURE";

export const GET_NEGATIVE_CULTURE = "GET_NEGATIVE_CULTURE";
export const GET_NEGATIVE_CULTURE_SUCCESS = "GET_NEGATIVE_CULTURE_SUCCESS";
export const GET_NEGATIVE_CULTURE_FAILURE = "GET_NEGATIVE_CULTURE_FAILURE";

export const GET_MORALE_TOPICS = "GET_MORALE_TOPICS";
export const GET_MORALE_TOPICS_SUCCESS = "GET_MORALE_TOPICS_SUCCESS";
export const GET_MORALE_TOPICS_FAILURE = "GET_MORALE_TOPICS_FAILURE";

export const GET_INSIGHTS_THRESHOLD = "GET_INSIGHTS_THRESHOLD";
export const GET_INSIGHTS_THRESHOLD_SUCCESS = "GET_INSIGHTS_THRESHOLD_SUCCESS";
export const GET_INSIGHTS_THRESHOLD_FAILURE = "GET_INSIGHTS_THRESHOLD_FAILURE";

export const SAVE_INSIGHTS_THRESHOLD = "SAVE_INSIGHTS_THRESHOLD";
export const SAVE_INSIGHTS_THRESHOLD_SUCCESS =
  "SAVE_INSIGHTS_THRESHOLD_SUCCESS";
export const SAVE_INSIGHTS_THRESHOLD_FAILURE =
  "SAVE_INSIGHTS_THRESHOLD_FAILURE";

export const GET_POSITIVE_CULTURE_THRESHOLD = "GET_POSITIVE_CULTURE_THRESHOLD";
export const GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS =
  "GET_POSITIVE_CULTURE_THRESHOLD_SUCCESS";
export const GET_POSITIVE_CULTURE_THRESHOLD_FAILURE =
  "GET_POSITIVE_CULTURE_THRESHOLD_FAILURE";

export const SAVE_POSITIVE_CULTURE_THRESHOLD =
  "SAVE_POSITIVE_CULTURE_THRESHOLD";
export const SAVE_POSITIVE_CULTURE_THRESHOLD_SUCCESS =
  "SAVE_POSITIVE_CULTURE_THRESHOLD_SUCCESS";
export const SAVE_POSITIVE_CULTURE_THRESHOLD_FAILURE =
  "SAVE_POSITIVE_CULTURE_THRESHOLD_FAILURE";

export const GET_NEGATIVE_CULURE_THRESHOLD = "GET_NEGATIVE_CULURE_THRESHOLD";
export const GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS =
  "GET_NEGATIVE_CULURE_THRESHOLD_SUCCESS";
export const GET_NEGATIVE_CULURE_THRESHOLD_FAILURE =
  "GET_NEGATIVE_CULURE_THRESHOLD_FAILURE";

export const SAVE_NEGATIVE_CULURE_THRESHOLD = "SAVE_NEGATIVE_CULURE_THRESHOLD";
export const SAVE_NEGATIVE_CULURE_THRESHOLD_SUCCESS =
  "SAVE_NEGATIVE_CULURE_THRESHOLD_SUCCESS";
export const SAVE_NEGATIVE_CULURE_THRESHOLD_FAILURE =
  "SAVE_NEGATIVE_CULURE_THRESHOLD_FAILURE";

export const GET_ENGAGEMENT_THRESHOLD = "GET_ENGAGEMENT+THRESHOLD";
export const GET_ENGAGEMENT_THRESHOLD_SUCCESS =
  "GET_ENGAGEMENT_THRESHOLD_SUCCESS";
export const GET_ENGAGEMENT_THRESHOLD_FAILURE =
  "GET_ENGAGEMENT_THRESHOLD_FAILURE";

export const SAVE_ENGAGEMENT_THRESHOLD = "SAVE_ENGAGEMENT_THRESHOLD";
export const SAVE_ENGAGEMENT_THRESHOLD_SUCCESS =
  "SAVE_ENGAGEMENT_THRESHOLD_SUCCESS";
export const SAVE_ENGAGEMENT_THRESHOLD_FAILURE =
  "SAVE_ENGAGEMENT_THRESHOLD_FAILURE";

export const GET_ENPS_THRESHOLD = "GET_ENPS_THRESHOLD";
export const GET_ENPS_THRESHOLD_SUCCESS = "GET_ENPS_THRESHOLD_SUCCESS";
export const GET_ENPS_THRESHOLD_FAILURE = "GET_ENPS_THRESHOLD_FAILURE";

export const SAVE_ENPS_THRESHOLD = "SAVE_ENPS_THRESHOLD";
export const SAVE_ENPS_THRESHOLD_SUCCESS = "SAVE_ENPS_THRESHOLD_SUCCESS";
export const SAVE_ENPS_THRESHOLD_FAILURE = "SAVE_ENPS_THRESHOLD_FAILURE";

export const GET_FAVORITE_FILTERS = "GET_FAVORITE_FILTERS";
export const GET_FAVORITE_FILTERS_SUCCESS = "GET_FAVORITE_FILTERS_SUCCESS";
export const GET_FAVORITE_FILTERS_FAILURE = "GET_FAVORITE_FILTERS_FAILURE";

export const SAVE_FAVORITE_FILTERS = "SAVE_FAVORITE_FILTERS";
export const SAVE_FAVORITE_FILTERS_SUCCESS = "SAVE_FAVORITE_FILTERS_SUCCESS";
export const SAVE_FAVORITE_FILTERS_FAILURE = "SAVE_FAVORITE_FILTERS_FAILURE";

export const DELETE_FAVORITE_FILTER = "DELETE_FAVORITE_FILTER";
export const DELETE_FAVORITE_FILTER_SUCCESS = "DELETE_FAVORITE_FILTER_SUCCESS";
export const DELETE_FAVORITE_FILTER_FAILURE = "DELETE_FAVORITE_FILTER_FAILURE";

export const GET_ENPS_TEXT_SCORE = "GET_ENPS_TEXT_SCORE";
export const GET_ENPS_TEXT_SCORE_SUCCESS = "GET_ENPS_TEXT_SCORE_SUCCESS";
export const GET_ENPS_TEXT_SCORE_FAILURE = "GET_ENPS_TEXT_SCORE_FAILURE";

export const GET_ENPS_TOPIC_SCORE = "GET_ENPS_TOPIC_SCORE";
export const GET_ENPS_TOPIC_SCORE_SUCCESS = "GET_ENPS_TOPIC_SCORE_SUCCESS";
export const GET_ENPS_TOPIC_SCORE_FAILURE = "GET_ENPS_TOPIC_SCORE_FAILURE";

export const GET_ENPS_SURVEY_SCORE = "GET_ENPS_SURVEY_SCORE";
export const GET_ENPS_SURVEY_SCORE_SUCCESS = "GET_ENPS_SURVEY_SCORE_SUCCESS";
export const GET_ENPS_SURVEY_SCORE_FAILURE = "GET_ENPS_SURVEY_SCORE_FAILURE";

export const GET_ENPS_TEXT_TREND = "GET_ENPS_TEXT_TREND";
export const GET_ENPS_TEXT_TREND_SUCCESS = "GET_ENPS_TEXT_TREND_SUCCESS";
export const GET_ENPS_TEXT_TREND_FAILURE = "GET_ENPS_TEXT_TREND_FAILURE";

export const GET_ENPS_TOPIC_TREND = "GET_ENPS_TOPIC_TREND";
export const GET_ENPS_TOPIC_TREND_SUCCESS = "GET_ENPS_TOPIC_TREND_SUCCESS";
export const GET_ENPS_TOPIC_TREND_FAILURE = "GET_ENPS_TOPIC_TREND_FAILURE";

export const GET_ENPS_SURVEY_TREND = "GET_ENPS_SURVEY_TREND";
export const GET_ENPS_SURVEY_TREND_SUCCESS = "GET_ENPS_SURVEY_TREND_SUCCESS";
export const GET_ENPS_SURVEY_TREND_FAILURE = "GET_ENPS_SURVEY_TREND_FAILURE";

export const GET_ENPS_OVERALL_SCORE = "GET_ENPS_OVERALL_SCORE";
export const GET_ENPS_OVERALL_SCORE_SUCCESS = "GET_ENPS_OVERALL_SCORE_SUCCESS";
export const GET_ENPS_OVERALL_SCORE_FAILURE = "GET_ENPS_OVERALL_SCORE_FAILURE";

export const GET_ENPS_OVERALL_TREND = "GET_ENPS_OVERALL_TREND";
export const GET_ENPS_OVERALL_TREND_SUCCESS = "GET_ENPS_OVERALL_TREND_SUCCESS";
export const GET_ENPS_OVERALL_TREND_FAILURE = "GET_ENPS_OVERALL_TREND_FAILURE";

export const GET_SURVEY_SETTINGS = "GET_SURVEY_SETTINGS";
export const GET_SURVEY_SETTINGS_SUCCESS = "GET_SURVEY_SETTINGS_SUCCESS";
export const GET_SURVEY_SETTINGS_FAILURE = "GET_SURVEY_SETTINGS_FAILURE";

export const SAVE_SURVEY_SETTINGS = "SAVE_SURVEY_SETTINGS";
export const SAVE_SURVEY_SETTINGS_SUCCESS = "SAVE_SURVEY_SETTINGS_SUCCESS";
export const SAVE_SURVEY_SETTINGS_FAILURE = "SAVE_SURVEY_SETTINGS_FAILURE";

export const GET_ENGAGEMENT_TOPICS = "GET_ENGAGEMENT_TOPICS";
export const GET_ENGAGEMENT_TOPICS_SUCCESS = "GET_ENGAGEMENT_TOPICS_SUCCESS";
export const GET_ENGAGEMENT_TOPICS_FAILURE = "GET_ENGAGEMENT_TOPICS_FAILURE";

export const GET_ENGAGEMENT_TOPIC_TREND = "GET_ENGAGEMENT_TOPIC_TREND";
export const GET_ENGAGEMENT_TOPIC_TREND_SUCCESS =
  "GET_ENGAGEMENT_TOPIC_TREND_SUCCESS";
export const GET_ENGAGEMENT_TOPIC_TREND_FAILURE =
  "GET_ENGAGEMENT_TOPIC_TREND_FAILURE";

export const SAVE_ENGAGEMENT_TOPICS = "SAVE_ENGAGEMENT_TOPICS";
export const SAVE_ENGAGEMENT_TOPICS_SUCCESS = "SAVE_ENGAGEMENT_TOPICS_SUCCESS";
export const SAVE_ENGAGEMENT_TOPICS_FAILURE = "SAVE_ENGAGEMENT_TOPICS_FAILURE";

export const GET_SUGGESTED_QUESTION = "GET_SUGGESTED_QUESTION";
export const GET_SUGGESTED_QUESTION_SUCCESS = "GET_SUGGESTED_QUESTION_SUCCESS";
export const GET_SUGGESTED_QUESTION_FAILURE = "GET_SUGGESTED_QUESTION_FAILURE";

export const ADD_SURVEY_QUESTION = "ADD_SURVEY_QUESTION";
export const ADD_SURVEY_QUESTION_SUCCESS = "ADD_SURVEY_QUESTION_SUCCESS";
export const ADD_SURVEY_QUESTION_FAILURE = "ADD_SURVEY_QUESTION_FAILURE";

export const GET_INSIGHTS = "GET_INSIGHTS";
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";
export const GET_INSIGHTS_FAILURE = "GET_INSIGHTS_FAILURE";

export const GENEREATE_FOCUS_GROUP_REPORT = "GENEREATE_FOCUS_GROUP_REPORT";
export const GENEREATE_FOCUS_GROUP_REPORT_SUCCESS =
  "GENEREATE_FOCUS_GROUP_REPORT_SUCCESS";
export const GENEREATE_FOCUS_GROUP_REPORT_FAILURE =
  "GENEREATE_FOCUS_GROUP_REPORT_FAILURE";

export const GET_FILTER_EMAILS = "GET_FILTER_EMAILS";
export const GET_FILTER_EMAILS_SUCCESS = "GET_FILTER_EMAILS_SUCCESS";
export const GET_FILTER_EMAILS_FAILURE = "GET_FILTER_EMAILS_FAILURE";

export const GET_ACTIVE_ENGAGEMENT_TOPICS = "GET_ACTIVE_ENGAGEMENT_TOPICS";
export const GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS =
  "GET_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS";
export const GET_ACTIVE_ENGAGEMENT_TOPICS_FAILURE =
  "GET_ACTIVE_ENGAGEMENT_TOPICS_FAILURE";

export const SAVE_ACTIVE_ENGAGEMENT_TOPICS = "SAVE_ACTIVE_ENGAGEMENT_TOPICS";
export const SAVE_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS =
  "SAVE_ACTIVE_ENGAGEMENT_TOPICS_SUCCESS";
export const SAVE_ACTIVE_ENGAGEMENT_TOPICS_FAILURE =
  "SAVE_ACTIVE_ENGAGEMENT_TOPICS_FAILURE";

export const GET_MORALE_GOAL = "GET_MORALE_GOAL";
export const GET_MORALE_GOAL_SUCCESS = "GET_MORALE_GOAL_SUCCESS";
export const GET_MORALE_GOAL_FAILURE = "GET_MORALE_GOAL_FAILURE";

export const GET_ENGAGEMENT_GOAL = "GET_ENGAGEMENT_GOAL";
export const GET_ENGAGEMENT_GOAL_SUCCESS = "GET_ENGAGEMENT_GOAL_SUCCESS";
export const GET_ENGAGEMENT_GOAL_FAILURE = "GET_ENGAGEMENT_GOAL_FAILURE";

export const GET_RETENTION_GOAL = "GET_RETENTION_GOAL";
export const GET_RETENTION_GOAL_SUCCESS = "GET_RETENTION_GOAL_SUCCESS";
export const GET_RETENTION_GOAL_FAILURE = "GET_RETENTION_GOAL_FAILURE";

export const GET_ENPS_GOAL = "GET_ENPS_GOAL";
export const GET_ENPS_GOAL_SUCCESS = "GET_ENPS_GOAL_SUCCESS";
export const GET_ENPS_GOAL_FAILURE = "GET_ENPS_GOAL_FAILURE";

export const GET_CULTURE_GOAL = "GET_CULTURE_GOAL";
export const GET_CULTURE_GOAL_SUCCESS = "GET_CULTURE_GOAL_SUCCESS";
export const GET_CULTURE_GOAL_FAILURE = "GET_CULTURE_GOAL_FAILURE";

export const SET_MORALE_GOAL = "SET_MORALE_GOAL";
export const SET_MORALE_GOAL_SUCCESS = "SET_MORALE_GOAL_SUCCESS";
export const SET_MORALE_GOAL_FAILURE = "SET_MORALE_GOAL_FAILURE";

export const SET_ENGAGEMENT_GOAL = "SET_ENGAGEMENT_GOAL";
export const SET_ENGAGEMENT_GOAL_SUCCESS = "SET_ENGAGEMENT_GOAL_SUCCESS";
export const SET_ENGAGEMENT_GOAL_FAILURE = "SET_ENGAGEMENT_GOAL_FAILURE";

export const SET_RETENTION_GOAL = "SET_RETENTION_GOAL";
export const SET_RETENTION_GOAL_SUCCESS = "SET_RETENTION_GOAL_SUCCESS";
export const SET_RETENTION_GOAL_FAILURE = "SET_RETENTION_GOAL_FAILURE";

export const SET_ENPS_GOAL = "SET_ENPS_GOAL";
export const SET_ENPS_GOAL_SUCCESS = "SET_ENPS_GOAL_SUCCESS";
export const SET_ENPS_GOAL_FAILURE = "SET_ENPS_GOAL_FAILURE";

export const SET_CULTURE_GOAL = "SET_CULTURE_GOAL";
export const SET_CULTURE_GOAL_SUCCESS = "SET_CULTURE_GOAL_SUCCESS";
export const SET_CULTURE_GOAL_FAILURE = "SET_CULTURE_GOAL_FAILURE";



export const GET_ALL_ACTION_PLANS = "GET_ALL_ACTION_PLANS";
export const GET_ALL_ACTION_PLANS_SUCCESS = "GET_ALL_ACTION_PLANS_SUCCESS";
export const GET_ALL_ACTION_PLANS_FAILURE = "GET_ALL_ACTION_PLANS_FAILURE";


export const SAVE_ACTION_PLAN = "SAVE_ACTION_PLAN";
export const SAVE_ACTION_PLAN_SUCCESS = "SAVE_ACTION_PLAN_SUCCESS";
export const SAVE_ACTION_PLAN_FAILURE = "SAVE_ACTION_PLAN_FAILURE";

export const DELETE_ACTION_PLAN = "DELETE_ACTION_PLAN";
export const DELETE_ACTION_PLAN_SUCCESS = "DELETE_ACTION_PLAN_SUCCESS";
export const DELETE_ACTION_PLAN_FAILURE = "DELETE_ACTION_PLAN_FAILURE";

export const INITIATE_ACTION_PLAN = "INITIATE_ACTION_PLAN";
export const INITIATE_ACTION_PLAN_SUCCESS = "INITIATE_ACTION_PLAN_SUCCESS";
export const INITIATE_ACTION_PLAN_FAILURE = "INITIATE_ACTION_PLAN_FAILURE";

export const CLOSE_ACTION_PLAN = "CLOSE_ACTION_PLAN";
export const CLOSE_ACTION_PLAN_SUCCESS = "CLOSE_ACTION_PLAN_SUCCESS";
export const CLOSE_ACTION_PLAN_FAILURE = "CLOSE_ACTION_PLAN_FAILURE";

export const CREATE_FOCUS_GROUP_ACTION_PLAN = "CREATE_FOCUS_GROUP_ACTION_PLAN";
export const CREATE_FOCUS_GROUP_ACTION_PLAN_SUCCESS = "CREATE_FOCUS_GROUP_ACTION_PLAN_SUCCESS";
export const CREATE_FOCUS_GROUP_ACTION_PLAN_FAILURE = "CREATE_FOCUS_GROUP_ACTION_PLAN_FAILURE";


export const GET_MESSAGE_DELETION_DAYS = "GET_MESSAGE_DELETION_DAYS"
export const GET_MESSAGE_DELETION_DAYS_SUCCESS = "GET_MESSAGE_DELETION_DAYS_SUCCESS";
export const GET_MESSAGE_DELETION_DAYS_FAILURE = "GET_MESSAGE_DELETION_DAYS_FAILURE";

export const SET_MESSAGE_DELETION_DAYS = "SET_MESSAGE_DELETION_DAYS";
export const SET_MESSAGE_DELETION_DAYS_SUCCESS = "SET_MESSAGE_DELETION_DAYS_SUCCESS";
export const SET_MESSAGE_DELETION_DAYS_FAILURE = "SET_MESSAGE_DELETION_DAYS_FAILURE";

export const GET_DYNAMIC_FILTERS = "GET_DYNAMIC_FILTERS";
export const GET_DYNAMIC_FILTERS_SUCCESS = "GET_DYNAMIC_FILTERS_SUCCESS";
export const GET_DYNAMIC_FILTERS_FAILURE = "GET_DYNAMIC_FILTERS_FAILURE";

export const SAVE_DYNAMIC_FILTERS = "SAVE_DYNAMIC_FILTERS";
export const SAVE_DYNAMIC_FILTERS_SUCCESS = "SAVE_DYNAMIC_FILTERS_SUCCESS";
export const SAVE_DYNAMIC_FILTERS_FAILURE = "SAVE_DYNAMIC_FILTERS_FAILURE";

export const GET_MINIMUM_USER_COUNT = "GET_MINIMUM_USER_COUNT";
export const GET_MINIMUM_USER_COUNT_SUCCESS = "GET_MINIMUM_USER_COUNT_SUCCESS";
export const GET_MINIMUM_USER_COUNT_FAILURE = "GET_MINIMUM_USER_COUNT_FAILURE";

export const SET_MINIMUM_USER_COUNT = "SET_MINIMUM_USER_COUNT";
export const SET_MINIMUM_USER_COUNT_SUCCESS = "SET_MINIMUM_USER_COUNT_SUCCESS";
export const SET_MINIMUM_USER_COUNT_FAILURE = "SET_MINIMUM_USER_COUNT_FAILURE";

export const GET_INSIGHT_SCHEDULE = "GET_INSIGHT_SCHEDULE";
export const GET_INSIGHT_SCHEDULE_SUCCESS = "GET_INSIGHT_SCHEDULE_SUCCESS";
export const GET_INSIGHT_SCHEDULE_FAILURE = "GET_INSIGHT_SCHEDULE_FAILURE";

export const GET_FINANCIAL_YEAR = "GET_FINANCIAL_YEAR";
export const GET_FINANCIAL_YEAR_SUCCESS = "GET_FINANCIAL_YEAR_SUCCESS";
export const GET_FINANCIAL_YEAR_FAILURE = "GET_FINANCIAL_YEAR_FAILURE";


export const GET_MESSAGE_DELETION_INTERVAL = "GET_MESSAGE_DELETION_INTERVAL";
export const GET_MESSAGE_DELETION_INTERVAL_SUCCESS = "GET_MESSAGE_DELETION_INTERVAL_SUCCESS";
export const GET_MESSAGE_DELETION_INTERVAL_FAILURE = "GET_MESSAGE_DELETION_INTERVAL_FAILURE";

export const GET_HRIS_DATA_UPDATE_INTERVAL = "GET_HRIS_DATA_UPDATE_INTERVAL";
export const GET_HRIS_DATA_UPDATE_INTERVAL_SUCCESS = "GET_HRIS_DATA_UPDATE_INTERVAL_SUCCESS";
export const GET_HRIS_DATA_UPDATE_INTERVAL_FAILURE = "GET_HRIS_DATA_UPDATE_INTERVAL_FAILURE";

export const ADD_DEMO_FILTERS = "ADD_DEMO_FILTERS"
