import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Markdown from "react-markdown";
import axios from "axios";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import "./index.css";
import Chart from "react-apexcharts";
import io from "socket.io-client";
import moment from "moment";
const base_url = process.env.REACT_APP_BASE_URL;
const socket = io(base_url);

function AssistantBox({ page }) {
  const messages = [
    "Searching for results...",
    "Please wait while we fetch the results...",
    "Loading your results...",
    "Hold tight! We're finding the best match for you...",
    "Just a moment, we're working on it!",
    "Fetching results tailored to your search...",
  ];

  const base_url = process.env.REACT_APP_BASE_URL;


  const [searchText, setSearchText] = useState("");
  const [resultBoxOpen, setResultBoxOpen] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const [result, setResult] = useState("");
  const [chatID, setChatID] = useState(null);
  const [userID, setUserID] = useState("");

  const generateChatID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let chatID = "";
    for (let i = 0; i < 7; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      chatID += characters[randomIndex];
    }
    return chatID;
  };

  useEffect(() => {
    if (chatID == null) {
      setChatID(generateChatID());
    }
  }, [chatID]);

  useEffect(() => {
    socket.emit("join");
    socket.off("message");
    let userData = localStorage.getItem("userData");

    userData = JSON.parse(userData);
    userData && setUserID(userData["userId"]);
    !chatID && setChatID(generateChatID());
  }, []);

  useEffect(() => {
    socket.on("receiveMessage", (message) => {
      setResult(JSON.parse(message).message);
    });
    return () => {
      socket.off("receiveMessage");
    };
  }, []);

  const sendMessage = () => {
    setResultBoxOpen(true);
    if (searchText.trim()) {
      socket.emit("sendMessage", {
        userID: userID,
        chatID: chatID,
        message: searchText,
      });
    }
  };

  useEffect(() => {
    if (resultBoxOpen && currentMessageIndex < messages.length) {
      const timer = setTimeout(() => {
        setCurrentMessageIndex(currentMessageIndex + 1);
      }, 3000); // Delay before showing next message

      return () => clearTimeout(timer);
    }
  }, [currentMessageIndex, resultBoxOpen]);



  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      sendMessage();
    }
  };

  const extractGraphData = (dataString) => {
    // Match the `graphData` array using a regular expression
    const match = dataString.match(/graphData:\s*(\[[\s\S]*\])/);
    if (match) {
      // Clean up the JSON-like string for parsing
      const jsonString = match[1]
        .replace(/(\w+):/g, '"$1":') // Add quotes around keys
        .replace(/'/g, '"');        // Replace single quotes with double quotes
      return JSON.parse(jsonString); // Parse as JSON
    }
    return [];
  };

  const extractBeforeGraphData = (dataString) => {
    const splitIndex = dataString.indexOf("graphData");
    return splitIndex !== -1 ? dataString.slice(0, splitIndex).trim() : dataString.trim();
  };

  var options = {

    chart: {
      type: "area",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      zoom: {
        allowMouseWheelZoom: false,
      },
      events: {
        click: function (event, chartContext, config) {
          console.log(event, chartContext, config);
        },
      },

      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#0396FE"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: [3, 3],
      strokeWidth: 2,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: "#666",
      },
      labels: {
        style: {
          colors: ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && parseInt(value);
        },
      },
      title: {
        text: "Average Morale Score",
        style: {
          color: "#666",
          fontSize: "12px",
        },
      },
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
      dashArray: [0, 5],
    },
    grid: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];


        const template = `${moment(data.x).format("MM-DD-YYYY")}: ${parseInt(data.y)}`;
        return template;
      },
    },
  };

  const testData = [{
    name: "Average Morale Score",
    data: [
      {
        "x": "2024-07-01",
        "y": 57.33
      },
      {
        "x": "2024-07-08",
        "y": 67
      },
      {
        "x": "2024-07-15",
        "y": 45
      },
      {
        "x": "2024-07-22",
        "y": 51.88
      },
      {
        "x": "2024-07-29",
        "y": 59.47
      },
      {
        "x": "2024-08-05",
        "y": 55.96
      },
      {
        "x": "2024-08-12",
        "y": 67.08
      },
      {
        "x": "2024-08-19",
        "y": 55.2
      },
      {
        "x": "2024-08-26",
        "y": 62.93
      },
      {
        "x": "2024-09-02",
        "y": 58.5
      },
      {
        "x": "2024-09-09",
        "y": 56.16
      },
      {
        "x": "2024-09-16",
        "y": 60.13
      },
      {
        "x": "2024-09-23",
        "y": 53.76
      },
      {
        "x": "2024-09-30",
        "y": 56.99
      },
      {
        "x": "2024-10-07",
        "y": 57.97
      },
      {
        "x": "2024-10-14",
        "y": 66.94
      },
      {
        "x": "2024-10-28",
        "y": 62.15
      },
      {
        "x": "2024-11-04",
        "y": 43.75
      },
      {
        "x": "2024-11-11",
        "y": 54.75
      },
      {
        "x": "2024-11-18",
        "y": 51
      },
      {
        "x": "2024-11-25",
        "y": 68.11
      },
      {
        "x": "2024-12-02",
        "y": 57.3
      },
      {
        "x": "2024-12-09",
        "y": 55.74
      },
      {
        "x": "2024-12-16",
        "y": 51.36
      },
      {
        "x": "2024-12-23",
        "y": 58.38
      },
      {
        "x": "2024-12-30",
        "y": 51.18
      }
    ]
  }]
  return (
    <div
      style={
        page === "insight"
          ? { minHeight: "100px" }
          : {
            minHeight: "50px",
            position: "relative",
            top: "10px",
            left: "30px",
          }
      }
    >
      <div
        style={{
          padding: "0.5rem",
          borderRadius: "8px",
          position: "fixed",
          zIndex: 700,
          width: "100vw",
        }}
      >
        <div class="input-box">
          <i class="uil uil-search"></i>
          <input
            type="text"
            placeholder="Ask Me Anything.."
            value={searchText}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          {/* <button class="button" onClick={getResponse}>
            <AutoFixHighIcon style={{ fontSize: "1rem" }} /> AI Search
          </button> */}
          <button class="button" onClick={sendMessage}>
            <AutoFixHighIcon style={{ fontSize: "1rem" }} /> AI Search
          </button>
        </div>
        {resultBoxOpen && (
          <div class="ask-result-box">
            <CloseIcon
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "20px",
                top: "20px",
              }}
              onClick={() => {
                setResultBoxOpen(false);
              }}
            />
            {result === searchText ? (
              <div>
                <div className="placeholder">
                  {currentMessageIndex > 0 && messages[currentMessageIndex - 1]}
                </div>
                <div>
                  <Skeleton height={40} width={"50%"} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "450px",
                  overflowY: "scroll",
                  textAlign: "left",
                  // margin: "1rem 0",
                }}
              >

                <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Result</p>
                <Markdown>{extractBeforeGraphData(result)}</Markdown>
                {result.includes("graphData") && <Chart
                  options={options}
                  series={extractGraphData(result)}
                  type="area"
                  width="100%"
                  height="290px"
                />}
              </div>
            )}

          </div>
        )}
      </div>
    </div>
  );
}

export default AssistantBox;
