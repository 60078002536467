import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { toast } from "react-toastify";
import { Box } from "@material-ui/core";
import SlackLogin from "../../Logic/Configuration/SlackLogin";
import {
  GET_ACCESS_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  VERIFY_TOKEN,
  SAVE_WORKFLOW,
} from "../../../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0!important",
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function KeysModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const base_url =process.env.REACT_APP_BASE_URL;
  const slackKey = useSelector((data) => data.dashboard.slackKey);
  const accessToken = useSelector((data) => data.dashboard.accessToken);
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);
  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [workflowName, setWorkflowName] = useState("");
  const onFailed = () => {
    !isDemo &&
      dispatch({
        type: VERIFY_TOKEN,
        payload: { app: "slack", userID },
      });
  };

  useEffect(() => {
    if (slackKey?.data?.length > 0 && !accessTokenSave) {
      !isDemo &&
        dispatch({
          type: GET_ACCESS_TOKEN,
          payload: { code: slackKey.data[0].token },
        });
    } else {
      // toast.error("Authorization Failed ! Please oauth again.");
    }
  }, [slackKey]);

  useEffect(() => {
    if (accessToken.data && slackKey.data.length > 0 && !accessTokenSave) {
      !isDemo &&
        dispatch({
          type: SAVING_ACCESS_TOKEN,
          payload: {
            app: "Slack",
            token: accessToken?.data?.authed_user?.access_token,
            headerToken: userToken,
          },
        });
    } else {
      // toast.error("Error while saving the token");
    }
  }, [accessToken, uploadedChannels]);

  useEffect(() => {
    if (accessTokenSave) {
      !isDemo &&
        dispatch({
          type: GET_CHANNELS,
          payload: { key: accessToken?.data?.authed_user?.access_token },
        });
      toast.success("Channels fetched Successfully");
    }
  }, [accessTokenSave]);

  const onSuccess = () => {
    toast.success("Oauth Successfull. Channels successfully imported.");
  };

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const handleSaveWorkflow = () => {
    if (!isDemo) {
      const tempChannel = [];
      const work = [
        {
          id: "dndnode_0",
          type: "input",
          position: {
            x: 50.77777099609375,
            y: 186.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: "Slack",
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/slack.e45eba1a.svg) no-repeat center center",
            border: "3px solid #5FA7F5",
          },
        },
      ];
      uploadedChannels?.channels?.forEach((item, id) => {
        tempChannel.push({
          id: `dndnode_${id + 1}`,
          type: "default",
          position: {
            x: 265.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(https://chec-front.s3.amazonaws.com/icons/channel.svg) no-repeat center center",
            border: "3px solid #FFA300",
          },
        }); 
        tempChannel.push({
          source: "dndnode_0",
          sourceHandle: null,
          target: `dndnode_${id + 1}`,
          targetHandle: null,
          arrowHeadType: "arrowclosed",
          animated: true,
          style: {
            stroke: "red",
          },
          id: "reactflow__edge-dndnode_0null-dndnode_1null",
        });
      });
      work.push(...tempChannel);
      const Models = [
        "Emotional",
        "Personal Information",
        "Profanity",
        "Hate Speech",
        "Textual Abuse",
        "Insult Detection",
        "Sexual Harrasment",
      ];
      const tempModels = [];
      Models.forEach((item, id) => {
        tempModels.push({
          id: `dndnode_${tempChannel.length + id + 1}`,
          type: "default",
          position: {
            x: 510.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/intellectual.bf40f944.svg) no-repeat center center",
            border: "3px solid #4cda89",
          },
        });
      });
      work.push(...tempModels);
      work.push({
        id: `dndnode_${work.length + 1}`,
        type: "default",
        position: {
          x: 752.8959970605468,
          y: 180.0627866931152,
        },
        targetPosition: "left",
        sourcePosition: "right",
        data: {
          label: "Email",
        },
        style: {
          backgroundRepeat: "no-repeat",
          backgroundSize: "40% 40%",
          backgroundOrigin: "content-box",
          fontSize: "14px",
          color: "#aebcc9",
          fontWeight: "600",
          borderRadius: "8px ",
          background:
            "url(/static/media/email.ab571ebd.svg) no-repeat center center",
          border: "3px solid #FF0892",
        },
      });

      dispatch({
        type: SAVE_WORKFLOW,
        payload: {
          workflowID: userID + Math.round(new Date().getTime() / 1000),
          workflow: work,
          token: accessToken && accessToken?.data?.authed_user?.access_token,
          workflowName,
          userID,
          clientID: JSON.parse(localStorage.getItem("userData")).userId,
          model_config: {
            emotionData: [
              {
                id: "dndnode_5",
                data: {
                  emotionData: {
                    joy: 2,
                    sadness: 3,
                    love: 3,
                    anger: 2,
                    fear: 2,
                    surprise: 3,
                    neutral: 3,
                  },
                },
              },
            ],
            personalInfoData: [
              {
                id: "dndnode_6",
                data: {
                  personalInfoData: [
                    "cardDetails",
                    "DOB",
                    "drivingLicence",
                    "email",
                    "IPaddress",
                    "otp",
                    "MACaddress",
                    "passportNumber",
                    "phoneNumber",
                    "SSN",
                    "VIN",
                    "ZIP",
                    "userName",
                    "password",
                  ],
                },
              },
            ],
            profanityData: [
              {
                id: "dndnode_7",
                data: {
                  profanity: 3,
                },
              },
            ],
            hateSpeechData: [
              {
                id: "dndnode_8",
                data: {
                  hateSpeechData: {
                    any: null,
                  },
                },
              },
            ],
            scriptData: [],
            textAbuseData: [
              {
                id: "dndnode_9",
                data: {
                  textualAbuseData: {
                    positive: 1,
                    negative: 2,
                    neutral: 1,
                    toxic: 3,
                    severe_toxic: 1,
                    obscene: 2,
                    threat: 2,
                    insult: 0,
                    identity_hate: 4,
                  },
                },
              },
            ],
            sexualHarassmentData: [
              {
                id: "dndnode_11",
                data: {
                  SexualHarassmentData: {
                    any: null,
                  },
                },
              },
            ],
            RacismData: [],
            SentimentData: [],
            insultData: [
              {
                id: "dndnode_10",
                data: {
                  insultData: {
                    any: null,
                  },
                },
              },
            ],
            sexismData: [],
            diversityData: [],
          },
          action_config: {
            email: [
              {
                id: "dndnode_12",
                data: {
                  toEmail: "test@scanta.io",
                  emailSubject: "test",
                  emailBody: "test",
                },
              },
            ],
            sms: [],
            alert: [],
            notification: [],
          },
          workSpaceName: "Scanta.io",
        },
      });
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 300 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#000" }}
          >
            {title}
          </div>
          <form className={classes.container}>
            <Box component="div">
              {/* <div class="wrapper2"> */}
              <div class="search-input">
                <p
                  style={{
                    marginBottom: "0.5rem",
                    color: !isNight ? "#aebcc9" : "#000",
                  }}
                >
                  Workflow Name:
                </p>
                <input
                  type="text"
                  placeholder="Enter workflow name"
                  onChange={(e) => {
                    setWorkflowName(e.target.value);
                  }}
                  value={workflowName}
                />
              </div>
              {/* </div> */}
            </Box>
            <div style={{ marginBottom: 20, marginTop: 10 }}>
              <p
                style={{
                  marginBottom: "0.5rem",
                  color: !isNight ? "#aebcc9" : "#000",
                }}
              >
                Slack:
              </p>
              {!accessTokenSave ? (
                <SlackLogin
                  redirectUrl={`${base_url}/oauth`}
                  onFailure={onFailed}
                  onSuccess={onSuccess}
                  slackClientId="2415696530561.2965925345619"
                  slackUserScope="channels:read,channels:history,groups:history,mpim:history,im:history,users:read,users.profile:read,groups:read,im:read,mpim:read"
                  state={`slack-${userID}`}
                />
              ) : (
                <p
                  style={{
                    color: !isNight ? "#aebcc9" : "#000",
                    // border: "1px solid rgb(54, 41, 183)",
                  }}
                >
                  Connected
                </p>
              )}
            </div>
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                  <div
                    className="btnText"
                    style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
              <div className="submitBtn">
                <div className="rangeBtn" type="submit">
                  <CheckIcon />
                  <div className="btnText" onClick={handleSaveWorkflow}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
