import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import "./styles/hrTab.css";
import axios from "axios";
import moment from "moment";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Box, Typography, Tooltip } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ChatLoader from "./chatLoadAnimation";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PlansModal from "./popup/PlansModal";

const base_url =process.env.REACT_APP_BASE_URL;
const socket = io(base_url);

const HrTab = ({ selectedChatID, newChat }) => {
  const divRef = useRef(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatID, setChatID] = useState(null);
  const [userID, setUserID] = useState("");
  const [selectedObjectiveIndex, setSelectedObjectiveIndex] = useState(null);

  const [planModalOpen, setPlansModalOpen] = useState(false);

  const handleObjectiveClick = (index) => {
    // Toggle the selected objective index
    setSelectedObjectiveIndex(selectedObjectiveIndex === index ? null : index);
  };

  const generateChatID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let chatID = "";
    for (let i = 0; i < 7; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      chatID += characters[randomIndex];
    }
    return chatID;
  };

  useEffect(() => {
    if (chatID == null) {
      setChatID(generateChatID());
    }
  }, [chatID]);

  useEffect(() => {
    if (selectedChatID != null) {
      setChatID(selectedChatID);
      const callChatHistory = async () => {
        const response = await axios.post(
          `${base_url}/HRAssistant`,
          {
            chatID: selectedChatID,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "x-access-token",
              "x-access-token": localStorage.getItem("auth-token"),
            },
          }
        );
        setMessages(response.data);
      };
      callChatHistory();
    } else {
      setChatID(selectedChatID);
      setMessages([]);
    }
  }, [selectedChatID]);

  useEffect(() => {
    socket.emit("join");
    socket.off("message");
    let userData = localStorage.getItem("userData");

    userData = JSON.parse(userData);
    userData && setUserID(userData["userId"]);
    !chatID && setChatID(generateChatID());
  }, []);

  useEffect(() => {
    socket.on("receiveMessage", (message) => {
      setMessages((messages) => [...messages, JSON.parse(message)]);
    });
    return () => {
      socket.off("receiveMessage");
    };
  }, []);

  const sendMessage = () => {
    if (message.trim()) {
      socket.emit("sendMessage", {
        userID: userID,
        chatID: chatID,
        message,
      });
      setMessage("");
    }
  };

  const sendAutoMessage = (autoMessage) => {
    if (autoMessage.trim()) {
      socket.emit("sendMessage", {
        userID: userID,
        chatID: chatID,
        message: autoMessage,
      });
      setMessage("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      style={{
        background: "#fff",
        padding: "10px",
        borderRadius: "10px",
        margin: "0.5rem",
        maxWidth: "70vw",
      }}
    >
      <div style={styles.chatContainer}>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", color: "#484848" }}
        >
          Welcome to the HR Assistant
        </Typography>
        <div style={styles.messagesContainer} ref={divRef}>
          {messages.length > 0 ? (
            <>
              {messages.map((msg, index) => (
                <div
                  key={index}
                  // style={index % 2 === 0 ? styles.messageEven : styles.messageOdd}
                  style={
                    msg.user === "user" ? styles.messageEven : styles.messageOdd
                  }
                >
                  <ReactMarkdown>{msg.message}</ReactMarkdown>

                  {msg?.actionplan && msg?.actionplan?.length > 0 && (
                    <div>
                      <Button
                        style={{ marginBottom: "1rem" }}
                        onClick={() => {
                          setPlansModalOpen(true);
                        }}
                        variant="contained"
                      >
                        Create Action Plan
                      </Button>
                      <Modal
                        open={planModalOpen}
                        onClose={() => {
                          setPlansModalOpen(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <PlansModal
                          plans={msg?.actionplan}
                          handleClose={() => {
                            setPlansModalOpen(false);
                          }}
                        />
                      </Modal>
                    </div>
                  )}

                  <Box style={{ display: "flex", justifyContent: "end" }}>
                    {msg.user === "HR Assistant" && (
                      <Box style={{ margin: "0 1rem" }}>
                        <Stack direction="row" spacing={1}>
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                Copy to clipboard
                              </span>
                            }
                          >
                            <ContentCopyIcon
                              style={{
                                fontSize: "1rem",
                                color: "#484848",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigator.clipboard.writeText(msg.message)
                              }
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                Good Response
                              </span>
                            }
                          >
                            <ThumbUpOffAltIcon
                              style={{
                                fontSize: "1rem",
                                color: "#484848",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                Bad Response
                              </span>
                            }
                          >
                            <ThumbDownOffAltIcon
                              style={{
                                fontSize: "1rem",
                                color: "#484848",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </Stack>
                      </Box>
                    )}
                    <div
                      style={{
                        color: "#484848",
                        fontSize: "12px",
                        textAlign: "end",
                      }}
                    >
                      {moment(msg.SentTime).format("MMM D, YYYY, h:mm A")}
                    </div>
                  </Box>
                </div>
              ))}
              {messages[messages.length - 1]?.user === "user" && <ChatLoader />}{" "}
              {/* Show loader while waiting for new messages */}
            </>
          ) : newChat == false && chatID == null && messages.length == 0 ? (
            <>
              <div style={{ margin: "2rem" }}>
                <Stack spacing={1}>
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    height={"40px"}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"220px"}
                    style={{ position: "relative", top: "10px" }}
                  />
                </Stack>
              </div>
            </>
          ) : (
            <Box>
              <Box style={{ margin: "2rem 0" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  style={{ margin: "0.5rem 0" }}
                >
                  <Box
                    style={{
                      border: "1px solid #a2a2a2",
                      width: "180px",
                      height: "150px",
                      borderRadius: "12px",
                      padding: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <LightbulbIcon />
                    <Typography
                      onClick={() => {
                        setMessage(
                          "Get help with using TruPulse or ask questions about the Employee Experience?"
                        );
                        sendAutoMessage(
                          "Get help with using TruPulse or ask questions about the Employee Experience?"
                        );
                      }}
                    >
                      Get help with using TruPulse or ask questions about the
                      Employee Experience?
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      border: "1px solid #a2a2a2",
                      width: "180px",
                      height: "150px",
                      borderRadius: "12px",
                      padding: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <HelpOutlineIcon />

                    <Typography
                      onClick={() => {
                        setMessage("How do I run a survey in TruPulse?");
                        sendAutoMessage("How do I run a survey in TruPulse?");
                      }}
                    >
                      How do I run a survey in TruPulse?
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ margin: "0.5rem 0" }}
                  justifyContent="center"
                >
                  <Box
                    style={{
                      border: "1px solid #a2a2a2",
                      width: "180px",
                      height: "150px",
                      borderRadius: "12px",
                      padding: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMessage(
                        "What was morale like in the company this month?"
                      );
                      sendAutoMessage(
                        "What was morale like in the company this month?"
                      );
                    }}
                  >
                    <HelpOutlineIcon />
                    <Typography>
                      What was morale like in the company this month?
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      border: "1px solid #a2a2a2",
                      width: "180px",
                      height: "150px",
                      borderRadius: "12px",
                      padding: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <HelpOutlineIcon />
                    <Typography
                      onClick={() => {
                        setMessage(
                          "What was morale like in the company last month?"
                        );
                        sendAutoMessage(
                          "What was morale like in the company last month?"
                        );
                      }}
                    >
                      What was morale like in the company last month?
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          )}
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            style={styles.input}
          />
          <button onClick={sendMessage} style={styles.button}>
            <TelegramIcon style={{ marginRight: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  chatContainer: {
    padding: "20px 2rem 0px 2rem",
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
    height: "70vh",
    // width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "7px",
    // background: '#f0f0f0',
  },
  messagesContainer: {
    width: "100%",
    height: "80%",
    overflowY: "scroll",
    background: "#fff",
    backgroundColor: "#fff",
    padding: "0px 10px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #f0f0f0",
    display: "flex",
    flexDirection: "column",
    scrollbarWidth: "none", // Firefox
    "-ms-overflow-style": "none", // IE 10+
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },
  messageOdd: {
    background: "#bfe4f5",
    padding: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    textAlign: "start",
    maxWidth: "75%",
    alignSelf: "flex-start" /* Aligns odd messages to the left */,
    wordWrap: "break-word",
  },
  messageEven: {
    background: "#ffcdd2" /* More pronounced pink background */,
    padding: "10px",
    borderRadius: "5px",
    textAlign: "start",
    margin: "5px 0",
    maxWidth: "75%" /* Adjusts the maximum width of the message */,
    alignSelf: "flex-end" /* Aligns even messages to the right */,
    wordWrap: "break-word",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    padding: "0px 0px 10px 0px",
  },
  input: {
    flex: 1,
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginRight: "10px",
  },
  button: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    background: "#d06290",
    color: "#fff",
    cursor: "pointer",
  },
  chatWindow: {
    padding: "2px",
    background: "#fafafa",
  },
  messageCircle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#007bff",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    fontWeight: "bold",
  },
};

export default HrTab;
