/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, createRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import _ from "lodash";

const HateSpeechTrend = lazy(() => import("../HateSpeechTrend"));

import {
  demoData,
  generateHateSpeechData,
  generatePositiveCultureData,
  generateNegativeCultureData,
  generateInsultData,
  generateSexualData,
  generateRacismData,
} from "../demoData";

import SevereToxicMeter from "./SevereToxicMeter";
import SevereToxicTrend from "./SevereToxicTrend";
import ObsceneMeter from "./ObsceneMeter";
import ObsceneTrend from "./ObsceneTrend";
import ThreatMeter from "./ThreatMeter";
import ThreatTrend from "./ThreatTrend";
import IdentityHateMeter from "./IdentityHateMeter";
import IdentityHateTrend from "./IdentityHateTrend";
import PositiveMeter from "./Positive/PositiveMeter";
import PositiveTrend from "./Positive/PositiveTrend";
import NegativeMeter from "./Negative/NegativeMeter";
import NegativeTrend from "./Negative/NegativeTrend";
import SplitButton from "../../../common/SplitButton";
import HateSpeechCompare from "./Negative/HateSpeech/HateSpeechCompare";
import HateSpeechMeter from "./Negative/HateSpeech/HateSpeechMeter";
import NegativeCompare from "./Negative/NegativeCompare";
import InsultMeter from "./Negative/Insult/InsultMeter";
import InsultTrend from "./Negative/Insult/InsultTrend";
import InsultCompare from "./Negative/Insult/InsultCompare";
import SexualHarrasmentMeter from "./Negative/SexualHarassment/SexualHarrasmentMeter";
import SexualHarrasmentTrend from "./Negative/SexualHarassment/SexualHarrasmentTrend";
import SexualHarrasmentCompare from "./Negative/SexualHarassment/SexualHarrasmentCompare";
import RacismMeter from "./Negative/Racism/RacismMeter";
import RacismTrend from "./Negative/Racism/RacismTrend";
import RacismCompare from "./Negative/Racism/RacismCompare";
import ToxicMeter from "./Negative/Toxic/ToxicMeter";
import ToxicTrend from "./Negative/Toxic/ToxicTrend";
import ToxicCompare from "./Negative/Toxic/ToxicCompare";
import Meter from "./Positive/GratitudeAndAppreciation/Meter";
import GratitudeAndAppreciationMeter from "./Positive/GratitudeAndAppreciation/Meter";
import GratitudeAndAppreciationTrend from "./Positive/GratitudeAndAppreciation/Trend";
import GratitudeAndAppreciationCompare from "./Positive/GratitudeAndAppreciation/Comparison";
import SupportiveLanguageMeter from "./Positive/SupportiveLanguage/Meter";
import SupportiveLanguageTrend from "./Positive/SupportiveLanguage/Trend";
import SupportiveLanguageCompare from "./Positive/SupportiveLanguage/Comparison";
import ConstructiveFeedbackMeter from "./Positive/CreativityInnovation/Meter";
import ConstructiveFeedbackTrend from "./Positive/CreativityInnovation/Trend";
import ConstructiveFeedbackCompare from "./Positive/CreativityInnovation/Comparison";
import InclusiveLanguageMeter from "./Positive/InclusiveLanguage/Meter";
import InclusiveLanguageTrend from "./Positive/InclusiveLanguage/Trend";
import InclusiveLanguageCompare from "./Positive/InclusiveLanguage/Comparison";
import ProfessionalismMeter from "./Positive/Professionalism/Meter";
import ProfessionalismTrend from "./Positive/Professionalism/Trend";
import ProfessionalismCompare from "./Positive/Professionalism/Comparison";
import MentoringMentionsMeter from "./Positive/MentoringMentions/Meter";
import MentoringMentionsTrend from "./Positive/MentoringMentions/Trend";
import MentoringMentionsCompare from "./Positive/MentoringMentions/Comparison";
import WorkLifeBalanceMeter from "./Positive/WorkLifeBalance/Meter";
import WorkLifeBalanceTrend from "./Positive/WorkLifeBalance/Trend";
import WorkLifeBalanceCompare from "./Positive/WorkLifeBalance/Comparison";
import EmployeeRecognitionMeter from "./Positive/EmployeeRecognition/Meter";
import EmployeeRecognitionTrend from "./Positive/EmployeeRecognition/Trend";
import EmployeeRecognitionCompare from "./Positive/EmployeeRecognition/Comparison";
import EmpathyMeter from "./Positive/Empathy/Meter";
import EmpathyTrend from "./Positive/Empathy/Trend";
import EmpathyCompare from "./Positive/Empathy/Comparison";
import TransparencyMeter from "./Positive/Transparency/Meter";
import TransparencyTrend from "./Positive/Transparency/Trend";
import TransparencyCompare from "./Positive/Transparency/Comparison";
import AdaptabilityMeter from "./Positive/CelebratingDiversity/Meter";
import AdaptabilityTrend from "./Positive/CelebratingDiversity/Trend";
import AdaptabilityCompare from "./Positive/CelebratingDiversity/Comparison";
import CreativityInnovationMeter from "./Positive/CreativityInnovation/Meter";
import CreativityInnovationTrend from "./Positive/CreativityInnovation/Trend";
import CreativityInnovationCompare from "./Positive/CreativityInnovation/Comparison";
import PersonalDevelopmentMeter from "./Positive/PersonalDevelopment/Meter";
import PersonalDevelopmentTrend from "./Positive/PersonalDevelopment/Trend";
import PersonalDevelopmentCompare from "./Positive/PersonalDevelopment/Comparison";
import CommunityInvolvementMeter from "./Positive/CommunityInvolvement/Meter";
import CommunityInvolvementTrend from "./Positive/CommunityInvolvement/Trend";
import CommunityInvolvementCompare from "./Positive/CommunityInvolvement/Comparison";
import WellnessHealthMeter from "./Positive/WellnessHealth/Meter";
import WellnessHealthTrend from "./Positive/WellnessHealth/Trend";
import WellnessHealthCompare from "./Positive/WellnessHealth/Comparison";
import CelebratingDiversityMeter from "./Positive/CelebratingDiversity/Meter";
import CelebratingDiversityTrend from "./Positive/CelebratingDiversity/Trend";
import CelebratingDiversityCompare from "./Positive/CelebratingDiversity/Comparison";
import OpenCommunicationMeter from "./Positive/OpenCommunication/Meter";
import OpenCommunicationTrend from "./Positive/OpenCommunication/Trend";
import OpenCommunicationCompare from "./Positive/OpenCommunication/Comparison";
import TeamCelebrationsMeter from "./Positive/TeamCelebrations/Meter";
import TeamCelebrationsTrend from "./Positive/TeamCelebrations/Trend";
import TeamCelebrationsCompare from "./Positive/TeamCelebrations/Comparison";
import PositiveCompare from "./Positive/PositiveCompare";
import PositiveConfigModal from "./popup/PositiveConfigModal";
import NegativeConfigModal from "./popup/NegativeConfigModal";
import OverallCultureMeter from "./Overall/OverallCultureMeter";
import OverallCultureTrend from "./Overall/OverallCultureTrend";
import OverallCultureComparison from "./Overall/OverallCultureComparison";
import CollaborationMeter from "./Positive/Collaboration/Meter";
import CollaborationTrend from "./Positive/Collaboration/Trend";
import DemoOverallCultureComparison from "./Overall/DemoOverallCultureComparison";
import SubCultureTrend from "./SubCulture/Trend";
import SubCultureCompare from "./SubCulture/Comparison";
import SubCultureMeter from "./SubCulture/Meter";
import MoraleCompare from "../MoraleCompare";

const positiveOptions = [
  "Overall",
  "Adaptability",
  "Celebrating Diversity",
  "Collaboration",
  "Community Involvement",
  "Constructive Feedback",
  "Creativity & Innovation",
  "Empathy",
  "Employee Recognition",
  "Gratitude & Appreciation",
  "Hate Speech",
  "Identity Hate",
  "Inclusive Language",
  "Insulting Language",
  "Mentoring Mentions",
  "Obscene",
  "Open Communication",
  "Personal Development",
  "Professionalism",
  "Racism",
  "Severe Toxic",
  "Sexual Harassment",
  "Supportive Language",
  "Team Celebrations",
  "Threat",
  "Toxic",
  "Transparency",
  "Wellness & Health",
  "Work-life Balance",
];
const negativeOption = [
  "Overall",
  "Hate Speech",
  "Insult",
  "Sexual Harassment",
  "Racism",
  "Toxic",
  "Severe Toxic",
  "Obscene",
  "Threat",
  "Identity Hate",
];

function CulturalOverview({
  currentWorkflowID,
  currentFilter,
  moraleCompareOption,
  setMoraleCompareOption,
  customDate,
}) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [isZoom, setIsZoom] = useState(false);
  const [selectedPositive, setSelectedPositive] = useState(0);
  const [positiveConfigModal, setPositiveConfigModal] = useState(false);
  const [negativeConfigModal, setNegativeConfigModal] = useState(false);
  const [moraleLineToCompare, setMoraleLineToCompare] = useState([]);

  const moraleScore = isDemo ? demoData : demoData;

  // useEffect(() => {}, [selectedPositive]);

  const positiveCultureData = isDemo
    ? generatePositiveCultureData(currentFilter, customDate)
    : useSelector((data) => data.dashboard.positiveCultureData);

  const negativeCultureData = isDemo
    ? generateNegativeCultureData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.negativeCultureData);

  const hateSpeechData = isDemo
    ? generateHateSpeechData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.hateSpeechData);

  const insultData = isDemo
    ? generateInsultData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.insultData);

  const sexualHarassmentData = isDemo
    ? generateSexualData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.sexualHarassmentData);

  const racismData = isDemo
    ? generateRacismData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.racismData);

  return (
    <div id="capture-culture">
      <div style={{ margin: "0 " }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            margin: "0rem 0 1.5rem 0.8rem",
          }}
        >
          <SplitButton
            options={positiveOptions}
            handleChange={setSelectedPositive}
          />
          <SettingsIcon
            onClick={() => {
              setPositiveConfigModal(true);
            }}
            style={{
              color: "#727272",
              cursor: "pointer",
              zIndex: "400",
              position: "absolute",
              right: "65px",
              top: "25px",
            }}
          />
        </div>
        {selectedPositive === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Suspense fallback={<p>loading</p>}>
                <OverallCultureMeter
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Suspense fallback={<p>loading</p>}>
                <OverallCultureTrend
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  isZoom={isZoom}
                  currentFilter={currentFilter}
                  customDate={customDate}
                />
              </Suspense>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <Suspense fallback={<p>loading</p>}>
                {isDemo && (
                  <OverallCultureComparison
                    currentWorkflowID={currentWorkflowID}
                    currentFilter={currentFilter}
                    customDate={customDate}
                  />
                )}
              </Suspense>
            </Grid>
          </Grid>
        )}
        {selectedPositive !== 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Suspense fallback={<p>loading</p>}>
                <SubCultureMeter
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  subculture={positiveOptions[selectedPositive]}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Suspense fallback={<p>loading</p>}>
                <SubCultureTrend
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  isZoom={isZoom}
                  currentFilter={currentFilter}
                  customDate={customDate}
                  subculture={positiveOptions[selectedPositive]}
                />
              </Suspense>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <Suspense fallback={<p>loading</p>}>
                {isDemo && (
                  <SubCultureCompare
                    currentWorkflowID={currentWorkflowID}
                    currentFilter={currentFilter}
                    customDate={customDate}
                    subculture={positiveOptions[selectedPositive]}
                  />
                )}
              </Suspense>
            </Grid>
          </Grid>
        )}
      </div>

      <Modal
        open={positiveConfigModal}
        onClose={() => {
          setPositiveConfigModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PositiveConfigModal
          handleClose={() => {
            setPositiveConfigModal(false);
          }}
        />
      </Modal>
      <Modal
        open={negativeConfigModal}
        onClose={() => {
          setNegativeConfigModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NegativeConfigModal
          handleClose={() => {
            setNegativeConfigModal(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default CulturalOverview;
