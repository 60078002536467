import React, { useState, useEffect } from "react";
import HrTab from "./Chat/HrTab";
import CustomNav from "./Chat/CustomNav";
import "./Chat/styles/home.css";
import io from "socket.io-client";
import axios from "axios";
import { Box } from "@material-ui/core";
import Grid from "@mui/material/Grid";


const base_url =process.env.REACT_APP_BASE_URL;
const socket = io(base_url);

function AskSection() {
  const [chatlist, setChatlist] = useState([]);
  const [newChat, setNewChat] = useState(true);
  const [selectedChatID, setSelectedChatID] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await axios.get(`${base_url}/HRAssistant`,
          {
            headers: {
              "Access-Control-Allow-Headers": "x-access-token",
              "x-access-token": localStorage.getItem("auth-token"),
            },
          });

        setChatlist(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
  }, [chatlist]);

  const handleChatSelect = (chatID) => {
    setSelectedChatID(chatID);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={isNavOpen ? 3 : 0.7}>
              <CustomNav
                chatlist={chatlist}
                onSelectChat={handleChatSelect}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                setNewChat ={setNewChat}
              />
            </Grid>
            <Grid item xs={isNavOpen ? 9 : 11.3}>
              <HrTab selectedChatID={selectedChatID} newChat={newChat}/>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default AskSection;
