import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@material-ui/core";
import SaveIcon from "@mui/icons-material/Save";
import {
  GET_FAVORITE_FILTERS_SUCCESS,
  SAVE_FAVORITE_FILTERS,
} from "../../../redux/Dashboard/Dashboard.types";
const performance = [
  "Poor",
  "Unsatisfactory",
  "Satisfactory",
  "Very Satisfactory",
  "Outstanding",
];
export default function AppliedFilters() {
  const dispatch = useDispatch();
  const filters = useSelector((data) => data.dashboard.filters);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const favoriteFilters = useSelector((data) => data.dashboard.favoriteFilters);

  const handleFilterChange = (filter, option) => {
    // Ensure filters[filter] is an array
    let temp = Array.isArray(filters[filter]) ? [...filters[filter]] : [];

    if (temp.includes(option)) {
      // Remove the option if it exists
      temp = temp.filter((item) => item !== option);
    } else {
      // Add the option if it doesn't exist
      temp.push(option);
    }

    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, [filter]: temp },
    });
  };

  const handleGenderChange = (value) => {
    var temp = filters.gender;
    if (filters.gender.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, gender: temp },
    });
  };

  const handleChannelChange = (value) => {
    var temp = filters.channels;
    if (filters.channels.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, channels: temp },
    });
  };

  const handleLocationChange = (value) => {
    var temp = filters.location;
    if (filters.location.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, location: temp },
    });
  };

  const handleRegionChange = (value) => {
    var temp = filters.region;
    if (filters.region.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, region: temp },
    });
  };

  const handleDeptChange = (value) => {
    var temp = filters.department;
    if (filters.department.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, department: temp },
    });
  };

  const handleServiceLengthChange = (value) => {
    var temp = filters.length_of_service;
    if (filters.length_of_service.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, length_of_service: temp },
    });
  };

  const handleMgmtLevelChange = (value) => {
    var temp = filters.management_level;
    if (filters.management_level.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, management_level: temp },
    });
  };

  const handleRaceChange = (value) => {
    var temp = filters.ethnicity;
    if (filters.ethnicity.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, ethnicity: temp },
    });
  };

  const handleMaritalChange = (value) => {
    if (value !== filters.marital_status) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: value },
      });
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: "" },
      });
    }
  };

  const handleTopicChange = (value) => {
    if (value !== filters.keyTopic[0]) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [value] },
      });
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [""] },
      });
    }
  };
  const handleEngagementTopicChange = (value) => {
    if (value !== filters.engagementTopic[0]) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, engagementTopic: [value] },
      });
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, engagementTopic: [""] },
      });
    }
  };
  const handleTrendingTopicChange = (value) => {
    if (value !== filters.trendingTopic[0]) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, trendingTopic: [value] },
      });
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, trendingTopic: [""] },
      });
    }
  };

  const handleGeneralTopicChange = (value) => {
    if (value !== filters.generalTopic[0]) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, generalTopic: [value] },
      });
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, generalTopic: [""] },
      });
    }
  };

  const handleSalaryChange = (value) => {
    var temp = filters.income;
    if (filters.income.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, income: temp },
    });
  };

  const handleAgeChange = (value) => {
    var temp = filters.age;
    if (filters.age.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, age: temp },
    });
  };

  const handlePerformanceChange = (value) => {
    var temp = filters.performance;
    if (filters.performance.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, performance: temp },
    });
  };

  const saveFavorites = () => {
    if (isDemo) {
      dispatch({
        type: GET_FAVORITE_FILTERS_SUCCESS,
        payload: [...favoriteFilters, filters],
      });
    } else {
      dispatch({
        type: SAVE_FAVORITE_FILTERS,
        payload: { favoriteFilter: filters },
      });
    }
  };

  function areAllFieldsEmpty(data) {
    for (let key in data) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        return false; // The array is not empty
      } else if (typeof data[key] === "string" && data[key].trim() !== "") {
        return false; // The string is not empty
      } else if (
        data[key] !== null &&
        typeof data[key] === "object" &&
        Object.keys(data[key]).length > 0
      ) {
        return false; // The object is not empty
      }
    }
    return true; // All fields are empty
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        {/* {[...new Set(filters.channels)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Channels: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleChannelChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.gender)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Gender: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleGenderChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.location)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Location: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleLocationChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.region)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Region: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRegionChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.department)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Department: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDeptChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.length_of_service)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Experience: {item}Yrs
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleServiceLengthChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.management_level)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Management Level: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleMgmtLevelChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.ethnicity)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Race: {item}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRaceChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.income)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Salary: {item} USD
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleSalaryChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.age)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Age: {item}Yrs
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleAgeChange(item);
              }}
            />
          </div>
        ))}
        {[...new Set(filters.performance)].map((item) => (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Performance: {performance[item - 1]}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handlePerformanceChange(item);
              }}
            />
          </div>
        ))}
        {filters.marital_status !== "" && (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
              textTransform: "capitalize",
            }}
          >
            Marital Status: {filters.marital_status}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleMaritalChange(filters.marital_status);
              }}
            />
          </div>
        )} */}
        {/* {filters.keyTopic.length > 0 && filters.keyTopic[0].length > 0 && (
          <div
            style={{
              border: "1px solid #666",
              borderRadius: "4px",
              fontSize: "0.7rem",
              padding: "0.2rem",
              margin: "0 0.2rem",
            }}
          >
            {filters.keyTopic[0]}
            <CloseIcon
              style={{
                fontSize: "0.7rem",
                position: "relative",
                top: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleTopicChange(filters.keyTopic[0]);
              }}
            />
          </div>
        )} */}
        {/* {filters.engagementTopic.length > 0 &&
          filters.engagementTopic[0].length > 0 && (
            <div
              style={{
                border: "1px solid #666",
                borderRadius: "4px",
                fontSize: "0.7rem",
                padding: "0.2rem",
                margin: "0 0.2rem",
                textTransform: "capitalize",
              }}
            >
              Topic: {filters.engagementTopic[0]}
              <CloseIcon
                style={{
                  fontSize: "0.7rem",
                  position: "relative",
                  top: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEngagementTopicChange(filters.engagementTopic[0]);
                }}
              />
            </div>
          )}
        {filters.trendingTopic.length > 0 &&
          filters.trendingTopic[0].length > 0 && (
            <div
              style={{
                border: "1px solid #666",
                borderRadius: "4px",
                fontSize: "0.7rem",
                padding: "0.2rem",
                margin: "0 0.2rem",
                textTransform: "capitalize",
              }}
            >
              Topic: {filters.trendingTopic[0]}
              <CloseIcon
                style={{
                  fontSize: "0.7rem",
                  position: "relative",
                  top: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleTrendingTopicChange(filters.trendingTopic[0]);
                }}
              />
            </div>
          )}
        {filters.generalTopic.length > 0 &&
          filters.generalTopic[0].length > 0 && (
            <div
              style={{
                border: "1px solid #666",
                borderRadius: "4px",
                fontSize: "0.7rem",
                padding: "0.2rem",
                margin: "0 0.2rem",
                textTransform: "capitalize",
              }}
            >
              Topic: {filters.generalTopic[0]}
              <CloseIcon
                style={{
                  fontSize: "0.7rem",
                  position: "relative",
                  top: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleGeneralTopicChange(filters.generalTopic[0]);
                }}
              />
            </div>
          )} */}

        {Object.keys(filters).map(
          (filter) =>
            [...new Set(filters[filter])].length > 0 &&
            [...new Set(filters[filter])].map(
              (item) =>
                item !== "" && (
                  <div
                    style={{
                      border: "1px solid #666",
                      borderRadius: "4px",
                      fontSize: "0.7rem",
                      padding: "0.2rem",
                      margin: "0 0.2rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {`${filter}: ${item}`}
                    <CloseIcon
                      style={{
                        fontSize: "0.7rem",
                        position: "relative",
                        top: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilterChange(filter, item);
                      }}
                    />
                  </div>
                )
            )
        )}
        {!areAllFieldsEmpty(filters) && (
          <Tooltip title="Save as Favorites">
            <SaveIcon onClick={saveFavorites} />
          </Tooltip>
        )}
      </div>
    </>
  );
}
