import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import GroupsIcon from "@mui/icons-material/Groups";
import { useDispatch, useSelector } from "react-redux";
import { GET_MINIMUM_USER_COUNT } from "../../../redux/Dashboard/Dashboard.types";
import Modal from "@mui/material/Modal";
import MinUserCountModal from "./Configurations/MinUserCountModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsightScheduleModal from "./Configurations/InsightScheduleModal";
import moment from "moment";
import FinancialYearModal from "./Configurations/FinancialYearModal";
import MessageDeletionModal from "./Configurations/MessageDeletionModal";
import HrisDataUpdateModal from "./Configurations/HrisDataUpdateModal";
function Customization() {
  const dispatch = useDispatch();
  const minUserCount = useSelector((data) => data.dashboard.minUserCount);

  const insightSchedule = useSelector((data) => data.dashboard.insightSchedule);

  const financialYearStartDate = useSelector(
    (data) => data.dashboard.financialYearStartDate
  );
  const messageInterval = useSelector((data) => data.dashboard.messageInterval);

  const hrisUpdateInterval = useSelector(
    (data) => data.dashboard.hrisUpdateInterval
  );

  const [minUserModalOpen, setMinUserModalOpen] = useState(false);
  const [insightScheduleOpen, setInsightScheduleOpen] = useState(false);
  const [financialYearOpen, setFinancialYearOpen] = useState(false);
  const [messageDeletionOpen, setMessageDeletionOpen] = useState(false);
  const [hrisUpdateOpen, setHrisUpdateOpen] = useState(false);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  useEffect(() => {
    !isDemo && dispatch({ type: GET_MINIMUM_USER_COUNT, payload: {} });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div
            style={{
              color: "#999",
              cursor: "pointer",
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
              margin: "1rem",
              borderRadius: "12px",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
            }}
          >
            <GroupsIcon style={{ fontSize: "2rem", margin: "1rem 0" }} />
            <div>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                HRIS Data Update Interval
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                  textTransform: "capitalize",
                }}
              >
                {hrisUpdateInterval}
              </p>
            </div>
            <button
              onClick={() => {
                setHrisUpdateOpen(true);
              }}
              style={{
                padding: "0.5rem",
                fontSize: "1rem",
                borderRadius: "5px",
                border: "1px solid #D06283",
                color: "#fff",
                cursor: "pointer",
                background: "#D06283",
                filter: "opacity(0.8)",
                margin: " 1rem 0",
              }}
            >
              Change
            </button>
            <Modal
              open={hrisUpdateOpen}
              onClose={() => {
                setHrisUpdateOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <HrisDataUpdateModal
                handleClose={() => {
                  setHrisUpdateOpen(false);
                }}
              />
            </Modal>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              color: "#999",
              cursor: "pointer",
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
              margin: "1rem",
              borderRadius: "12px",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
            }}
          >
            <GroupsIcon style={{ fontSize: "2rem", margin: "1rem 0" }} />
            <div>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Minimum Group Size
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                {minUserCount > 0 ? minUserCount : ""}
              </p>
            </div>
            <button
              onClick={() => {
                setMinUserModalOpen(true);
              }}
              style={{
                padding: "0.5rem",
                fontSize: "1rem",
                borderRadius: "5px",
                border: "1px solid #D06283",
                color: "#fff",
                cursor: "pointer",
                background: "#D06283",
                filter: "opacity(0.8)",
                margin: " 1rem 0",
              }}
            >
              Change
            </button>
            <Modal
              open={minUserModalOpen}
              onClose={() => {
                setMinUserModalOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MinUserCountModal
                handleClose={() => {
                  setMinUserModalOpen(false);
                }}
              />
            </Modal>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              color: "#999",
              cursor: "pointer",
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
              margin: "1rem",
              borderRadius: "12px",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
            }}
          >
            <CalendarMonthIcon style={{ fontSize: "2rem", margin: "1rem 0" }} />
            <div>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Key Insights Schedule
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                  textTransform: "capitalize",
                }}
              >
                {insightSchedule}
              </p>
            </div>
            <button
              onClick={() => {
                setInsightScheduleOpen(true);
              }}
              style={{
                padding: "0.5rem",
                fontSize: "1rem",
                borderRadius: "5px",
                border: "1px solid #D06283",
                color: "#fff",
                cursor: "pointer",
                background: "#D06283",
                filter: "opacity(0.8)",
                margin: " 1rem 0",
              }}
            >
              Change
            </button>
            <Modal
              open={insightScheduleOpen}
              onClose={() => {
                setInsightScheduleOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <InsightScheduleModal
                handleClose={() => {
                  setInsightScheduleOpen(false);
                }}
              />
            </Modal>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              color: "#999",
              cursor: "pointer",
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
              margin: "1rem",
              borderRadius: "12px",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
            }}
          >
            <CalendarMonthIcon style={{ fontSize: "2rem", margin: "1rem 0" }} />
            <div>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Company Financial Year
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                  textTransform: "capitalize",
                }}
              >
                {moment(financialYearStartDate).format("MM-DD")}
              </p>
            </div>
            <button
              onClick={() => {
                setFinancialYearOpen(true);
              }}
              style={{
                padding: "0.5rem",
                fontSize: "1rem",
                borderRadius: "5px",
                border: "1px solid #D06283",
                color: "#fff",
                cursor: "pointer",
                background: "#D06283",
                filter: "opacity(0.8)",
                margin: " 1rem 0",
              }}
            >
              Change
            </button>
            <Modal
              open={financialYearOpen}
              onClose={() => {
                setFinancialYearOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <FinancialYearModal
                handleClose={() => {
                  setFinancialYearOpen(false);
                }}
              />
            </Modal>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              color: "#999",
              cursor: "pointer",
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
              margin: "1rem",
              borderRadius: "12px",
              boxShadow:
                "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
            }}
          >
            <CalendarMonthIcon style={{ fontSize: "2rem", margin: "1rem 0" }} />
            <div>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Source Message Deletion Interval
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                  textTransform: "capitalize",
                }}
              >
                {messageInterval > 0 ? `${messageInterval} Days` : ""}
              </p>
            </div>
            <button
              onClick={() => {
                setMessageDeletionOpen(true);
              }}
              style={{
                padding: "0.5rem",
                fontSize: "1rem",
                borderRadius: "5px",
                border: "1px solid #D06283",
                color: "#fff",
                cursor: "pointer",
                background: "#D06283",
                filter: "opacity(0.8)",
                margin: " 1rem 0",
              }}
            >
              Change
            </button>
            <Modal
              open={messageDeletionOpen}
              onClose={() => {
                setMessageDeletionOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MessageDeletionModal
                handleClose={() => {
                  setMessageDeletionOpen(false);
                }}
              />
            </Modal>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Customization;
