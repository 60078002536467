import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { demoSurveys, demoFocusGroup } from "../demoSurveys";
import { Link } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import PollIcon from "@mui/icons-material/Poll";
import GroupsIcon from "@mui/icons-material/Groups";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import SendIcon from "@mui/icons-material/Send";
function SidePanel({ setSelectedTab, selectedTab, setSeletedSurvey }) {
  const [open, setOpen] = useState(false);
  const [surveyOpen, setSurveyOpen] = useState(false);
  const [focusChatOpen, setFocusChatOpen] = useState(false);
  const [focusGroupOpen, setFocusGroupOpen] = useState(false);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const surveyQuestion = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);
  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f2f2f2",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    padding: "1rem",
    color: theme.palette.text.secondary,
  }));
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100px",
        backgroundColor: "#f2f2f2",
        paddingTop: "2rem",
      }}
    >
      <Item sx={{ fontSize: "1.2rem", fontWeight: "Bold", textAlign: "left" }}>
        Feedback
      </Item>
      {/* <Box>
        <Link
          to="/dashboard/feedback/survey"
          // to={`${window.location.origin}/dashboard/feedback/survey`}
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "survey"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`Surveys`}
          </Item>
        </Link>
        <Link
          to="/dashboard/feedback/result"
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "result"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`Survey Results`}
          </Item>
        </Link>
        <Link
          to="/dashboard/feedback/focus-group"
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "focusGroup"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`Focus Groups`}
          </Item>
        </Link>
        <Link
          to="/dashboard/feedback/focus-group-res/"
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "focusGroupRes"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`Focus Group Results`}
          </Item>
        </Link>
        <Link
          to="/dashboard/feedback/focus-chat"
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "focusChat"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`1:1 Interviews`}
          </Item>
        </Link>
        <Link
          to="/dashboard/feedback/focus-chat-res/"
          style={{ textDecoration: "none" }}
        >
          <Item
            sx={
              selectedTab === "focusChatRes"
                ? { background: "#D06283", color: "#fff" }
                : {}
            }
          >
            {`1:1 Interview Results`}
          </Item>
        </Link>
      </Box> */}
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {/* Focus Chat */}
        <ListItemButton
          onClick={() => {
            setFocusChatOpen(!focusChatOpen);
          }}
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="1:1 Discussions" />
          {focusChatOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={focusChatOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/dashboard/feedback/focus-chat"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}
                <ListItemText
                  primary="Available Discussions"
                  style={{ fontSize: "0.9rem", color: "#484848" }}
                />
              </ListItemButton>
            </Link>
            <Link
              to="/dashboard/feedback/focus-chat-res/"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}
                <ListItemText
                  primary="Results"
                  style={{ fontSize: "0.9rem", color: "#484848" }}
                />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        {/* Focus Group */}
        <ListItemButton
          onClick={() => {
            setFocusGroupOpen(!focusGroupOpen);
          }}
        >
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Focus Groups" />
          {focusGroupOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={focusGroupOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/dashboard/feedback/focus-group"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}
                <ListItemText
                  style={{ fontSize: "0.9rem", color: "#484848" }}
                  primary="Available Focus Groups"
                />
              </ListItemButton>
            </Link>
            <Link
              to="/dashboard/feedback/focus-group-res/"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}
                <ListItemText
                  primary="Results"
                  style={{ fontSize: "0.9rem", color: "#484848" }}
                />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        {/* Survey */}
        <ListItemButton
          onClick={() => {
            setSurveyOpen(!surveyOpen);
          }}
        >
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          <ListItemText primary="Surveys" />
          {surveyOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={surveyOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/dashboard/feedback/survey"
              // to={`${window.location.origin}/dashboard/feedback/survey`}
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}

                <ListItemText
                  primary="Available Surveys"
                  style={{
                    fontSize: "0.9rem",
                    color: "#484848",
                  }}
                />
              </ListItemButton>
            </Link>
            <Link
              to="/dashboard/feedback/result"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                <PollIcon />
              </ListItemIcon> */}

                <ListItemText
                  primary="Results"
                  style={{
                    fontSize: "0.9rem ",
                    color: "#484848",
                  }}
                />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>
    </Box>
  );
}

export default SidePanel;
