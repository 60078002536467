import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
// import DatePicker from 'react-datepicker';
import { Tooltip } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PeopleIcon from "@mui/icons-material/People";
import {
  CREATE_FOCUS_GROUP,
  GET_FILTER_EMAILS,
  GET_STATUS_BOX,
  GET_STATUS_BOX_SUCCESS,
  UPDATE_STATUS_BOX,
} from "../../../../redux/Dashboard/Dashboard.types";
import { demoSurveys } from "../../Feedback/demoSurveys";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateSurveyModal({ currentInsight, metric, handleClose }) {
  const dispatch = useDispatch();
  const base_url =process.env.REACT_APP_BASE_URL;
  const statusBoxItems = useSelector((data) => data.dashboard.statusBoxItems);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filterEmails = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.filterEmails);
  const [isAnnonymous, setIsAnnonymous] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [duration, setDuration] = useState(30);
  const [numOfEmp, setNumOfEmp] = useState(10);
  const [topics, setTopics] = useState([""]);

  const handleTopicChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index] = event.target.value;
    setTopics(newTopics);
  };

  const AddSuggestedTopic = (index, topic) => {
    const newTopics = [...topics];
    newTopics.push(topic);
    const set = new Set(newTopics.filter((item) => item.trim() !== ""));
    setTopics(set);
  };

  const addTopicField = () => {
    setTopics([...topics, ""]);
  };

  const removeTopicField = (index) => {
    const newTopics = topics.filter((_, i) => i !== index);
    setTopics(newTopics);
  };

  const handleSave = () => {
    // const endDate = new Date(startDate);
    // endDate.setMinutes(endDate.getMinutes() + duration);
    // const payload = {
    //   insightID: currentInsight.surveyID,
    //   statement: currentInsight.statement,
    //   topic: metric,
    //   targetGroup: currentInsight?.feature ? currentInsight.feature : "",
    //   startTime: new Date(startDate).toISOString(),
    //   endTime: endDate.toISOString(),
    //   anonymous: isAnnonymous,
    //   discussionTopics: [...topics],
    // };
    // dispatch({ type: CREATE_FOCUS_GROUP, payload: payload });
  };

  function transformObjectFormat(input) {
    const transformed = {};

    // Iterate over each key-value pair in the input object
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        // Wrap each value in an array
        transformed[key] = [input[key]];
      }
    }

    return transformed;
  }
  const [suggestedTopics, setSuggestedTopics] = useState([]);

  const suggestTopics = async () => {
    const res = await axios.post(
      `${base_url}/focusGroup/suggestTopics`,
      {
        topic: "",
        statement: "",
      }
    );
    if (res.status === 200) {
      setSuggestedTopics(res?.data?.discussionTopics);
      // setTopics(res?.data?.discussionTopics);
    }
  };

  useEffect(() => {
    const payload = currentInsight.feature
      ? transformObjectFormat(JSON.parse(currentInsight.feature))
      : {};

    dispatch({
      type: GET_FILTER_EMAILS,
      payload: payload,
    });
    suggestTopics();
  }, []);

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Create Survey for this Insight
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
          maxHeight: "70vh",
          overflowY: "scroll",
        }}
      >
        <Typography>Insight: {currentInsight.statement}</Typography>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Create
        </Button>
      </Stack>
    </Box>
  );
}

export default CreateSurveyModal;
