import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: base_url,
  // baseURL: "http://localhost:80/",
});

export const reqHeaders = {
  headers: {
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": localStorage.getItem("auth-token"),
  },
};

export default instance;
