import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import moralePlan from "./Recommendation/moralePlan.json";
import engagementPlan from "./Recommendation/engagementPlan.json";
import retentionPlan from "./Recommendation/retentionPlan.json";
import eNPSPlan from "./Recommendation/eNPSPlan.json";
import Button from "@mui/material/Button";
import topicPlan from "./Recommendation/topicPlan.json";
import culturePlan from "./Recommendation/culturePlan.json";
import { Card, CardContent, Grid, Paper, Container } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SaveActionPlanModal from "./Recommendation/popup/SaveActionPlanModal";
import Modal from "@mui/material/Modal";
import DeleteActionPlanModal from "./Recommendation/popup/DeleteActionPlanModal";
import EditActionPlanModal from "./Recommendation/popup/EditActionPlanModal";
import { useSelector } from "react-redux";
import { demoActionPlans } from "./Recommendation/demoActionPlans";
import { demoFocusGroup, demoSurveys } from "../Feedback/demoSurveys";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function formatQuery(query) {
  // Parse the JSON string into an object
  if (!query || query === "overall") {
    return "Overall";
  } else {
    let queryObject = typeof query === "string" ? JSON.parse(query) : query;

    // Map for comparison operators
    const comparisonMap = {
      $lte: "<=",
      $gte: ">=",
      $lt: "<",
      $gt: ">",
    };

    let result = [];

    // Loop through each key-value pair in the object
    for (let [key, value] of Object.entries(queryObject)) {
      // Capitalize the key (replace underscores with spaces and capitalize words)
      let formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      // Check if value is an object (for comparison operators like $lte, $gte, etc.)
      if (typeof value === "object" && value !== null) {
        for (let [operator, comparisonValue] of Object.entries(value)) {
          // Map the operator to its symbol or keep it as is
          let comparison = comparisonMap[operator] || operator;
          result.push(`${formattedKey}: ${comparison} ${comparisonValue}`);
        }
      } else {
        // For simple key-value pairs, just capitalize the value and append
        let formattedValue = value
          .toString()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        result.push(`${formattedKey}: ${formattedValue}`);
      }
    }

    // Join the results into a string
    return result.join(", ");
  }
}

export default function RecommendedPlanDetails({ planID }) {
  const [plans, setPlans] = useState([
    ...moralePlan.action_plans,
    ...engagementPlan.action_plans,
    ...retentionPlan.action_plans,
    ...eNPSPlan.action_plans,
    ...topicPlan.action_plans,
    ...culturePlan.action_plans,
  ]);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const [currentPlan, setCurrentPlan] = useState(
    actionPlans.length > 0
      ? actionPlans.filter((item) => item._id === planID)[0]
      : {}
  );
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  useEffect(() => {
    setCurrentPlan(actionPlans.filter((item) => item._id === planID)[0]);
  }, [actionPlans]);


  return (
    <Box
      style={{
        margin: "2rem",
        padding: "1rem 2rem",
        minHeight: "700px",
        background: "#fff",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Link
          to={`/dashboard/action-plan/recommendation`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button
            variant="outlined"
            style={{
              padding: "0.5rem 0",
              border: "2px solid #D06283",
              color: "#D06283",
              margin: "1rem 0",
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Link>
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          align="left"
          style={{ margin: "2rem 1rem 1rem 0", fontWeight: "bold" }}
          gutterBottom
        >
          {currentPlan?.objective}
        </Typography>
        <Button
          onClick={() => {
            setEditModalOpen(true);
          }}
          variant="contained"
          style={{
            background: "#D06283",
            color: "#fff",
            height: "40px",
            margin: "0 0.5rem",
          }}
        >
          <EditIcon />
          Edit
        </Button>
      </Box>

      <Typography
        style={{
          margin: "2rem 0 1rem",
          textAlign: "left",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Purpose:</span>{" "}
        {currentPlan?.reason}
      </Typography>

      <Typography style={{ textAlign: "left", margin: "0 " }} gutterBottom>
        <span style={{ fontWeight: "bold" }}>Source: </span>
        {!currentPlan?.type ? "Insight" : currentPlan?.type}
      </Typography>

      <Typography style={{ textAlign: "left", margin: "1rem 0 " }} gutterBottom>
        <span style={{ fontWeight: "bold" }}>Insight: </span>
        {currentPlan?.statement}
      </Typography>

      {surveyData.filter((item) => item.statement === currentPlan?.statement)
        .length > 0 && (
        <Typography
          style={{ textAlign: "left", margin: "1rem 0 " }}
          gutterBottom
        >
          <span style={{ fontWeight: "bold" }}>Survey: </span>
          {`${
            surveyData.filter(
              (item) => item.statement === currentPlan?.statement
            )[0]?.display?.name ||
            surveyData.filter(
              (item) => item.statement === currentPlan?.statement
            )[0].surveyName
          }`}
          <Link
            to={`/dashboard/feedback/survey/${
              surveyData.filter(
                (item) => item.statement === currentPlan?.statement
              )[0]._id
            }`}
            style={{ textDecoration: "none", color: "#484848" }}
            target="_blank"
          >
            <LinkIcon
              style={{ margin: "0 0.5rem", position: "relative", top: "5px" }}
            />
          </Link>
        </Typography>
      )}

      {currentPlan?.type === "Focus Group" && currentPlan?.focusGroupID && (
        <Typography
          style={{ textAlign: "left", margin: "1rem 0 " }}
          gutterBottom
        >
          <span style={{ fontWeight: "bold" }}>Focus Group: </span>
          {`${currentPlan?.section} Focus Group`}
          <Link
            to={`/dashboard/feedback/focus-group-res/${currentPlan?.focusGroupID}`}
            style={{ textDecoration: "none", color: "#484848" }}
            target="_blank"
          >
            <LinkIcon
              style={{ margin: "0 0.5rem", position: "relative", top: "5px" }}
            />
          </Link>
        </Typography>
      )}

      <Typography
        style={{
          textAlign: "left",
          margin: "1rem 0 1rem",
          textTransform: "capitalize",
        }}
        gutterBottom
      >
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Metric:{" "}
        </span>
        {currentPlan?.section}
      </Typography>

      <Typography
        style={{
          margin: "1rem 0 1rem",
          textAlign: "left",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Target Group:</span>{" "}
        {currentPlan?.targetGroup === ""
          ? "Overall"
          : formatQuery(currentPlan?.targetGroup)}
      </Typography>
      <Typography
        style={{
          margin: "2rem 0 0",
          textAlign: "left",
          fontSize: "1.3rem",
          fontWeight: "bold",
        }}
      >
        Steps
      </Typography>
      {
        <Grid container>
          {currentPlan?.steps?.map((step, index) => (
            <Grid item xs={12} key={index}>
              <Box style={{ margin: "1rem" }}>
                <Typography variant="h6" gutterBottom align="left">
                  <span style={{ fontWeight: "bold" }}>Action:</span>{" "}
                  {step?.action}
                </Typography>
                <Typography variant="body1" gutterBottom align="left">
                  <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                  {step?.description}
                </Typography>
                <Typography variant="body2" align="left">
                  <span style={{ fontWeight: "bold" }}>Implementation:</span>{" "}
                  {step?.implementation}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      }
      {currentPlan?.status === "recommended" && (
        <Box style={{ display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            variant="outlined"
            style={{
              border: "1px solid #D06283",
              color: "#484848",
              margin: "0 0.5rem",
            }}
          >
            <CloseIcon />
            Discard
          </Button>
          <Button
            onClick={() => {
              setSaveModalOpen(true);
            }}
            variant="contained"
            style={{
              background: "#D06283",
              color: "#fff",
              margin: "0 0.5rem",
            }}
          >
            <BookmarkIcon />
            Save
          </Button>
        </Box>
      )}
      <Modal
        open={saveModalOpen}
        onClose={() => {
          setSaveModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SaveActionPlanModal
          plan={currentPlan}
          handleClose={() => {
            setSaveModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteActionPlanModal
          plan={currentPlan}
          handleClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditActionPlanModal
          plan={currentPlan}
          handleClose={() => {
            setEditModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}
