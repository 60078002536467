import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import { Tooltip } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@material-ui/core";
import axios from "axios";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import {
  CREATE_CUSTOM_FOCUS_GROUP,
  CREATE_CUSTOM_SURVEY,
  GET_ALL_FOCUS_GROUPS,
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const metrics = [
  "Morale",
  "Text Engagement",
  "User Engagement",
  "Retention Risk",
  "Supporters",
  "Detractors",
  "Positive Sentiment",
  "Negative Sentiment",
];

const targetGroups = {
  gender: ["Male", "Female", "Non-binary", "Transgender"],
  department: [
    "Accounting and Finance",
    "Engineering",
    "Human Resource",
    "Information Technology",
    "Operations",
    "Research and Development",
    "Sales and Marketing",
  ],
  region: [
    "Asia Pacific",
    "Europe",
    "Middle East/Africa",
    "North America",
    "South America",
  ],
  location: ["Headquarters", "Regional Office", "Home office"],
  length_of_service: [
    "<1 year",
    "1-5 years",
    "5-9 years",
    "10+ years",
    "exited employee",
  ],
  management_level: [
    "Executive",
    "SVP",
    "VP",
    "Director",
    "Manager",
    "Individual Contributor",
  ],
  ethnicity: [
    "African American",
    "Asian",
    "Hispanic",
    "Native American or Alaskan Native",
    "Native Hawaiian or Pacific Islander",
    "white",
    "Others",
  ],
  marital_status: ["Married", "Single"],
  Salary_level: [
    "<$50,000",
    "$50,001-$100,000",
    "$100,001-$250,000",
    "$250,001",
  ],
  age: ["<25", "26-35", "36-49", "50+"],
  performance: [
    "Outstanding",
    "Very Satisfactory",
    "Satisfactory",
    "Unsatisfactory",
    "Poor",
  ],
};

function CustomFocusGroupModal({ handleClose }) {

  const base_url =process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  function transformEmails(emailList) {
    const transformedEmails = emailList.map((email) => {
      const [username] = email.split("@");
      return {
        name: username,
        email: email,
      };
    });

    return transformedEmails;
  }

  const createFocusGroup = () => {
    let data = {
      topic: name,
      description: desc,
      emails: transformEmails(emails),
      startTime: startTime,
      endTime: endTime,
    };

    dispatch({
      type: CREATE_CUSTOM_FOCUS_GROUP,
      payload: data,
    });
    setTimeout(() => {
      dispatch({
        type: GET_ALL_FOCUS_GROUPS,
      });
      handleClose();
    }, 2000);
  };

  const [filter1, setFilter1] = useState("gender");
  const [option1, setOption1] = useState("");
  const [filter2, setFilter2] = useState("gender");
  const [option2, setOption2] = useState("");
  const [allChecked, setAllChecked] = useState("");
  const [mulipleInput, setMultipleInputs] = useState([0]);

  const [topics, setTopics] = useState([""]);
  const [startDate, setStartDate] = useState(new Date());
  const [duration, setDuration] = useState(30);

  const handleTopicChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index] = event.target.value;
    setTopics(newTopics);
  };

  const addTopicField = () => {
    setTopics([...topics, ""]);
  };

  const removeTopicField = (index) => {
    const newTopics = topics.filter((_, i) => i !== index);
    setTopics(newTopics);
  };

  const changeFilter1 = (event) => {
    setFilter1(event.target.value);
  };
  const changeOption1 = (event) => {
    setOption1(event.target.value);
  };

  const changeFilter2 = (event) => {
    setFilter2(event.target.value);
  };
  const changeOption2 = (event) => {
    setOption2(event.target.value);
  };

  function removeUnderscoreAndCapitalize(str) {
    return str
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back together with spaces
  }

  const AddSuggestedTopic = (index, topic) => {
    const newTopics = [...topics];
    newTopics.push(topic);
    const set = new Set(newTopics.filter((item) => item.trim() !== ""));
    setTopics(set);
  };

  const [suggestedTopics, setSuggestedTopics] = useState([]);

  const suggestTopics = async () => {
    const res = await axios.post(
      `${base_url}/focusGroup/suggestTopics`,
      {
        topic: name,
        statement: desc,
      }
    );
    if (res.status === 200) {
      setSuggestedTopics(res?.data?.discussionTopics);
      // setTopics(res?.data?.discussionTopics);
    }
  };

  useEffect(() => {
    suggestTopics();
  }, [name, desc]);

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Create Your Own Focus Group
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        ></Box>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Typography sx={{ margin: "1rem 0" }}>Title</Typography>
          <Input
            type="text"
            placeholder=""
            sx={{ width: "100%" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Typography sx={{ margin: "1rem 0" }}>Description</Typography>
          <Input
            type="text"
            placeholder=""
            sx={{ width: "100%" }}
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography style={{ margin: "1rem 0 0" }}>Add Topics</Typography>
            <Typography style={{ margin: "1rem 0 0" }}>
              Click a Topic to Add it or Write in Your Own
            </Typography>
            <Grid container spacing={2}>
              {suggestedTopics.map((item) => (
                <Grid item>
                  {" "}
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        Add this topic
                      </span>
                    }
                  >
                    <Typography
                      style={{
                        background: "#f2f2f2",
                        borderRadius: "12px",
                        padding: "0 0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        AddSuggestedTopic(topics.length - 1, item);
                      }}
                    >
                      {item}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
            {topics.map((topic, index) => (
              <Stack
                direction="row"
                spacing={2}
                key={index}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  id={`topic-text-${index}`}
                  label={`Topic ${index + 1}`}
                  variant="outlined"
                  value={topic}
                  onChange={(event) => handleTopicChange(index, event)}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => removeTopicField(index)}
                >
                  Remove
                </Button>
              </Stack>
            ))}

            <Typography variant="outlined" onClick={addTopicField}>
              <AddIcon style={{ position: "relative", top: "5px" }} /> Add
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Typography>Target Group</Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={allChecked}
                onChange={(e) => {
                  setAllChecked(e.target.checked);
                }}
              />
            }
            label="All"
          />
          {!allChecked && (
            <Box>
              <Stack direction="row">
                <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                <Typography>Choose Filter</Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter1}
                    onChange={changeFilter1}
                  >
                    {Object.keys(targetGroups).map((item) => (
                      <MenuItem value={item}>
                        {removeUnderscoreAndCapitalize(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                <Typography>Choose Option</Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={option1}
                    onChange={changeOption1}
                  >
                    {targetGroups[filter1].map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {mulipleInput.length > 1 && (
                <Stack direction="row">
                  <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                  <Typography>Choose Filter</Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filter2}
                      onChange={changeFilter2}
                    >
                      {Object.keys(targetGroups).map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                  <Typography>Choose Option</Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={option2}
                      onChange={changeOption2}
                    >
                      {targetGroups[filter2].map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <CloseIcon
                    onClick={() => {
                      setMultipleInputs([0]);
                    }}
                    sx={{ position: "relative", top: "30px", color: "#727272" }}
                  />
                </Stack>
              )}
              {mulipleInput.length < 2 && (
                <Typography
                  onClick={() => {
                    const temp = mulipleInput;
                    setMultipleInputs([...temp, temp.length]);
                  }}
                >
                  +Add more
                </Typography>
              )}
            </Box>
          )}
          {/* <ReactMultiEmail
          placeholder="Enter guests email"
          emails={emails}
          onChange={(_emails) => {
            setEmails(_emails);
          }}
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid #484848",
            boxShadow: "none",
          }}
          autoFocus={true}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        /> */}
        </Box>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography style={{ margin: "1rem 0 0" }}>
              Start Date and Time
            </Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                id="date"
                type="date"
                variant="outlined"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) =>
                  setStartDate(new Date(e.target.value + "T" + startTime))
                }
              />
              <TextField
                fullWidth
                id="time"
                type="time"
                variant="outlined"
                inputProps={{
                  step: 900, // 15 minutes = 900 seconds
                }}
                value={startDate.toTimeString().substring(0, 5)}
                onChange={(e) =>
                  setStartDate(
                    new Date(
                      startDate.toISOString().split("T")[0] +
                        "T" +
                        e.target.value
                    )
                  )
                }
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography style={{ margin: "1rem 0 0" }}>Duration</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={duration}
                label="Duration"
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              >
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={60}>1 Hour</MenuItem>
                <MenuItem value={120}>2 Hours</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={createFocusGroup}
            style={{
              background: "#D06283",
              color: "#fff",
            }}
            variant="contained"
          >
            Create
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default CustomFocusGroupModal;
