import React, { useState, useEffect, createRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import SettingsIcon from "@mui/icons-material/Settings";
import { base64StringToBlob } from "blob-util";
import { Box, FormControl, MenuItem, Select, Tooltip } from "@material-ui/core";
import OverallENPSScore from "./OverallENPSScore";
import OverallENPSDist from "./OverallENPSDist";
import OverallENPSTrend from "./OverallENPSTrend";
import TopicENPSScore from "./TopicENPSScore";
import TopicENPSDist from "./TopicENPSDist";
import TopicENPSTrend from "./TopicENPSTrend";
import SurveyENPSScore from "./SurveyENPSScore";
import SurveyENPSDist from "./SurveyENPSDist";
import SurveyENPSTrend from "./SurveyENPSTrend";
import ConfigModal from "./popup/ConfigModal";
import SplitButton from "../../../common/SplitButton";
import OverallENPSCompare from "./OverallENPSCompare";
import TopicENPSCompare from "./TopicENPSCompare";
import SurveyENPSCompare from "./SurveyENPSCompare";
import { generateMoraleScore } from "../demoData";
import MoraleCompare from "../MoraleCompare";

function SupporterDetractor({
  currentFilter,
  customDate,
  currentWorkflowID,
  supporterData,
}) {
  const dispatch = useDispatch();
  const options = ["Overall", "Topic", "Survey"];
  const [clipProcessing, setClipProcessing] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const stateMoraleScore = useSelector((state) => state.dashboard.moraleScore);
  const moraleScore = useMemo(() => {
    return isDemo
      ? generateMoraleScore(currentFilter, customDate)
      : stateMoraleScore;
  }, [isDemo, currentFilter, customDate, stateMoraleScore]);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const sentScoreRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(sentScoreRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);
  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const [selectedNPSType, setSelectedNPSType] = useState("overall");
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const changeNPSType = (e) => {
    setSelectedNPSType(e.target.value);
  };

  return (
    <div style={{ margin: "0 0 1.5rem" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          style={{
            margin: "0 0 1.5rem 0",
            width: "120px",
            height: "20px",
            right: "10px",
          }}
        >
          <SettingsIcon
            onClick={() => {
              setConfigModalOpen(true);
            }}
            style={{
              color: "#727272",
              cursor: "pointer",
              zIndex: "400",
              position: "absolute",
              right: "65px",
              top: "25px",
            }}
          />{" "}
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "start",
        }}
      >
        <div style={{ margin: "1rem", position: "absolute", top: "50px" }}>
          <SplitButton options={options} handleChange={handleOptionChange} />
        </div>
      </Box>
      {selectedOption === 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <OverallENPSScore
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <OverallENPSDist
              currentFilter={currentFilter}
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              customDate={customDate}
            />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <OverallENPSTrend
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <OverallENPSCompare
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
        </Grid>
      ) : selectedOption === 1 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <TopicENPSScore
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <TopicENPSDist
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <TopicENPSTrend
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <TopicENPSCompare
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <SurveyENPSScore
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <SurveyENPSDist
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <SurveyENPSTrend
              selectedNPSType={selectedNPSType}
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>{" "}
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <SurveyENPSCompare
              currentWorkflowID={currentWorkflowID}
              currentFilter={currentFilter}
              customDate={customDate}
            />
          </Grid>
        </Grid>
      )}
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfigModal
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default SupporterDetractor;
