import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ICONS } from "../../../../AppConfig";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";
import _ from "lodash";

export const Card = ({
  id,
  text,
  index,
  moveCard,
  deleteTopic,
  selected,
  workflowChangeHandler,
}) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const style = {
    // borderBottom: '1px dashed gray',
    width: "100%",
    padding: " 0",
    marginBottom: ".5rem",
    backgroundColor: !isNight ? "#212047" : "white",
    cursor: "move",
    display: "flex",
    color: !isNight ? "#aebcc9" : "#666",
    justifyContent: "space-between",
    borderRadius: "5px",
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector((data) => data.dashboard.filters);
  const level = useSelector((data) => data.dashboard.level);
  const department = useSelector((data) => data.dashboard.department);
  const currentSource = useSelector((data) => data.dashboard.currentSource);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filterChannels = useSelector((data) => data.dashboard.filterChannels);
  const [maritalChecked, setMaritalChecked] = useState("");
  const filterMapping = useSelector((data) => data.dashboard.filterMapping);


  const handleFilterChange = (filter, option) => {
    // Ensure filters[filter] is an array
    let temp = Array.isArray(filters[filter]) ? [...filters[filter]] : [];

    if (temp.includes(option)) {
      // Remove the option if it exists
      temp = temp.filter((item) => item !== option);
    } else {
      // Add the option if it doesn't exist
      temp.push(option);
    }

    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, [filter]: temp },
    });
  };




  const handleGenderChange = (value) => {
    var temp = filters.gender;
    if (filters.gender.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, gender: temp },
    });
  };

  const handleMaritalChange = (value) => {
    if (value !== filters.marital_status) {
      setMaritalChecked(value);
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: value },
      });
    } else {
      setMaritalChecked("");
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: "" },
      });
    }
  };

  const handleChannelChange = (value) => {
    // zoom-meeting
    if (value === "zoom-meeting") {
      workflowChangeHandler("overall-zoom");
    } else if (value === "glassdoor") {
      history.push("/dashboard/glassdoor");
      window.location.reload();
    }
    var temp = _.union(filters.channels);
    const adding = !filters.channels.includes(value);
    if (adding) {
      temp.push(value);
    } else {
      _.remove(temp, (x) => x === value);
    }

    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, channels: temp },
    });
  };

  const handleDeptChange = (value) => {
    var temp = filters.department;
    if (filters.department.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, department: temp },
    });
  };

  const handleRegionChange = (value) => {
    var temp = filters.region;
    if (filters.region.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, region: temp },
    });
  };

  const handleLocationChange = (value) => {
    var temp = filters.location;
    if (filters.location.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, location: temp },
    });
  };

  const handleServiceLengthChange = (value) => {
    var temp = filters.length_of_service;
    if (filters.length_of_service.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, length_of_service: temp },
    });
  };

  const handleMgmtLevelChange = (value) => {
    var temp = filters.management_level;
    if (filters.management_level.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, management_level: temp },
    });
  };

  const handleSalaryChange = (value) => {
    var temp = filters.income;
    if (filters.income.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, income: temp },
    });
  };

  const handleAgeChange = (value) => {
    var temp = filters.age;
    if (filters.age.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, age: temp },
    });
  };
  const handlePerformanceChange = (value) => {
    var temp = filters.performance;
    if (filters.performance.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, performance: temp },
    });
  };

  const handleRaceChange = (value) => {
    var temp = filters.ethnicity;
    if (filters.ethnicity.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, ethnicity: temp },
    });
  };

  const handleLocChange = (value) => {
    dispatch({ type: "SET_FILTERS", payload: { ...filters, location: value } });
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const checkVisibility = (level, department) => {
    if (level === "admin") {
      return "all";
    } else if (level === "employee") {
      return "none";
    } else if (level === "manager") {
      return isDemo ? "Accounting & Finance" : department;
    }
  };
  function removeUnderscoreAndCapitalize(str) {
    return str
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back together with spaces
  }
  return (
    <>
      <div
        ref={ref}
        style={{
          ...style,
          opacity,
          backgroundColor:
            filters.keyTopic[0] === text
              ? "#D06283"
              : !isNight
                ? "#212047"
                : "#f6f7fc",
        }}
        data-handler-id={handlerId}
      >
        {level !== "employee" && (
          <div style={{ width: "100%" }}>
            {text === "experience" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Length of Service</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("0-1")}
                            onClick={() => {
                              handleServiceLengthChange("0-1");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"<1 Year"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("1-5")}
                            onClick={() => {
                              handleServiceLengthChange("1-5");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            1 Year - 5 Years
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("5-9")}
                            onClick={() => {
                              handleServiceLengthChange("5-9");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            5 Years - 9 Years
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("10+")}
                            onClick={() => {
                              handleServiceLengthChange("10+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            10 Years +
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes(
                              "exited"
                            )}
                            onClick={() => {
                              handleServiceLengthChange("exited");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Exited Employees
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "income" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Salary Level</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("0-50000")}
                            onClick={() => {
                              handleSalaryChange("0-50000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"<$50,000"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("50001-100000")}
                            onClick={() => {
                              handleSalaryChange("50001-100000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $50,001 - $100,000
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("100001-250000")}
                            onClick={() => {
                              handleSalaryChange("100001-250000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $100,001 - $250,000
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("250,001+")}
                            onClick={() => {
                              handleSalaryChange("250,001+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $250,001 +
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "age" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Age</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("0-25")}
                            onClick={() => {
                              handleAgeChange("0-25");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"< 25"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("26-35")}
                            onClick={() => {
                              handleAgeChange("26-35");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            26 - 35
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("36-49")}
                            onClick={() => {
                              handleAgeChange("36-49");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            36 - 49
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("50+")}
                            onClick={() => {
                              handleAgeChange("50+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            50+
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    padding: "0 0.5rem",
                    textAlign: "left",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>{removeUnderscoreAndCapitalize(text)}</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      {!_.isEmpty(filterMapping) && filterMapping[text] && Object.keys(filterMapping[text])?.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={() => {
                                handleFilterChange(text, item);
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >{item}
                            </p>
                          }
                        />))}

                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        )}
      </div>
    </>
  );
};
