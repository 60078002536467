import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { GET_MINIMUM_USER_COUNT_SUCCESS, SET_MINIMUM_USER_COUNT } from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function MinUserCountModal({ plan, handleClose }) {
  const dispatch = useDispatch();
  const minUserCount = useSelector((data) => data.dashboard.minUserCount);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [minUsers, setMinUsers] = useState(minUserCount);
  const handleSave = () => {
    if (isDemo) {
      dispatch({
        type: GET_MINIMUM_USER_COUNT_SUCCESS,
        payload: minUsers,
      });
    }else{
        dispatch({
            type: SET_MINIMUM_USER_COUNT,
            payload: {
              MINIMUM_USER_COUNT: minUsers,
            },
          });
    }
    handleClose();
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Set Minimum Group Size
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <hr />
      <Typography style={{ margin: "1rem 0", fontSize: "1rem" }}>
        To protect individual privacy, only groups with a minimum sample size
        will be displayed. Increase the threshold to further safeguard data.
        Groups smaller than the set size will not appear in reports or
        visualizations.
      </Typography>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <TextField
          fullWidth
          id="question-text"
          type="number"
          variant="outlined"
          value={minUsers}
          onChange={(e) => setMinUsers(e.target.value)}
        />
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default MinUserCountModal;
